import React, { useState, useEffect, useContext } from 'react';
import { useAsync, useGetState, AccountContext, CentreKeyContext, ContestForceRenderContext, HistoryContext, CentreForceRenderContext } from '../../hooks';
import { NavLink } from 'react-router-dom';
import axios from 'axios';

import Btn from '../controls/Btn';
import ProfilePics from '../user/ProfilePics';
import Username from '../user/Username';
import A from '../controls/A';

export default function MessagesPanel({}) {
    let { navigate } = useContext(HistoryContext);
    let centreKey = useContext(CentreKeyContext);
    let { user } = useContext(AccountContext);
    let [loading, setLoading] = useState(false);
    let [chats, setChats] = useState([]);
    let [count, setCount] = useState(0);
    let [hovering, setHovering] = useState(false);
    let [url, setUrl] = useState();
    let [centreForce, centreForceRender] = useContext(CentreForceRenderContext);
    
    useAsync(async () => {
        let { data } = await axios.post('/api/chats',{user});
        let { chats, count } = data;
        setChats(chats);
        setCount(count);
    }, [])

    useEffect(() => {
        setUrl(centreKey.split('$')[1]);
    }, [centreKey])
    
    useEffect(() => {
        centreForceRender();
    },[chats])

    return chats.length > 0 ? <div className="MessagesPanel Panel" id={"MessagesPanel"} onMouseEnter={()=>setHovering(true)} onMouseLeave={()=>setHovering(false)}><div className="messagesPanel">
        {chats.map(chat => <Btn onClick={() => navigate(chat.url)} colour="" key={chat._id}><div className={`ChatItem fadein centreFlex ${url==chat.url || hovering ? '' : "faded"}`}>
            <div className="pictures">
                <ProfilePics width={64} usernames={chat.users.filter(u=>u!=user.username)}/>
            </div>
            <div className="usernames">
                {chat.name ? chat.name : chat.users.filter(u => u != user.username).map((u, i) => <Username key={i} username={u} unClickable />)}
            </div>
        </div></Btn>)}
        <Btn colour="" className={`loadMore fader ${hovering && chats.length > 0 && count > chats.length ? '' : 'hidden'}`} onClick={async () => {
            setLoading(true);
            let { data } = await axios.post('/api/chats', { user, stamp: chats[chats.length - 1].last });
            setChats([...chats, ...data.chats]);
            setCount(data.count);
            setLoading(false);
        }} loading={loading}>Load more</Btn>
    </div></div>: null
}