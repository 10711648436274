import React, {useState, useContext} from 'react';
import { ToastContext, AccountContext, useTimeout } from '../../hooks';
import Button from '../controls/Button';
import ModalHeading from '../modal/ModalHeading';
import Toast from '../modal/Toast';
import axios from 'axios';


export default function VerificationMessage({ close, feature }) {
    let Toasts = useContext(ToastContext);
    let { user } = useContext(AccountContext);
    let { doTimeout } = useTimeout();
    let [loading, setLoading] = useState(false);
    let [error, setError] = useState(false);
    return <div className="modal">
        <ModalHeading title={"Verify your account"} close={close} />
        <div className="body">
            {feature?<p>You need to verify your account to use that feature.</p>:null}
            <p>You have been sent a verification email - check your spam folder!</p>
            <p>If the email is indeed marked as spam please mark it as safe, as it is how you will be contacted in future.</p>
            <div className="formButtons">
                <div className="buttons">
                    <Button onClick={close}>OK</Button>
                    <Button
                        loading={loading}
                        onClick={async () => {
                            setLoading(true);
                            let {data} = await axios.post('/api/verify-email', { user });
                            if (data) {
                                await new Promise(resolve => doTimeout(resolve, 2, 's'));
                                Toasts.create(Toast, { text: 'Email sent!' });
                            } else {
                                setError(true);
                                Toasts.create(Toast, { text: 'Server error! Maybe try again later.', type: 'error' });
                            }
                            setLoading(false);
                        }
                    }>Resend email</Button>
                </div>
                <div className="error">
                    {error? 'Server error!' : null}
                </div>
            </div>
        </div>
    </div>
}
