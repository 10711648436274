import React, { useEffect, useState, useRef } from 'react';
import { NavLink } from 'react-router-dom';
import { useTimeout, useMount, useGetReducer, useClickOutside } from '../../hooks';
import Btn from '../controls/Btn';

export default function Dropdown({ className, children, options, align = "right", disabled, btnProps, title }) {
    let { componentDidMount } = useMount();
    let { doTimeout, undoTimeout } = useTimeout();
    let [drop, setDrop, getDrop] = useGetReducer(d => {
        if (d) {
            return false;
        } else if (!disabled) {
            return true;
        }
    }, false);
    let [height, setHeight] = useState(0);
    let inner = useRef();
    let lis = useRef({});
    let [maxWidth, setMaxWidth] = useState(0);
    let [ref, onClickOutside] = useClickOutside();
    let hovering = useRef();
    let timeout = useRef();

    componentDidMount(() => {
        let max = 0;
        for (let k in lis.current) {
            let width = lis.current[k].clientWidth;
            if (width > max) max = width;
        }
        setMaxWidth(max);
    })

    useEffect(() => {
        if (drop) {
            let height = inner.current.clientHeight;
            setHeight(height);
            doTimeout(() => {
                setHeight('auto')
            },0.2,'s')
        } else {
            let height = inner.current.clientHeight;
            setHeight(height);
            doTimeout(() => {
                setHeight(0);
            },50)
        }
    }, [drop])

    onClickOutside(() => {
        let drop = getDrop();
        if (drop) setDrop(false);
    })

    function onMouseLeave() {
        hovering.current = false;
        let drop = getDrop();
        if (drop) {
            undoTimeout(timeout.current);
            timeout.current = doTimeout(() => {
                let drop = getDrop();
                if (drop && !hovering.current) {
                    setDrop(false);
                }
            }, 1, 's');
        }
    }

    let Options = title ? ([{ title, unclickable: true }]).concat(options) : options;

    return <div
        className={"Dropdown"+(className?' '+className:'')}
        ref={ref}
        onMouseEnter={()=>hovering.current = true}
        onMouseLeave={onMouseLeave}
    >
        <Btn {...btnProps} onClick={setDrop}>
            {children}
        </Btn>
        <div
            className={"options " + align}
            style={{ height, zIndex: 20, opacity: height && maxWidth ? 1 : 0 }}
        >
            <div
                className="inner"
                ref={inner}
                style={maxWidth ? { maxWidth:`calc(${maxWidth}px + 1px)` } : null}
            >
                <ul>
                    {Options.map(({ title, link, callback, unclickable, newTab, colour }, i) => <li
                        key={i}
                        ref={ref => lis.current[i] = ref}
                        style={maxWidth ? null : { display: 'inline-block' }}
                        onClick={unclickable?null:()=>setDrop(false)}
                    >
                        {!unclickable ? link ? newTab ? <a
                            className="drpdn"
                            target="_blank"
                            rel="noopener noreferrer"
                            href={link}>{title}
                        </a> : <NavLink
                            className="drpdn"
                            to={link}>{title}
                        </NavLink> : callback ? <button
                            className={"but"+ (colour?' '+colour:'')}
                            type="button"
                            onClick={disabled?null:callback}>{title}
                        </button> : <div
                            className="but"
                        >
                            {title}
                        </div> : <div className="tit">
                            <h4>{title}</h4>
                        </div>}
                    </li>)}
                </ul>
            </div>
        </div>
    </div>
}