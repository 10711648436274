import React, { useContext, useEffect, useState } from 'react';
import ModalHeading from './ModalHeading';
import Button from '../controls/Button';
import { AccountContext, ToastContext, useAsync } from '../../hooks';
import Spinner from '../Logo/Spinner';
import axios from 'axios';
import Username from '../user/Username';
import Toast from './Toast';

export default function BanModal({ profile, callback = () => { }, close }) {
    let { user } = useContext(AccountContext);
    let Toasts = useContext(ToastContext);
    let [aliases, setAliases] = useState();
    let [banning, setBanning] = useState(false);
    useAsync(async () => {
        let { data } = await axios.post('/api/aliases', { user, username: profile.username });
        setAliases(data);
    }, []);
    return <div className="modal">
        <ModalHeading title={"Ban "+profile.username} close={async () => {
            await close();
        }} />
        <div className={`body`}>
            <p>Are you sure you want to ban {profile.username}?</p>
            {aliases ? aliases.length > 0 ? <div className="mb"><p>These accounts will also be banned: </p>{aliases.map(a => <div key={a}><Username username={a}/></div>)}</div>: null :<div className="mb"><Spinner/></div>}
            <div className="formButtons">
                <div className="buttons">
                    <Button
                        loading = {banning}
                        onClick={async () => {
                            setBanning(true)
                            await axios.post('/api/ban', { user, username: profile.username });
                            Toasts.create(Toast, { text: `You have banned ${profile.username}!` });
                            callback();
                            await close();
                        }}
                    >
                        Ban {profile.username}!
                    </Button>
                </div>
            </div>
        </div>
    </div>
}