import React, {useState, useContext, useRef} from 'react';
import { ModalContext, ToastContext, useSubmit, useForceRender, useGetState, AccountContext } from '../../hooks';
import Input from '../controls/Input';
import Button from '../controls/Button';
import ModalHeading from '../modal/ModalHeading';
import { tidyGlyphs, validateGlyphs } from '../../functions';
import axios from 'axios';
import Toast from '../modal/Toast';
import Ambigram from '../ambigram/Ambigram';

export default function EditGlyphs({ close, post, callback }) {
    let Toasts = useContext(ToastContext);
    let { user } = useContext(AccountContext);

    let [c, submit, onSubmit] = useSubmit();

    let [glyphs, setGlyphs] = useState(post.glyphs.join(' '));
    let [glyphsError, setGlyphsError] = useState('');

    let formatStyle = () => {
        let style = post.style.substr(0, 7) == 'Special' ? 'Special' : post.style.substr(0, 6) == 'Custom' ? 'Custom' : post.style.substr(0, 6) == 'Change' ? 'Change' : post.style;

        if (style == 'Custom') {
            let custom = post.style.substr(0,6) == 'Custom' ? post.style.substr(6, post.style.length - 6) + '°' :'90°';
            custom = custom.substr(0, custom.length - 1);
            return 'Custom' + custom;
        } else if (style == 'Special') {
            let special = post.style.substr(0, 7) == 'Special' ? post.style : 'Special';
            if (special == 'Special') {
                return 'None'
            } else {
                return special;
            }
        } else if (style == 'Change') {
            let change = post.style.substr(0, 6) == 'Change' ? post.style.substr(7, post.style.length - 7) : '';
            if (change == '') {
                return 'None';
            } else {
                return 'Change_' + change;
            }
        } else return style;
    }

    onSubmit(() => {
        if (!glyphsError) {
            let g = tidyGlyphs(glyphs.trim().split(' ').map(i => i.toUpperCase()));
            axios.post('/api/edit-glyphs', { user, post: { _id: post._id }, glyphs: g });
            callback(g);
            Toasts.create(Toast, { text: 'The glyph tags have been updated!' });
            close();
        }
    })

    return <div className="EditGlyphs modal">
        <ModalHeading title="Edit glyphs" close={close} />
        <div className="body">
            <Ambigram picture={post.picture} style={formatStyle()} bounds={200} />
            <form onSubmit={submit}>
                <Input
                    label="Glyphs (e.g. E/E XA/PL M/M)"
                    value={glyphs}
                    setValue={setGlyphs}
                    error={glyphsError}
                    setError={setGlyphsError}
                    validation={validateGlyphs}
                    attempts={c}
                />
                
                <br/>

                <div className="formButtons">
                    <div className="buttons">
                        <Button submit>Submit</Button>
                    </div>
                </div>
            </form>
        </div>
    </div>
}