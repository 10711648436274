import React, { useContext, useEffect, useState } from 'react';
import { AccountContext, ToastContext, useChange } from '../../hooks';
import Checkbox from '../controls/Checkbox';
import axios from 'axios';
import ModalHeading from '../modal/ModalHeading';
import Toast from '../modal/Toast';
import Btn from '../controls/Btn';
import { ImgArrowLeft, ImgArrowRight } from '../../pictures/SVG';

export default function ProfileTags({ profile, tags: tags0, setTags: setTags0, close: close0 }) {
    let t = [
        {tag: "Curated",         type: "boolean"}, {tag: "Noob",               type: "boolean"},
        {tag: "Muted",           type: "boolean"}, {tag: "Vote blocked",       type: "boolean"},
        {tag: "Glyph moderator", type: "boolean"}, {tag: "Glyph cynic",        type: "boolean"},
        {tag: "Moderator",       type: "boolean"}, {                blank: true               },
        {              blank: true              }, {                blank: true               },
        {tag: "Post limit",      type: "number" }, { tag: "Polite post limit", type: "number" },
    ]

    let { user } = useContext(AccountContext);
    let [changed, setChanged] = useState(false);
    let [tags, setTags] = useState(t.map(({ tag, type }) => {
        let i = tags0.findIndex(t => t[0] == tag);
        if (i >= 0) {
            return { tag, type, value: tags0[i][1] }
        } else {
            return { tag, type, value: null }
        }
    }));
    let Toasts = useContext(ToastContext);

    
    function close() {
        if (changed) {
            if (tags.length > 0) {
                let tags0 = [];
                console.log(tags)
                tags.forEach(({ tag, type, value }) => {
                    if (value) tags0.push([tag, value]);
                })
                setTags0(tags0);
            }
        }
        close0();
    }


    return <div className="modal">
        <ModalHeading title="Tags" close={async () => {
            await close();
        }} />
        <div className={`body blueLinks ProfileTags`}>
            {tags.map(t => <div className="profileTag" key={t.tag}> {t.blank ? null : t.type=="boolean" ? <Checkbox
                label={t.tag}
                checked={t.value}
                setChecked={checked => {
                    t.value = checked;
                    setTags(tags.slice());
                }}
                onChange={async checked => {
                    setChanged(true);
                    try {
                        await axios.post('/api/tag', { username: profile.username, value: checked, tag:t.tag, user });
                    } catch (e) {
                        Toasts.create(Toast, { type: 'error', text: "There was an error!" });
                        console.log(e.message);
                    }
                }} 
            /> : t.type == "number" ? <Number
                label={t.tag}
                value={t.value}
                setValue={value => {
                    t.value = value;
                    setTags(tags.slice());
                }}
                onChange={async value => {
                    setChanged(true);
                    try {
                        await axios.post('/api/tag', { username: profile.username, value, tag: t.tag, user });
                    } catch (e) {
                        Toasts.create(Toast, { type: 'error', text: "There was an error!" });
                        console.log(e.message);
                    }
                }}
            /> : null}</div>)}
            
    {/* <Checkbox label={`Post limit${postLimit ? `: ${postLimit}` : ''}`} checked={!!postLimit} setChecked={async checked => {
        if (!checked) {
            await axios.post('/api/tag', { username: profile.username, value: false, tag: 'Post limit', user });
            setPostLimit(false);
        } else {
            let n = await inputModal({ title: 'Post limit', body: `How many daily posts can ${profile.username} make?`, number: 1 });
            await axios.post('/api/tag', { username: profile.username, value: n, tag: 'Post limit', user });
            setPostLimit(n);
        }
    }}/>
    <Checkbox label={`Polite post limit${politePostLimit ? `: ${politePostLimit}` : ''}`} checked={!!politePostLimit} setChecked={async checked => {
        if (!checked) {
            await axios.post('/api/tag', { username: profile.username, value: false, tag: 'Polite post limit', user });
            setPolitePostLimit(false);
        } else {
            let n = await inputModal({ title: 'Polite post limit', body: `How many daily posts can ${profile.username} make?`, number: 1 });
            await axios.post('/api/tag', { username: profile.username, value: n, tag: 'Polite post limit', user });
            setPolitePostLimit(n);
        }
    }}/>  */}
        </div>
    </div>
}

function Number({ label, min=1, max=100, value, setValue, onChange }) {
    let [displayValue, setDisplayValue] = useState();
    useEffect(() => {
        if (value) setDisplayValue(value);
    }, [value])
    
    useChange(() => {
        onChange(value);
    },[value])

    return <div className="number">
        <div className="checkboxBit">
            <Checkbox
                label={label}
                checked={value}
                setChecked={checked => {
                    if (!checked) {
                        setValue(null);
                    } else {
                        setValue(1);
                    }
                }}
            />
        </div>
        <div className={`buttonBit fader ${value ? '' : 'hidden'}`}>
            <span><Btn onClick={value > min ? () => setValue(value - 1) : () => { }}><ImgArrowLeft /></Btn></span>
            <div className="n">{displayValue}</div>
            <span><Btn onClick={value < max ? ()=> setValue(value+1) : () => { }}><ImgArrowRight /></Btn></span>
        </div>
    </div>
}