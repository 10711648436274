import React, {useState, useContext} from 'react';
import { ModalContext, ToastContext, useSubmit, AccountContext } from '../../hooks';
import TextArea from '../controls/TextArea';
import Button from '../controls/Button';
import ModalHeading from '../modal/ModalHeading';
import Toast from '../modal/Toast';
import { validateDescription } from '../../functions';

export default function EditChallenge({ close, post, callback, blockChangePicture }) {
    let Modals = useContext(ModalContext);
    let Toasts = useContext(ToastContext);
    let { user } = useContext(AccountContext);

    let [c, submit, onSubmit] = useSubmit();
    let [loading, setLoading] = useState(false);
    let [loadingUpload, setLoadingUpload] = useState(false);

    let [message, setMessage] = useState(post ? post.message : '');

    let [messageError, setMessageError] = useState('');

    let [serverError, setServerError] = useState('');


    onSubmit(async () => {
        if (!messageError) {
            setLoading(true);
            post.message = message;
            let res = await callback(post);
            if (res) {
                close();
            } else {
                Toasts.create(Toast, { type: "error", text: 'Edit failed! Maybe try again later.' });
                setServerError('Upload failed');
            }
            setLoading(false);
        }
    })


    return <div className="UploadAmbigram modal">
        <ModalHeading title={'Edit challenge'} close={close} />
        <div className="body">
            <h4 className="mb">You cannot edit the word, but you may edit the description!</h4>
            <form onSubmit={submit}>
                <TextArea
                    label="Description"
                    value={message}
                    setValue={setMessage}
                    error={messageError}
                    setError={setMessageError}
                    validation={validateDescription}
                    attempts={c}
                    disabled={loading}
                />

                <br />

                <div className="formButtons">
                    <div className="buttons">
                        <Button submit loading={loading}>Submit</Button>
                    </div>
                    <div className="error">
                        {serverError}
                    </div>
                </div>
            </form>
        </div>
    </div>
}