import React, { useState, useRef, useContext, useEffect } from 'react';
import axios from 'axios';
import { useAsync,AccountContext, CentreForceRenderContext, useChangeAsync } from '../../hooks';
import Post from './Post';
import DelaySpinner from '../Logo/DelaySpinner';
import Pagination from './Pagination';
import Username from '../user/Username';
import { NavLink } from 'react-router-dom/cjs/react-router-dom.min';
import Btn from '../controls/Btn';


export default function Pages({ query, sketches, mainFeed, hearted, force, halloffame, following, contest3, startingPage, url, heading, noPosts=null, validateHidden, countResults, postsPerPage, sort, mini }) {
    let [page, setPage] = useState(startingPage ? startingPage : 0);
    let [pages, setPages] = useState(0);
    let [count, setCount] = useState(0);
    let [posts, setPosts] = useState([]);
    let [nothing, setNothing] = useState(false);
    let [loading, setLoading] = useState(false);
    let virgin = useRef(true);
    let { user } = useContext(AccountContext);
    let [centreForce, centreForceRender] = useContext(CentreForceRenderContext);

    let top = useRef();

    async function search() {
        setLoading(true);
        let { data } = mainFeed ? await axios.post('/api/main-feed', { page, user, filter: mainFeed, sketches }) : following ? await axios.post('/api/following', { page, user }) : hearted ? await axios.post('/api/hearted', { page, user }) : halloffame ? await axios.post('/api/hall-of-fame', { page, user }) : await axios.post('/api/page', { page, user, query, postsPerPage, sort });
        console.log('PAGE:',data);
        setLoading(false);
        if (!virgin.current && top.current && top.current.getBoundingClientRect().top < 0) {
            top.current.scrollIntoView();
        }
        let { posts, pages, count } = data;
        setNothing(posts.length == 0);
        setPosts(posts);
        setPages(pages);
        setCount(count);
        virgin.current = false;
    }

    useChangeAsync(async () => {
        if (mainFeed) {
            if (page == 0) {
                await search();
            } else {
                setPage(0);
            }
        } 
    },[mainFeed])

    useChangeAsync(async () => {
        if (mainFeed) {
            if (page == 0) {
                await search();
            } else {
                setPage(0);
            }
        } 
    },[sketches])

    useChangeAsync(async () => {
        if (force) {
            if (page == 0) {
                await search();
            } else {
                setPage(0);
            }
        } 
    },[force])

    useAsync(async () => {
        await search();
    }, [page, user, centreForce])
    
    return nothing ? noPosts : <div className="Pages">
        <div className="PagesSpinner top"><DelaySpinner loading={posts.length == 0}/></div>
        {posts.length > 0 ? <>
            {countResults ? <h3 style={{marginTop:'1rem'}}>{count} result{count==1?'':'s'} found!</h3>:heading}
            <div ref={top}>
                <Pagination page={page} pages={pages} setPage={setPage} url={url} />
                <div className="pagesSpinner top"><DelaySpinner loading={loading}/></div>
                <div className={`pages ${pages == 0 ? ' marginTop' : ''} ${mini?'miniPage':''}`} style={{ opacity: loading ? 0 : 1 }} >
                    {posts.map(post => post.type == 'Challenge' && mainFeed ? <Reminder key={post._id} post={post}/> : <Post mini={mini} contest3={contest3} key={post._id} post={post} hidden={validateHidden ? validateHidden(post) : false}/>)}
                </div>
                <div className="pagesSpinner bottom"><DelaySpinner loading={loading}/></div>
                <Pagination page={page} pages={pages} setPage={setPage} url={url}/>
            </div>
        </> : null}
    </div>
}

function Reminder({ post }) {
    return <div className="Reminder">
        {post.closed ? <><Username username={post.username} />'s mini challenge just ended! <Btn to={post.url}>Click here</Btn> to view the results.</> : 
        post.voting ? <><Username username={post.username} />'s mini challenge is open for voting! <Btn to={post.url}>Click here</Btn> to leave a vote.</> :
        <><Username username={post.username} /> just started a mini challenge! <Btn to={post.url}>Click here</Btn> to enter.</>}
    </div>
}