import React, { useContext } from 'react';
import Pages from '../posts/Pages'
import { ResponsiveContext, AccountContext, HistoryContext } from '../../hooks';

import ShareButtons from '../posts/ShareButtons';

export default function MainFeed({ page, route }) {
    let { desktop } = useContext(ResponsiveContext);
    let { user } = useContext(AccountContext);
    let { noNavigate } = useContext(HistoryContext);

    return <div className="Panel MainFeed centrepanel">
        {desktop ? <ShareButtons heading={false} />:null}
        <Pages
            mainFeed
            validateHidden={post=>user&&(post.type=="Ambigram"||post.type=="Entry"||post.type=="ChallengeEntry")&&(user.username==post.username || user.admin)&&!post.visFeed}
            startingPage={page}
            url={`/`}
        />
    </div>
}