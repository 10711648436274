import React, { useEffect, useRef } from 'react';
import { useGetState, useTimeout } from '../../hooks';
import Spinner from './Spinner';

export default function DelaySpinner({ loading }) {
    let [spin, setSpin, getSpin] = useGetState(loading);
    let [visible, setVisible, getVisible] = useGetState(loading);
    let { doTimeout, undoTimeout } = useTimeout();
    let timeout = useRef();
    useEffect(() => {
        let spin = getSpin();
        let visible = getVisible();
        if (loading) {
            setSpin(true);
            if (!visible) {
                timeout.current = doTimeout(() => setVisible(true), 1000);
            }
        } else {
            setVisible(false);
            doTimeout(() => {
                if (!getVisible()) setSpin(false);
            }, 300);
            undoTimeout(timeout.current);
        }
        
    }, [loading])
    return <div className="DelaySpinner" style={{opacity:visible?1:0}}>{spin?<Spinner/>:null}</div>
}