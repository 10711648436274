import React, { useState, useContext } from 'react';
import { useMount, AccountContext } from '../../hooks';
import axios from 'axios';
import Btn from '../controls/Btn';
import { ImgSmallArrowLeft, ImgSmallArrowRight } from '../../pictures/SVG';
import Username from '../user/Username';
import Button from '../controls/Button';


export default function Admin({ }) {
    let { componentDidMount } = useMount();
    let { user } = useContext(AccountContext);
    let [loading, setLoading] = useState(true);
    let [moderators, setModerators] = useState();
    let [noobs, setNoobs] = useState();
    let [curated, setCurated] = useState();
    let [muted, setMuted] = useState();
    let [postLimited, setPostLimited] = useState();
    let [politePostLimited, setPolitePostLimited] = useState();

    let [banned, setBanned] = useState();
    let [bannedIps, setBannedIps] = useState();

    componentDidMount(async () => {
        setLoading(true);
        let { data } = await axios.post('/api/admin/', { user });
        let { moderators, noobs, curated, muted, postLimited, politePostLimited, banned, bannedIps } = data;
        setModerators(moderators);
        setNoobs(noobs);
        setCurated(curated);
        setMuted(muted);
        setPostLimited(postLimited);
        setPolitePostLimited(politePostLimited);
        setBanned(banned);
        setBannedIps(bannedIps);
        setLoading(false);
    })


    return <div className="Panel Admin">
        {!loading ? <div className="fadein">
            <h1>Admin</h1>
            <div className="split">
                <List title="Banned users" users={banned}/>
                {bannedIps.length > 0 ? <div className="userList PANEL">
                    <h4>Banned IPs x {bannedIps.length}</h4>
                    <div className="list">{bannedIps.map(ip => <div key={ip.ip}>{ip.ip}</div>)}</div>
                </div> : null}
            </div>
            <div className="lists">
                <List title="Moderators" users={moderators}/>
                <List title="Noobs" users={noobs}/>
                <List title="Curated" users={curated}/>
                <List title="Muted" users={muted}/>
                <List title="PostLimited" users={postLimited}/>
                <List title="PolitePostLimited" users={politePostLimited}/>
            </div>
        </div> : null}
    </div>
}

function List({ title, users }) {
    if (users.length == 0) return null;
    return <div className="userList PANEL">
        <h4>{title} x {users.length}</h4>
        <div className="list">
            {users.map(user => <div key={user.username}><Username username={user.username}/>{user.value && user.value !==true ? ': ' + user.value : null}</div>)}
        </div>
    </div>
}