import React, { useEffect, useState, useContext, useRef } from 'react';
import { useMount, AccountContext, ExpandLeftMenuContext, useGetState } from '../../hooks';
import axios from 'axios';
import DelaySpinner from '../Logo/DelaySpinner';
import Btn from '../controls/Btn';
import { ImgArrowLeft, ImgArrowRight, ImgStar } from '../../pictures/SVG';
import { NavLink } from 'react-router-dom';

export default function Performance({ }) {
    let { componentDidMount } = useMount();
    let { user } = useContext(AccountContext);
    let [loading, setLoading] = useState(true);
    let [legibilityGraph, setLegibilityGraph] = useState();
    let [legibility, setLegibility] = useState();
    let [contestsGraph, setContestsGraph] = useState();
    let [contests, setContests] = useState();
    let [challengesGraph, setChallengesGraph] = useState();
    let [challenges, setChallenges] = useState();
    let [nothing, setNothing] = useState(false);

    componentDidMount(async () => {
        setLoading(true);
        let { data } = await axios.post('/api/performance/', { user });
        console.log(data);
        if (data) {
            setLegibility(data.legibilityGraph.total);
            setLegibilityGraph(data.legibilityGraph.graph);
            setContests(data.contestsGraph.total);
            setContestsGraph(data.contestsGraph.graph);
            setChallenges(data.challengesGraph.total);
            setChallengesGraph(data.challengesGraph.graph);
        } else {
            setNothing(true);
        }
        setLoading(false);
    })

    return <div className="Panel Performance centrepanel">
        <h1>Your performance</h1>
        <div className="PagesSpinner top"><DelaySpinner loading={loading} /></div>
        {nothing ? <h4>There is nothing...</h4> : <>
            {contestsGraph && contestsGraph.length > 0 ? <ContestsGraph total={contests} graph={contestsGraph} />: null}
            {legibilityGraph && legibilityGraph.length > 0 ? <LegibilityGraph total={legibility} graph={legibilityGraph} /> : null}
            {challengesGraph && challengesGraph.length > 0 ? <ChallengesGraph total={challenges} graph={challengesGraph} /> : null}
        </>}
    </div>
}

function ContestsGraph({ total, graph }) {
    let { showRightPanel } = useContext(ExpandLeftMenuContext);
    let [columns, setColumns] = useState([graph]);
    let ref = useRef();
    

    return <div className="Graph PANEL">
        <h2>Contest performance</h2>
        <div className="ContestsGraph" ref={ref}>
            {graph.map(({ date, stars, theme,url }, j) => <div className="contestItem" key={`${theme} ${j}`}>
                <Stars n={stars} />
                <NavLink to={url}><div className="theme">{theme}</div></NavLink>
            </div>)}
        </div>

    </div>
}

function Stars({ n }) {
    let [hovered, setHovered] = useState(false);
    let floor = Math.floor(n);
    let rem = n - floor;
    let stars = [];
    return <div className="Stars"><div className="stars" style={{width:`${n}rem`}}  onMouseLeave={() => setHovered(false)}>
        <div className="s" style={{ width: `${n}rem` }} onMouseEnter={() => setHovered(true)}>
            <ImgStar style={{left:'0rem'}} />
            <ImgStar style={{left:'1rem'}} />
            <ImgStar style={{left:'2rem'}} />
            <ImgStar style={{left:'3rem'}} />
            <ImgStar style={{left:'4rem'}} />
        </div>
        <div className={hovered ? "tooltip" : "hidden tooltip"} >
            {Math.floor(n * 100) / 100} star{n==1?null:'s'}
        </div>
    </div></div>
}

let months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

function LegibilityGraph({ total, graph }) {
    let ref = useRef();
    let [width, setWidth] = useState(0);
    let [n, setN] = useState(0);
    let [scroll, setScroll, getScroll] = useGetState(0);
    let [scrollBars, setScrollBars] = useState(false);
    let [minScroll, setMinScroll] = useState(0);
    let [year, setYear] = useState(new Date().getFullYear());
    let { showRightPanel } = useContext(ExpandLeftMenuContext);
    let [selected, setSelected] = useState(-1);
    let { user } = useContext(AccountContext);

    useEffect(() => {
        let resize = () => {
            let width = ref.current.getBoundingClientRect().width;
            let n = Math.floor(ref.current.getBoundingClientRect().width / (16));
            setN(n);
            let fullWidth = graph.length;
            let windowWidth = n - 2;
            if (fullWidth * 16 < width) {
                setScroll(0);
                setMinScroll(0);
                setScrollBars(false);
                setWidth(fullWidth);
            } else {
                let minScroll = windowWidth - graph.length;
                setScroll(Math.max(minScroll, getScroll()));
                setMinScroll(minScroll);
                setScrollBars(true);
                setWidth(windowWidth);
            }
        }
        resize();
        window.addEventListener('resize', resize);
        return () => window.removeEventListener('resize', resize);
    }, [showRightPanel])

    let h = 100;
    let scrollAmount = 5;

    return <div className="Graph PANEL">
        <h2>Legibility performance</h2>
        <h4>This graph shows your average legibility score by month.</h4>
        <div className="BarGraph" ref={ref}>
            {scrollBars ? <Btn onClick={()=>setScroll(Math.max(scroll-scrollAmount,minScroll))} disabled={scroll <= minScroll}><ImgArrowLeft/></Btn>:null}
            <div className="barGraph">
                <div className="graph" style={{ width:width*16 }}>
                    <div className="bars" style={{
                        width: graph.length * 16,
                        right: scroll * 16
                    }}>
                        {graph.map(({ date, legibility }, i) => <div key={i} className={`Bar ${selected==i?'selected':''}`} onClick={()=>setSelected(i)}>
                            {legibility == 0 ? <div className="bar"><div className="score">0</div></div> : <div className="bar">
                                <div className="topBit">
                                    {legibility > 0 ? <div className="b" style={{ height: h * legibility }} /> : <div className="score">{Math.round(legibility*100)/100}</div>}
                                </div>
                                <div className="bottomBit">
                                    {legibility < 0 ? <div className="b" style={{ height: h * (0-legibility) }} /> : <div className="score">{Math.round(legibility*100)/100}</div>}
                                </div>
                            </div>}
                            <div className="date"><NavLink to={`/search/username=${encodeURIComponent(user.username)}&from=${new Date(date.year,date.month).getTime()}&to=${new Date(date.year,date.month+1,0).getTime()}`} >{date.year==year?months[date.month]:`${months[date.month]} ${date.year}`}</NavLink></div>
                        </div>)}
                    </div>
                </div>
            </div>
            {scrollBars ? <Btn onClick={()=>setScroll(Math.min(scroll+scrollAmount,0))}disabled={scroll >= 0}><ImgArrowRight/></Btn>:null}
        </div>
    </div>
}

function ChallengesGraph({ total, graph }) {
    let ref = useRef();
    let [width, setWidth] = useState(0);
    let [n, setN] = useState(0);
    let [scroll, setScroll, getScroll] = useGetState(0);
    let [scrollBars, setScrollBars] = useState(false);
    let [minScroll, setMinScroll] = useState(0);
    let [year, setYear] = useState(new Date().getFullYear());
    let { showRightPanel } = useContext(ExpandLeftMenuContext);
    let [selected, setSelected] = useState(-1);
    let w = (16 * 1.5);
    let { user } = useContext(AccountContext);

    useEffect(() => {
        let resize = () => {
            let width = ref.current.getBoundingClientRect().width;
            let n = Math.floor(ref.current.getBoundingClientRect().width / w);
            setN(n);
            let fullWidth = graph.length;
            let windowWidth = n - 2;
            if (fullWidth * w < width) {
                setScroll(0);
                setMinScroll(0);
                setScrollBars(false);
                setWidth(fullWidth);
            } else {
                let minScroll = windowWidth - graph.length;
                setScroll(Math.max(minScroll, getScroll()));
                setMinScroll(minScroll);
                setScrollBars(true);
                setWidth(windowWidth);
            }
        }
        resize();
        window.addEventListener('resize', resize);
        return () => window.removeEventListener('resize', resize);
    }, [showRightPanel])

    let h = 5 * 16;
    let scrollAmount = 5;

    return <div className="Graph PANEL">
        <h2>Mini challenges performance</h2>
        <h4>This graph shows your average challenge entry ratings by month.</h4>
        <div className="BarGraph ChallengesGraph" ref={ref}>
            {scrollBars ? <Btn onClick={()=>setScroll(Math.max(scroll-scrollAmount,minScroll))} disabled={scroll <= minScroll}><ImgArrowLeft/></Btn>:null}
            <div className="barGraph">
                <div className="graph" style={{ width:width*w }}>
                    <div className="bars" style={{
                        width: graph.length * w,
                        right: scroll * w
                    }}>
                        {graph.map(({ date, stars }, i) => <div key={i} className={`Bar ${selected==i?'selected':''}`} onClick={()=>setSelected(i)}>
                            <div className="bar">
                                <div className="starsBit">
                                    <div className="tt"><div className="tooltip">{Math.floor(stars * 100) / 100} star{ Math.floor(stars*100)/100 == 1 ? '' : 's'}</div></div>
                                    <div className="b" style={{ height: h * (stars/5) }} ><div className="sss"><ImgStar/><ImgStar/><ImgStar/><ImgStar/><ImgStar/></div></div>
                                </div>
                            </div>
                            <div className="date"><NavLink to={`/search/username=${encodeURIComponent(user.username)}&from=${new Date(date.year,date.month).getTime()}&to=${new Date(date.year,date.month+1,0).getTime()}&type=Challenge%20entry`} >{date.year==year?months[date.month]:`${months[date.month]} ${date.year}`}</NavLink></div>
                        </div>)}
                    </div>
                </div>
            </div>
            {scrollBars ? <Btn onClick={()=>setScroll(Math.min(scroll+scrollAmount,0))}disabled={scroll >= 0}><ImgArrowRight/></Btn>:null}
        </div>
    </div>
}