import React, { useState, useEffect } from 'react';

export default function FormError({ setError = () => { }, value, error, attempts, validation }) {
    let [lastError, setLastError] = useState('');

    useEffect(() => {
        if (validation) {
            let error = validation(value)
            setError(error);
        }
    }, [attempts, value])

    useEffect(() => {
        if (error) setLastError(error);
    },[error])

    let showErrors = attempts > 0;

    return <div className="FormError"><div className={error && showErrors ? "error" : "hidden error"}>{lastError}</div></div>

}