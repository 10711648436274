import React, { useEffect, useState, useContext, useRef } from 'react';
import { AccountContext, CentreForceRenderContext, HistoryContext, ModalContext, NotificationsContext, SocketContext, useAsync, useChange, useGetState, useTimeout, useTitle, VisibilityContext } from '../../hooks';
import Button from '../controls/Button';
import AddUsers from '../forms/AddUsers';
import DelaySpinner from '../Logo/DelaySpinner';
import axios from 'axios';
import Username from '../user/Username';
import Btn from '../controls/Btn';
import { Comment, WriteComment } from '../posts/Comment';
import Login from '../forms/Login';
import Signup from '../forms/Signup';
import VerificationMessage from '../forms/VerificationMessage';
import Shrinker from '../controls/Shrinker';
import ProfilePic from '../user/ProfilePic';
import ProfilePics from '../user/ProfilePics';
import { ImgPencil } from '../../pictures/SVG';
import EditChat from '../forms/EditChat';

function Schmomment({ comment, chat }) {
    let [height, setHeight] = useState(0);
    let [loaded, setLoaded] = useState(false);
    let ref = useRef();
    let [done, setDone] = useState(false);
    let { doTimeout } = useTimeout();

    useEffect(() => {
        if (loaded) {
            doTimeout(() => {
                let height = ref.current.getBoundingClientRect().height;
                setHeight(height);
                doTimeout(() => {
                    setDone(true);
                }, 0.3, 's')
            }, 0);
        }
    }, [loaded])

    return <div className="shmomment" style={done ? null : { height }}>
        <div ref={ref}>
            <Comment comment={comment} chat={chat} onLoad={()=>setLoaded(true)} />
        </div>
    </div>
}

export default function Messages({ url }) {
    let { navigate } = useContext(HistoryContext);
    let Modals = useContext(ModalContext);
    let { user } = useContext(AccountContext);
    let [chat, setChat] = useState(false);
    let [messages, setMessages] = useState([]);
    let socket = useContext(SocketContext);
    let ref = useRef();
    let writeCommentContainerRef = useRef();
    let headings = useRef();
    let anchor = useRef();
    let [extraHeight, setExtraHeight] = useState(0);
    let [loadingMore, setLoadingMore] = useState();
    let [loadingChats, setLoadingChats] = useState();
    let [first, setFirst] = useState({});
    let [invisible, setInvisible] = useState(false);
    let { doTimeout } = useTimeout();
    let [chats, setChats] = useState(false);
    let [chatsCount, setChatsCount] = useState(0);
    let { getNotifications, setNotifications } = useContext(NotificationsContext);
    let [centreForce] = useContext(CentreForceRenderContext);
    let bigRef = useRef();
    let [height, setHeight] = useState();
    let [lock, setLock] = useState(true);
    let { pageVisible } = useContext(VisibilityContext);

    useEffect(() => {
        let doAction = user && socket && chat;
        if (doAction) {
            socket.emit('chatConnect', { _id: chat._id });
            socket.on('message_' + chat._id, (data) => {
                console.log(data);
                addMessage(data)
            })
        }
        return () => {
            if (doAction) {
                socket.emit('chatDisconnect', { _id: chat._id });
                socket.off('message_' + chat._id);
            }
        }
    }, [user, socket, chat]);

    function login() {
        if (user) {
            if (!user.verified) {
                Modals.create(VerificationMessage, { feature: true });
            }
        } else {
            if (localStorage.getItem('happy')) {
                Modals.create(Login, {feature:true});
            } else {
                Modals.create(Signup, {feature:true});
            }
        }
    }

    function scrollDown(smooth=true) {
        if (ref.current) {
            ref.current.scrollTo({
                top: ref.current.scrollHeight,
                behavior: smooth?'smooth':'auto'
            });
        }
    }

    useEffect(() => {
        let messagesPanel = document.getElementById('MessagesPanel');
        if (messagesPanel) {
            setHeight(messagesPanel.getBoundingClientRect().height);
        }
    },[centreForce])

    function onScroll() {
        function f() {
            let a = anchor.current.getBoundingClientRect().top;
            let b = ref.current.getBoundingClientRect().bottom;
            setLock(a <= b + 10);
        }
        f();
        doTimeout(f, 0.5, 's');
    }

    function addMessage(message) {
        setMessages(b => [...b, {...message, expand:true}]);
        doTimeout(() => {
            recalculateTextAreaHeight();
        }, 0)

        if (lock) doTimeout(() => {
            scrollDown();
        },0.3, 's')
    }

    useEffect(() => {
        let interval;
        if (lock) {
            interval = setInterval(() => {
                scrollDown(false)
            }, 500);
        }
        return ()=>clearInterval(interval);
    }, [lock])
    
    let { setDocumentTitle } = useTitle();

    useAsync(async () => {
        if (user) {
            if (!url) {
                let { data } = await axios.post('/api/chats',{user});
                let { chats, count } = data;
                setChats(chats);
                setChatsCount(count);
            } else {
                let { data } = await axios.post('/api/chat', { user, url });
                let { chat, messages, firstId } = data;
                setChat(chat);
                setMessages(messages.map(m => ({ ...m, init: true })));
                setFirst(firstId);
                recalculateTextAreaHeight();
            }
        }
    }, [url, user])

    useEffect(() => {
        if (chat) {
            setDocumentTitle(`${chat.name?chat.name:chat.users.filter(u => u != user.username).map((u, i) => `${i>0?i==chat.users.length-2?' & ':', ':''}${u}`).join('')} chat - $`);
        }
    },[chat])

    async function seen() {
        let notifications = getNotifications();
        let i = notifications.findIndex(n => n.url == chat.url);
        if (i >= 0) {
            axios.post('/api/seen', { url: chat.url, user });
            notifications.splice(i, 1);
            setNotifications(notifications.slice());
        }
    }

    useEffect(() => {
        seen();
    },[pageVisible])
    
    function recalculateTextAreaHeight(scroll) {
        doTimeout(() => {
            setExtraHeight(headings.current.getBoundingClientRect().height+writeCommentContainerRef.current.getBoundingClientRect().height)
            if (scroll) doTimeout(() => {
                scrollDown()
            },0)
        }, 0);
    }
    if (url) {
        return <div className="Panel Messages centrepanel" ref={bigRef} style={{height}} onMouseMove={()=>seen()}>
            <div className="PagesSpinner top"><DelaySpinner loading={chat===false}/></div>
            {chat ? <div className="PANEL MessageBox">
                <div className="messageHeading" ref={headings}>
                    <h1>{chat.name ? chat.name : 'Chat'} {chat.hosts.includes(user.username)?<Btn colour="" onClick={()=>Modals.create(EditChat, {
                    chat, callback: async (usernames, name) => {
                    console.log({usernames, name})
                    let { data } = await axios.post('/api/edit-chat', { user, usernames, name, chat:{_id:chat._id} });
                    setChat(data); 
                    navigate(data.url);
                }})}><ImgPencil/></Btn>:null}</h1>
                    <h4>{chat.users.filter(u=>u!=user.username).map((u, i) => <span key={u}>{i > 0 ? i == chat.users.length - 2 ? ' and ' : ', ' : ''}<Username username={u} /></span>)}</h4>
                </div>
                <div className="messages" onTouchMove={onScroll} onWheel={onScroll} ref={ref} style={extraHeight?{height:`calc(100dvh - 8rem - 2px - ${extraHeight}px)`}:{height:0}}>
                    {messages.length > 0 && (first && messages[0].id != first)?<>
                        <div className="loadMore">
                            <Btn colour="darkGrey" loading={loadingMore} onClick={async () => {
                                setLoadingMore(true);
                                let { data } = await axios.post('/api/messages', { user, id: chat._id, stamp: messages[0].birthday });
                                setInvisible(true);
                                await new Promise(resolve => doTimeout(resolve, 0.3,'s'));
                                setMessages([...data, ...messages]);
                                await new Promise(resolve => doTimeout(resolve, 0.3,'s'));
                                setInvisible(false);
                                setLoadingMore(false);
                            }}>Load more</Btn>
                        </div>
                    </>:null}
                    <div className={`fader ${invisible ? 'hidden':''}`}>
                        {messages.map(m => m.expand? <Schmomment comment={m} chat={chat} key={m.id} />: <Comment comment={m} chat={chat} key={m.id} onLoad={() => {
                            if (m.init) {
                                doTimeout(scrollDown, 100);
                                doTimeout(scrollDown, 200);
                            }
                        }} />)}
                    </div>
                    <div className="anchor" ref={anchor} />
                </div>
                <div className="writeCommentContainer" ref={writeCommentContainerRef}>
                    <WriteComment onChangeHeight={h => recalculateTextAreaHeight(true)} addComment={addMessage} chat={chat}/>
                </div>
            </div> : null}
        </div>
    } else {
        return <div className="Panel Messages centrepanel">
            <h1>Messages</h1>
            <div className="messageButtons">
                <Button onClick={() => user && user.verified ? Modals.create(AddUsers, {
                    button: "Chat", callback: async usernames => {
                    console.log({usernames})
                    let { data } = await axios.post('/api/new-chat', { user, usernames });
                    console.log(data);  
                    navigate(data.url);
                }}) : login()}>New message</Button>
            </div>
            <div className="PagesSpinner top"><DelaySpinner loading={chats===false}/></div>
            <div className="ChatsList">
                {chats ? chats.map((c, i) => <div key={i}><Chat chat={c} /></div>):null}
            </div>
            <div className={`loadMore fader ${chats.length > 0 && chatsCount > chats.length ? '' : 'hidden'}`}>
                <Btn colour="darkGrey" loading={loadingChats} onClick={async () => {
                    setLoadingChats(true);
                    let { data } = await axios.post('/api/chats', { user, stamp: chats[chats.length - 1].last });
                    console.log(data);
                    setChats([...chats, ...data.chats]);
                    setChatsCount(data.count);
                    setLoadingChats(false);
                }}>Load more</Btn>
            </div>
        </div>
    }
}

function Chat({chat}) {
    let { navigate } = useContext(HistoryContext);
    let { user } = useContext(AccountContext);

    let previewMessage = '';
    if (chat.lastMessage) {
        if (chat.lastMessage.username == user.username) {
            previewMessage = 'You: ';
        } else if (chat.users.length > 2) {
            previewMessage = chat.lastMessage.username + ': ';
        }
        previewMessage += chat.lastMessage.message;
    }

    return <Btn colour={""} onClick={() => navigate(chat.url)}>
        <div className="chatItem PANEL fadein">
            <div className="pictures">
                <ProfilePics usernames={chat.users.filter(u=>u!=user.username)}/>
            </div>
            <div className="chatTextBit">
                <div className="chatName">
                    {chat.name ? chat.name : chat.users.filter(u => u != user.username).map((u, i) => <>{i > 0 ? ', ' : ''}<Username key={i} username={u} unClickable /></>)}
                </div>
                {chat.lastMessage ? <div className="chatPreview">
                    <i>{previewMessage}</i>
                </div>:null}
            </div>
        </div>
    </Btn>
}