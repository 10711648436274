import React, { useContext, useState } from 'react'
import { ModalContext } from '../../hooks';
import Arranger from '../controls/Arranger'
import Thumbnail from '../controls/Thumbnail'
import PickAmbigram from './PickAmbigram';

function RenderAmbigram(ambigram) {
    return <div className="sampleAmbigramItem">
        <div className="sampleThumbnail">
            <Thumbnail height={50} key={ambigram.picture.path} src={'/api/pictures/' + ambigram.picture.path} />
        </div>
        <div className="sampleWord">
            <h4>{ambigram.word}</h4>
        </div>
    </div>
}

export default function SampleAmbigrams({ ambigrams, setAmbigrams }) {
    let Modals = useContext(ModalContext);
    let [block, setBlock] = useState(ambigrams.map(i => i.url));
    return <div className="SampleAmbigrams">
        <Arranger
            renders={ambigrams.map(a => { return { render: RenderAmbigram(a), data: a } })}
            addButtonText="Add ambigram"
            number
            add={async () => {
                let post = await new Promise(resolve => Modals.create(PickAmbigram, { callback: resolve, block }))
                console.log(post);
                return { render: RenderAmbigram(post), data: post };
            }}
            callback={ambigrams => {
                setAmbigrams(ambigrams);
                setBlock(ambigrams.map(i => i.url));
            }}
            max={10}
        />
    </div>
}