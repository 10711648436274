import React, { useContext } from 'react';
import { AccountContext } from '../../hooks';
import ProfilePic from '../user/ProfilePic';
import Username from '../user/Username';
import Btn from '../controls/Btn';

export default function AdvertsPanel({ }) {
    let { user } = useContext(AccountContext);
    return <div className="PostsPanel Panel">
        {user ? <>
            <ProfilePic username={user.username} width={150} />
            <h3><Username username={user.username}/></h3>
        </> : null}
        <ul>
            {user?<li>
                <Btn className="looksLikeAButton"
                    to={'/advert/'+user.username.toLowerCase()}
                >
                    Your advert
                </Btn>
            </li>:null}
        </ul>
    </div>
}