import React, { useState, useContext, useRef, useEffect } from 'react';
import { ModalContext, AccountContext, useGetState, ResponsiveContext} from '../../hooks';
import Btn from '../controls/Btn';
import Login from '../forms/Login';
import Signup from '../forms/Signup';
import VerificationMessage from '../forms/VerificationMessage';
import axios from 'axios';

import { ImgHeart0, ImgHeart1 } from '../../pictures/SVG';

function RealHeartButton({ hearts, post }) {
    let {user} = useContext(AccountContext);
    let Modals = useContext(ModalContext);
    let { bigPhone } = useContext(ResponsiveContext);
    let [hearted, setHearted, getHearted] = useGetState(user && hearts.find(username => user.username == username));
    let [count, setCount] = useState(hearts.length);
    let [count1, setCount1] = useState(hearts.length);
    let [countWidth, setCountWidth] = useState(0);
    let login = () => Modals.create(localStorage.getItem('happy') ? Login : Signup, { feature: true });

    function vote() {
        if (!user) {
            login();
        } else {
            if (user.verified) {
                let hearted = getHearted();
                if (hearted) {
                    setHearted(false);
                    hearted = false;
                    let i = hearts.indexOf(user.username);
                    if (i >= 0) {
                        hearts.splice(i, 1);
                    }
                } else {
                    setHearted(true);
                    hearted = true;
                    hearts.push(user.username);
                }
                setTimeout(() => {
                    setCount(hearts.length);
                },1000*0.25)
                axios.post('/api/heart', { hearted, post: { _id: post._id }, user });
            } else {
                Modals.create(VerificationMessage,{feature:true});
            }
        }
    }

    useEffect(() => {
        if (ref.current) setCountWidth(ref.current.getBoundingClientRect().width);
    },[count])

    let ref = useRef();

    return <Btn
        className="heartButton"
        onClick={vote}
    >
        <div className="heart">
            <ImgHeart0 className="RED"/>
            <ImgHeart1 className={`RED heart1 ${hearted?'':'mini'}`}/>
        </div>
        <div className={bigPhone?'HIDDEN':''}>
            <div><div className={`count ${count?'':'mini'}`} style={{width:count>0?countWidth:0}}>{Math.max(count,1)}</div></div>
            <div className="count HIDDEN">
                <div ref={ref}>{Math.max(count,1)}</div>
            </div>
        </div>
    </Btn>
}

function FakeHeartButton({ }) {
    let [hearted, setHearted] = useState(false);
    return <Btn
        className="heartButton"
        onClick={()=>setHearted(!hearted)}
    >
        <div className="heart">
            <ImgHeart0 className="RED"/>
            <ImgHeart1 className="RED heart1" style={{transform:`scale(${hearted?1:0})`}} />
        </div>
    </Btn>
}

export default function HeartButton({ hearts, post }) {
    if (post) {
        return <RealHeartButton hearts={hearts} post={post} />
    } else {
        return <FakeHeartButton/>
    }
}