import React from 'react';

export function Img0({className, style}) { return <svg className={className+" SVG_0"} style={style} viewBox="160 -160 500 500" > <path d="M360-1c-5.5,0-10-4.5-10-10v-75c0-5.5,4.5-10,10-10s10,4.5,10,10v75C370-5.5,365.5-1,360-1z"/> <path d="M460-1c-5.5,0-10-4.5-10-10v-75c0-5.5,4.5-10,10-10s10,4.5,10,10v75C470-5.5,465.5-1,460-1z"/> <path d="M573.3,201.1c-5.5,0-10-4.5-10-10c0-84.5-68.8-153.3-153.3-153.3c-84.5,0-153.3,68.8-153.3,153.3 c0,5.5-4.5,10-10,10s-10-4.5-10-10c0-95.6,77.7-173.3,173.3-173.3c95.6,0,173.3,77.7,173.3,173.3 C583.3,196.6,578.8,201.1,573.3,201.1z"/> </svg>  }
export function Img1({className, style}) { return <svg className={className+" SVG_1"} style={style} viewBox="160 -160 500 500" > <path d="M360-1c-5.5,0-10-4.5-10-10v-75c0-5.5,4.5-10,10-10s10,4.5,10,10v75C370-5.5,365.5-1,360-1z"/> <path d="M460-1c-5.5,0-10-4.5-10-10v-75c0-5.5,4.5-10,10-10s10,4.5,10,10v75C470-5.5,465.5-1,460-1z"/> <path d="M241,174.8c-0.7,0-1.5-0.1-2.2-0.2c-5.4-1.2-8.8-6.6-7.6-12c18-79.9,93.1-137.8,178.8-137.8 c85.6,0,160.8,58,178.8,137.8c1.2,5.4-2.2,10.7-7.6,12c-5.4,1.2-10.7-2.2-12-7.6C553.3,96.2,486.3,44.8,410,44.8 c-76.4,0-143.3,51.4-159.2,122.2C249.7,171.6,245.6,174.8,241,174.8z"/> </svg>  }
export function Img10({className, style}) { return <svg className={className+" SVG_10"} style={style} viewBox="160 -160 500 500" > <path d="M360,52c-5.5,0-10-4.5-10-10v-75c0-5.5,4.5-10,10-10s10,4.5,10,10v75C370,47.5,365.5,52,360,52z"/> <path d="M460,52c-5.5,0-10-4.5-10-10v-75c0-5.5,4.5-10,10-10s10,4.5,10,10v75C470,47.5,465.5,52,460,52z"/> <path d="M410,160.6c-86.9,0-167.9-44.8-206.3-114.3c-2.7-4.8-0.9-10.9,3.9-13.6c4.8-2.7,10.9-0.9,13.6,3.9 C256.1,99.8,330.2,140.6,410,140.6c79.8,0,153.9-40.8,188.9-103.9c2.7-4.8,8.8-6.6,13.6-3.9c4.8,2.7,6.6,8.8,3.9,13.6 C577.9,115.8,496.9,160.6,410,160.6z"/> </svg>  }
export function Img11({className, style}) { return <svg className={className+" SVG_11"} style={style} viewBox="160 -160 500 500" > <path d="M360,58c-5.5,0-10-4.5-10-10v-75c0-5.5,4.5-10,10-10s10,4.5,10,10v75C370,53.5,365.5,58,360,58z"/> <path d="M460,58c-5.5,0-10-4.5-10-10v-75c0-5.5,4.5-10,10-10s10,4.5,10,10v75C470,53.5,465.5,58,460,58z"/> <path d="M410,172.1c-48.8,0-96.5-16.1-134.3-45.3c-37.3-28.9-63-68.8-72.2-112.4c-1.1-5.4,2.3-10.7,7.7-11.8 c5.4-1.1,10.7,2.3,11.8,7.7C240.4,92.4,319,152.1,410,152.1S579.6,92.4,596.9,10.2c1.1-5.4,6.4-8.9,11.8-7.7 c5.4,1.1,8.9,6.4,7.7,11.8c-9.2,43.6-34.8,83.5-72.2,112.4C506.5,156,458.8,172.1,410,172.1z"/> </svg>  }
export function Img12({className, style}) { return <svg className={className+" SVG_12"} style={style} viewBox="160 -160 500 500" > <path d="M360,64c-5.5,0-10-4.5-10-10v-75c0-5.5,4.5-10,10-10s10,4.5,10,10v75C370,59.5,365.5,64,360,64z"/> <path d="M460,64c-5.5,0-10-4.5-10-10v-75c0-5.5,4.5-10,10-10s10,4.5,10,10v75C470,59.5,465.5,64,460,64z"/> <path d="M410,183.7c-110.7,0-200.8-90.1-200.8-200.8c0-5.5,4.5-10,10-10s10,4.5,10,10c0,99.7,81.1,180.8,180.8,180.8 S590.8,82.6,590.8-17.1c0-5.5,4.5-10,10-10s10,4.5,10,10c0,53.6-20.9,104.1-58.8,142C514.1,162.8,463.6,183.7,410,183.7z"/> </svg>  }
export function Img2({className, style}) { return <svg className={className+" SVG_2"} style={style} viewBox="160 -160 500 500" > <path d="M360,4c-5.5,0-10-4.5-10-10v-75c0-5.5,4.5-10,10-10s10,4.5,10,10v75C370-0.5,365.5,4,360,4z"/> <path d="M460,4c-5.5,0-10-4.5-10-10v-75c0-5.5,4.5-10,10-10s10,4.5,10,10v75C470-0.5,465.5,4,460,4z"/> <path d="M584.7,153.6c-3.4,0-6.8-1.8-8.6-5C543.5,93,478.4,57,410,57c-68.4,0-133.6,36-166.1,91.6 c-2.8,4.8-8.9,6.4-13.7,3.6c-4.8-2.8-6.4-8.9-3.6-13.7c17.4-29.8,43.4-55.1,75-73.1C334.5,46.8,371.9,37,410,37 c38,0,75.5,9.8,108.2,28.4c31.7,18,57.7,43.3,75.1,73.1c2.8,4.8,1.2,10.9-3.6,13.7C588.2,153.2,586.4,153.6,584.7,153.6z"/> </svg>  }
export function Img3({className, style}) { return <svg className={className+" SVG_3"} style={style} viewBox="160 -160 500 500" > <path d="M360,10c-5.5,0-10-4.5-10-10v-75c0-5.5,4.5-10,10-10s10,4.5,10,10V0C370,5.5,365.5,10,360,10z"/> <path d="M460,10c-5.5,0-10-4.5-10-10v-75c0-5.5,4.5-10,10-10s10,4.5,10,10V0C470,5.5,465.5,10,460,10z"/> <path d="M229.5,139.6c-2.8,0-5.5-1.1-7.5-3.4c-3.6-4.1-3.2-10.5,0.9-14.1c26-22.8,55-40.6,86.1-52.9 C341.4,56.5,375.4,50,410,50c34.7,0,68.6,6.4,101,19.1c31.2,12.3,60.2,30.1,86.2,53c4.1,3.7,4.5,10,0.9,14.1s-10,4.5-14.1,0.9 C534,93.2,473.9,70,410,70c-63.8,0-123.9,23.2-173.9,67.1C234.2,138.8,231.8,139.6,229.5,139.6z"/> </svg>  }
export function Img4({className, style}) { return <svg className={className+" SVG_4"} style={style} viewBox="160 -160 500 500" > <path d="M360,16c-5.5,0-10-4.5-10-10v-75c0-5.5,4.5-10,10-10s10,4.5,10,10V6C370,11.5,365.5,16,360,16z"/> <path d="M460,16c-5.5,0-10-4.5-10-10v-75c0-5.5,4.5-10,10-10s10,4.5,10,10V6C470,11.5,465.5,16,460,16z"/> <path d="M593.3,128.8c-2.3,0-4.6-0.8-6.5-2.4c-30.4-26.2-133.2-43-176.8-43c-43.6,0-146.3,16.7-176.8,43 c-4.2,3.6-10.5,3.1-14.1-1.1c-3.6-4.2-3.1-10.5,1.1-14.1c36-30.9,144.3-47.8,189.8-47.8c45.6,0,154,16.9,189.8,47.8 c4.2,3.6,4.6,9.9,1,14.1C598.9,127.6,596.1,128.8,593.3,128.8z"/> </svg>  }
export function Img5({className, style}) { return <svg className={className+" SVG_5"} style={style} viewBox="160 -160 500 500" > <path d="M360,22c-5.5,0-10-4.5-10-10v-75c0-5.5,4.5-10,10-10s10,4.5,10,10v75C370,17.5,365.5,22,360,22z"/> <path d="M460,22c-5.5,0-10-4.5-10-10v-75c0-5.5,4.5-10,10-10s10,4.5,10,10v75C470,17.5,465.5,22,460,22z"/> <path d="M223.8,119.5c-2.8,0-5.6-1.2-7.6-3.5c-3.6-4.2-3.1-10.5,1-14.1c3.9-3.4,13.1-11.3,96.4-19.2 c42.5-4,85.3-6.1,96.4-6.1s53.9,2.1,96.4,6.1c83.3,7.8,92.4,15.8,96.4,19.2c4.2,3.6,4.6,9.9,1,14.1c-3.6,4.2-9.9,4.6-14.1,1 c-0.9-0.7-11.8-7.5-86.2-14.5c-41.5-3.9-82.9-5.9-93.5-5.9c-10.6,0-52,2-93.5,5.9c-74.4,6.9-85.2,13.7-86.2,14.5 C228.4,118.7,226.1,119.5,223.8,119.5z"/> </svg>  }
export function Img6({className, style}) { return <svg className={className+" SVG_6"} style={style} viewBox="160 -160 500 500" > <path d="M360,28c-5.5,0-10-4.5-10-10v-75c0-5.5,4.5-10,10-10s10,4.5,10,10v75C370,23.5,365.5,28,360,28z"/> <path d="M460,28c-5.5,0-10-4.5-10-10v-75c0-5.5,4.5-10,10-10s10,4.5,10,10v75C470,23.5,465.5,28,460,28z"/> <path d="M599,110H221c-5.5,0-10-4.5-10-10s4.5-10,10-10h378c5.5,0,10,4.5,10,10S604.5,110,599,110z"/> </svg>  }
export function Img7({className, style}) { return <svg className={className+" SVG_7"} style={style} viewBox="160 -160 500 500" > <path d="M360,34c-5.5,0-10-4.5-10-10v-75c0-5.5,4.5-10,10-10s10,4.5,10,10v75C370,29.5,365.5,34,360,34z"/> <path d="M460,34c-5.5,0-10-4.5-10-10v-75c0-5.5,4.5-10,10-10s10,4.5,10,10v75C470,29.5,465.5,34,460,34z"/> <path d="M410,123c-14,0-58.7-2.5-100-6.6c-84.9-8.4-94.5-16.9-98.6-20.5c-4.2-3.6-4.6-10-0.9-14.1 c3.6-4.2,10-4.6,14.1-0.9c1,0.8,11.9,8.1,88,15.7c43.4,4.3,85.9,6.5,97.4,6.5c11.5,0,54-2.2,97.4-6.5c76.1-7.5,87-14.9,88-15.7 c4.2-3.6,10.5-3.2,14.1,1c3.6,4.2,3.2,10.5-1,14.1c-4.1,3.6-13.8,12-98.6,20.5C468.7,120.5,424,123,410,123z"/> </svg>  }
export function Img8({className, style}) { return <svg className={className+" SVG_8"} style={style} viewBox="160 -160 500 500" > <path d="M360,40c-5.5,0-10-4.5-10-10v-75c0-5.5,4.5-10,10-10s10,4.5,10,10v75C370,35.5,365.5,40,360,40z"/> <path d="M460,40c-5.5,0-10-4.5-10-10v-75c0-5.5,4.5-10,10-10s10,4.5,10,10v75C470,35.5,465.5,40,460,40z"/> <path d="M410,136.1c-27.7,0-69.2-5.3-105.6-13.4c-31.9-7.1-74.5-19.6-95.9-38.3c-4.2-3.6-4.6-10-0.9-14.1 c3.6-4.2,10-4.6,14.1-0.9c31,27.1,134.1,46.7,188.4,46.7c54.4,0,157.5-19.6,188.4-46.7c4.2-3.6,10.5-3.2,14.1,0.9 c3.6,4.2,3.2,10.5-0.9,14.1C572.9,118.3,459.8,136.1,410,136.1z"/> </svg>  }
export function Img9({className, style}) { return <svg className={className+" SVG_9"} style={style} viewBox="160 -160 500 500" > <path d="M360,46c-5.5,0-10-4.5-10-10v-75c0-5.5,4.5-10,10-10s10,4.5,10,10v75C370,41.5,365.5,46,360,46z"/> <path d="M460,46c-5.5,0-10-4.5-10-10v-75c0-5.5,4.5-10,10-10s10,4.5,10,10v75C470,41.5,465.5,46,460,46z"/> <path d="M410,149.1c-75.8,0-148.5-28.2-204.6-79.4c-4.1-3.7-4.4-10-0.6-14.1c3.7-4.1,10-4.4,14.1-0.6 c52.5,47.8,120.3,74.2,191.2,74.2c70.9,0,138.7-26.4,191.2-74.3c4.1-3.7,10.4-3.4,14.1,0.6c3.7,4.1,3.4,10.4-0.6,14.1 C558.5,120.9,485.9,149.1,410,149.1z"/> </svg>  }
export function ImgO({className, style}) { return <svg className={className+" SVG_o"} style={style} viewBox="-9 -20 500 500" > <path d="M191,168c-5.5,0-10-4.5-10-10V83c0-5.5,4.5-10,10-10s10,4.5,10,10v75C201,163.5,196.5,168,191,168z"/> <path d="M291,168c-5.5,0-10-4.5-10-10V83c0-5.5,4.5-10,10-10s10,4.5,10,10v75C301,163.5,296.5,168,291,168z"/> <circle cx="241" cy="240" r="30"/> </svg>  }