import React, { useEffect, useRef, useState } from 'react';
import { useGetState, useTimeout } from '../../hooks';
import { ImgD, ImgE, ImgM, ImgA, ImgN, ImgP } from '../../pictures/demand/SVG';

export default function DemandSupply({ }) {
    let [wiggle, setWiggle, getWiggle] = useGetState(0);
    let { doTimeout, undoTimeout } = useTimeout();
    let [tooltip, setTooltip] = useState(false);
    let [clicks, setClicks] = useState(0);
    let timeout = useRef();
    let ref = useRef();
    let [width, setWidth] = useState();
    let [height, setHeight] = useState();
    let [x, setX] = useState();
    let [y, setY] = useState();

    function wigglewiggle() {
        if (getWiggle()==0) {
            let direction = Math.random() < 0.5 ? -1 : 1;
            setWiggle(1 * direction);
            doTimeout(() => {
                direction *= -1;
                setWiggle(2 * direction);
            }, 120)
            doTimeout(() => {setWiggle(0)}, 240)
        }
    }

    useEffect(() => {
        function resize() {
            let width = ref.current.getBoundingClientRect().width;
            let height = width * 0.6;
            setWidth(width);
            setHeight(height);
        }
        resize();
        window.addEventListener('resize', resize);
        return () => window.removeEventListener('resize', resize);
    }, [])

    useEffect(() => {
        if (clicks % 2 == 0) {
            setX([126, 187, 227, 0, 40, 77]);
        } else {
            setX([0, 41, 92, 156, 194, 247]);
        }
        let resetY = [0, 0, 0, 0, 0, 0];
        if (clicks == 0) {
            setY(resetY);
        } else {
            let Y = 80;
            let y = [0,Y/2,-Y/2,Y,-Y,Math.random()<0.5?Y:-Y]
            for (let i = y.length - 1; i > 0; i--) {
                let j = Math.floor(Math.random() * (i + 1));
                [y[i], y[j]] = [y[j], y[i]];
            }
            setY(y)
        }
        undoTimeout(timeout1.current);
        timeout1.current = doTimeout(() => setY(resetY),0.5,'s')
    },[clicks])
    let timeout1 = useRef();

    let h = height * 0.5;
    let px = h / 150;

    // let r = [
    //     (clicks+1) * 180,
    //     -(clicks+1) * 180,
    //     (clicks+1) * 180,
    //     -(clicks+1) * 180,
    //     (clicks+1) * 180,
    //     -(clicks+1) * 180
    // ]

    let R = clicks % 2 == 0 ? 180 : 0;
    let r = [
        R,
        -R,
        R,
        -R,
        R,
        -R
    ]

    let Y = (height - h) / 2;

    let yy = y?y.map(y => Y + y*px):[Y,Y,Y,Y,Y,Y];

    return <div className="DemandSupply Ambigram">
        <div
            className="container" ref={ref}
            onMouseEnter={() => timeout.current = doTimeout(() => setTooltip(clicks == 0), 0.5,'s')}
            onMouseLeave={() => {
                setTooltip(false);
                undoTimeout(timeout.current);
            }}
            onClick={() => setTooltip(false)}
        >
            {x&&y?<div
                className="wiggler"
                onMouseEnter={wigglewiggle}
                style={{ transform: `rotate(${wiggle}deg)`, height }}
                onClick={() => setClicks(c => c + 1)}
            >
                <div className="hellothere" style={{width:291*px, height}} key={height}>
                    <ImgD style={{height:h,transform:`skewX(-8deg) rotate(${r[0]}deg)`,top:yy[0],left:x[0]*px}}/>
                    <ImgE style={{height:h,transform:`skewX(-8deg) rotate(${r[1]}deg)`,top:yy[1]+(clicks%2==0?-2*px:0),left:x[1]*px}}/>
                    <ImgM style={{height:h,transform:`skewX(-8deg) rotate(${r[2]}deg)`,top:yy[2],left:x[2]*px}}/>
                    <ImgA style={{height:h,transform:`skewX(-8deg) rotate(${r[2]}deg) scale(1,${clicks%2?1:-1})`,top:yy[3],left:x[3]*px}} />
                    <ImgN style={{height:h,transform:`skewX(-8deg) rotate(${r[4]}deg)`,top:yy[4],left:x[4]*px}}/>
                    <ImgP style={{height:h,transform:`skewX(-8deg) rotate(${r[5]}deg)`,top:yy[5],left:x[5]*px}}/>
                </div>

            </div>:null}

            <div
                className={tooltip && clicks == 0 ? "tooltip" : "hidden tooltip"}
                onClick={()=>setClicks(c=>c+1)}
            >Click to animate!</div>
        </div>
    </div>
}