import React, { useEffect, useState, useContext } from 'react';
import Pages from '../posts/Pages'
import { HistoryContext, ModalContext, useChange } from '../../hooks';
import Input from '../controls/Input'
import Button from '../controls/Button'
import { ImgSearch, ImgSmallArrowLeft, ImgSmallArrowRight } from '../../pictures/SVG';
import Expand from '../controls/Expand';
import SelectDropdown from '../controls/SelectDropdown';
import Btn from '../controls/Btn';
import ModalHeading from '../modal/ModalHeading';
import ModalMessage from '../modal/ModalMessage';
import DateSelect from '../controls/DateSelector';

export default function Search({ page, term, params }) {
    let [query, setQuery] = useState('');
    
    let [style, setStyle] = useState('All');
    let [type, setType] = useState('All');
    let [dateRange, setDateRange] = useState();

    let Modals = useContext(ModalContext);

    let { navigate } = useContext(HistoryContext);
    if (!term && !params) {
        return <div className="Panel Search centrepanel">
            <div className="searchBox">
                <h1>Search posts</h1>
                <Input
                    icon={<ImgSearch/>}
                    value={query}
                    setValue={setQuery}
                    red
                    onKeyDown={e => {
                        if (e.key == "Enter" && query.length > 0) {
                            e.preventDefault();
                            navigate('/search/'+encodeURIComponent(query));
                        }
                    }}
                />
                <div className="advancedBit">
                    <Expand label="Advanced options">
                        <div className="advanced">
                            <label >
                                Animation:  <SelectDropdown selected={style} setSelected={setStyle} options={[ 'All', 'Rotational', 'Mirror', 'Lake', '4-way animation', 'Non-standard', 'None/Invert' ]} />
                            </label>
                            <label >
                                Type:  <SelectDropdown selected={type} setSelected={setType} options={[ 'All', 'Ambigram post', 'Contest entry', 'Challenge entry', 'Duel entry' ]} />
                            </label>
                            <Btn onClick={() => Modals.create(DateSelectModal, {
                                dateRange,
                                callback: (from, to) => {
                                    if (from) {
                                        setDateRange([from, to])
                                    } else {
                                        setDateRange(null);
                                    }
                                }
                            })}>{dateRange ? <div className="dr">{ds(dateRange[0])} to {ds(dateRange[1])}</div> : 'Custom date range'}</Btn>
                        </div>
                    </Expand>
                </div>
                <div className="formButtons">
                    <Button onClick={e => {
                        if (query.length > 0 && style == 'All' && type == 'All' && !dateRange) {
                            navigate('/search/' + encodeURIComponent(query));
                        } else {
                            let params = {};
                            
                            if (query.length > 0) params.query = query;
                            if (style != 'All') params.style = style;
                            if (type != 'All') params.type = type;
                            if (dateRange) {
                                params.from = dateRange[0];
                                params.to = dateRange[1];
                            }
                            let url = '';
                            for (let key in params) {
                                if (url.length > 0)  {
                                    url += '&';
                                }
                                url += `${key}=${encodeURIComponent(params[key])}`;
                            }
                            navigate('/search/' + url);
                        }
                    }}>
                        Search
                    </Button>
                </div>

            </div>
        </div>
    } else if (params) {
        let { style, query, type, from, to, username } = params;
        let q = {};
        if (from && to) {
            q.birthday = {
                $gte: parseInt(from),
                $lte: parseInt(to) + 1000 * 60 * 60 * 24
            }
        }
        if (style) {
            let styles = {
                'Rotational':'Rotational',
                'Mirror':'Mirror',
                'Lake':'Lake',
                '4-way animation':'Ottogram',
                'Non-standard':{$nin : ['Rotational','Mirror','Lake','None']},
                'None/Invert':{$in:['None','Invert']}
            }
            if (styles[style]) q.style = styles[style];
        }
        if (query) {
            q.$text={$search: query}
        }
        if (username) {
            q.username = username;
        }
        if (type) {
            let types = {
                'Ambigram post':'Ambigram',
                'Contest entry':'Entry',
                'Challenge entry':'ChallengeEntry',
                'Duel entry':'DuelEntry'
            }
            if (types[type]) {
                q.type = types[type];
                if (types[type] == 'Ambigram') q.visFeed = true;
            }
        }
        if (!q.type) q.$or = [
            { type: 'Ambigram', visFeed: true },
            { type: 'Entry' },
            { type: 'ChallengeEntry' },
            { type: 'DuelEntry' },
        ]

        console.log(q);

        return <div className="Panel Search centrepanel">
            <Pages
                mini={true}
                postsPerPage={24}
                query={q}
                startingPage={page}
                url={`/search/${encodeURIComponent(term)}/`}
                noPosts={<h1 style={{ marginTop: '1rem' }}>No results!</h1>}
                countResults
            />
        </div>
    } else if (term) {
        return <div className="Panel Search centrepanel">
            <Pages
                mini={true}
                postsPerPage={24}
                query={{
                    $text: {
                        $search: term
                    },
                    $or: [
                        { type: 'Ambigram', visFeed: true },
                        { type: 'Entry' },
                        { type: 'ChallengeEntry' },
                        { type: 'DuelEntry' },
                    ]
                }}
                startingPage={page}
                url={`/search/${encodeURIComponent(term)}/`}
                noPosts={<h1 style={{ marginTop: '1rem' }}>No results!</h1>}
                countResults
            />
        </div>
    }

}

let months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
let th = ['th','st','nd','rd','th','th','th','th','th','th']
function ds(date) {
    let d = new Date(date);
    return `${d.getDate()}${th[d.getDate() % 10]} ${months[d.getMonth()]} ${d.getFullYear()}`
}

function DateSelectModal({ callback = () => { }, close, dateRange }) {
    console.log(1,{dateRange})
    let [from, setFrom] = useState(dateRange?dateRange[0]:new Date().getTime());
    let [to, setTo] = useState(dateRange?dateRange[1]:new Date().getTime());
    let [fromString, setFromString] = useState();
    let [toString, setToString] = useState();
    let Modals = useContext(ModalContext);
    useEffect(() => {
        setFromString(ds(from))
    },[from])
    useEffect(() => {
        setToString(ds(to))
    },[to])
    return <div className="modal">
        <ModalHeading title="Select date range" close={async () => {
            await close();
            callback(false);
        }} />
        <div className="body DateSelectModal">
            <div className="flex1">
                <h4>From {fromString}</h4>
                <DateSelect date={from} setDate={setFrom} />
            </div>
            <div className="flex1">
                <h4>To {toString}</h4>
                <DateSelect date={to} setDate={setTo} />
            </div>
        </div>
        <div className="formButtons">
            <Button onClick={async () => {
                await close();
                if (from > to) {
                    Modals.create(ModalMessage,{title:'Error',body:"Your 'from' date is after your 'to' date!",buttons:[{text:'Close'}]})
                } else {
                    callback(from,to)
                }
            }}>Select</Button>
            <Button colour="red" onClick={async () => {
                await close();
                callback();
            }}>Clear date range</Button>
        </div>
    </div>
}