import React, {useState, useContext, useEffect} from 'react';
import P from '../controls/P';
import { AccountContext, ContestForceRenderContext, HistoryContext, ModalContext, useTitle } from '../../hooks';
import { Countdown } from '../controls/Countdown';
import { dateString } from '../../functions';
import Vote from './Vote';
import Button from '../controls/Button';
import VoteAnalytics from '../modal/VoteAnalytics';

export default function Contest2({ post }) {
    let [ended, setEnded] = useState(() => post.deathday < new Date().getTime());
    let [contestForce, contestForceRender] = useContext(ContestForceRenderContext);
    let [moderate, setModerate] = useState(false);
    let { user, checkPermissions } = useContext(AccountContext);
    let Modals = useContext(ModalContext)
    let { navigate } = useContext(HistoryContext);
    let { setDocumentTitle } = useTitle();

    useEffect(() => {
        setDocumentTitle(`${post.theme} contest - $`);
    },[post])

    useEffect(() => {
        console.log('Contest2');
        if (ended) contestForceRender();
    }, [ended])

    useEffect(() => {
        console.log(checkPermissions('Moderate contests'))
    },[user])
    

    return <div className="Contest2">
        <h1>Contest #{post.count < 10 ? '0' + post.count : post.count} - <span>{post.theme}!</span></h1>
        {ended ? <h4>The voting period is over!</h4> : <h4>Voting ends: {dateString(post.deathday)} 13:00 GMT</h4>}
        <p><i>Before leaving your vote, be sure to check out our rules and guidelines by <a href="/voting-rules" target="_blank" rel="noopener noreferrer">clicking here!</a></i></p>
        <Countdown time={post.deathday} setEnded={setEnded} />
        {ended ? <h1>The voting has finished!</h1> : <>
            <P>{post.message}</P>

            {checkPermissions('Moderate contests') ? <div className="moderateContest2">
                <h2>Moderation tools</h2>
                <Button onClick={()=>Modals.create(VoteAnalytics,{navigate})}>Vote analytics</Button>
            </div> : <>
                <h2>There were {post.entries.length} entries!</h2>
                <h4>Please leave a vote on as many as you want.</h4>
            </>}
            
            <Vote
                post={post}
                PANEL
            />
            
        </>}
    </div>
}