import React, {useState, useContext, useEffect} from 'react';
import P from '../controls/P';
import { useTimeout, ModalContext, AccountContext, ResponsiveContext, useForceRender, useAreYouSure, ToastContext, ContestForceRenderContext, useChange, useTitle } from '../../hooks';
import Button from '../controls/Button';
import Btn from '../controls/Btn';
import Checkbox from '../controls/Checkbox';
import Shrinker from '../controls/Shrinker';
import UploadAmbigram from '../forms/UploadAmbigram';
import Login from '../forms/Login';
import Signup from '../forms/Signup';
import VerificationMessage from '../forms/VerificationMessage';
import { Countdown } from '../controls/Countdown';
import Toast from '../modal/Toast';
import Ambigram from '../ambigram/Ambigram';
import { dateString } from '../../functions';
import axios from 'axios';
import Comments from '../posts/Comments';
import { NavLink } from 'react-router-dom';

export default function Contest1({ post }) {
    let Modals = useContext(ModalContext);
    let { user, subbed, checkPermissions } = useContext(AccountContext);
    let [width, setWidth] = useState(600);
    let { device } = useContext(ResponsiveContext);
    let [force, forceRender] = useForceRender();
    let { doTimeout } = useTimeout();
    let areYouSure = useAreYouSure();
    let Toasts = useContext(ToastContext);
    let [contestForce, contestForceRender] = useContext(ContestForceRenderContext);
    let [moderation, setModeration] = useState(user && post.moderators.indexOf(user.username) >= 0);

    let { setDocumentTitle } = useTitle();

    useEffect(() => {
        setDocumentTitle(`${post.theme} contest - $`);
    }, [post])
    
    useEffect(() => {
        if (device == 'phone') {
            setWidth(400);
        } else if (device == 'tablet') {
            setWidth(500);
        } else setWidth(600);
    }, [device])

    let maxEntries = subbed ? 3 : 2;
    
    let [ended, setEnded] = useState(() => post.deathday < new Date().getTime());

    useEffect(() => {
        if (ended) contestForceRender();
    }, [ended])
    
    useChange(() => {
        if (moderation) contestForceRender();
    }, [moderation])
    

    let login = () => Modals.create(localStorage.getItem('happy') ? Login : Signup, {feature:true});

    function uploadAmbigram() {
        Modals.create(UploadAmbigram, {
            callback: async entry => {
                let {data} = await axios.post('/api/submit-entry', {...entry, post:{ _id: post._id }, user});
                if (data) {
                    data.entry.new = true;
                    post.entries.push(data.entry);
                    return true;
                } else {
                    return false;
                }
            },
            entry: true
        })
    }

    async function editEntry(i) {
        Modals.create(UploadAmbigram, {
            post: post.entries[i],
            callback: async entry => {
                delete entry.new;
                let {data} = await axios.post('/api/edit-submission', { post, entry, user });
                if (data) {
                    if (data.error) {
                        console.log(data.error)
                    } else {
                        post.entries[i] = entry;
                        forceRender();
                        return true;
                    }
                } else {
                    return false;
                }
            },
            entry: true
        })
    }

    async function deleteEntry(i) {
        if (await areYouSure('Are you sure you want to remove this entry?')) {
            let { data } = await axios.post('/api/remove-entry', { post, entry: post.entries[i], user });
            if (data) {
                post.entries[i].shrink = true;
                forceRender();
                doTimeout(() => {
                    post.entries.splice(i, 1);
                }, 0.35, 's')
            } else {
                Toasts.create(Toast, { preset: "server error" });
            }
        }
    }

    

    return <div className="Contest1">
        <h1>Contest #{post.count < 10 ? '0' + post.count : post.count} - <span>{post.theme}!</span></h1>
        {ended ? <h4>The submission period is over!</h4> : <h4>Deadline: {dateString(post.deathday)} 13:00 GMT</h4>}
        <p><i>Before entering the contest, be sure to check out our rules and guidelines by <a href="/submission-rules" target="_blank" rel="noopener noreferrer">clicking here!</a></i></p>
        <Countdown time={post.deathday} setEnded={setEnded} />
        {ended ? <h1>This contest has finished!</h1> : <>
            <P>{post.message}</P>
            <div className="PANEL">
                <h2>You may submit {maxEntries} entries!</h2>
                <div className="entries">
                    {post.entries.map((entry,i) => <Shrinker key={entry.word+'\\*\\'+i} expanded={!entry.shrink} animateOn={entry.new}>
                        <div className="entry">
                            <div className="entryLeft">
                                <Ambigram picture={entry.picture} style={entry.style} bounds={width} />
                            </div>
                            <div className="entryRight">
                                {moderation ?<NavLink to={'/user/'+entry.username.toLowerCase()}><h3>{entry.username}</h3></NavLink> :null}
                                <h4>{entry.word}</h4>
                                <P>{entry.message}</P>
                                {user && user.admin ? <Checkbox
                                    label="Good ambigram"
                                    checked={entry.curated}
                                    setChecked={checked => {
                                        entry.curated = checked;
                                        forceRender();
                                        axios.post('/api/bubble-up',{post, entry, curated:checked, user})
                                    }}
                                /> : null}
                                {!ended ? <>
                                    {!moderation?<Btn onClick={() => editEntry(i)}>Edit</Btn>:null}
                                    <Btn colour="red" onClick={() => deleteEntry(i)}>Delete</Btn>
                                </> : null}
                            </div>
                        </div>
                    </Shrinker>)}

                    <p>There {post.entryCount == 1 ? 'is' : 'are'} {post.entryCount == 0 ? 'no' : post.entryCount} entr{post.entryCount == 1 ? 'y' : 'ies'} so far!</p>
                    {!moderation ? <div className="buttonsGap">
                        <Button
                            onClick={!user ? login : !user.verified ? () => Modals.create(VerificationMessage, { feature: true }) : uploadAmbigram}
                            disabled={post.entries.length >= maxEntries}
                        >
                            Submit an ambigram
                        </Button>
                        {checkPermissions('Moderate contests') ?
                            <Button
                                colour="red"
                                onClick={async () => {
                                    if (await areYouSure('Are you sure you want to enter moderation mode?\nThis will mean you can not edit your existing entries, or submit any more!')) {
                                        await axios.post('/api/moderate-contest1', {post:{ _id: post._id }, user});
                                        setModeration(true);
                                    }
                                }}
                            >
                                Moderate entries
                            </Button>
                        : null}
                    </div> : null}
                </div>
                <h4>Leave a comment!</h4>
                <Comments post={post} n={8}/>
            </div>
        </>}
    </div>
}