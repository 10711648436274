import React, { useContext, useEffect, useRef, useState, memo } from 'react'
import { AccountContext, useMount, useTimeout } from '../../hooks';
import axios from 'axios';
import DelaySpinner from '../Logo/DelaySpinner';
import Thumbnail from '../controls/Thumbnail';
import { NavLink } from 'react-router-dom';

let Slide = memo(({ posts }) => {
    return <div className="sl">{posts.map((p, i) => <Thumbnail key={i} src={'/api/pictures/' + p.picture.path} style={p.style} height={100} ratio={1} />)}</div>
})

export default function Slideshow({ query, link }) {
    let { componentDidMount } = useMount();
    let [loading, setLoading] = useState(true);
    let { user } = useContext(AccountContext);
    let [nothing, setNothing] = useState(false);
    let [posts, setPosts] = useState();
    let [width, setWidth] = useState(0);
    let [w, setW] = useState(0);
    let [offset, setOffset] = useState(0);
    let { doInterval } = useTimeout();
    let [down, setDown] = useState(false);

    useEffect(() => {
        function resize() {
            setWidth(ref.current.getBoundingClientRect().width);
        }
        document.addEventListener('resize', resize);
        resize();
        return () => document.removeEventListener('resize', resize);
    })

    componentDidMount(async () => {
        setLoading(true);
        let { data } = await axios.post('/api/page', { page:0, user, query});
        setLoading(false);
        setNothing(data.posts.length == 0);
        setPosts(data.posts);
        let w = (data.posts.length * (100 + 16));
        setW(w);
        doInterval(() => setOffset(o => (o + 1)%w), 1 / 20, 's');
    })

    useEffect(() => {
        if (down) {
            let up = () => setDown(false);
            document.addEventListener('mouseup', up);
            return () => document.removeEventListener('mouseup', up);
        }
    },[down])

    let ref = useRef();

    let n = w>0 ? Math.ceil(width / w) + 1: 0;
    let render = [];
    for (let i = 0; i < n; i++) {
        render.push(<Slide posts={posts}/>);
    }
    let r = <div className={`Slideshow ${link ? 'linky' : ''} ${down ? 'down' : ''}`} ref={ref} onMouseDown={() => setDown(true)}>
        <div className="PagesSpinner top"><DelaySpinner loading={loading} /></div>
        {!loading ? nothing ? <h4>There is nothing...</h4> : posts && render.length > 0 ? <div className="slider">{render.map((r, i) => <div key={i} className="slide" style={{ left: i * w - offset }}>
            {r}
        </div>)}</div> : null : null}
        {link && !loading ? <div className="bg"><div className="link">{link.title}</div></div>:null}
    </div>
    if (link) return <NavLink to={link.url}>{r}</NavLink>
    return { r }
}