import React, { useState, useContext } from 'react';
import { AccountContext, useAsync, ModalContext, useAreYouSure } from '../../../hooks';
import VerificationMessage from '../../forms/VerificationMessage';
import Login from '../../forms/Login';
import Signup from '../../forms/Signup';
import Expand from '../../controls/Expand';
import P from '../../controls/P';
import Ambigram from '../../ambigram/Ambigram';
import Username from '../../user/Username';
import DemandSupply from '../../ambigram/DemandSupply';

function Definitions({ }) {
    let definitions = [
        ["Ambigram","A typographical design consisting of letterforms that have been manipulated such that they remain legible when rotated, reflected, or subjected to some other series of geometric transformations."],
        
        ["Rotational ambigram","An ambigram which must be rotated to reveal its alternate orientation.","E.g. Chump:",<Ambigram picture={{ path: 'chump1.png', width: 331, height: 127 }} style="Rotational" bounds={331}/>,"You may click the image to rotate it."],


        ["Glyph","An ambigram is composed of what we call 'glyphs'. These are mini ambigrams that make up parts of the word, and can not be subdivided further.",<Ambigram picture={{ path: 'atlantis.png', width: 471, height: 151 }} style="Rotational" bounds={471}/>,"Consider this ambigram of 'Atlantis'. We can subdivide this into 3 unique glyphs:",<div className="flexywexy"><Ambigram picture={{ path: 'a.png', width: 107, height: 151 }} style="Rotational" bounds={151}/><Ambigram picture={{ path: 't.png', width: 41, height: 151 }} style="Rotational" bounds={151}/><Ambigram picture={{ path: 'lan.png', width: 170, height: 151 }} style="Rotational" bounds={170}/></div>,"You should see that the full 'Atlantis' ambigram can be made up of these 3 glyphs - you can click them to rotate each one.","We would describe these glyphs using the notation: 'A/is', 't/t', and 'lan/lan' - to represent which letters comprise them."],


        ["Letter ratio","A glyph can be described by its letter ratio.","E.g. Click this 'B' to see it rotate to become an 'er':",<Ambigram picture={{ path: 'b.png', width: 150, height: 178 }} style="Rotational" bounds={178}/>,"We would say this has a 1:2 letter ratio, since 'B' is 1 letter long, and 'er' is 2."],



        ["Symbiotogram", "An ambigram that reads something different in its alternative orientation.", "E.g. Welcome/Farewell:", <Ambigram picture={{ path: 'welcome.png', width: 376, height: 109 }} style="Rotational" bounds={376} />,"You may click the image to rotate it."],
        

        ["Mirror ambigram","An ambigram which must be reflected on the y-axis to reveal its alternate orientation.","E.g. Wow:",<Ambigram picture={{ path: 'wow.png', width: 324, height: 97 }} style="Mirror" bounds={300}/>],
        

        ["Lake ambigram","An ambigram which must be reflected on the x-axis to reveal its alternate orientation.","E.g. Kai:",<Ambigram picture={{ path: 'kai.png', width: 275, height: 130 }} style="Lake" bounds={300}/>],
        

        ["Chain ambigram","An ambigram which is presented as a repeating design. This allows for more flexibility, as the centre of rotation does not need to be in the centre of the word, and can be moved to allow for a greater range of solutions.",<p>E.g. Onion by <Username username="John" display="John Langdon"/></p>,<Ambigram picture={{ path: 'onion.png', width: 300, height: 300 }} style="Custom90" bounds={300}/>,"If we extract the repeating 'Onion' from the chain ambigram, we get this:",<Ambigram picture={{ path: 'onion1.png', width: 231, height: 134 }} style="Rotational" bounds={231}/>,"Click on the image to rotate it, and you will find it says 'Nonio' upside-down. This is what gives chain ambigrams their flexibility; making a typical ambigram of 'Onion' would be hard, as you would need to match an O with an N. But with a chain ambigram, we can adjust the centre of rotation so that the O's line up, giving us an easier challenge."],
        

        ["Figure-ground ambigram","An ambigram where the second word is hidden in the white space of the first word.",<p>E.g. Care/Love by <Username username="Dannyjcris" display="Danilo J. Cris"/></p>,<Ambigram picture={{ path: 'care.png', width: 289, height: 162 }} style="Change_love.png" bounds={289}/>,"You should be able to read 'Care' in the black letters above. Click on the image to highlight the white space between the letters, and a new word should emerge!"],
        

        

        

        ["Spinonym", "An design which is comprised of one repeating glyph in various orientations.", <p>E.g. Remember me by <Username username="jpmanansala" display="Jeffry Manansala" /></p>, <div className="centre"><img src="/api/pictures/remember me.png" alt="remember me"/></div>, 'You should be able to see that each letter is made from the same glyph at different orientations. Here is that glyph:', <Ambigram picture={{ path: 'r.png', width: 90, height: 90 }} style="Special|180/1/1|90/1/-1|90/1/1|90/1/-1|-90/1/1" bounds={90} />, "Click on the glyph to see it change from an R, to an E, to an M, a B, and finally a W - a letter which was not in the original ambigram, but could have worked if it had been.", "Perhaps there exists a glyph that could be used to represent every letter in the alphabet - good luck with finding that!"],

        

        ["Perceptual-shift", "An design where the same glpyhs can represent more than one word. Rather than needing to transform the design through rotation or reflection, you need to adjust the way you interpret the glyphs.", <p>E.g. Werewolf by <Username username="otto" display="Otto Kronstedt" /></p>, <div className="centre"><img src="/api/pictures/werewolf.png" alt="werewolf"/></div>,"In the image above, you should see that the 'were' and the 'wolf' are made from exactly the same shapes. Consider the shapes on their own:",<div className="centre"><img src="/api/pictures/wolf.png" alt="werewolf"/></div>,"By adjusting your perception of the glyphs, you might be able to read either 'were' or 'wolf' in the image above."],



        ["Jumblegram", "An design where each glyph must be oriented and positioned differently to reveal its alternate orientation.", <p>E.g. Supply/Demand by <Username username="otto" display="Otto Kronstedt" /></p>, <DemandSupply/>, "Click on the design to watch the word transform from 'supply' to 'demand'."],
        

    ]
    // definitions = definitions.sort((a, b) => a[0].localeCompare(b[0]));
    return <ul>
        {definitions.map((definition, i) => <li key={i}><Expand label={definition[0]}>
            {definition.map((d, i) => i == 0 ? null : typeof d == 'string' ? <P key={i} noMarginBottom={i == definition.length - 1}>{d}</P> : <span key={i}>{d}</span>)}
        </Expand></li>)}
    </ul>
}


export default function Glossary({ url }) {
    return <div className="Info Panel centrepanel">
        <h1>Glossary</h1>
        <h4>There are a lot of esoteric words in the ambigram world. Look them up with this useful glossary!</h4>
        <Definitions/>
    </div>
}