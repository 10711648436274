import React from 'react';

export function Img1({className, style}) { return <svg className={className+" SVG_1"} style={style} viewBox="-244 233 36 16" > <polygon points="-244,249 -237.5,233 -233.5,233 -227,249 -232.9,249 -235.5,238.9 -238.1,249 		"/> <polygon points="-208,233 -214.5,249 -218.5,249 -225,233 -219.1,233 -216.5,243.1 -213.9,233 		"/> </svg>  }
export function Img2({className, style}) { return <svg className={className+" SVG_2"} style={style} viewBox="-251 231 19 16" > <polygon points="-251,231 -246.4,231 -246.8,239 -246.4,247 -251,247 	"/> <polygon points="-236.6,231 -232,231 -232,247 -236.6,247 -236.2,239 	"/> </svg>  }
export function ImgBlue1({className, style}) { return <svg className={className+" SVG_blue1"} style={style} viewBox="0 0 30 20" > <polygon points="7,0 0,18 5.5,18 7,11.8 8.5,18 14,18 	"/> <polygon points="23,20 30,2 24.5,2 23,8.2 21.5,2 16,2 	"/> </svg>  }
export function ImgBlue2({className, style}) { return <svg className={className+" SVG_blue2"} style={style} viewBox="0 0 16 16" > <polygon points="5,0 0,0 0,16 5,16 4,8 	"/> <polygon points="11,16 16,16 16,0 11,0 12,8 	"/> </svg>  }
export function ImgDarkgrey1({className, style}) { return <svg className={className+" SVG_darkgrey1"} style={style} viewBox="5 0 30 20" > <polygon points="12,0 5,18 10.5,18 12,11.8 13.5,18 19,18 	"/> <polygon points="28,20 35,2 29.5,2 28,8.2 26.5,2 21,2 	"/> </svg>  }
export function ImgDarkgrey2({className, style}) { return <svg className={className+" SVG_darkgrey2"} style={style} viewBox="-1 3 16 16" > <polygon points="4,3 -1,3 -1,19 4,19 3,11 	"/> <polygon points="10,19 15,19 15,3 10,3 11,11 	"/> </svg>  }
export function ImgGradient1({className, style}) { return <svg className={className+" SVG_gradient1"} style={style} viewBox="0 0 30 20" > <polygon points="7,0 0,18 5.5,18 7,11.8 8.5,18 14,18 "/> <linearGradient gradientUnits="userSpaceOnUse" x1="15" y1="11" x2="30.9864" y2="11"> <stop offset="0" /> <stop offset="1" /> </linearGradient> <polygon points="23,20 30,2 24.5,2 23,8.2 21.5,2 16,2 "/> </svg>  }
export function ImgGradient2({className, style}) { return <svg className={className+" SVG_gradient2"} style={style} viewBox="0 0 16 16" > <linearGradient gradientUnits="userSpaceOnUse" x1="0" y1="8" x2="15.9864" y2="8"> <stop offset="0" /> <stop offset="1" /> </linearGradient> <polygon points="5,0 0,0 0,16 5,16 4,8 	"/> <linearGradient gradientUnits="userSpaceOnUse" x1="0" y1="8" x2="15.9864" y2="8"> <stop offset="0" /> <stop offset="1" /> </linearGradient> <polygon points="11,16 16,16 16,0 11,0 12,8 	"/> </svg>  }
export function ImgGrey1({className, style}) { return <svg className={className+" SVG_grey1"} style={style} viewBox="0 0 30 20" > <polygon points="7,0 0,18 5.5,18 7,11.8 8.5,18 14,18 	"/> <polygon points="23,20 30,2 24.5,2 23,8.2 21.5,2 16,2 	"/> </svg>  }
export function ImgGrey2({className, style}) { return <svg className={className+" SVG_grey2"} style={style} viewBox="0 0 16 16" > <polygon points="5,0 0,0 0,16 5,16 4,8 	"/> <polygon points="11,16 16,16 16,0 11,0 12,8 	"/> </svg>  }