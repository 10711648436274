import React, { useContext } from 'react';
import { CurrencyContext } from '../../hooks';
import AdvertPages from '../advert/AdvertPages';
import Currencies from '../advert/Currencies';

export default function Adverts({ page }) {
    let { currency } = useContext(CurrencyContext);

    return <div className="Panel Adverts centrepanel">
        <h1>Our artists</h1>
        <p>If you find an artist that you like, you may contact them and request a quote!</p>
        <label>Select your currency</label>
        <Currencies currency={currency} title />
        <div className="mb">
        </div>

        <AdvertPages
            startingPage={page}
        />
        
    </div>
}