import React, { useContext, useEffect, useState } from 'react';
import ModalHeading from './ModalHeading';
import Button from '../controls/Button';
import { AccountContext, ToastContext, useAsync } from '../../hooks';
import Spinner from '../Logo/Spinner';
import axios from 'axios';
import Username from '../user/Username';
import Toast from './Toast';

export default function IPLookup({ profile, close }) {
    let { user } = useContext(AccountContext);
    let Toasts = useContext(ToastContext);
    let [aliases, setAliases] = useState();
    useAsync(async () => {
        let { data } = await axios.post('/api/aliases', { user, username: profile.username });
        setAliases(data);
    }, []);
    return <div className="modal">
        <ModalHeading title={profile.username+"'s alt accounts"} close={async () => {
            await close();
        }} />
        <div className={`body`} style={{minHeight:"5rem"}}>
            {aliases ? aliases.length > 0 ? aliases.map(a => <div key={a}><Username username={a} /></div>) : <p>{profile.username} has no alt accounts.</p> :<div className="mt"><Spinner/></div>}
        </div>
    </div>
}