import React, { useEffect, useState } from 'react';
import Ambigram from './Ambigram';
import { useResize, useClickOutside } from '../../hooks';

export default function FullScreenAmbigram({ picture, style, close }) {
    let [width, height] = useResize();
    let [bounds, setBounds] = useState(0);
    let [ref, onClickOutside] = useClickOutside();

    useEffect(() => {
        let bounds = Math.min(width, height);
        setBounds(bounds);
    }, [width, height])
    
    onClickOutside(close);

    return <div ref={ref}>
        <Ambigram picture={picture} style={style} bounds={bounds} />
    </div>
}