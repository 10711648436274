import React, { useState } from 'react';

import {ImgCheck,ImgTick} from '../../pictures/controls/SVG';

export default function Checkbox({label, checked, setChecked, disabled, onChange, className}) {
    return <label className={`Checkbox ${className?className:''}`}>
        <input type="checkbox" checked={checked} onChange={e => {
            if (onChange) onChange(e.target.checked);
            setChecked(e.target.checked)
        }} />
        <div className="box">
            <ImgCheck className={"back " + (disabled ? 'LIGHTGREY' : 'BLUE')} />
            <ImgTick className={"tick "+(disabled?'LIGHTGREY':'BLUE')} style={{transform:`scale(${checked?1:0})`}}/>
        </div>
        {label}
    </label>
}