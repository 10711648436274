import React, { useState, useRef, useEffect } from 'react';
import { useTimeout, useMount } from '../../hooks';
import Spinner from '../Logo/Spinner';

export default function Button({ colour="blue", className, children, onClick = () => { }, onMouseEnter, onMouseLeave = ()=>{}, submit, loading, disabled, fast}) {
    let { componentDidMount } = useMount();
    let [down, setDown] = useState(false);
    let { doTimeout } = useTimeout();
    let ref = useRef();
    let [width, setWidth] = useState(0);
    let [height, setHeight] = useState(0);

    componentDidMount(() => {
        setWidth(ref.current.clientWidth);
        setHeight(ref.current.clientHeight);
    })

    useEffect(() => {
        ref.current.disabled = loading || disabled;
    },[loading, disabled])

    let buttonClass = "button " + colour;
    if (className) buttonClass += ' ' + className;
    if (down) buttonClass += ' down';
    if (loading) buttonClass += ' loading';
    if (disabled) buttonClass += ' isDisabled';
    let isDisabled = disabled || loading;

    return <div className="Button">
        <button
            className={buttonClass}
            ref={ref}
            onMouseEnter={onMouseEnter}
            onMouseLeave={() => {
                setDown(false);
                onMouseLeave();
            }}
            onMouseDown={() => setDown(true)}
            onMouseUp={()=>setDown(false)}
            onClick={isDisabled?null:fast?onClick:() => doTimeout(onClick, 0.07, 's')}
            type={submit ? 'submit' : 'button'}
        >
            {children}
            {loading ? <div className="abs centreFlex" style={{width,height}}>
                <Spinner />
            </div>:null}
        </button>
    </div>
}