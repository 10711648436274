import React, { useContext, useEffect, useRef, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { AccountContext, ModalContext, useDynamic } from '../../hooks';
import EditGlyphs from '../forms/EditGlyphs';

export default function GlyphTags({ post, contest3 }) {
    let { user, checkPermissions } = useContext(AccountContext);
    let [hover, setHover] = useState();
    let glyphs = post.glyphs;
    let Modals = useContext(ModalContext);
    let Dynamic = useDynamic('post', post._id);
    let SetPost = Dynamic ? Dynamic.SetPost : null;

    useEffect(() => {
        if (hover) {
            let rect = ref.current.getBoundingClientRect();
            if (tag1.current && (tag1.current.getBoundingClientRect().right > rect.right || tag0.current.getBoundingClientRect().left < rect.left)) {
                let wheel = e => {
                    e.preventDefault();
                    let delta = Math.max(-1, Math.min(1, (e.wheelDelta || -e.detail)));
                    ref.current.scrollLeft -= (delta * 10);
                }
                window.addEventListener('wheel', wheel, {passive:false});
                return () => window.removeEventListener('wheel', wheel);
            }
        }
    },[hover])
    let ref = useRef();
    let tag0 = useRef();
    let tag1 = useRef();

    return <div className="GlyphTags"
        ref={ref}
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
    >
        {glyphs.length == 0 ? <div className="glyphTags emptyGlyphs">
            {user && !contest3 && (checkPermissions('Edit glyphs')) ? <div className={`g glyphImposter`} onClick={() => Modals.create(EditGlyphs, { post, callback: glyphs => {
            post.glyphs = glyphs;
            SetPost({ ...post });
        }})}><span className="glyph">Add glyphs</span></div> : null}
        </div> : <div className="glyphTags" ref={ref}>
            {glyphs.map((g,i) => <div className="g" ref={i == 0 ? tag0 : i == glyphs.length-1 ? tag1 : null} key={i+'*'+g}><NavLink to={'/glyphs/all/'+g.split('/').map(g=>encodeURIComponent(g.toLowerCase())).join('/')} className="glyph noUnderline">{g}</NavLink></div>)}
        </div>}
        
    </div>
}