import React, {useState, useContext} from 'react';
import { useSubmit, ToastContext } from '../../hooks';
import Input from '../controls/Input';
import TextArea from '../controls/TextArea';
import Button from '../controls/Button';
import ModalHeading from '../modal/ModalHeading';
import Toast from '../modal/Toast';

let validateTheme = title => {
    if (title.length == 0) return 'Please provide a theme!';
    if (title.length > 50) return 'Theme too long!';
}

let validateMessage = message => {
    if (message.length == 0) return 'Please provide a message!';
    if (message.length < 6) return 'Message too short!';
    if (message.length > 5000) return 'Message too long!';
}

export default function StartContest({ close, callback }) {
    let Toasts = useContext(ToastContext);

    let [c, submit, onSubmit] = useSubmit();
    let [loading, setLoading] = useState(false);

    let [theme, setTheme] = useState('');
    let [message, setMessage] = useState('');

    let [themeError, setThemeError] = useState('');
    let [messageError, setMessageError] = useState('');

    let [serverError, setServerError] = useState('');

    onSubmit(async () => {
        if (!themeError && !messageError) {
            setLoading(true);
            let post = {};
            post.message = message;
            post.theme = theme;
            let res = await callback(post);
            if (res) {
                close();
            } else {
                Toasts.create(Toast, { type: "error", preset: 'server error' });
                setServerError('Server error!');
            }
            setLoading(false);
        }
    })

    return <div className="ShareThought modal">
        <ModalHeading title="Start contest!" close={close} />
        <div className="body">
            <form onSubmit={submit}>

                <Input
                    label="Theme"
                    value={theme}
                    setValue={setTheme}
                    error={themeError}
                    setError={setThemeError}
                    validation={validateTheme}
                    attempts={c}
                    disabled={loading}
                />

                <TextArea
                    label="Message"
                    value={message}
                    setValue={setMessage}
                    error={messageError}
                    setError={setMessageError}
                    validation={validateMessage}
                    attempts={c}
                    disabled={loading}
                />

                <br/>

                <div className="formButtons">
                    <div className="buttons">
                        <Button submit loading={loading}>Submit</Button>
                    </div>
                    <div className="error">
                        {serverError}
                    </div>
                </div>
            </form>
        </div>
    </div>
}