import React, { useState, useEffect, useContext } from 'react';
import { useMount, CentreKeyContext } from '../../hooks';
import { NavLink } from 'react-router-dom';
import axios from 'axios';

export default function GlyphsPanel({}) {
    let centreKey = useContext(CentreKeyContext);
    let [selected, setSelected] = useState();
    let [glyphs, setGlyphs] = useState([]);
    let [loading, setLoading] = useState(false);
    let { componentDidMount } = useMount();

    componentDidMount(async () => {
        setLoading(true);
        let { data } = await axios.get('/api/glyphs');
        setGlyphs(data);
        setLoading(false);
    })

    useEffect(() => {
        if (centreKey && centreKey.indexOf('*') >= 0) {
            let split = centreKey.split('*');
            if (split[0] == 'Glyphs') {
                if (split.length>2) setSelected(split[2]);
            } else setSelected();
        } else setSelected();
    }, [centreKey])


    return glyphs.length > 0 ?<div className="GlyphsPanel Panel"><div className="CommonGlyphs">
        <h3>Most common glyphs</h3>
        <div className="commonGlyphs">
            {glyphs.map(glyph => <NavLink
                key = {glyph}
                className = "noUnderline"
                to = {'/glyphs/all/'+glyph.split('/').map(g=>encodeURIComponent(g.toLowerCase())).join('/')}
            ><div className={glyph == selected ? "glyph selected" : "glyph"}>
                {glyph}
            </div></NavLink>)}
        </div>
    </div></div> : null
}