import React from 'react';

export function Img1({className, style}) { return <svg className={className+" SVG_1"} style={style} viewBox="390 -140 960 460" > <polygon points="410,300 579.5,-120 680.5,-120 850,300 698.3,300 630,36 561.7,300 		"/> <polygon points="1330,-120 1160.5,300 1059.5,300 890,-120 1041.7,-120 1110,144 1178.3,-120 		"/> </svg>  }
export function Img2({className, style}) { return <svg className={className+" SVG_2"} style={style} viewBox="0 0 520 460" > <polygon points="20,20 140,20 129.1,229 140,440 20,440 	"/> <polygon points="380,20 500,20 500,440 380,440 390.9,229 	"/> </svg>  }
export function Img3({className, style}) { return <svg className={className+" SVG_3"} style={style} viewBox="180 -140 560 460" > <rect x="580" y="-120" width="140" height="420"/> <rect x="200" y="-120" width="140" height="420"/> </svg>  }
export function Img4({className, style}) { return <svg className={className+" SVG_4"} style={style} viewBox="110 -140 400 460" > <path d="M270.3,300H350c64.3,0,140-53.7,140-134.9c0-64.8-34.5-100.6-107.5-98.1L276,90 c-106.5,15-131.7,44.2-131.7,102C144.3,266.3,214.7,300,270.3,300z M245,165c0-22.1,18.2-35,35-35h60c16.8,0,35,12.9,35,35l0,0 c0,22.1-18.2,35-35,35h-60C263.2,200,245,187.1,245,165L245,165z"/> <path d="M349.7-120H270c-64.3,0-140,53.7-140,134.9c0,64.8,34.5,100.6,107.5,98.1L344,90 C450.5,75,475.7,45.8,475.7-12C475.7-86.3,405.3-120,349.7-120z M375,15c0,22.1-18.2,35-35,35h-60c-16.8,0-35-12.9-35-35l0,0 c0-22.1,18.2-35,35-35h60C356.8-20,375-7.1,375,15L375,15z"/> </svg>  }
export function Img5({className, style}) { return <svg className={className+" SVG_5"} style={style} viewBox="140 31 800 400" > <path d="M680,51H575h-15v100c0,0,62.9,0,120,0c51.9,0,95,27.9,95,80.3c0,52.4-42.1,84.9-94,84.9 c-26,0-57.8-8.1-73.2-12.8L575,407.7c40.5,12.5,99.3,19.5,144.8,14C832.3,408.2,910,330.9,910,231.3C910,118.4,814,51,680,51z"/> <path d="M400,411h105h15V311c0,0-62.9,0-120,0c-51.9,0-95-27.9-95-80.3c0-52.4,42.1-84.9,94-84.9 c26,0,57.8,8.1,73.2,12.8L505,54.3c-40.5-12.5-99.3-19.5-144.8-14C247.7,53.8,170,131.1,170,230.7C170,343.6,266,411,400,411z"/> </svg>  }
export function Img6({className, style}) { return <svg className={className+" SVG_6"} style={style} viewBox="70 -60 320 300" > <rect x="90" y="-39" width="120" height="259"/> <rect x="250" y="-40" width="120" height="259"/> </svg>  }
export function Img7({className, style}) { return <svg className={className+" SVG_7"} style={style} viewBox="155 171 480 160" > <polygon points="400,311 260,311 180,191 320,191 			"/> <polygon points="390,191 530,191 610,311 470,311 			"/> </svg>  }
export function Img8({className, style}) { return <svg className={className+" SVG_8"} style={style} viewBox="390 -140 960 460" > <polygon points="410,300 579.5,-120 680.5,-120 850,300 698.3,300 630,36 561.7,300 		"/> <polygon points="1330,-120 1160.5,300 1059.5,300 890,-120 1041.7,-120 1110,144 1178.3,-120 		"/> </svg>  }
export function Img9({className, style}) { return <svg className={className+" SVG_9"} style={style} viewBox="0 0 520 460" > <polygon points="20,20 140,20 129.1,229 140,440 20,440 	"/> <polygon points="380,20 500,20 500,440 380,440 390.9,229 	"/> </svg>  }
export function ImgG1({className, style}) { return <svg className={className+" SVG_g1"} style={style} viewBox="380 -240 860 580" > <polygon points="600,-205 410,260 555,260 600,80 645,260 790,260 "/> <linearGradient gradientUnits="userSpaceOnUse" x1="800" y1="72.5" x2="1241.4702" y2="72.5"> <stop offset="0" /> <stop offset="1" /> </linearGradient> <polygon points="1020,305 1210,-160 1065,-160 1020,20 975,-160 830,-160 "/> </svg>  }
export function ImgG2({className, style}) { return <svg className={className+" SVG_g2"} style={style} viewBox="190 -180 480 460" > <linearGradient gradientUnits="userSpaceOnUse" x1="210" y1="50" x2="651.4703" y2="50"> <stop offset="0" /> <stop offset="1" /> </linearGradient> <polygon points="210,-160 210,260 340,260 320,50 340,-160 		"/> <linearGradient gradientUnits="userSpaceOnUse" x1="210" y1="50" x2="651.4703" y2="50"> <stop offset="0" /> <stop offset="1" /> </linearGradient> <polygon points="520,-160 540,50 520,260 650,260 650,-160 		"/> </svg>  }
export function ImgG3({className, style}) { return <svg className={className+" SVG_g3"} style={style} viewBox="220 -180 540 460" > <linearGradient gradientUnits="userSpaceOnUse" x1="310" y1="260" x2="310" y2="-159.3447"> <stop offset="0" /> <stop offset="1" /> </linearGradient> <rect x="240" y="-160" width="140" height="420"/> <rect x="600" y="-160" width="140" height="420"/> </svg>  }
export function ImgG4({className, style}) { return <svg className={className+" SVG_g4"} style={style} viewBox="90 -170.1 380 460" > <linearGradient gradientUnits="userSpaceOnUse" x1="280" y1="-128.22" x2="280" y2="291.1247" gradientTransform="matrix(1 0 0 1 0 -21.89)"> <stop offset="0" /> <stop offset="1" /> </linearGradient> <path d="M186.5,55.9c0.9,0.3,1.8,0.5,2.6,0.8c-0.2,0.1-0.4,0.1-0.6,0.2c-42,15-69,51-69,93c0,68,60,120,138,120h80 c63,0,108-45,108-100c0-47-28-87-72-106c-0.9-0.3-1.8-0.5-2.6-0.8c0.2-0.1,0.4-0.1,0.6-0.2c42-15,69-51,69-93c0-68-60-120-138-120 h-80c-63,0-108,45-108,100C114.5-3.1,142.5,36.9,186.5,55.9z M245.5-34.1h65c13,0,23,8.5,23,24.4s-10,25.6-23,25.6h-65 c-13,0-23-9.8-23-25.6S232.5-34.1,245.5-34.1z M314.5,153.9h-65c-13,0-23-8.5-23-24.4s10-25.6,23-25.6h65c13,0,23,9.8,23,25.6 S327.5,153.9,314.5,153.9z"/> </svg>  }
export function ImgG5({className, style}) { return <svg className={className+" SVG_g5"} style={style} viewBox="310 -150 720 400" > <linearGradient gradientUnits="userSpaceOnUse" x1="492.5" y1="289" x2="492.5" y2="-130.1444"> <stop offset="0" /> <stop offset="1" /> </linearGradient> <path d="M555,230h95V50H530v40c-10,0-19,0-20,0c-24,0-40-20-40-40s16-40,40-40c5,0,75,0,140,0l-35-140 h-60c-120,0-220,80-220,180S435,230,555,230z"/> <linearGradient gradientUnits="userSpaceOnUse" x1="847.5" y1="-130" x2="847.5" y2="291.019"> <stop offset="0" /> <stop offset="1" /> </linearGradient> <path d="M785-130h-95V50h120V10c10,0,19,0,20,0c24,0,40,20,40,40s-16,40-40,40c-5,0-75,0-140,0l35,140 h60c120,0,220-80,220-180S905-130,785-130z"/> </svg>  }
export function ImgG6({className, style}) { return <svg className={className+" SVG_g6"} style={style} viewBox="110 -100 320 300" > <linearGradient gradientUnits="userSpaceOnUse" x1="190" y1="179" x2="190" y2="-240.1444"> <stop offset="0" /> <stop offset="1" /> </linearGradient> <rect x="130" y="-80" width="120" height="260"/> <linearGradient gradientUnits="userSpaceOnUse" x1="350" y1="-240" x2="350" y2="181.019"> <stop offset="0" /> <stop offset="1" /> </linearGradient> <rect x="290" y="-80" width="120" height="260"/> </svg>  }
export function ImgG7({className, style}) { return <svg className={className+" SVG_g7"} style={style} viewBox="195 -30 490 160" > <polygon points="650,110 510,110 430,-10 570,-10 "/> <linearGradient gradientUnits="userSpaceOnUse" x1="340" y1="-310" x2="340" y2="111.019"> <stop offset="0" /> <stop offset="1" /> </linearGradient> <polygon points="450,110 310,110 230,-10 370,-10 "/> </svg>  }
export function ImgG8({className, style}) { return <svg className={className+" SVG_g8"} style={style} viewBox="380 -240 860 580" > <polygon points="600,-205 410,260 555,260 600,80 645,260 790,260 "/> <linearGradient gradientUnits="userSpaceOnUse" x1="801" y1="72.5" x2="1240.1639" y2="72.5"> <stop offset="0" /> <stop offset="1" /> </linearGradient> <polygon points="1020,305 1210,-160 1065,-160 1020,20 975,-160 830,-160 "/> </svg>  }
export function ImgG9({className, style}) { return <svg className={className+" SVG_g9"} style={style} viewBox="190 -180 480 460" > <linearGradient gradientUnits="userSpaceOnUse" x1="211" y1="50" x2="650.164" y2="50"> <stop offset="0" /> <stop offset="1" /> </linearGradient> <polygon points="210,-160 210,260 340,260 320,50 340,-160 		"/> <linearGradient gradientUnits="userSpaceOnUse" x1="211" y1="50" x2="650.164" y2="50"> <stop offset="0" /> <stop offset="1" /> </linearGradient> <polygon points="520,-160 540,50 520,260 650,260 650,-160 		"/> </svg>  }
export function ImgR1({className, style}) { return <svg className={className+" SVG_r1"} style={style} viewBox="380 -240 860 580" > <polygon points="600,-205 410,260 555,260 600,80 645,260 790,260 	"/> <polygon points="1020,305 1210,-160 1065,-160 1020,20 975,-160 830,-160 	"/> </svg>  }
export function ImgR2({className, style}) { return <svg className={className+" SVG_r2"} style={style} viewBox="-244 46 480 460" > <polygon points="-224,66 -224,486 -94,486 -114,276 -94,66 		"/> <polygon points="86,66 106,276 86,486 216,486 216,66 		"/> </svg>  }
export function ImgR3({className, style}) { return <svg className={className+" SVG_r3"} style={style} viewBox="-209 46 540 460" > <rect x="-189" y="66" width="140" height="420"/> <rect x="171" y="66" width="140" height="420"/> </svg>  }
export function ImgR4({className, style}) { return <svg className={className+" SVG_r4"} style={style} viewBox="90 -170.1 380 460" > <path d="M186.5,55.9c0.9,0.3,1.8,0.5,2.6,0.8c-0.2,0.1-0.4,0.1-0.6,0.2c-42,15-69,51-69,93c0,68,60,120,138,120h80 c63,0,108-45,108-100c0-47-28-87-72-106c-0.9-0.3-1.8-0.5-2.6-0.8c0.2-0.1,0.4-0.1,0.6-0.2c42-15,69-51,69-93c0-68-60-120-138-120 h-80c-63,0-108,45-108,100C114.5-3.1,142.5,36.9,186.5,55.9z M245.5-34.1h65c13,0,23,8.5,23,24.4s-10,25.6-23,25.6h-65 c-13,0-23-9.8-23-25.6S232.5-34.1,245.5-34.1z M314.5,153.9h-65c-13,0-23-8.5-23-24.4s10-25.6,23-25.6h65c13,0,23,9.8,23,25.6 S327.5,153.9,314.5,153.9z"/> </svg>  }
export function ImgR5({className, style}) { return <svg className={className+" SVG_r5"} style={style} viewBox="-109 76 720 400" > <path d="M136,456h95V276H111v40c-10,0-19,0-20,0c-24,0-40-20-40-40s16-40,40-40c5,0,75,0,140,0L196,96 h-60C16,96-84,176-84,276S16,456,136,456z"/> <path d="M366,96h-95v180h120v-40c10,0,19,0,20,0c24,0,40,20,40,40s-16,40-40,40c-5,0-75,0-140,0 l35,140h60c120,0,220-80,220-180S486,96,366,96z"/> </svg>  }
export function ImgR6({className, style}) { return <svg className={className+" SVG_r6"} style={style} viewBox="-299 131 320 300" > <rect x="-279" y="151" width="120" height="260"/> <rect x="-119" y="151" width="120" height="260"/> </svg>  }
export function ImgR7({className, style}) { return <svg className={className+" SVG_r7"} style={style} viewBox="195 -30 490 160" > <polygon points="650,110 510,110 430,-10 570,-10 		"/> <polygon points="450,110 310,110 230,-10 370,-10 		"/> </svg>  }
export function ImgR8({className, style}) { return <svg className={className+" SVG_r8"} style={style} viewBox="380 -240 860 580" > <polygon points="600,-205 410,260 555,260 600,80 645,260 790,260 	"/> <polygon points="1020,305 1210,-160 1065,-160 1020,20 975,-160 830,-160 	"/> </svg>  }
export function ImgR9({className, style}) { return <svg className={className+" SVG_r9"} style={style} viewBox="-244 46 480 460" > <polygon points="-224,66 -224,486 -94,486 -114,276 -94,66 		"/> <polygon points="86,66 106,276 86,486 216,486 216,66 		"/> </svg>  }