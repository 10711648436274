import React from 'react';

import ModalHeading from '../modal/ModalHeading';
import Checkout from './Checkout';

import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js'

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const stripePromise = loadStripe(process.env.NODE_ENV == "production" ? "pk_live_51I9wtCIJjRw514SloORHCksLlHcXhQxTKoxsRBZDEaOz3I1iFCEUwhbHTLqyGfxLYI5wvMBtyA4nILmQXpGfweuI00CkpMMPhp" : "pk_test_51I9wtCIJjRw514SlDreQcQtRGcFRFu1cSCyeXLedOtAr2HPjYZLVNpqKKg2xfYhO9TgWRcbiQKewZxIWnu0k7SAJ00iX6YgDNu");

export default function CheckoutModal({ close, callback }) {
    return <div className="CheckoutModal modal">
        <ModalHeading title={'Donate'} close={close} />
        <div className="body">
            <Elements stripe={stripePromise}>
                <Checkout close={close} callback={callback}/>
            </Elements>
        </div>
    </div>
}