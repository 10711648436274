import React, { useState, useEffect, useContext } from 'react';
import { useAsync, useGetState, AccountContext, CentreKeyContext } from '../../hooks';
import { NavLink } from 'react-router-dom';
import axios from 'axios';

import Btn from '../controls/Btn';

export default function LeaderboardPanel() {
    let centreKey = useContext(CentreKeyContext);
    let [selected, setSelected] = useState();
    let { user } = useContext(AccountContext);
    let [n, setN, getN] = useGetState(100);
    let [users, setUsers] = useState([]);
    let [reachedEnd, setReachedEnd] = useState();
    let [loading, setLoading] = useState(false);

    useAsync(async () => {
        if (!reachedEnd && users.length < n) {
            setLoading(true);
            let { data } = await axios.post('/api/leaderboard', { n });
            let { results, reachedEnd } = data;
            setUsers(results);
            setReachedEnd(reachedEnd);
            setLoading(false);
        }
    }, [n])

    useEffect(() => {
        if (centreKey && centreKey.indexOf('$') >= 0) {
            let split = centreKey.split('$');
            if (split[0] == 'User') {
                setSelected(split[1]);
            } else setSelected();
        } else setSelected();
    },[centreKey])

    return users.length>0 ? <div className="ContestsPanel Side Panel">
        <h3 className="mb">Leaderboard</h3>
        {users.map(({ username, medals, silver, bronze },i) => <NavLink
            key = {username}
            className = "noUnderline"
            to = {'/user/'+username.toLowerCase()}
        ><div className={username.toLowerCase() == selected ? "chainLink selected" : "chainLink"}>
            <div className="count">{(i+1) < 10 ? '0' + (i+1) : (i+1)}</div>
            <div className="theme">
                <h4>{username}
                        <span className={"med " + (medals?'gold':silver?'silver':'bronze')}>
                            {medals ? <><img src={require('../../pictures/gold.svg')} />{medals>1?"×"+medals:''}</>:null}
                            {silver ? <><img src={require('../../pictures/silver.svg')} />{silver>1?"×"+silver:''}</>:null}
                            {bronze ? <><img src={require('../../pictures/bronze.svg')} />{bronze>1?"×"+bronze:''}</>:null}
                    </span>
                </h4>
            </div>
        </div></NavLink>)}
        {!reachedEnd ? <Btn colour="darkGrey" bold onClick={() => setN(n => n + 100)} loading={loading}>Load more</Btn> : null}
    </div> : null
}