import React, { useContext, useState } from 'react'
import { NavLink } from "react-router-dom";
import { useMount, UsersContext } from '../../hooks';

export default function Username({ username, display, onClick, unClickable, onLoad }) {
    let { getMedals } = useContext(UsersContext);
    let { componentDidMount } = useMount();
    let [medals, setMedals] = useState(0);
    let [silver, setSilver] = useState(false);
    let [bronze, setBronze] = useState(false);
    let [crown, setCrown] = useState(false);

    componentDidMount(async () => {
        let { medals, silver, bronze, crown } = await getMedals(username);
        setMedals(medals);
        setSilver(silver);
        setBronze(bronze);
        setCrown(crown);
        if (onLoad) onLoad();
    })

    let formattedUsername = <>
        {crown ? <span className='crown'>
            <img src={require('../../pictures/crown.svg')} />
        </span> : null}
        {display?display:username}
        {medals > 0 ? <span className='gold'>
            <img src={require('../../pictures/gold.svg')} />
            {medals>1?"×"+medals:null}
        </span>
        : silver > 0 ? <img src={require('../../pictures/silver.svg')} />
        : bronze > 0 ? <img src={require('../../pictures/bronze.svg')} /> 
        : null}
    </>

    return <span className="username" >
        {unClickable ? formattedUsername :onClick ? <div className="A" onClick={onClick}>{formattedUsername}</div>:<NavLink to={'/user/' + username}>
            {formattedUsername}
        </NavLink>}
    </span>
}