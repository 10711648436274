import React, { useContext, useState, useEffect } from 'react'
import { useAsync } from '../../hooks';
import { NavLink } from "react-router-dom";
import { UsersContext, DarkContext } from '../../hooks';

export default function ProfilePic({username, alpha=true, width = 100, square, clickable = true, picture: startingPicture, noLink, animate=true, onLoad }) {
    let { getProfilePic, getTempPic } = useContext(UsersContext);
    let [picture, setPicture] = useState({});
    let [height, setHeight] = useState(width);
    let [imgWidth, setImgWidth] = useState(width);
    let [imgHeight, setImgHeight] = useState(width);
    let { dark } = useContext(DarkContext);

    clickable = false;
    

    useAsync(async () => {
        if (!startingPicture) {
            if (!username) {
                setPicture('?')
            } else {
                let picture = await getProfilePic(username);
                setPicture(picture);
            }
        } else {
            if (startingPicture.constructor.name == 'String') {
                setPicture(getTempPic(startingPicture));
            } else {
                setPicture(startingPicture);
            }
        }
        if (onLoad) onLoad();
    },[startingPicture])

    useEffect(() => {
        if (picture.height) {
            let ratio = picture.height / picture.width;
            let imgWidth = width;
            let imgHeight = imgWidth * ratio;

            let deform = square ? 1 : 3 / 4;

            if (ratio < deform) ratio = deform;
            if (ratio > (1 / deform)) ratio = (1 / deform);
            let height = width * ratio;
            if (imgHeight < height) {
                imgHeight = height;
                let ratio = picture.width / picture.height;
                imgWidth = imgHeight * ratio;
            }
            setHeight(height);
            setImgHeight(imgHeight);
            setImgWidth(imgWidth);
        } else {
            setHeight(width);
        }
    }, [width, picture])

    let animated = animate && picture.style && picture.style != 'None';

    let [animation, setAnimation] = useState(0);
    
    let render = picture.path ? <div
        className="ProfilePic"
        onClick={animated ? () => setAnimation(animation + 1) : null}
        onMouseEnter={animated ? () => setAnimation(animation + 1) : null}
        style={{ width, height }}
    >
        <div
            className={"profilePic " + (animated?"animateProfilePic":null)}
            style={{
                width,
                height,
                transform: animated ? picture.style == "Rotational" ? `rotate(${animation * 180}deg)` : picture.style == "Mirror" ? `scale(${((animation + 1) % 2) * 2 - 1}, 1)` : picture.style == "Lake" ? `scale(1, ${((animation + 1) % 2) * 2 - 1})` : null : null
            }}
        >
            <img
                src={'/api/pictures/' + picture.path}
                width={imgWidth}
                style={{
                    left: (width - imgWidth) / 2,
                    top: (height - imgHeight) / 2,
                    backgroundColor: dark || !alpha ? 'white' : null
                }}
            />
        </div>
    </div> : <div className={clickable ? "emptyProfilePic" : 'emptyProfilePic nolink'} style={{ width, height }}>
        <div style={{ 
            width,
            height,
            fontSize: width / 2 + 'pt',
            paddingTop: width * 0.05,
            backgroundColor: picture.colour
        }}>{picture.letter}</div>
    </div>

    return picture ? picture=='?' ? <div className="questionProfilePic" style={{ width, height }}>
        <div style={{ 
            width,
            height,
        }}></div>
    </div> : clickable && !noLink ? <NavLink className="white" to={'/user/' + username.toLowerCase()}>
        {render}
    </NavLink> : render : null
}