import React, { useState, useRef, useEffect, memo } from 'react';
import { useTimeout, useResponsive } from '../../hooks';
import Spinner from '../Logo/Spinner';
import {NavLink} from 'react-router-dom';

export default function Btn({ width, fast, children, disabled, loading, className, onClick = () => { }, onMouseEnter = () => { }, noPadding, bold, colour = "blue", to }) {
    let [down, setDown] = useState(false);
    let ref = useRef();
    let { doTimeout } = useTimeout();
    let [btnWidth, setBtnWidth] = useState(0);
    let [btnHeight, setBtnHeight] = useState(0);
    let { device } = useResponsive();

    useEffect(() => {
        setBtnWidth(ref.current.clientWidth);
        setBtnHeight(ref.current.clientHeight);
    }, [device]);

    return <div className={"BtnContainer" + (noPadding ? ' noPadding' : '') + (disabled ? ' disabled' : '')}>
        {to ?
            <NavLink
                className={
                    (down ? "Btn down" : "Btn") +
                    (className ? ' ' + className : '') +
                    (loading ? ' loading' : '') +
                    (disabled ? ' disabled' : '') +
                    (bold ? ' bold' : '') +
                    ' ' + colour
                }
                to={to}
                onMouseDown={() => setDown(true)}
                onMouseUp={()=>setDown(false)}
                onMouseLeave={() => setDown(false)}
                onMouseEnter={onMouseEnter}
                onClick={disabled?null:fast?onClick:onClick?() => doTimeout(onClick, 0.07, 's'):null}
                ref={ref} 
                style={{ width }}
                type="button"
            >
                {children}
                {loading ? <div className="abs centreFlex" style={{ width: btnWidth,height: btnHeight}}>
                    <Spinner />
                </div>:null}
            </NavLink>
            :
            <button
                className={
                    (down ? "Btn down" : "Btn") +
                    (className ? ' ' + className : '') +
                    (loading ? ' loading' : '') +
                    (disabled ? ' disabled' : '') +
                    (bold ? ' bold' : '') +
                    ' ' + colour
                }
                onMouseDown={() => setDown(true)}
                onMouseUp={()=>setDown(false)}
                onMouseLeave={() => setDown(false)}
                onMouseEnter={onMouseEnter}
                onClick={disabled?null:fast?onClick:() => doTimeout(onClick, 0.07, 's')}
                ref={ref} 
                style={{ width }}
                type="button"
            >
                {children}
                {loading ? <div className="abs centreFlex" style={{ width: btnWidth,height: btnHeight}}>
                    <Spinner />
                </div>:null}
            </button>
        }
    </div>
}