import React from 'react';
import UserPanel from '../user/UserPanel';
import ChallengePanel from '../ambigram/ChallengePanel';
import Pages from './Pages';
import Username from '../user/Username';

export default function ChallengePost({ post, single, page }) {
    return <>
        <div className="Post" >
            <div className="postLeft">
                <UserPanel post={post}/>
            </div>
            <div className="postRight">
                <ChallengePanel post={post} single={single} />
            </div>
        </div>
        {single && post.closed && !post.failed?<Pages
            query={{ type: 'ChallengeEntry', word: post.word }}
            heading={<div className="congrats">
                <h4>And the winner is... <Username username={post.winner.username}/></h4>
                <p>Here are all the entries in order of their rating.</p>
            </div>}
            startingPage={page}
            url={post.url+'/'}
        />:null}
    </>
}