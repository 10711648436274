import React, { useEffect, useState } from 'react';
import { useChange } from '../../hooks';
import { ImgSmallArrowLeft, ImgSmallArrowRight } from '../../pictures/SVG';
import Btn from '../controls/Btn';


function YearSelect({ year, setYear }) {
    return <div className="yearSelect">
        <Btn onClick={()=>setYear(year-1)}><ImgSmallArrowLeft /></Btn>
        <div className="year">{year}</div>
        <Btn onClick={()=>setYear(year+1)}><ImgSmallArrowRight/></Btn>
    </div>
}

let months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

function MonthSelect({ month, setMonth }) {
    return <div className="monthSelect">
        <Btn onClick={() => {
            month--;
            while (month < 0) month += 12;
            setMonth(month)
        }}><ImgSmallArrowLeft /></Btn>
        <div className="month">{months[month]}</div>
        <Btn onClick={()=>setMonth((month+1)%12)}><ImgSmallArrowRight/></Btn>
    </div>
}

export default function DateSelect({ date, setDate }) {
    let [year, setYear] = useState(new Date(date).getFullYear());
    let [month, setMonth] = useState(new Date(date).getMonth());
    let [day, setDay] = useState(new Date(date).getDate());
    let [daysInMonth, setDaysInMonth] = useState(0);
    let [spacing, setSpacing] = useState(0);

    let [selected, setSelected] = useState({ year, month, day });

    useEffect(() => {
        setDaysInMonth(new Date(year, month + 1, 0).getDate());
        let d = new Date(year, month, 1).getDay() - 1;
        while (d < 0) d += 7;
        setSpacing(d*1.5)
    }, [year, month])

    useChange(() => {
        setDate(new Date(selected.year, selected.month, selected.day).getTime());
    },[selected])

    let dates = [];
    for (let i = 0; i < daysInMonth; i++) {
        dates.push(i + 1);
    }
    
    return <div className="dateSelect">
        <div className="yearMonthSelect">
            <YearSelect year={year} setYear={setYear}/>
            <MonthSelect month={month} setMonth={setMonth}/>
        </div>
        <div className="dates">
            <div className="spacer" style={{width:`${spacing}rem`}}></div>
            {dates.map((d,i) => <Btn noPadding className={`day ${selected.year==year && selected.month == month && selected.day == d ? 'selected':''}`} key={`${d}_${month}_${year}`} colour={selected.year==year && selected.month == month && selected.day == d ? 'white':'blue'} onClick={()=>setSelected({year,month,day:d})}>{d}</Btn>)}
        </div>
    </div>
}

let th = ['th','st','nd','rd','th','th','th','th','th','th']

function ds(date) {
    let d = new Date(date);
    return `${d.getDate()}${th[d.getDate() % 10]} ${months[d.getMonth()]} ${d.getFullYear()}`
}
