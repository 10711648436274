import React, { useState, useEffect, memo } from 'react';
import Shrinker from './Shrinker';
import Btn from './Btn';
import {ImgDropdown} from '../../pictures/SVG';

export default function Expand({ children, label, disabled, setExpanded: setExpanded2 = () => { }}) {
    let [expanded, setExpanded] = useState(false);
    let COLOUR = disabled ? 'LIGHTGREY' : 'BLUE';
    let colour = disabled ? 'lightGrey' : 'blue';
    useEffect(() => {
        setExpanded2(expanded);
    },[expanded])
    return <div className="Expand">
        <div className="expand">
            <Btn onClick={()=>setExpanded(e=>!e)} colour={colour} disabled={disabled}>
                {label}<ImgDropdown className={expanded ? 'expanded ' + COLOUR : COLOUR} />
            </Btn>
        </div>
        <div className="expandedBit">
            <Shrinker unrender speed={0.2} expanded={expanded}>{children}</Shrinker>
        </div>
    </div>
}