import React, { useState, useContext, useEffect } from 'react';
import { ResponsiveContext, CurrencyContext, useAsync } from '../../hooks';
import Ambigram from '../ambigram/Ambigram';
import Btn from '../controls/Btn';
import P from '../controls/P';
import ProfilePic from '../user/ProfilePic';
import { NavLink } from "react-router-dom";

export default function AdvertPreview({ advert }) {
    let { device, smallDesktop } = useContext(ResponsiveContext);
    let { currency, convertCurrency } = useContext(CurrencyContext);

    let [width, setWidth] = useState(600);
    let [price, setPrice] = useState();

    let [small, setSmall] = useState(false);
    
    useEffect(() => {
        setSmall(smallDesktop);
    }, [smallDesktop])

    useEffect(() => {
        if (device == 'phone') {
            setWidth(400);
        } else if (device == 'tablet') {
            setWidth(500);
        } else setWidth(600);
    }, [device])

    useAsync(async () => {
        if (currency.code == advert.currency.code) {
            setPrice(advert.personalPrice);
        } else if (currency.code == 'GBP') {
            setPrice(advert.personalPounds);
        } else {
            setPrice();
            let price = await convertCurrency(advert.personalPounds);
            setPrice(price);
        }
    },[currency])

    return <div className="AdvertPreview Post" >
        <div className="postLeft">
            <div className={"UserPanel"}>
                <div className="pictureBit">
                    <ProfilePic username={advert.username} width={small?50:100} square={small} />
                </div>
                {small?<NavLink to={'/advert/'+advert.username.toLowerCase()}><h3 className="big">{advert.name}</h3></NavLink>:null}
            </div>
        </div>
        <div className="postRight">
            {small ? null :<NavLink to={'/advert/'+advert.username.toLowerCase()}><h2>{advert.name}</h2></NavLink>}
            <P>{advert.introduction}</P>
            <Ambigram picture={advert.picture} style={advert.style} bounds={width} />
            <div className="priceAndButton">
                <p>Prices from <b>{price ? currency.symbol + Math.round(price) : advert.currency.symbol + advert.personalPrice}</b></p>
                <Btn className="white" to={'/advert/'+advert.username.toLowerCase()}>View more</Btn>
            </div>
            {/* <AmbigramPanel post={post}/> */}
        </div>
    </div>
}