import React, { useRef, useEffect, useContext } from 'react';
import { useGetState, useTimeout, ModalContext, AccountContext, SocketContext } from '../../hooks';
import ProfilePic from '../user/ProfilePic';
import Btn from '../controls/Btn.js';
import Login from '../forms/Login';
import Signup from '../forms/Signup';
import Shrinker from '../controls/Shrinker';
import VerificationMessage from '../forms/VerificationMessage';

import { Comment, WriteComment } from './Comment.js';

import Appender from '../controls/Appender';

export default function Comments({ post, n = 3 }) {
    let { user } = useContext(AccountContext);
    let [show, setShow, getShow] = useGetState(n);
    let { doTimeout } = useTimeout();
    let socket = useContext(SocketContext);
    let [renders, setRenders] = useGetState();
    let appender = useRef();

    useEffect(() => {
        let doAction = user && socket;
        if (doAction) {
            socket.emit('postConnect', { _id: post._id });
            socket.on('comment_' + post._id, (data) => {
                console.log(data);
                addComment(data)
            })
        }
        return () => {
            if (doAction) {
                socket.emit('postDisconnect', { _id: post._id });
                socket.off('comment_' + post._id);
            }
        }
    }, [user, socket]);

    useEffect(() => {
        let renders = [];
        let min = post.comments.length - show;
        if (min < 0) min = 0;
        for (let i = min; i < post.comments.length; i++) renders.push(<Comment key={post.comments[i].id} comment={post.comments[i]} post={post} />);
        setRenders(renders);
    }, []);

    function addComment(comment) {
        console.log(comment);
        appender.current.push(<Comment key={comment.id} comment={comment} post={post} />);
    }

    if (post.deleted) return null;

    return <div className="Comments">
        <Shrinker expanded={post.comments.length > show} unrender>
            <div className="loadMore">
                <div className="dotted" />
                <Btn colour="darkGrey" onClick={() => {
                    let show2 = show + 5;
                    if (show2 > post.comments.length) show2 = post.comments.length;
                    let renders = [];
                    let min = post.comments.length - show2;
                    if (min < 0) min = 0;
                    let max = post.comments.length - show;
                    for (let i = min; i < max; i++) {
                        renders.push(<Comment key={post.comments[i].id} comment={post.comments[i]} post={post}/>);
                    }
                    appender.current.unshift(renders);
                    setShow(show2);
                }}>Load more</Btn>
                <div className="dotted"/>
            </div>
        </Shrinker>

        {renders ? <Appender
            ref={appender}
            renders={renders}
        /> : null}
        {user && user.verified ? <WriteComment addComment={addComment} post={post} /> : <LoginPrompt />}
    </div>
}

function LoginPrompt() {
    let Modals = useContext(ModalContext);
    let { user } = useContext(AccountContext);

    function login() {
        if (user) {
            if (!user.verified) {
                Modals.create(VerificationMessage, { feature: true });
            }
        } else {
            if (localStorage.getItem('happy')) {
                Modals.create(Login, {feature:true});
            } else {
                Modals.create(Signup, {feature:true});
            }
        }
    }

    return <div className="loginPrompt">
        {user ? <ProfilePic width={30} username={user.username} square />:<div className="pp" />}
        <button className="dotted" onClick={login}>Write a comment</button>
    </div>
}