import React, { useState, useEffect, useContext } from 'react';
import { useAsync, useGetState, AccountContext, CentreKeyContext, ContestForceRenderContext } from '../../hooks';
import { NavLink } from 'react-router-dom';
import axios from 'axios';

import Btn from '../controls/Btn';

export default function DuelsPanel() {
    let centreKey = useContext(CentreKeyContext);
    let [selected, setSelected] = useState();
    let { user } = useContext(AccountContext);
    let [n, setN, getN] = useGetState(100);
    let [duels, setDuels] = useState([]);
    let [current, setCurrent] = useState();
    let [reachedEnd, setReachedEnd] = useState();
    let [loading, setLoading] = useState(false);
    let [contestForce, contestForceRender] = useContext(ContestForceRenderContext);

    useAsync(async () => {
        let { data } = await axios.post('/api/current-duel', { user });
        setCurrent(data);
    },[contestForce])

    useAsync(async () => {
        if (!reachedEnd && duels.length < n) {
            setLoading(true);
            let { data } = await axios.post('/api/duels', { n, user });
            let { results, reachedEnd } = data;
            setDuels(results);
            setReachedEnd(reachedEnd);
            setLoading(false);
        }
    }, [n, contestForce])

    useEffect(() => {
        if (centreKey && centreKey.indexOf('*') >= 0) {
            let split = centreKey.split('*');
            if (split[0] == 'Duel') {
                setSelected(split[1]);
            } else setSelected();
        } else setSelected();
    },[centreKey])

    return duels.length>0 || current ? <div className="ContestsPanel Panel">
        <h3 className="mb">All duels</h3>
        {current ? <NavLink
            className="noUnderline"
            to={current.url}
        ><div className={"chainLink" + (selected == '' || current.url == selected ? ' selected' : '')}>
            <div className="count">{current.count < 10 ? '0' + current.count : current.count}</div>
            <div className="theme">
                <h4>{current.word}</h4>
            </div>
        </div></NavLink> : null}
        {duels.map(({ count, word, url }) => <NavLink
            key = {url}
            className = "noUnderline"
            to = {url}
        ><div className={url == selected ? "chainLink selected" : "chainLink"}>
            <div className="count">{count < 10 ? '0' + count : count}</div>
            <div className="theme"><h4>{word}</h4></div>
        </div></NavLink>)}
        {!reachedEnd ? <Btn colour="darkGrey" bold onClick={() => setN(n => n + 100)} loading={loading}>Load more</Btn> : null}
    </div> : null
}