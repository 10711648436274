import React, {useState, useRef} from 'react';
import ModalHeading from '../modal/ModalHeading';
import Thumbnail from '../controls/Thumbnail';
import Btn from '../controls/Btn';
import Button from '../controls/Button';
import Shrinker from '../controls/Shrinker';
import { useForceRender, useTimeout } from '../../hooks';
import { specialToString, stringToSpecial } from '../../functions';

import { ImgHflip, ImgRotleft, ImgRotright, ImgVflip, ImgX } from '../../pictures/special/SVG';
import { ImgDropdown } from '../../pictures/SVG';

export default function Special({ special, setSpecial, close, picture }) {
    let k = useRef(1);
    let { doTimeout } = useTimeout();
    let [force, forceRender] = useForceRender();
    let [orientations, setOrientations] = useState(special ? stringToSpecial(special) : [{ rotation: 0, hflip: false, vflip: false, key:-1 }])
    let src = picture ? '/api/pictures/' + picture : require('../../pictures/Aa.bmp');

    return <div className="Special modal">
        <ModalHeading title="Custom animation" close={close} />
        <div className="body">
            <label>Orientations:</label>
            {orientations.map((o, i) => <div className={o.animate ? 'orientationAnimate' : null} key={o.key}>
                <Shrinker expanded={!o.disappear} speed={0.2}>
                    <div className="orientation">
                        <label>{i + 1}.</label>
                        <div className="thumb" style={{
                            transform: `rotate(${o.rotation}deg) scale(${o.hflip ? -1 : 1},${o.vflip ? -1 : 1})`
                        }}>
                            <Thumbnail height={35} ratio={2} src={src} />
                        </div>

                        {i == 0 ? <div className="spacer" /> : <>
                            <Btn onClick={() => {
                                o.rotation -= 90;
                                setOrientations(orientations);
                                forceRender();
                            }}><ImgRotleft className="rotleft BLUE" /></Btn>
                            <Btn onClick={() => {
                                o.rotation += 90;
                                setOrientations(orientations);
                                forceRender();
                            }}><ImgRotright className="rotright BLUE"/></Btn>
                            <Btn onClick={() => {
                                if (o.rotation % 180 == 0) {
                                    o.hflip = !o.hflip;
                                } else {
                                    o.vflip = !o.vflip;
                                }
                                setOrientations(orientations);
                                forceRender();
                            }}><ImgHflip className="hflip BLUE" /></Btn>
                            <Btn onClick={() => {
                                if (o.rotation % 180 == 0) {
                                    o.vflip = !o.vflip;
                                } else {
                                    o.hflip = !o.hflip;
                                }
                                setOrientations(orientations);
                                forceRender();
                            }}><ImgVflip className="vflip BLUE" /></Btn>
                            <Btn onClick={() => {
                                let key = o.key;
                                o.disappear = key;
                                setOrientations(orientations);
                                forceRender();
                                doTimeout(() => {
                                    orientations = orientations.filter(o => o.disappear != key);
                                    setOrientations(orientations);
                                    forceRender();
                                }, 0.2, 's')
                            }}><ImgX className="X RED" /></Btn>
                        </>}
                    </div>
                    <div className="downArrow GREY">
                        <ImgDropdown />
                    </div>
                </Shrinker>
            </div>)}
            <div className="centreFlex addButtonBit">
                <Button
                    onClick={() => {
                        let o = {
                            rotation: orientations[orientations.length-1].rotation,
                            hflip: orientations[orientations.length-1].hflip,
                            vflip: orientations[orientations.length - 1].vflip,
                            animate: true,
                            key:k.current++
                        }
                        orientations.push(o)
                        setOrientations(orientations);
                        forceRender();
                        doTimeout(() => {
                            o.animate = false;
                            setOrientations(orientations);
                            forceRender();
                        },0.2,'s')
                    }}
                    disabled={orientations.length>=8}
                >Add orientation</Button>
            </div>
            <div className="formButtons">
                <Button onClick={() => {
                    let special = specialToString(orientations);
                    setSpecial(special);
                    close();
                }}>Save</Button>
                <Button colour="red" onClick={close}>Cancel</Button>
            </div>
        </div>
    </div>
}