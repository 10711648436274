import React, { memo, useState, useContext } from 'react';
import P5Wrapper from 'react-p5-wrapper';
import Button from '../controls/Button';
import AnimationSettings from '../forms/AnimationSettings';
import { ModalContext, AccountContext, useAsync, useMount } from '../../hooks';
import axios from 'axios';
import Btn from '../controls/Btn';


let Sketch = memo(({ style, path, dimensions, framerate, motionBlur, secondsGap, setCompletion, setFrames }) => {
    function sketch(p) {
        let img, W = dimensions, w, h, s, blur=[], screen, raw;
        let curve = [0.000,0.0006876,0.002873,0.0067683,0.0126332,0.0207885,0.0316353,0.0456814,0.0635754,0.0861506,0.1144718,0.1498591,0.193804,0.2475713,0.3111828,0.3819875,0.4545632,0.5232941,0.5849651,0.6388412,0.6854919,0.7258887,0.7610001,0.7916632,0.818566,0.8422652,0.8632103,0.8817663,0.8982318,0.9128532,0.925836,0.9373529,0.9475501,0.9565529,0.964469,0.9713919,0.9774029,0.9825736,0.9869673,0.9906399,0.9936414,0.9960164,0.9978052,0.999044,0.9997656,1.0];
        let rot = [], hScale = [], vScale = [], max = 0, instructions;
        let f = 0, gap = 30 * secondsGap + 44;
        let prevR, prevH, prevV, prevprevR = 666.666, prevprevH = 666.666, prevprevV = 666.666;
        function setPixel(pixels, I, r, g, b, a=255) {
            pixels[I * 4] = r; pixels[I * 4 + 1] = g; pixels[I * 4 + 2] = b; pixels[I * 4 + 3] = a;
        }
        let red = (pixels, i) => pixels[i * 4], green = (pixels, i) => pixels[i * 4 + 1], blue = (pixels, i) => pixels[i * 4 + 2];
        p.preload = () => {
            raw = p.loadImage('/api/pictures/' + path);
        }
        let canvas;
        p.setup = () => {
            if (style == 'Ottogram') style = 'Special|180/1/1|0/-1/1|180/1/1|0/-1/1'
            if (style == 'Rotational') {
                rot = [0, 180, 360];
                hScale = [1, 1, 1];
                vScale = [1, 1, 1];
            } else if (style == 'Mirror') {
                rot = [0, 0, 0];
                hScale = [1, -1, 1];
                vScale = [1,  1, 1];
            } else if (style == 'Lake') {
                rot = [0, 0, 0];
                hScale = [1,  1, 1];
                vScale = [1, -1, 1];
            } else if (style.substr(0, 7) == 'Special') {
                let specials = style.split('|');
                specials.shift();
                let R = 0;
                let H = 1;
                let V = 1;
                rot = [R];
                hScale = [H];
                vScale = [V];
                specials.forEach(s => {
                    let [r, h, v] = s.split('/');
                    R += parseInt(r);
                    H *= parseInt(h);
                    V *= parseInt(v);
                    rot.push(R);
                    hScale.push(H);
                    vScale.push(V);
                })
            } else if (style.substr(0, 6) == 'Custom') {
                let r = parseInt(style.substr(6, style.length - 6));
                let R = 0;
                rot = [R];
                hScale = [1];
                vScale = [1];
                while (R < 360) {
                    R += r;
                    rot.push(R);
                    hScale.push(1);
                    vScale.push(1);
                }
            }
            instructions = rot.length;
            max = (instructions - 1) * gap;
            prevR = rot[0]; prevH = hScale[0]; prevV = vScale[0];
            canvas=p.createCanvas(W, W);
            document.getElementById("defaultCanvas0").style.width = '200px';
            document.getElementById("defaultCanvas0").style.height = '200px';
            screen = p.createImage(W, W); img = p.createImage(W, W); img.loadPixels(); raw.loadPixels();
            for (let i = 0; i < motionBlur; i++) blur.push(p.createImage(W, W));
            w = raw.width; h = raw.height;
            let WW = W, ww = w, hh = h;
            s = w > h ? ww / WW : hh / WW;
            let dx = - (WW * s - ww) / 2, dy = - (WW * s - hh) / 2;
            for (let X = 0; X < W; X++) for (let Y = 0; Y < W; Y++) {
                let I = X + Y * W, xx = X * s + dx, yy = Y * s + dy;
                setPixel(img.pixels, I, 255, 255, 255);
                let x0 = Math.floor(xx), y0 = Math.floor(yy), x1 = Math.floor((X + 1) * s + dx), y1 = Math.floor((Y + 1) * s + dy);
                if (x1 == x0) x1++; if (y1 == y0) y1++;
                let xx0 = Math.floor(x0 < 0 ? 0 : x0 >= w ? w - 1 : x0), xx1 = Math.floor(x1 < 0 ? 0 : x1 >= w ? w - 1 : x1);
                let yy0 = Math.floor(y0 < 0 ? 0 : y0 >= h ? h - 1 : y0), yy1 = Math.floor(y1 < 0 ? 0 : y1 >= h ? h - 1 : y1);
                if (s > 1) {
                    if (xx1 == xx0) xx1++; if (yy1 == yy0) yy1++;
                    let r = 0, g = 0, b = 0;
                    for (let x = xx0; x < xx1; x++) for (let y = yy0; y < yy1; y++) {
                        let i = x + y * w;
                        r += red(raw.pixels, i); g += green(raw.pixels, i); b += blue(raw.pixels, i);
                    }
                    let total = (xx1 - xx0) * (yy1 - yy0);
                    if (x0 >= 0 && x0 < w && y0 >= 0 && y0 < h) setPixel(img.pixels, I, r/total, g/total, b/total);
                } else {
                    let x2 = (xx - xx0), y2 = (yy - yy0);
                    let [r, g, b] = lerp4(raw, w, h, xx0, yy0, xx1, yy1, x2, y2);
                    if (x0 >= 0 && x0 < w && y0 >= 0 && y0 < h) setPixel(img.pixels, I, r, g, b);
                }
            }
            img.updatePixels();
        }
        
        function lerp4(img, w, h, x0, y0, x1, y1, dx, dy) {
            let i00 = x0 + y0 * w, i01 = x0 + y1 * w, i10 = x1 + y0 * w, i11 = x1 + y1 * w;
            if (dx < 0) dx = 1 + dx;
            let B00 = x0 >= 0 && x0 < w && y0 >= 0 && y0 < h, B01 = x0 >= 0 && x0 < w && y1 >= 0 && y1 < h;
            let B10 = x1 >= 0 && x1 < w && y0 >= 0 && y0 < h, B11 = x1 >= 0 && x1 < w && y1 >= 0 && y1 < h;
            let r00 = B00 ?   red(img.pixels,i00) : 255, r01 = B01 ?   red(img.pixels,i01) : 255;
            let r10 = B10 ?   red(img.pixels,i10) : 255, r11 = B11 ?   red(img.pixels,i11) : 255;
            let g00 = B00 ? green(img.pixels,i00) : 255, g01 = B01 ? green(img.pixels,i01) : 255;
            let g10 = B10 ? green(img.pixels,i10) : 255, g11 = B11 ? green(img.pixels,i11) : 255;
            let b00 = B00 ?  blue(img.pixels,i00) : 255, b01 = B01 ?  blue(img.pixels,i01) : 255;
            let b10 = B10 ?  blue(img.pixels,i10) : 255, b11 = B11 ?  blue(img.pixels,i11) : 255;
            let r0 = p.lerp(r00, r01, dy), g0 = p.lerp(g00, g01, dy), b0 = p.lerp(b00, b01, dy);
            let r1 = p.lerp(r10, r11, dy), g1 = p.lerp(g10, g11, dy), b1 = p.lerp(b10, b11, dy);
            return [p.lerp(r0, r1, dx), p.lerp(g0, g1, dx), p.lerp(b0, b1, dx)];
        }
        
        function paint(tmp, rr, h, v) {
            let ddxx = rr % 360 < 180 ? 1 * ((rr % 360) / 180) : 1 * (1 - (((rr % 360) - 180) / 180));
            if (h < 0) ddxx = 1 - ddxx;
            let r = - p.radians(rr);
            img.loadPixels(); tmp.loadPixels();
            for (let X = 0; X < W; X++) for (let Y = 0; Y < W; Y++) {
                let I  = X + Y * W, DX = W / 2.0 - X - ddxx, DY = W / 2.0 - Y, xx, yy;
                if (rr != 0.0) {
                    let d = Math.sqrt(DX * DX + DY * DY), R = Math.atan2(DY, DX);
                    xx = (Math.cos(r + R + p.PI) * d) / h + W / 2.0; yy = (Math.sin(r + R + p.PI) * d) / v + W / 2.0;
                } else {
                    xx = W / 2.0 - DX / h; yy = W / 2.0 - DY / v;
                }
                let x0 = Math.floor(xx), y0 = Math.floor(yy);
                let x1 = x0 + 1, y1 = y0 + 1, x2 = xx - x0, y2 = yy - y0;
                let blurX = (1.0 / (Math.abs(h) < 0.2 ? 0.2 : Math.abs(h)));
                let blurY = (1.0 / (Math.abs(v) < 0.2 ? 0.2 : Math.abs(v)));
                blurX *= blurX; blurY *= blurY;
                if (blurX <= 3 && blurY <= 3) {
                    setPixel(tmp.pixels, I, ...lerp4(img, W, W, x0, y0, x1, y1, x2, y2));
                } else {
                    x0 = Math.floor((xx - (blurX/2))+1); x1 = Math.floor((xx + (blurX/2))+1);
                    y0 = Math.floor((yy - (blurY/2))+1); y1 = Math.floor((yy + (blurY/2))+1);
                    if (x1 == x0) x1++; if (y1 == y0) y1++;
                    let r = 0, g = 0, b = 0;
                    for (let x = x0; x < x1; x++) for (let y = y0; y < y1; y++) {
                        if (x >= 0 && x < W && y >= 0 && y < W) {
                            let i = x + y * W;
                            r += red(img.pixels,i); g += green(img.pixels,i); b += blue(img.pixels,i);
                        } else {
                            r += 255; g += 255; b += 255;
                        }
                    }
                    let total = (x1 - x0) * (y1 - y0);
                    setPixel(tmp.pixels, I, r/total, g/total, b/total);
                }
            }
            tmp.updatePixels();
        }
        let tween = (v0, v1, i) => i >= curve.length ? v1 : p.lerp(v0, v1, curve[i]);
        function copyPixels(img0, img1) {
            img0.loadPixels(); img1.loadPixels();
            for (let i = 0; i < img0.pixels.length; i++) img1.pixels[i] = img0.pixels[i];
            img0.updatePixels();
        }
        let frames = [];
        p.draw = () => {
            if (f <= max) {
                setCompletion(f / max);
                let F = f / gap, i0 = Math.floor(F), i1 = i0 + 1, i = f % gap;
                if (i0 >= instructions) i0 = instructions - 1; if (i1 >= instructions) i1 = instructions - 1;
                let r0 =    rot[i0 % instructions], r1 =    rot[i1 % instructions];
                let h0 = hScale[i0 % instructions], h1 = hScale[i1 % instructions];
                let v0 = vScale[i0 % instructions], v1 = vScale[i1 % instructions];
                let r = tween(r0, r1, i), h = tween(h0, h1, i), v = tween(v0, v1, i);
                if (prevR == r && prevH == h && prevV == v){
                    if (prevprevR == r && prevprevH == h && prevprevV == v) {
                        copyPixels(screen,blur[0]);
                    } else {
                        paint(blur[0], r, h, v);
                        copyPixels(screen,blur[0]);
                    }
                    prevprevR = r; prevprevH = h; prevprevV = v;
                } else {
                    prevprevR = 666.666;
                    for (let ii = 0; ii < motionBlur; ii++) {
                        let I = (motionBlur - (ii / 2)) / motionBlur;
                        paint(blur[ii], p.lerp(prevR, r, I), p.lerp(prevH, h, I), p.lerp(prevV, v, I));
                        blur[ii].loadPixels();
                    }
                    screen.loadPixels();
                    for (let x = 0; x < W; x++) for (let y = 0; y < W; y++) {
                        let ii = x + y * W, R = 0, G = 0, B = 0;
                        for (let j = 0; j < motionBlur; j++) {
                            R += red(blur[j].pixels, ii); G += green(blur[j].pixels, ii); B += blue(blur[j].pixels, ii);
                        }
                        setPixel(screen.pixels, ii, R/motionBlur, G/motionBlur, B/motionBlur);
                    }
                    screen.updatePixels();
                }
                p.image(screen, 0, 0);
                let dataURL = canvas.elt.toDataURL('image/jpeg');
                if (frames.length > 0 && dataURL == frames[frames.length - 1].data) {
                    frames[frames.length - 1].copies++;
                } else frames.push({data:dataURL, copies:1});
                if (f == max) setFrames(frames);
                f++; prevR = r; prevH = h; prevV = v;
            }
        }        
    }
    return <div className="sketch"><P5Wrapper sketch={sketch} /></div>
})

export default function Animation({ id }) {
    let Modals = useContext(ModalContext);
    let [animating, setAnimating] = useState(false);
    let { user, setUser } = useContext(AccountContext);
    let [dimensions, setDimensions] = useState('1080×1080px');
    let [framerate, setFramerate] = useState('30 fps');
    let [motionBlur, setMotionBlur] = useState('Good quality');
    let [secondsGap, setSecondsGap] = useState('5 seconds');
    let [animated, setAnimated] = useState(false);
    let [completion, setCompletion] = useState(0);
    let [frames, setFrames] = useState();
    let [error, setError] = useState();
    let [path, setPath] = useState();
    let [style, setStyle] = useState();
    let { componentDidMount } = useMount();

    componentDidMount(async () => {
        let { data } = await axios.post('/api/get-post', { post: { _id: id }, user });
        setPath(data.picture.path);
        setStyle(data.style);
    })

    useAsync(async () => {
        if (frames) {
            let { data } = await axios.post('/api/render-animation-0', { path, framerate, user });
            let { key } = data;
            let i = 0;
            for (let x = 1; x < frames.length; x++) {
                let { data } = await axios.post('/api/render-animation-1', { i, ...frames[x], key, user });
                i += frames[x].copies;
            }
            {
                let { data } = await axios.post('/api/render-animation-2', { key, framerate: { '25 fps': 25, '30 fps': 30 }[framerate], user });
                if (data.success) {
                    setAnimated(key);
                } else {
                    setError('Server error!')
                }
            }
        }
    }, [frames])
    

    return <div className="Panel Animation centrepanel">
        <h1>{animating?completion==1?"Generating video...":"Rendering animation "+Math.round(completion*100)+'%':animated?"Download animation":"Render animation"}</h1>
        {!(animated || animating) ? <div className='sketch'><div className="blank"/></div>:<Sketch
            path={path}
            style={style}
            setCompletion={setCompletion}
            setFrames={setFrames}
            dimensions={
                {
                    '480×480px': 480,
                    '640×640px':640,
                    '720×720px':720,
                    '1080×1080px':1080,
                    '1280×1280px':1280,
                    '1920×1920px': 1920
                }[dimensions]
            }
            framerate={{ '25 fps': 25, '30 fps': 30 }[framerate]}
            motionBlur={
                {
                    'None':1,
                    'Basic quality':4,
                    'Good quality':8,
                    'Best quality': 12
                }[motionBlur]
            }
            secondsGap={
                {
                    '1 second':1,
                    '2 seconds':2,
                    '3 seconds':3,
                    '4 seconds':4,
                    '5 seconds':5,
                    '6 seconds':6,
                    '7 seconds':7,
                    '8 seconds':8,
                    '9 seconds':9,
                    '10 seconds':10,
                    '11 seconds':11,
                    '12 seconds':12
                }[secondsGap]
            }
        />}
        {animating ? <div>
            {frames ? <div className='buttons'>
                {animated ?
                    <a className="noUnderline lookslikeabutton" href={`/api/videos/${animated}.mp4`} download>Download</a>
                : <Button 
                    onClick={()=>axios.post('',{key:animated})}
                    loading={true}
                >Download</Button>}
                
                
            </div> : <>
                <div className="loadingBar"><div className="bar" style={{ width: (completion * 100) + '%' }} /></div>
                <div className="warning">You may need to keep this tab open while the animation renders!</div>
            </>}
        </div>:error?<div className="warning">{error}</div>:path?<div className='buttons'>
            <Button onClick={()=>setAnimating(true)}>Generate</Button>
            <Btn onClick={() => Modals.create(AnimationSettings, {
                dimensions: dimensions,
                framerate: framerate,
                motionBlur: motionBlur,
                secondsGap: secondsGap,
                callback: (dimensions, framerate, motionBlur, secondsGap) => {
                    setDimensions(dimensions);
                    setFramerate(framerate);
                    setMotionBlur(motionBlur);
                    setSecondsGap(secondsGap);
                }
            })}>Settings</Btn>
        </div>:null}
    </div>
}
