import React, { useContext } from 'react';
import Expand from '../../controls/Expand';
import { AccountContext } from '../../../hooks';
import { NavLink } from 'react-router-dom';

export default function VotingRules({ }) {
    let { subbed } = useContext(AccountContext);
    
    return <div className="Info Panel centrepanel">
        <h1>Voting rules</h1>
        <h4>It’s time - get your clicking fingers ready!</h4>
        <p>The moment you've all been waiting for – the voting stage of our esteemed ambigram contest has arrived! For one week, you cast your votes, rating the entries on a scale of 1 to 5 stars.</p>
        <p>After the voting process is over, the votes are collected and averaged. If you do not leave a rating on an entry, your vote will not be counted in the average for that ambigram so the results will remain fair. For this reason, it is not necessary to leave a rating on every single design.</p>
        <p>If you are confused about how to judge a design, consider following these steps:</p>
        <ol>
            <li>Start off with 3 stars – this is considered a median rating.</li>
            <li>Remove one star or more if the design is hard to read.</li>
            <li>Add a star if the design is clearly legible.</li>
            <li>Remove a star if the design shows a lack of refinement in its visual presentation.</li>
            <li>Add a star if the design shows particular aesthetic or stylistic beauty.</li>
            <li>Remove a star if the design is not closely related to the theme.</li>
            <li>Add a star if the design relates to the theme in a clever or unique way.</li>
        </ol>
        <p>You may also use your own criteria for voting, but in general a 5 star entry should be legible, beautiful, and in adherence to the theme.</p>
        <p>Here are some more guidelines for you to consider:</p>
        <ul>
            <li><Expand label="Be objective">Rate the entries based on their artistic merit, originality, and adherence to the theme. Keep personal biases and preferences aside to ensure a fair voting process.</Expand></li>
            <li><Expand label="No vote solicitation">Do not solicit votes for your own entry or encourage others to vote in a biased manner. This includes voting on multiple accounts.</Expand></li>
            <li><Expand label="Be aware of the comments section">Refrain from reading the comments section until you have decided on your final ratings; reading any discussion of the designs might influence your vote. These contest posts might attract a lot of comments, so please refrain from posting spammy or irrelevant comments as there will be a lot of people getting notifications.</Expand></li>
            <li><Expand label="Be gentlemanly (or gentlewomanly)">Be gracious in both victory and defeat. Congratulate winners, and appreciate the efforts of all participants. Remember that the primary goal of these contests is to have fun, learn, and grow as artists.</Expand></li>
        </ul>
    </div>
}