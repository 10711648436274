import React, {useState, useContext} from 'react';
import { ModalContext, ToastContext, useSubmit, AccountContext } from '../../hooks';
import Input from '../controls/Input';
import Button from '../controls/Button';
import ModalHeading from '../modal/ModalHeading';
import Toast from '../modal/Toast';
import Captcha from '../controls/Captcha';
import A from '../controls/A';
import Login from './Login';
import VerificationMessage from './VerificationMessage';

import { validateUsername, validateName, validateEmail, validatePassword } from '../../functions';

export default function Signup({ close, feature }) {
    let { signup } = useContext(AccountContext);
    let Toasts = useContext(ToastContext);

    let [username, setUsername] = useState('');
    let [name, setName] = useState('');
    let [email, setEmail] = useState('');
    let [password1, setPassword1] = useState('');
    let [password2, setPassword2] = useState('');

    let [serverError, setServerError] = useState('');
    let [usernameError, setUsernameError] = useState('');
    let [nameError, setNameError] = useState('');
    let [emailError, setEmailError] = useState('');
    let [password1Error, setPassword1Error] = useState('');
    let [password2Error, setPassword2Error] = useState('');
    let [loading, setLoading] = useState('');

    let [c, submit, onSubmit] = useSubmit();

    let Modals = useContext(ModalContext);

    function showCaptcha() {
        return new Promise(resolve => Modals.create(Captcha, {callback: resolve}))
    }

    onSubmit(async () => {
        if (!usernameError && !nameError && !emailError && !password1Error && password1 == password2) {
            setLoading(true);
            setServerError('');
            let { closed, correct } = await showCaptcha();
            if (correct) {
                let { user, error } = await signup({ username, name, email, password: password1 });
                if (error) {
                    setUsernameError(error.username);
                    setNameError(error.name);
                    setEmailError(error.email);
                    setPassword1Error(error.password);
                    setServerError(error.server);
                    if (error.server) Toasts.create(Toast, {preset:'server error'});
                } else {
                    // localStorage.setItem('hearted',':)')
                    Toasts.create(Toast,{text:`Welcome, ${user.username}!`});
                    await close();
                    Modals.create(VerificationMessage);
                }
            } else {
                if (!closed) setServerError('Captcha incorrect, try again!');
            }
            setLoading(false);
        }
    })

    return <div className="Login modal">
        <ModalHeading title="Sign up" close={close} />
        <div className="body">
            {feature ? <p>I'm sorry, need an account to use that feature.</p> : null}
            <p>Already have an account? <A red onClick={async () => {
                await close();
                Modals.create(Login);
            }}>Click here to log in!</A></p>
            
            <form onSubmit={submit}>
                <Input
                    label="Username"
                    value={username}
                    setValue={setUsername}
                    error={usernameError}
                    setError={setUsernameError}
                    validation={validateUsername}
                    attempts={c}
                    disabled={loading}
                />
                <Input
                    label="Name"
                    value={name}
                    setValue={setName}
                    error={nameError}
                    setError={setNameError}
                    validation={validateName}
                    attempts={c}
                    disabled={loading}
                />
                <Input
                    label="Email"
                    value={email}
                    setValue={setEmail}
                    error={emailError}
                    setError={setEmailError}
                    validation={validateEmail}
                    attempts={c}
                    disabled={loading}
                />
                <Input
                    label="Password"
                    type="password"
                    value={password1}
                    setValue={setPassword1}
                    error={password1Error}
                    setError={setPassword1Error}
                    validation={validatePassword}
                    attempts={c}
                    disabled={loading}
                />
                <Input
                    label="Confirm password"
                    type="password"
                    value={password2}
                    setValue={setPassword2}
                    error={password2Error}
                    setError={setPassword2Error}
                    validation={p => {
                        if (p != password1) return 'Passwords do not match!';
                    }}
                    attempts={c}
                    disabled={loading}
                />

                <div className="formButtons">
                    <div className="buttons">
                        <Button submit loading={loading}>Sign up</Button>
                    </div>
                    <div className="error">
                        {serverError}
                    </div>
                </div>
            </form>
        </div>
    </div>
}