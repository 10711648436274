import React, {useRef} from 'react';
import { useGetState, useTimeout } from '../../hooks';
import { ImgCross, ImgLilDropdown, ImgLilDropup } from '../../pictures/SVG';
import Btn from './Btn';
import Button from './Button';
import Shrinker from './Shrinker';

export default function Arranger({ renders, addButtonText='Add', add, callback, number, max }) {
    let k = useRef(0);
    let refs = useRef({});
    let block = useRef(false);
    let [items, setItems, getItems] = useGetState(renders.map(({render, data}) => { return { render, data:JSON.parse(JSON.stringify(data)), key: k.current++, top:0 } }));

    let { doTimeout } = useTimeout();

    function push({render, data}) {
        if (!block.current) {
            block.current = true;
            let items = getItems();
            let key = k.current++;
            items.push({ render: render, data, key, top:0, height:0 });
            setItems(items.slice());
            setTimeout(() => {
                let items = getItems();
                let height = refs.current['$' + key].getBoundingClientRect().height;
                items[items.length - 1].height = height;
                setItems(items.slice());
                block.current = false;
                returnData();
            }, 0);
        }
    }

    function swap(i0, i1) {
        if (!block.current) {
            block.current = true;
            let items = getItems();
            let k0 = items[i0].key;
            let k1 = items[i1].key;
            let top0 = refs.current[k0].getBoundingClientRect().top;
            let top1 = refs.current[k1].getBoundingClientRect().top;
            let d0 = top1 - top0;
            let d1 = top0 - top1;
            items[i0].top = d0;
            items[i1].top = d1;

            setItems(items.slice());
            doTimeout(() => {
                let items = getItems();
                let tmp = items[i0];
                items[i0] = items[i1];
                items[i1] = tmp;
                items[i0].key = k.current++;
                items[i1].key = k.current++;
                items[i0].top = 0;
                items[i1].top = 0;
                setItems(items.slice());
                block.current = false;
                returnData();
            }, 0.2, 's');
        }
    }

    function remove(i) {
        if (!block.current) {
            block.current = true;
            let items = getItems();
            let key = items[i].key;
            let height = refs.current[key].getBoundingClientRect().height;
            items[i].height = height;
            items[i].key = k.current++;
            console.log(height);
            setItems(items.slice());
            setTimeout(() => {
                let items = getItems();
                items[i].height = 0;
                setItems(items.slice());
                doTimeout(() => {
                    let items = getItems();
                    items.splice(i, 1);
                    setItems(items.slice());
                    block.current = false;
                    returnData();
                }, 0.2, 's');
            }, 0);
        }
    }

    function returnData() {
        if (callback) {
            let items = getItems();
            callback(items.map(item => item.data));
        }
    }

    return <div className="Arranger">
        <div className="arranger">
            {items.map(({ render, key, top, height }, i) => <div
                className="arrangeItem"
                key={key}
                ref={r => refs.current[key] = r}
                style={{
                    top,
                    height,
                    overflow: height === undefined ? null : 'hidden'
                }}
            >
                {number ? <div className="arrangerNumber">{i + 1}</div> : null}
                <div
                    className="arrangerLeft"
                    ref={r => refs.current['$' + key] = r}
                >
                    {render}
                </div>
                <div className="arrangerRight">
                    <div className="arrows">
                        <div className="flex1"/>
                        <Btn disabled={i==0} onClick={()=>swap(i, i-1)}>
                            <ImgLilDropup className="DARKGREY"/>
                        </Btn>
                        <Btn disabled={i==items.length-1} onClick={()=>swap(i, i+1)}>
                            <ImgLilDropdown className="DARKGREY"/>
                        </Btn>
                        <div className="flex1"/>
                    </div>
                    <div className="arrangerCross">
                        <Btn onClick={()=>remove(i)}>
                            <ImgCross className="RED"/>
                        </Btn>
                    </div>
                </div>
            </div>)}
        </div>
        <Shrinker expanded={!max || items.length < max}>
            <Button onClick={async () => {
                if (add) {
                    let newRender = await add();
                    push(newRender);
                }
            }}>
                {addButtonText}
            </Button>
        </Shrinker>
    </div>
};