import React, {useState, useContext } from 'react';
import { useSubmit, ToastContext, AccountContext } from '../../hooks';
import Input from '../controls/Input';
import TextArea from '../controls/TextArea';
import Button from '../controls/Button';
import ModalHeading from '../modal/ModalHeading';
import Toast from '../modal/Toast';

import { validateEmail } from '../../functions';
import axios from 'axios';

let validateSubject = subject => {
    if (subject.length == 0) return 'Please provide a subject!';
    if (subject.length < 6) return 'Subject too short!';
    if (subject.length > 100) return 'Subject too long!';
}

let validateMessage = message => {
    if (message.length == 0) return 'Please provide a message!';
    if (message.length < 12) return 'Message too short!';
    if (message.length > 5000) return 'Message too long!';
}

export default function Contact({ close }) {
    let Toasts = useContext(ToastContext);
    let { user } = useContext(AccountContext);

    let [c, submit, onSubmit] = useSubmit();
    let [loading, setLoading] = useState(false);

    let [email, setEmail] = useState(user?user.email:'');
    let [subject, setSubject] = useState('');
    let [message, setMessage] = useState('');

    let [emailError, setEmailError] = useState('');
    let [subjectError, setSubjectError] = useState('');
    let [messageError, setMessageError] = useState('');

    let [serverError, setServerError] = useState('');

    onSubmit(async () => {
        if (!subjectError && !messageError && !emailError) {
            setLoading(true);
            let { data } = await axios.post('/api/contact', { email, subject, message, username:user?user.username:null });
            if (data.success) {
                Toasts.create(Toast, { text:"Your message has been sent!" });
                close();
            } else {
                Toasts.create(Toast, { type: "error", preset: 'server error' });
                setServerError('Server error!');
            }
            setLoading(false);
        }
    })

    return <div className="ShareThought modal">
        <ModalHeading title="Contact" close={close} />
        <div className="body">
            <form onSubmit={submit}>

                <Input
                    label="Email"
                    value={email}
                    setValue={setEmail}
                    error={emailError}
                    setError={setEmailError}
                    validation={validateEmail}
                    attempts={c}
                    disabled={loading}
                />

                <Input
                    label="Subject"
                    value={subject}
                    setValue={setSubject}
                    error={subjectError}
                    setError={setSubjectError}
                    validation={validateSubject}
                    attempts={c}
                    disabled={loading}
                />

                <TextArea
                    label="Message"
                    value={message}
                    setValue={setMessage}
                    error={messageError}
                    setError={setMessageError}
                    validation={validateMessage}
                    attempts={c}
                    disabled={loading}
                />

                <br/>

                <div className="formButtons">
                    <div className="buttons">
                        <Button submit loading={loading}>Submit</Button>
                    </div>
                    <div className="error">
                        {serverError}
                    </div>
                </div>
            </form>
        </div>
    </div>
}