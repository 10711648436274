import React, { useReducer } from 'react';

export default function AnimButton({ className, setTransform, style, setStyle, disabled, Img }) {
    let [c, setC] = useReducer(c => c + 1, 0);

    return <label onMouseEnter={disabled?null:setC} className={className+(style==className?' checked':'')+(disabled?' disabled':'')}>
        <input type="radio" name="Animation" checked={style==className} onChange={e => setStyle(className)} />
        <Img style={{transform:setTransform(c)}}/>
        {className}
    </label>
}