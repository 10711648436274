import React, { useState, useEffect, useContext } from 'react';
import { NavLink } from 'react-router-dom';
import axios from 'axios';
import { AccountContext, useAsync } from '../../../hooks';

export default function DuelsAdminPanel() {
    let { user } = useContext(AccountContext);
    let [users, setUsers] = useState([]);

    useAsync(async () => {
        let { data } = await axios.post('/api/duel-pool', { user });
        setUsers(data);
    }, [])

    return users.length>0 ? <div className="ContestsPanel Side Panel">
        <h3 className="mb">Pool</h3>
        {users.map(({ username, medals, silver, bronze },i) => <NavLink
            key = {username}
            className = "noUnderline"
            to = {'/user/'+username.toLowerCase()}
        ><div className={"chainLink"}>
            <div className="count">{(i+1) < 10 ? '0' + (i+1) : (i+1)}</div>
            <div className="theme">
                <h4>{username}
                        <span className={"med " + (medals?'gold':silver?'silver':'bronze')}>
                            {medals ? <><img src={require('../../../pictures/gold.svg')} />{medals>1?"×"+medals:''}</>:null}
                            {silver ? <><img src={require('../../../pictures/silver.svg')} />{silver>1?"×"+silver:''}</>:null}
                            {bronze ? <><img src={require('../../../pictures/bronze.svg')} />{bronze>1?"×"+bronze:''}</>:null}
                    </span>
                </h4>
            </div>
        </div></NavLink>)}
    </div> : null
}