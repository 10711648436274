import React, { useRef, useState, useContext } from 'react';
import { useResponsive, useChange, useGetState, DarkContext } from '../../hooks';

export default function ImgExpand({src, bounds1 = 50, bounds2 = 500}) {
    let [expanded, setExpanded] = useState(false);
    let [hovering, setHovering] = useState(false);
    let [dimensions, setDimensions, getDimensions] = useGetState();
    let [bounds3, setBounds3] = useState();
    let img = useRef();
    let hiddenImg = useRef();
    let div = useRef();
    let { dark } = useContext(DarkContext);

    let {device} = useResponsive();

    useChange(() => {
        if (expanded) getBounds3();
    },[device])

    function getBounds3() {
        let dimensions = getDimensions();
        if (dimensions) {
            let ratio = img.current.height / img.current.width;
            let divWidth = div.current.clientWidth;
            let bounds3 = divWidth * ratio;
            if (dimensions.height < bounds3) bounds3 = dimensions.height;
            if (bounds3 < bounds2) setBounds3(bounds3);
        }
    }

    function expand() {
        if (expanded) {
            setExpanded(false);
        } else {
            getBounds3();
            setExpanded(true);
        }
    }

    return dimensions ? <div
        className="Img"
        style={{
            height: expanded ? bounds3?bounds3:bounds2 : bounds1
        }}
        onMouseEnter={() => setHovering(true)}
        onMouseLeave={() => setHovering(false)}
        onClick={expand}
        ref={div}
    >
        <img
            src={src}
            ref={img}
            style={dark?{backgroundColor:'white'}:null}
        />   
        <div className={hovering?"tooltip":'tooltip hidden'} onClick={expand}>
            Click to {expanded?"shrink":'expand'}
        </div>
    </div> : <div className="HIDDEN">
        <img 
            ref={hiddenImg} 
            src={src} 
            onLoad={()=>{
                setDimensions({
                    width: hiddenImg.current.clientWidth,
                    height: hiddenImg.current.clientHeight
                })
            }}
        />
    </div>
}