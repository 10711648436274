import React, {useState, useContext, useRef} from 'react';
import { AccountContext, ToastContext } from '../../hooks';
import ModalHeading from '../modal/ModalHeading';
import axios from 'axios';
import Btn from '../controls/Btn';
import Toast from '../modal/Toast';

export default function BlockList({ close, blocked }) {
    let Toasts = useContext(ToastContext);
    let { user } = useContext(AccountContext);
    let [list, setList] = useState(blocked.slice());

    return <div className="BlockList modal">
        <ModalHeading title="Block list" close={close} />
        <div className="body">
            <p>You have blocked {list.length} user{list.length==1?'':'s'}.</p>
            <p>You will not see their posts or comments.</p>
            <p>If you want to unblock any of them, simply click on their name in this list:</p>
            <ul>{list.map(username => <li><Btn onClick={async () => {
                axios.post('/api/unblock', { username, user });
                list = list.filter(u => u != username)
                setList(list);
                Toasts.create(Toast, { text: `${username} has been unblocked!` });
                if (list.length == 0) close();
            }}>{username}</Btn></li>)}</ul>
        </div>
    </div>
}