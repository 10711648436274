import React, { useContext, useEffect, useState } from 'react';
import Pages from '../posts/Pages'
import { ResponsiveContext, AccountContext, useChange, HistoryContext } from '../../hooks';

import ShareButtons from '../posts/ShareButtons';
import Button from '../controls/Button';
import Info from '../controls/Info';

export default function Challenges({ page, route }) {
    let { desktop } = useContext(ResponsiveContext);
    let { user } = useContext(AccountContext);
    let { noNavigate } = useContext(HistoryContext);


    let feedOptions = [{ title: 'All', url: 'challenges' }, { title: 'Open', url: 'challenges/open' }, { title: 'Voting', url: 'challenges/voting' }, { title: 'Concluded', url: 'challenges/concluded' }]

    let [feed, setFeed] = useState(feedOptions[Math.max(0, feedOptions.findIndex(f => f.title == route))]);

    useChange(() => {
        noNavigate(`/${feed.url}`);
    }, [feed])

    return <div className="Panel MainFeed centrepanel">
        {desktop ? <ShareButtons heading={false} /> : null}
        <div className="feedButtons">
            <label>Filter<Info title="Mini challenges"><div>
                <p>Users of the site can pick a difficult word, and set an ambigram challenge for everyone to attempt.</p>
                <p>Use the tabs at the top of the page to filter between challenges that are open for submissions, challenges which are open for voting, and challenges that have concluded.</p>
                <p>So if you want to share your artwork, vote on other people's submissions, or just check out who won the previous challenges, you can do all of that here!</p>
            </div></Info></label>
            {feedOptions.map(({title,url})=><label className="feedButton" key={title}>
                <div className={`feedButtonInner ${feed.title==title ? 'selected' : ''}`}>
                    {title}
                    <input
                        type="radio"
                        name={"feed"}
                        checked={feed.title==title}
                        onChange={e => { if (e.target.checked) setFeed({title,url}) }}
                    />
                </div>
            </label>)}
        </div>
        <Pages
            query={feed.title == 'Open' ? {type:'Challenge',open:true} : feed.title=='Voting' ? {type:'Challenge',voting:true} : feed.title =='Concluded'?{type:'Challenge',closed:true} : {type:'Challenge'}}
            startingPage={page}
            url={`/${feed.url}/`}
            force={feed.url}
            noPosts={<div className="mt">There are no posts to show...</div>}
        />
    </div>
}