import React from 'react';
import Btn from '../controls/Btn';

import { ImgCross } from '../../pictures/SVG';

export default function Cross({ close }) {
    return <div className="CrossContainer"><Btn
        className="Cross"
        onClick={close}
        height="1rem"
    >
        <ImgCross className="red"/>
    </Btn></div>
}