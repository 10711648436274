import React from 'react';
import ModalHeading from './ModalHeading';
import Button from '../controls/Button';
import P from '../controls/P';

export default function ModalMessage({ className, preset, title, body, render, callback = () => { }, close, buttons = [{ text: 'Cool' }] }) {
    if (preset == "Donate") {
        title = "Feature unavailable!"
        body = "This feature is only available to those who have made a donation to the site!"
    }
    return <div className="modal">
        <ModalHeading title={title} close={async () => {
            await close();
            callback({closed:true})
        }} />
        <div className={`body blueLinks ${className?className:''}`}>
            {render?render:<P>{body}</P>}
            <div className="formButtons">
                <div className="buttons">
                    {buttons.map((button, i) =>
                        <Button
                            key={i}
                            colour={button.colour}
                            onClick={button.callback ? button.callback : async () => {
                                await close();
                                callback({ button: button.text });
                            }}
                        >
                            {button.text}
                        </Button>
                    )}
                </div>
            </div>
        </div>
    </div>
}