import React, { useEffect, useState, memo, useContext } from 'react';
import { useClickOutside, CurrencyContext } from '../../hooks';
import Button from '../controls/Button';

import {ImgDropdown} from '../../pictures/SVG';
import Input from '../controls/Input';

let list = [
    {i: 0, code: 'AED', title: 'United Arab Emirates Dirham', symbol:'د.إ'},
    {i: 1, code: 'AFN', title: 'Afghan Afghani', symbol:'؋'},
    {i: 2, code: 'ALL', title: 'Albanian Lek', symbol:'L'},
    {i: 3, code: 'AMD', title: 'Armenian Dram', symbol:'֏'},
    {i: 4, code: 'ANG', title: 'Netherlands Antillean Guilder', symbol:'ƒ'},
    {i: 5, code: 'AOA', title: 'Angolan Kwanza', symbol:'Kz'},
    {i: 6, code: 'ARS', title: 'Argentine Peso', symbol:'$'},
    {i: 7, code: 'AUD', title: 'Australian Dollar', symbol:'$'},
    {i: 8, code: 'AWG', title: 'Aruban Florin', symbol:'ƒ'},
    {i: 9, code: 'AZN', title: 'Azerbaijani Manat', symbol:'₼'},
    {i: 10, code: 'BAM', title: 'Bosnia-Herzegovina Convertible Mark', symbol:'KM'},
    {i: 11, code: 'BBD', title: 'Barbadian Dollar', symbol:'$'},
    {i: 12, code: 'BDT', title: 'Bangladeshi Taka', symbol:'৳'},
    {i: 13, code: 'BGN', title: 'Bulgarian Lev', symbol:'лв'},
    {i: 14, code: 'BHD', title: 'Bahraini Dinar', symbol:'.د.ب'},
    {i: 15, code: 'BIF', title: 'Burundian Franc', symbol:'FBu'},
    {i: 16, code: 'BMD', title: 'Bermudan Dollar', symbol:'$'},
    {i: 17, code: 'BND', title: 'Brunei Dollar', symbol:'$'},
    {i: 18, code: 'BOB', title: 'Bolivian Boliviano', symbol:'$b'},
    {i: 19, code: 'BRL', title: 'Brazilian Real', symbol:'R$'},
    {i: 20, code: 'BSD', title: 'Bahamian Dollar', symbol:'$'},
    {i: 21, code: 'BTN', title: 'Bhutanese Ngultrum', symbol:'Nu.'},
    {i: 22, code: 'BWP', title: 'Botswanan Pula', symbol:'P'},
    {i: 23, code: 'BYN', title: 'Belarusian Ruble', symbol:'Br'},
    {i: 24, code: 'BZD', title: 'Belize Dollar', symbol:'BZ$'},
    {i: 25, code: 'CAD', title: 'Canadian Dollar', symbol:'$'},
    {i: 26, code: 'CDF', title: 'Congolese Franc', symbol:'FC'},
    {i: 27, code: 'CHF', title: 'Swiss Franc', symbol:'CHF'},
    {i: 28, code: 'CLP', title: 'Chilean Peso', symbol:'$'},
    {i: 29, code: 'CNY', title: 'Chinese Yuan', symbol:'¥'},
    {i: 30, code: 'COP', title: 'Colombian Peso', symbol:'$'},
    {i: 31, code: 'CRC', title: 'Costa Rican Colón', symbol:'₡'},
    {i: 32, code: 'CUC', title: 'Cuban Convertible Peso', symbol:'$'},
    {i: 33, code: 'CUP', title: 'Cuban Peso', symbol:'₱'},
    {i: 34, code: 'CVE', title: 'Cape Verdean Escudo', symbol:'$'},
    {i: 35, code: 'CZK', title: 'Czech Republic Koruna', symbol:'Kč'},
    {i: 36, code: 'DJF', title: 'Djiboutian Franc', symbol:'Fdj'},
    {i: 37, code: 'DKK', title: 'Danish Krone', symbol:'kr'},
    {i: 38, code: 'DOP', title: 'Dominican Peso', symbol:'RD$'},
    {i: 39, code: 'DZD', title: 'Algerian Dinar', symbol:'دج'},
    {i: 40, code: 'EGP', title: 'Egyptian Pound', symbol:'£'},
    {i: 41, code: 'ERN', title: 'Eritrean Nakfa', symbol:'Nfk'},
    {i: 42, code: 'ETB', title: 'Ethiopian Birr', symbol:'Br'},
    {i: 43, code: 'EUR', title: 'Euro', symbol:'€'},
    {i: 44, code: 'FJD', title: 'Fijian Dollar', symbol:'$'},
    {i: 45, code: 'FKP', title: 'Falkland Islands Pound', symbol:'£'},
    {i: 46, code: 'GBP', title: 'British Pound Sterling', symbol:'£'},
    {i: 47, code: 'GEL', title: 'Georgian Lari', symbol:'₾'},
    {i: 48, code: 'GGP', title: 'Guernsey Pound', symbol:'£'},
    {i: 49, code: 'GHS', title: 'Ghanaian Cedi', symbol:'GH₵'},
    {i: 50, code: 'GIP', title: 'Gibraltar Pound', symbol:'£'},
    {i: 51, code: 'GMD', title: 'Gambian Dalasi', symbol:'D'},
    {i: 52, code: 'GNF', title: 'Guinean Franc', symbol:'FG'},
    {i: 53, code: 'GTQ', title: 'Guatemalan Quetzal', symbol:'Q'},
    {i: 54, code: 'GYD', title: 'Guyanaese Dollar', symbol:'$'},
    {i: 55, code: 'HKD', title: 'Hong Kong Dollar', symbol:'$'},
    {i: 56, code: 'HNL', title: 'Honduran Lempira', symbol:'L'},
    {i: 57, code: 'HRK', title: 'Croatian Kuna', symbol:'kn'},
    {i: 58, code: 'HTG', title: 'Haitian Gourde', symbol:'G'},
    {i: 59, code: 'HUF', title: 'Hungarian Forint', symbol:'Ft'},
    {i: 60, code: 'IDR', title: 'Indonesian Rupiah', symbol:'Rp'},
    {i: 61, code: 'ILS', title: 'Israeli New Sheqel', symbol:'₪'},
    {i: 62, code: 'IMP', title: 'Manx pound', symbol:'£'},
    {i: 63, code: 'INR', title: 'Indian Rupee', symbol:'₹'},
    {i: 64, code: 'IQD', title: 'Iraqi Dinar', symbol:'ع.د'},
    {i: 65, code: 'IRR', title: 'Iranian Rial', symbol:'﷼'},
    {i: 66, code: 'ISK', title: 'Icelandic Króna', symbol:'kr'},
    {i: 67, code: 'JEP', title: 'Jersey Pound', symbol:'£'},
    {i: 68, code: 'JMD', title: 'Jamaican Dollar', symbol:'J$'},
    {i: 69, code: 'JOD', title: 'Jordanian Dinar', symbol:'JD'},
    {i: 70, code: 'JPY', title: 'Japanese Yen', symbol:'¥'},
    {i: 71, code: 'KES', title: 'Kenyan Shilling', symbol:'KSh'},
    {i: 72, code: 'KGS', title: 'Kyrgystani Som', symbol:'лв'},
    {i: 73, code: 'KHR', title: 'Cambodian Riel', symbol:'៛'},
    {i: 74, code: 'KMF', title: 'Comorian Franc', symbol:'CF'},
    {i: 75, code: 'KPW', title: 'North Korean Won', symbol:'₩'},
    {i: 76, code: 'KRW', title: 'South Korean Won', symbol:'₩'},
    {i: 77, code: 'KWD', title: 'Kuwaiti Dinar', symbol:'KD'},
    {i: 78, code: 'KYD', title: 'Cayman Islands Dollar', symbol:'$'},
    {i: 79, code: 'KZT', title: 'Kazakhstani Tenge', symbol:'лв'},
    {i: 80, code: 'LAK', title: 'Laotian Kip', symbol:'₭'},
    {i: 81, code: 'LBP', title: 'Lebanese Pound', symbol:'£'},
    {i: 82, code: 'LKR', title: 'Sri Lankan Rupee', symbol:'₨'},
    {i: 83, code: 'LRD', title: 'Liberian Dollar', symbol:'$'},
    {i: 84, code: 'LSL', title: 'Lesotho Loti', symbol:'M'},
    {i: 85, code: 'LYD', title: 'Libyan Dinar', symbol:'LD'},
    {i: 86, code: 'MAD', title: 'Moroccan Dirham', symbol:'MAD'},
    {i: 87, code: 'MDL', title: 'Moldovan Leu', symbol:'lei'},
    {i: 88, code: 'MGA', title: 'Malagasy Ariary', symbol:'Ar'},
    {i: 89, code: 'MKD', title: 'Macedonian Denar', symbol:'ден'},
    {i: 90, code: 'MMK', title: 'Myanma Kyat', symbol:'K'},
    {i: 91, code: 'MNT', title: 'Mongolian Tugrik', symbol:'₮'},
    {i: 92, code: 'MOP', title: 'Macanese Pataca', symbol:'MOP$'},
    {i: 93, code: 'MRO', title: 'Mauritanian Ouguiya', symbol:'UM'},
    {i: 94, code: 'MUR', title: 'Mauritian Rupee', symbol:'₨'},
    {i: 95, code: 'MVR', title: 'Maldivian Rufiyaa', symbol:'Rf'},
    {i: 96, code: 'MWK', title: 'Malawian Kwacha', symbol:'MK'},
    {i: 97, code: 'MXN', title: 'Mexican Peso', symbol:'$'},
    {i: 98, code: 'MYR', title: 'Malaysian Ringgit', symbol:'RM'},
    {i: 99, code: 'MZN', title: 'Mozambican Metical', symbol:'MT'},
    {i: 100, code: 'NAD', title: 'Namibian Dollar', symbol:'$'},
    {i: 101, code: 'NGN', title: 'Nigerian Naira', symbol:'₦'},
    {i: 102, code: 'NIO', title: 'Nicaraguan Córdoba', symbol:'C$'},
    {i: 103, code: 'NOK', title: 'Norwegian Krone', symbol:'kr'},
    {i: 104, code: 'NPR', title: 'Nepalese Rupee', symbol:'₨'},
    {i: 105, code: 'NZD', title: 'New Zealand Dollar', symbol:'$'},
    {i: 106, code: 'OMR', title: 'Omani Rial', symbol:'﷼'},
    {i: 107, code: 'PAB', title: 'Panamanian Balboa', symbol:'B/.'},
    {i: 108, code: 'PEN', title: 'Peruvian Nuevo Sol', symbol:'S/.'},
    {i: 109, code: 'PGK', title: 'Papua New Guinean Kina', symbol:'K'},
    {i: 110, code: 'PHP', title: 'Philippine Peso', symbol:'₱'},
    {i: 111, code: 'PKR', title: 'Pakistani Rupee', symbol:'₨'},
    {i: 112, code: 'PLN', title: 'Polish Zloty', symbol:'zł'},
    {i: 113, code: 'PYG', title: 'Paraguayan Guarani', symbol:'Gs'},
    {i: 114, code: 'QAR', title: 'Qatari Rial', symbol:'﷼'},
    {i: 115, code: 'RON', title: 'Romanian Leu', symbol:'lei'},
    {i: 116, code: 'RSD', title: 'Serbian Dinar', symbol:'Дин.'},
    {i: 117, code: 'RUB', title: 'Russian Ruble', symbol:'₽'},
    {i: 118, code: 'RWF', title: 'Rwandan Franc', symbol:'R₣'},
    {i: 119, code: 'SAR', title: 'Saudi Riyal', symbol:'﷼'},
    {i: 120, code: 'SBD', title: 'Solomon Islands Dollar', symbol:'$'},
    {i: 121, code: 'SCR', title: 'Seychellois Rupee', symbol:'₨'},
    {i: 122, code: 'SDG', title: 'Sudanese Pound', symbol:'ج.س.'},
    {i: 123, code: 'SEK', title: 'Swedish Krona', symbol:'kr'},
    {i: 124, code: 'SGD', title: 'Singapore Dollar', symbol:'$'},
    {i: 125, code: 'SHP', title: 'Saint Helena Pound', symbol:'£'},
    {i: 126, code: 'SLL', title: 'Sierra Leonean Leone', symbol:'Le'},
    {i: 127, code: 'SOS', title: 'Somali Shilling', symbol:'S'},
    {i: 128, code: 'SRD', title: 'Surinamese Dollar', symbol:'$'},
    {i: 129, code: 'STD', title: 'São Tomé and Príncipe Dobra', symbol:'Db'},
    {i: 130, code: 'SYP', title: 'Syrian Pound', symbol:'£'},
    {i: 131, code: 'SZL', title: 'Swazi Lilangeni', symbol:'E'},
    {i: 132, code: 'THB', title: 'Thai Baht', symbol:'฿'},
    {i: 133, code: 'TJS', title: 'Tajikistani Somoni', symbol:'SM'},
    {i: 134, code: 'TMT', title: 'Turkmenistani Manat', symbol:'T'},
    {i: 135, code: 'TND', title: 'Tunisian Dinar', symbol:'د.ت'},
    {i: 136, code: 'TOP', title: 'Tongan Paʻanga', symbol:'T$'},
    {i: 137, code: 'TRY', title: 'Turkish Lira', symbol:'₺'},
    {i: 138, code: 'TTD', title: 'Trinidad and Tobago Dollar', symbol:'TT$'},
    {i: 139, code: 'TWD', title: 'New Taiwan Dollar', symbol:'NT$'},
    {i: 140, code: 'TZS', title: 'Tanzanian Shilling', symbol:'TSh'},
    {i: 141, code: 'UAH', title: 'Ukrainian Hryvnia', symbol:'₴'},
    {i: 142, code: 'UGX', title: 'Ugandan Shilling', symbol:'USh'},
    {i: 143, code: 'USD', title: 'United States Dollar', symbol:'$'},
    {i: 144, code: 'UYU', title: 'Uruguayan Peso', symbol:'$U'},
    {i: 145, code: 'UZS', title: 'Uzbekistan Som', symbol:'лв'},
    {i: 146, code: 'VEF', title: 'Venezuelan Bolívar Fuerte', symbol:'Bs'},
    {i: 147, code: 'VND', title: 'Vietnamese Dong', symbol:'₫'},
    {i: 148, code: 'VUV', title: 'Vanuatu Vatu', symbol:'VT'},
    {i: 149, code: 'WST', title: 'Samoan Tala', symbol:'WS$'},
    {i: 150, code: 'XAF', title: 'CFA Franc BEAC', symbol:'FCFA'},
    {i: 151, code: 'XCD', title: 'East Caribbean Dollar', symbol:'$'},
    {i: 152, code: 'XOF', title: 'CFA Franc BCEAO', symbol:'CFA'},
    {i: 153, code: 'XPF', title: 'CFP Franc', symbol:'₣'},
    {i: 154, code: 'YER', title: 'Yemeni Rial', symbol:'﷼'},
    {i: 155, code: 'ZAR', title: 'South African Rand', symbol:'R'},
    {i: 156, code: 'ZMW', title: 'Zambian Kwacha', symbol:'ZK'}
]

export default function Currencies({ title=false, className, currency, setCurrency = () => { }, disabled }) {
    let { setCurrency: setGlobalCurrency } = useContext(CurrencyContext);
    let [ref, onClickOutside] = useClickOutside();

    let [sel, setSel] = useState(list.findIndex(l => l.code == currency.code));
    let [drop, setDrop] = useState(false);

    let [search, setSearch] = useState('');
    let [options, setOptions] = useState(list);

    useEffect(() => {
        if (drop) setSearch('');
    },[drop])

    onClickOutside(() => {
        setDrop(false);
    })

    useEffect(() => {
        let term = search.toUpperCase();
        if (term.length == 0) {
            setOptions(list);
        } else {
            setOptions(list.filter(l => l.code.indexOf(term) >= 0 || l.title.toUpperCase().indexOf(term) >= 0));
        }
    }, [search])
    
    let rems = 16;

    return <div className={"Currencies " + (className ? className : '')}>
        <Button colour="darkGrey" onClick={()=>setDrop(!drop)} disabled={disabled}>
            <b>{list[sel].code}</b> {list[sel].title} <ImgDropdown className="WHITE"/>
        </Button>
        <div
            className={"options "}
            style={{ height: drop ? rems + 'rem' : 0, zIndex: 20, opacity: drop ? 1 : 0 }}
            ref={ref}
        >
            <Input
                label={'Search'}
                littleLabel
                value={search}
                setValue={setSearch}
            />
            <div className="currencyList" style={{ height: (rems - 2.6) + 'rem' }}>
                <ul>
                    {options.map(({ title, code, symbol, i }) => <li
                        key={i}
                        onClick={() => {
                            setSel(i);
                            let currency = { code: list[i].code, symbol: list[i].symbol };
                            setCurrency(currency);
                            setGlobalCurrency(currency);
                            setDrop(false);
                        }}
                    >
                        <div className="currencyCode">
                            <b>{code}</b>
                        </div>
                        {title}
                    </li>)}
                </ul>
            </div>
        </div>
    </div>
}