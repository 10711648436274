import React, { useState } from 'react';
import ModalHeading from './ModalHeading';
import Button from '../controls/Button';
import P from '../controls/P';
import Pounds from '../checkout/Pounds';

export default function NumberModal({ title, body, number, callback = () => { }, close }) {
    let [n, setN] = useState(number);
    return <div className="modal NumberModal">
        <ModalHeading title={title} close={async () => {
            await close();
            callback(false);
        }} />
        <div className="body">
            <P>{body}</P>
            <Pounds pounds={n} setPounds={setN} normalNumber label="" monthly={false} symbol={''} />
            <div className="formButtons">
                <div className="buttons">
                    <Button
                        colour={"blue"}
                        onClick={async () => {
                            await close();
                            callback(n);
                        }}
                    >
                        OK
                    </Button>
                </div>
            </div>
        </div>
    </div>
}