import React, { useState, useContext } from 'react';
import { AccountContext } from '../../hooks';
import Button from '../controls/Button';
import ModalHeading from '../modal/ModalHeading';
import UsernamesInput from './UsernamesInput';

export default function AddUsers({ close, button="Add", callback }) {
    let { user } = useContext(AccountContext);
    let [usernames, setUsernames] = useState([]);
    let [loading, setLoading] = useState(false);

    return <div className="AddUsers modal">
        <ModalHeading title="New message" close={close} />
        <div className="body">
            <UsernamesInput disabled={loading} callback={u => {
                setUsernames(u);
            }} />
            <div className="buttons">
                <Button disabled={usernames.length == 0} loading={loading} onClick={async () => {
                    setLoading(true);
                    await callback(usernames);
                    close();
                }}>{button}</Button>
            </div>
        </div>
    </div>
}
