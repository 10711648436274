import React, {useState} from 'react';

export default function A({ className, children, onClick, onMouseEnter, onMouseLeave, red, blue}) {
    let [focussed, setFocussed] = useState(false);
    let body = '', first = '';
    
    let index = children.indexOf(' ');
    if (index >= 0) {
        first = children.substr(0, index);
        body = children.substr(index, children.length - index);
    } else {
        first = children;
    }

    return <span className={`A ${className?className:''} ${focussed?'focussed':''} ${red?'red':''} ${blue?'blueblue':''}`}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
    >
        <button
            onClick={onClick}
            onFocus={() => setFocussed(true)}
            onBlur={() => setFocussed(false)}
        >{first}</button><span onClick={onClick}>{body}</span>
    </span>
}