import React from 'react';
import Pages from '../posts/Pages'

export default function HallOfFame({ page }) {
    return <div className="Panel HallOfFame centrepanel">
        <h1>Hall of fame</h1>
        <h4>Please enjoy this collection of our contest winners!</h4>
        <Pages
            halloffame
            startingPage={page}
            url={'/hall-of-fame/'}
        />
    </div>
}