import React from 'react';
import Dropdown from './Dropdown';

import {ImgDropdown} from '../../pictures/SVG';

export default function SelectDropdown({options, selected, setSelected, disabled}) {
    let dropdownOptions = options.map(option => {
        return {
            title: option,
            callback: ()=>setSelected(option)
        }
    })
    return <Dropdown disabled={false} options={dropdownOptions} btnProps={{colour:"white", className:"SelectDropdown", disabled:disabled}}>
        {selected}<ImgDropdown className="WHITE"/>
    </Dropdown>
}