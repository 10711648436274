import React, { useState, useReducer, useEffect, useRef, memo, createContext, useContext } from 'react';
import axios from 'axios';
import { validateUsername, validateName, validateEmail, validatePassword, noErrors } from './functions';
import AreYouSure from './components/modal/AreYouSure';

let VERSION = 3; // WE ON VERSION 3 NOW BABY!

let defaultDocumentTitle = document.title;

export const banKey = 'b1';

export function useTitle(title) {
    let [documentTitle, setDocumentTitle] = useState(title);
    useEffect(() => {
        document.title = documentTitle?documentTitle.replace('$',defaultDocumentTitle):defaultDocumentTitle;
        // return () => document.title = defaultDocumentTitle;
    }, [documentTitle])
    return { documentTitle, setDocumentTitle };
}

export const SoundsContext = createContext();
export function useSound(sound) {
    let urls = {
        'crabcanon': 'https://ambigr.am/api/pictures/crabcanon.mp3',
        'pop':       'https://ambigr.am/api/pictures/pop.mp3',
    };
    let [audio, setAudio] = useState(null);

    useEffect(() => {
        let loadSound = async () => {
            let audio = new Audio(urls[sound]);
            await new Promise((resolve) => {
                audio.addEventListener('canplaythrough', resolve, { once: true });
            });
            setAudio(audio);
            audio.muted = true;
            audio.currentTime = 0;
            audio.play();
        };
        loadSound();
        return () => {
            if (audio) {
                audio.pause();
                audio.src = '';
                setAudio(null);
            }
        };
    }, [sound]);

    let playSound = () => {
        if (audio) {
            audio.muted = false;
            audio.currentTime = 0;
            audio.play();
            console.log(sound);
        }
    };

    return playSound;
};

export const DarkContext = createContext();

export function useDark() {
    let [dark, setDark] = useState(localStorage.getItem('dark'));
    useEffect(() => {
        if (dark) {
            localStorage.setItem('dark', '(_)_)===D');
        } else {
            localStorage.removeItem('dark');
        }
    },[dark])
    return {dark, setDark}
}

export function useColours() {
    let { dark } = useDark();

    let staticColours = {
        red: "#FB3F34",
        darkenRed: "#D41E6A",
        lightenRed: "#F86B1A",
        darkRed: "#E23D34",
        transparentRed: "#FB3F3200",
        blue: "#13B9B9",
        darkBlue: "#16AFAF",
        transparentBlue: "#13B9B900",
        yellow: "#FFE66C",
        darkYellow: "#F1D95E",
        gold: "#D79928",
        silver: "#B3B3B3",
        bronze: "#B78576",
        grey: "#828282"
    }
    
    let darkColours = {
        darkGrey: "#C3C3C3",
        darkDarkGrey: "#D1D1D1",
        transparentDarkGrey: "#C3C3C300",
        black: "#FFFFFF",
        transparentBlack: "#FFFFFF00",
        white: "#000000",
        transparentWhite: "#00000000",
        lightGrey: "#292929",
        lighterGrey: "#181818",
        lightishGrey: "#555555"
    }

    let lightColours = {
        darkGrey: "#3C3C3C",
        darkDarkGrey: "#2E2E2E",
        transparentDarkGrey: "#3C3C3C00",
        black: "#000000",
        transparentBlack: "#00000000",
        white: "#FFFFFF",
        transparentWhite: "#FFFFFF00",
        lightGrey: "#E6E6E6",
        lighterGrey: "#F8F8F8",
        lightishGrey: "#C7C7C7"
    }

    let [colours, setColours] = useState({...staticColours,...lightColours});

    useEffect(() => {
        setColours(dark ? {...staticColours,...darkColours} : {...staticColours,...lightColours})
    }, [dark])
    return { colours }
}

export const VisibilityContext = createContext();

export function usePageVisible() {
    let { componentDidMount, componentWillUnmount } = useMount();
    let [pageVisible, setPageVisible, getPageVisible] = useGetState(!document.hidden);
    function visibilitychange(e) {
        setPageVisible(!document.hidden);
    }
    componentDidMount(() => {
        document.addEventListener('visibilitychange',visibilitychange)
    })
    componentWillUnmount(() => {
        document.removeEventListener('visibilitychange',visibilitychange)
    })
    return {pageVisible,getPageVisible};
}

export const Contexts = {};

export function useDynamic(key1, key2) {
    let key = key1 + '\\*\\*\\' + key2;
    return useContext(Contexts[key]?Contexts[key]:AccountContext);
}

export function CreateDynamic(key1, key2) {
    let { componentWillUnmount } = useMount();
    let [DC] = useState(() => createContext());
    let key = key1 + '\\*\\*\\' + key2
    Contexts[key] = DC;
    componentWillUnmount(() => delete Contexts[key]);
    return DC
}

export function useAreYouSure() {
    let Modals = useContext(ModalContext);
    return body => new Promise(resolve => Modals.create(AreYouSure, {body,callback: resolve}))
}

export function useModalCallback(Modal) {
    let Modals = useContext(ModalContext);
    return parameters => new Promise(resolve => Modals.create(Modal, {...parameters,callback: resolve}))
}

export const IdContext = createContext();

export function useId() {
    let id = useRef(0);
    let getId = () => "*" + id.current++ + "*";
    return getId;
}

export const CentreForceRenderContext = createContext();
export const ContestForceRenderContext = createContext();

export const HistoryContext = createContext();

export const CurrencyContext = createContext();

export function useCurrency() {
    let [currency, setCurrency0, getCurrency] = useGetState({ code: 'GBP', symbol: '£' });
    let rate = useRef(1);
    let { componentDidMount } = useMount();
    componentDidMount(() => {
        let currency = localStorage.getItem('currency');
        if (currency) {
            currency = JSON.parse(currency);
            if (currency.symbol) setCurrency(currency);
        }
    })

    function setCurrency(currency) {
        localStorage.setItem('currency', JSON.stringify(currency));
        rate.current = (currency.code == "GBP" ? 1 : -1);
        setCurrency0(currency);
    }
    
    async function getRate() {
        if (rate.current > 0) return rate.current;
        let { data } = await axios.post('/api/exchange-rate', { code: currency.code });
        rate.current = data;
        return data;
    }
    async function convertCurrency(pounds) {
        let rate = await getRate();
        return pounds * rate;
    }
    return { currency, setCurrency, getRate, convertCurrency };
}

export const AccountContext = createContext();

export function useAccount() {
    let [user, setUser0] = useState();
    let [subbed, setSubbed] = useState();
    let [openChallenge, setOpenChallenge] = useState(0);
    let { componentDidMount } = useMount();

    let [postLimit, setPostLimit] = useState(-1);
    let [postsToday, setPostsToday] = useState(0);

    let userHash = useRef('hello');

    function setUser(user) {
        let hash = JSON.stringify(user);
        if (hash != userHash.current) {
            setUser0(user);
            userHash.current = hash;
        }
        console.log(user);
    }

    function checkSubbed(user) {
        if (!user || !user.username) return false;
        if (user.admin) return true;
        return user.sub && user.sub.pounds > 0 && user.sub.expires > new Date().getTime();
    }

    async function refreshUser(user) {
        let { data } = await axios.post('/api/refresh-user', { user, b: localStorage.getItem(banKey) });
        if (data.user) {
            console.log('REFRESH',data.user)
            setOpenChallenge(data.openChallenge);
            setUser(data.user);
            setSubbed(checkSubbed(data.user));
            if (data.user.ub) localStorage.removeItem(banKey);
        } else if (data.b) {
            localStorage.setItem(banKey, true);
        }
    }

    componentDidMount(async () => {
        if (!localStorage.getItem(banKey)) {
            let user = localStorage.getItem('user');
            if (user) {
                user = JSON.parse(user);
                if (user.username && user.token) {
                    setUser(user);
                    refreshUser(user);
                    if (user.admin && console._log) console.log = console._log;
                }
            }
            setSubbed(checkSubbed(user));
        }
    })

    useChange(() => {
        if (user && user.username) {
            user.v = VERSION;
            localStorage.setItem('user', JSON.stringify(user));
            localStorage.setItem('happy', ':)');
            if (user.postLimit) {
                setPostLimit(user.postLimit);
                setPostsToday(user.postsToday);
            } else {
                setPostLimit(-1);
            }
        } else {
            localStorage.removeItem('user');
        }
        setSubbed(checkSubbed(user));
    }, [user])

    async function login(username, password) {
        let error = {
            username: validateUsername(username),
            password: validatePassword(password),
        }
        if (noErrors(error)) {
            let { data } = await axios.post('/api/login', { username, password, b: localStorage.getItem(banKey) });
            if (data.b) {
                localStorage.setItem(banKey, true);
                return { error: 'Error connecting to server!' };
            } else {
                setUser(data.user);
                setOpenChallenge(data.openChallenge);
                return data;
            }
        } else return {error}
    }

    async function logout() {
        return new Promise(resolve => {
            let delay = Math.random() * Math.random() * 1000;
            setTimeout(() => {
                setUser(null);
                setOpenChallenge();
                resolve();
            }, delay);
        })
    }

    async function signup({ username, name, email, password }) {
        let error = {
            username: validateUsername(username),
            password: validatePassword(password),
            name: validateName(name),
            email: validateEmail(email),
        }
        if (noErrors(error)) {
            let { data } = await axios.post('/api/signup', { username, name, email, password, b: localStorage.getItem(banKey) });
            if (data.b) {
                localStorage.setItem(banKey, true);
                return { error: 'Error connecting to server!' };
            } else {
                setUser(data.user);
                return data;
            }
        } else return {error}
    }

    async function verify(id) {
        let { data } = await axios.post('/api/verify', { id }); 
        if (data) {
            if (user && user.username == data.username) {
                user.verified = true;
                localStorage.setItem('user', JSON.stringify(user));
            }
            return true;
        } else return false;
    }

    async function block(username) {
        if (user) {
            let { data } = await axios.post('/api/block', { user, username }); 
        }
    }

    async function unblock(username) {
        if (user) {
            let { data } = await axios.post('/api/unblock', { user, username }); 
        }
    }

    function checkPermissions(permission) {
        return user && (user.admin || (user.permissions && user.permissions.indexOf(permission) >= 0));
    }

    function postsRemaining() {
        return postLimit >= 0 ? postLimit - postsToday : 99;
    }

    function incrementPostsToday() {
        if (postLimit >= 0) {
            setPostsToday(p => p + 1);
        }
    }

    return {user, setUser, login, logout, signup, verify, subbed, checkSubbed, openChallenge, setOpenChallenge, block, unblock, checkPermissions, postsRemaining, incrementPostsToday}
}

export const ResponsiveContext = createContext();

export function useResponsive() {
    let { componentDidMount, componentWillUnmount } = useMount();
    let [device, setDevice] = useState();

    let [phone, setPhone] = useState(window.innerWidth <= 360);
    let [bigPhone, setBigPhone] = useState(window.innerWidth <= 425);
    let [tablet, setTablet] = useState(window.innerWidth <= 768);
    let [smallDesktop, setSmallDesktop] = useState(window.innerWidth <= 950);
    let [desktop, setDesktop] = useState(window.innerWidth <= 1260);
    let [innerWidth, setInnerWidth] = useState();
    
    let breakpoints = [
        { name: 'phone',        width:  360, f: setPhone        },
        { name: 'bigPhone',     width:  425, f: setBigPhone     },
        { name: 'tablet',       width:  768, f: setTablet       },
        { name: 'smallDesktop', width:  950, f: setSmallDesktop },
        { name: 'desktop',      width: 1260, f: setDesktop      }
    ]

    // useEffect(() => {
    //     console.log({innerWidth})
    // },[innerWidth])

    function resize() {
        let device;
        setInnerWidth(window.innerWidth);
        for (let i = 0; i < breakpoints.length; i++) {
            if (window.innerWidth <= breakpoints[i].width) {
                breakpoints[i].f(true);
                if (!device) {
                    device = breakpoints[i].name;
                    setDevice(device);
                }
            } else breakpoints[i].f(false);
        }
        setDevice(device);
    }

    componentDidMount(() => {
        window.addEventListener('resize', resize);
        resize();
    });
    componentWillUnmount(() => window.removeEventListener('resize', resize));

    return {device, phone, bigPhone, tablet, smallDesktop, desktop, innerWidth};
}

export function useResize() {
    let { componentDidMount, componentWillUnmount } = useMount();
    let [width, setWidth] = useState(window.innerWidth);
    let [height, setHeight] = useState(window.innerHeight);

    function resize() {
        setWidth(window.innerWidth);
        setHeight(window.innerHeight);
    }

    componentDidMount(() => window.addEventListener('resize', resize));
    componentWillUnmount(() => window.removeEventListener('resize', resize));

    return [width, height];
}

export const UsersContext = createContext();

export function useUsers() {
    let profilePics = useRef({});
    let medals = useRef({});
    
    let loading = useRef({});

    async function get(url) {
        if (loading.current[url]) {
            let data = await loading.current[url]();
            return { data }
        } else {
            let data;
            loading.current[url] = async () => {
                return new Promise(async resolve => {
                    while (!data) await new Promise(resolve => setTimeout(resolve, 1));
                    resolve(data);
                })
            }
            data = (await axios.get(url)).data;
            return { data }
        }
    }

    async function getMedals(username) {
        username = username.toLowerCase();
        let gold = medals.current[username];
        if (!gold) {
            let {data} = await get('/api/medals/' + username);
            gold = data;
            medals.current[username] = gold;
        }
        return gold;
    }

    async function getProfilePic(username) {
        username = username.toLowerCase();
        let picture = profilePics.current[username];
        if (!picture) {
            let { data } = await get('/api/profile-picture/' + username);
            picture = data.picture;
            if (!picture) picture = getTempPic(username)
            profilePics.current[username] = picture;
        }
        return picture;
    }

    function resetProfilePic(username, picture) {
        username = username.toLowerCase();
        profilePics.current[username] = picture ? picture : getTempPic(username);
    }

    function getTempPic(username) {
        let colours = ['teal',  'orchid',  'fuchsia',  'tomato',  'turquoise',  'sienna',  'steelblue', 'mediumorchid',  'darkslateblue',
            'orangered',  'darkseagreen',  'mediumturquoise',   'mediumvioletred', 'midnightblue',  'thistle', 'sandybrown',  'deeppink',
            'mediumblue', 'olivedrab',  'skyblue', 'slateblue',  'slategrey',  'springgreen',  'darkslategrey', 'darkturquoise',  'navy',
            'indianred',  'deepskyblue',   'dodgerblue',  'burlywood',   'lightsteelblue',   'limegreen',   'maroon',  'peru',  'indigo',
            'firebrick', 'forestgreen', 'mediumpurple', 'blueviolet', 'hotpink', 'seagreen',  'mediumspringgreen', 'crimson', 'darkblue',
            'darkcyan', 'lightseagreen',  'lightslategrey',  'darksalmon',  'mediumslateblue',   'mediumaquamarine',  'olive',  'orange',
            'lightskyblue',  'brown',  'darkviolet',   'darkorange',  'darkred',   'plum',  'purple',   'rebeccapurple',   'red',  'tan',
            'greenyellow', 'darkmagenta',  'lightblue', 'coral',  'cornflowerblue', 'saddlebrown',  'salmon',  'darkkhaki',  'darkgreen',
            'mediumseagreen',  'rosybrown',  'darkolivegreen',   'cadetblue',  'chocolate',  'lightcoral',   'lightgreen',   'lightpink',
            'darkgoldenrod', 'lightsalmon', 'royalblue', 'gold', 'goldenrod', 'green', 'palevioletred', 'violet', 'wheat', 'yellowgreen']
        let hash = 0;
        let USERNAME = username.toUpperCase();
        for (let i = 0; i < USERNAME.length; i++) hash += USERNAME.charCodeAt(i);
        let picture = { letter: USERNAME[0], colour: colours[hash % colours.length] };
        return picture;
    }

    return {getProfilePic, getMedals, getTempPic, resetProfilePic}
}

export const ModalContext = createContext();
export const ToastContext = createContext();

export function useModal(className = "Modal", speed=0.3) {
    let [modals, setModals, getModals] = useGetState({});
    let k = useRef(10);
    let [force, forceRender] = useForceRender();
    let { doTimeout } = useTimeout();

    let eatSlugsMalfoy = className;

    function render(Component, args, className) {
        let C = memo(({ hidden }) => {
            let c = className;
            if (hidden) c += ' hidden';
            return <div className={c}>
                <Component {...args} />
            </div>
        })
        return C;
    }

    function create(component, args, className = eatSlugsMalfoy) {
        let id = k.current++;
        let modals = getModals();
        if (!args) args = {}
        args.id = id;
        args.close = () => {
            return new Promise(resolve => {
                let modals = getModals();
                modals[id].hidden = true;
                setModals(modals);
                forceRender();
                doTimeout(() => {
                    let modals = getModals();
                    resolve();
                    delete modals[id];
                    forceRender();
                }, speed, 's');
            })
        }
        let modal = { Render: render(component, args, className), hidden: false };
        if (args.name) modal.name = args.name;
        modals[id] = modal;
        setModals(modals);
        forceRender();
    }

    let arr = [];
    for (let k in modals) {
        arr.push(modals[k]);
    }

    return {Render:arr.map(({Render, hidden}, i) => 
        <Render key={i} hidden={hidden} />
    ), create}
}

export function useClickOutside() {
    let { componentDidMount, componentWillUnmount } = useMount();
    let outsideRef = useRef();
    let callback = useRef(() => { });

    componentDidMount(() => document.addEventListener('mousedown', click));
    componentWillUnmount(() => document.removeEventListener('mousedown', click));

    let click = e => {
        if (outsideRef.current && !outsideRef.current.contains(e.target)) callback.current();
    }
    let onClickOutside = f => callback.current = f;

    return [outsideRef, onClickOutside];
}

export const NotificationsContext = createContext();
export const SocketContext = createContext();
export const CentreKeyContext = createContext();
export const ExpandLeftMenuContext = createContext();

export function useTransition(className = "fader", speed = 0.2, options = {}) {
    let { forceKey, unForceKey, setKey, wait, scrollUp } = options;
    let [components, setComponents, getComponents] = useGetState([]);
    let [Render, setRender] = useState(<div/>);
    let [force, forceRender] = useForceRender();
    let { doTimeout, doInterval } = useTimeout();
    let k = useRef(0);

    let render = (Component, args) => memo(({ hidden }) => <div className={`${className} ${hidden?'hidden':''}`}><Component {...args} /></div>)

    async function set(component, args, key, intermediary) {
        if (key === undefined) key = "*key*" + k.current++;
        if (setKey) setKey(key);
        let components = getComponents();
        let doRender = components.length == 0 || components[components.length - 1].key != key;
        if (forceKey && forceKey.current) {
            doRender = true;
            forceKey.current = false;
        }
        if (unForceKey && unForceKey.current) {
            doRender = false;
            unForceKey.current = false;
            if (components.length > 0) components[components.length - 1].key = key;
        }
        if (doRender) {
            let dead = {};
            components.forEach(component => {
                component.hidden = true;
                dead[component.id] = true;
            });
            let id = k.current++;
            components.push({ Render: render(component ? component : ()=><div/>, args), component, args, key, id, hidden:wait });
            setComponents(components);
            forceRender();
            doTimeout(() => {
                if (intermediary) intermediary();
                if (scrollUp) document.getElementById("TOP").scrollIntoView();
                let components = getComponents();
                for (let i = components.length - 1; i >= 0; i--) {
                    if (dead[components[i].id]) components.splice(i, 1);
                    if (wait && components[i].id == id) components[i].hidden = false;
                }
                setComponents(components);
                forceRender();
            }, speed, 's')
        }
    }

    useEffect(() => {
        let components = getComponents();
        if (components.length == 0) {
            setRender(<div/>)
        } else {
            let Render = components.map(({ Render, key, hidden, id },i) => <Render key={id} hidden={hidden} />)
            // let Render = components.map(({ Render, key, hidden, id },i) => <Render key={key} hidden={hidden} />)
            setRender(Render);
        }
    }, [force])

    return {Render, set}
}

export default function useDelay(between = 2000) {
    let previousAction = useRef(0);
    let interval = useRef();
    let queue = useRef([]);
    let nextAvailable = useRef(0);

    function timeRemaining() {
        if (interval.current) {
            return (nextAvailable.current - new Date().getTime())
        } else return false;
    }

    async function performAction(f) {
        let now = new Date().getTime();
        previousAction.current = now;
        let result, error;
        try {
            result = await f();
        } catch (e) {
            error = e;
        }
        return { result, error };
    }

    async function popQueue() {
        let { f, resolve } = queue.current.pop();
        if (queue.current.length == 0) {
            clearInterval(interval.current);
            interval.current = null;
        }
        resolve(await performAction(f));
    }

    function pushQueue(f, prioritise) {
        return new Promise(resolve => {
            if (prioritise) {
                queue.current.push({ f, resolve })
            } else {
                queue.current.unshift({ f, resolve })
            }
        })
    }

    async function delay(f, prioritise) {
        if (interval.current) {
            nextAvailable.current += between;
            return await pushQueue(f, prioritise);
        } else {
            let now = new Date().getTime();
            let waitTime = (previousAction.current + between) - now;
            if (waitTime <= 0) {
                nextAvailable.current = now + between;
                return await performAction(f);
            } else {
                nextAvailable.current += waitTime;
                interval.current = setTimeout(async () => {
                    popQueue();
                    if (queue.current.length == 0) {
                        clearTimeout(interval.current);
                        interval.current = null;
                    } else {
                        interval.current = setInterval(() => {
                            popQueue();
                        }, between)
                    }
                }, waitTime);
                return await pushQueue(f, prioritise);
            }
        }
    }

    async function wait(prioritise) {
        return await delay(() => { }, prioritise);
    }

    return {delay, wait, timeRemaining};
}

export function useTimeout() {
    let { componentWillUnmount } = useMount();
    let timeouts = useRef({});
    let intervals = useRef({});
    let c = useRef(0);
    componentWillUnmount(() => {
        for (let k in timeouts.current) clearTimeout(timeouts.current[k]);
        for (let k in intervals.current) clearInterval(intervals.current[k]);
    })
    let doTimeout = (f, t, unit) => {
        let mult = unit == 's' ? 1000 : unit == 'm' ? 1000 * 60 : unit == 'h' ? 1000 * 60 * 60 : 1;
        let key = c.current++
        timeouts.current[key] = setTimeout(() => {
            f();
            delete timeouts[key];
        }, t * mult);
        return key;
    }
    let doInterval = (f, t, unit) => {
        let mult = unit == 's' ? 1000 : unit == 'm' ? 1000 * 60 : unit == 'h' ? 1000 * 60 * 60 : 1;
        let key = c.current++
        intervals.current[key] = setInterval(() => {
            f();
            delete intervals[key];
        }, t * mult);
        return key;
    }
    let undoTimeout = key => clearTimeout(timeouts.current[key]);
    let undoInterval = key => clearInterval(intervals.current[key]);
    return { doTimeout, doInterval, undoTimeout, undoInterval };
}

export function useMount() {
    let f1 = useRef(() => { });
    let f2 = useRef(() => { });

    let componentDidMount = f => f1.current = f;
    let componentWillUnmount = f => f2.current = f;

    useEffect(() => {
        f1.current();
        return () => f2.current();
    },[])

    return {componentDidMount, componentWillUnmount};
}

export function useGetReducer(f,v) {
    let [state, setState] = useReducer(f,v);
    let loaded = useRef(false);
    let ref = useRef();
    useEffect(() => {
        ref.current = state;
        loaded.current = true;
    }, [state]);
    function getState() {
        if (loaded.current) return ref.current;
        return v;
    }
    return [state, setState, getState];
}

export function useGetState(v) {
    let [state, setState] = useState(v);
    let loaded = useRef(false);
    let ref = useRef();
    useEffect(() => {
        ref.current = state;
        loaded.current = true;
    }, [state]);
    function getState() {
        if (loaded.current) return ref.current;
        return v;
    }
    return [state, setState, getState];
}

export function useForceRender() {
    return useReducer(c => c + 1, 0);
}

export function useSubmit() {
    let [count, setCount] = useReducer((c, x = 1) => c + x, 0);
    let g = useRef(() => { });
    let block = useRef(false)
    let submit = e => {
        e.preventDefault();
        setCount();
    }
    let onSubmit = f => {
        g.current = f;
    }
    useChange(async () => {
        if (!block.current) {
            block.current = true;
            let result = await g.current();
            if (!result) block.current = false;
        }
    }, [count]);
    return [count, submit, onSubmit];
}

export function useChange(f, dependencies) {
    let loaded = useRef(false);
    let g = useRef();
    useEffect(() => {
        if (loaded.current) {
            g.current = f();
        } else {
            loaded.current = true;
        }
        return () => {
            if (g.current && g.current.constructor.name == 'Function') g.current();
        }
    },dependencies)
}

export function useAsync(f, dependencies) {
    useEffect(() => {
        f();
    },dependencies)
}

export function useChangeAsync(f, dependencies) {
    let loaded = useRef(false);
    let g = useRef();
    useAsync(async () => {
        if (loaded.current) {
            g.current = await f();
        } else {
            loaded.current = true;
        }
        return () => {
            if (g.current && g.current.constructor.name == 'Function') g.current();
        }
    },dependencies)
}