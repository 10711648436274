import React, { useContext, useEffect, useState } from 'react';
import Logo from '../Logo/Logo';
import Ambigram from '../ambigram/Ambigram';
import { ResponsiveContext } from '../../hooks';
import { NavLink } from 'react-router-dom';

export default function Home({ }) {
    let { device } = useContext(ResponsiveContext);
    let [scale, setScale] = useState(0);

    useEffect(() => {
        if (device == 'smallDesktop') {
            setScale(1.5);
        } else if (device == 'tablet' || device == 'bigPhone') {
            setScale(1);
        } else if (device == 'phone') {
            setScale(0.5);
        } else setScale(2);
    },[device])

    return <div className="Home">
        <Logo scale={scale} />
        <div className="infoBox">
            <div className="txt">
                <h2>What is an ambigram?</h2>
                <h4>Click the word 'chump' below</h4>
                <Ambigram picture={{ path: 'chump.png', width: 300, height: 120 }} style="Rotational" bounds={300}/>
                <h4>That's an ambigram.</h4>
                <p>On this website, we all create new ambigrams, and endulge in healthy competition in a monthly contest.</p>
                <p>Click the button to enter the site and find more ambigrams!</p>
            </div>
            <NavLink className="noUnderline" to={'/posts'}>Enter the site</NavLink>
        </div>
    </div>
}