import React, { useState, useContext } from 'react';
import { useMount, AccountContext } from '../../hooks';
import axios from 'axios';
import Btn from '../controls/Btn';
import { ImgSmallArrowLeft, ImgSmallArrowRight } from '../../pictures/SVG';
import Username from '../user/Username';
import Button from '../controls/Button';


export default function AdminAnalytics({ }) {
    let { componentDidMount } = useMount();
    let { user } = useContext(AccountContext);
    let [loading, setLoading] = useState(true);
    let [users, setUsers] = useState();
    let [activeUsers, setActiveUsers] = useState();
    let [howMuchMoneyAmIMaking, setHowMuchMoneyAmIMaking] = useState();
    let [visits, setVisits] = useState([]);
    let [signups, setSignups] = useState([]);
    let [year, setYear] = useState(new Date().getFullYear())
    let [visitTip, setVisitTip] = useState();
    let [signupTip, setSignupTip] = useState();

    componentDidMount(async () => {
        setLoading(true);
        let { data } = await axios.post('/api/site-analytics/', { user });
        console.log(data);
        let { users, activeUsers, howMuchMoneyAmIMaking, visits, signups } = data;
        setUsers(users);
        setActiveUsers(activeUsers);
        setHowMuchMoneyAmIMaking(howMuchMoneyAmIMaking);
        setVisits(visits);
        setSignups(signups);
        setLoading(false);
    })

    let months = ['January','February','March','April','May','June','July','August','September','October','November','December']
    let maxVisits = Math.max(...visits);
    let maxSignups = Math.max(...signups);

    return <div className="Panel SiteAnalytics">
        {!loading ? <div className="fadein">
            <h1>Site analytics</h1>
            <h2>Visits</h2>
            <div className="graph" onMouseLeave={() => setVisitTip()}>
                <div className="tip"><h4>{visitTip}</h4></div>
                {visits.map((v, i) => <div onMouseEnter={()=>setVisitTip(v)}onMouseLeave={() => setVisitTip()} key={i} className="bar" style={{height:(v/maxVisits)*300}} />)}
            </div>
            <h2>New users</h2>
            <div className="graph newUsers" onMouseLeave={() => setSignupTip()}>
                <div className="tip"><h4>{signupTip}</h4></div>
                {signups.map((v, i) => <div onMouseEnter={()=>setSignupTip(v)}onMouseLeave={() => setSignupTip()} key={i} className="bar" style={{height:(v/maxSignups)*100}} />)}
            </div>
            <div className="siteAnalytics">
                <div className="panpan">
                    <h2>Total users: {users}</h2>
                    <h4>Active this month: {activeUsers.length}</h4>
                    <div className="activeUsers">
                        {activeUsers.map((u, i) => <div key={i} className="activeUser"><Username username={u.username} />: {u.count} visit{u.count==1?'':'s'}</div>)}
                    </div>
                </div>
                <div className="panpan">
                    <h2>Total earnings: £{howMuchMoneyAmIMaking.total}</h2>
                    <h3><Btn onClick={() => setYear(year - 1)}><ImgSmallArrowLeft /></Btn>{year}: £{howMuchMoneyAmIMaking.payments.filter(p => p.date.year == year).reduce((a,b)=>a+b.total,0)}<Btn onClick={()=>setYear(year+1)}><ImgSmallArrowRight/></Btn></h3>
                    <div className="earnings">
                        {howMuchMoneyAmIMaking.payments.filter(p => p.date.year == year).map(({ date, payments, total }, i) => <div className="paymentMonth" key={JSON.stringify(date)}>
                            <h4>{months[date.month]}: £{total}</h4>
                        </div>)}
                    </div>
                </div>
            </div>
        </div> : null}
    </div>
}