import React, {useState, useContext, useRef} from 'react';
import { ModalContext, ToastContext, useSubmit, useForceRender, AccountContext, UsersContext } from '../../hooks';
import Input from '../controls/Input';
import TextArea from '../controls/TextArea';
import Button from '../controls/Button';
import ModalHeading from '../modal/ModalHeading';
import Uploader from '../controls/Uploader';
import AnimButton from '../controls/AnimButton';
import Toast from '../modal/Toast';

import { validateName, validateBio, validateInsta } from '../../functions';
import ProfilePic from '../user/ProfilePic';

import { ImgBoat,ImgButterfly,ImgNone,ImgYinyang } from '../../pictures/animations/SVG';

import axios from 'axios';
import ModalMessage from '../modal/ModalMessage';

export default function ProfileSettings({ close, profile }) {
    let Modals = useContext(ModalContext);
    let Toasts = useContext(ToastContext);
    let { setUser, user, subbed } = useContext(AccountContext);
    let { resetProfilePic } = useContext(UsersContext);

    let [c, submit, onSubmit] = useSubmit();
    let [loading, setLoading] = useState(false);
    let [loadingUpload, setLoadingUpload] = useState(false);

    let [name, setName] = useState(profile.name);
    let [insta, setInsta] = useState(profile.insta);
    let [picture, setPicture] = useState(profile.picture ? profile.picture : {});
    let [removePic, setRemovePic] = useForceRender();
    // let [uploadPic, setUploadPic] = useForceRender();
    let [bio, setBio] = useState(profile.bio);

    let [nameError, setNameError] = useState('');
    let [instaError, setInstaError] = useState('');
    let [pictureError, setPictureError] = useState('');
    let [bioError, setBioError] = useState('');

    let [style, setStyle] = useState(subbed && picture.style ? picture.style : "None");

    let [serverError, setServerError] = useState('');

    onSubmit(async () => {
        if (!nameError && !bioError && !instaError) {
            if (pictureError) picture = {};
            setLoading(true);
            let { data } = await axios.post('/api/edit-profile', { name, bio, picture:{...picture, style}, insta, user });
            let { error } = data;
            if (error) {
                setServerError(error);
            } else {
                setUser(data.user);
                resetProfilePic(data.user.username, data.user.picture);
                Toasts.create(Toast, { text: 'Profile updated!' });
                close();
            }
            setLoading(false);
        }
    })

    let uploader = useRef();

    let animClick = () => {
        if (!subbed) Modals.create(ModalMessage, { preset: "Donate" });
    }

    return <div className="ProfileSettings modal">
        <ModalHeading title="Profile settings" close={close} />
        <div className="body">
            <form onSubmit={submit}>

                <Input
                    label="Name"
                    value={name}
                    setValue={setName}
                    error={nameError}
                    setError={setNameError}
                    validation={validateName}
                    attempts={c}
                    disabled={loading}
                />

                <Input
                    label="Instagram handle"
                    value={insta}
                    setValue={setInsta}
                    error={instaError}
                    setError={setInstaError}
                    validation={validateInsta}
                    attempts={c}
                    disabled={loading}
                />

                <TextArea
                    label="Bio"
                    value={bio}
                    setValue={setBio}
                    error={bioError}
                    setError={setBioError}
                    validation={validateBio}
                    attempts={c}
                    disabled={loading}
                />

                <br />
                
                <div className="centre buttonBit">
                    {picture.path ?
                        <>
                            <ProfilePic clickable={false} picture={{...picture, style}} width={100} />
                            <div className="buttons">
                                <Button fast onClick={()=>document.getElementById(uploader.current).click()} loading={loadingUpload} disabled={loading}>
                                    Change picture
                                </Button>
                                <Button onClick={setRemovePic} loading={loadingUpload} disabled={loading}>
                                    Remove picture
                                </Button>
                            </div>
                        </> : <>
                            <ProfilePic clickable={false} picture={user.username} width={100} />
                            <div className="buttons">
                                <Button fast onClick={()=>document.getElementById(uploader.current).click()} loading={loadingUpload} disabled={loading}>
                                    Upload image
                                </Button>
                            </div>
                        </>
                    }
                    <Uploader
                        setError={setPictureError}
                        setPicture={setPicture}
                        // upload={uploadPic}
                        remove={removePic}
                        setLoading={setLoadingUpload}
                        setId={id=>uploader.current = id}
                    />
                </div>

                {pictureError?<div className="err">{pictureError}</div>:<br/>}

                <label>Profile picture animation</label>
                <div className="AnimationSelector">
                    <div className="AnimButtons">
                        <AnimButton className="None" setTransform={c => null} style={subbed?style:"None"} setStyle={subbed?setStyle:animClick} disabled={loading} Img={ImgNone}/>
                        <AnimButton className="Rotational" setTransform={c => `rotate(${c * 180}deg)`} style={subbed?style:"None"} setStyle={subbed?setStyle:animClick} disabled={loading} Img={ImgYinyang}/>
                        <AnimButton className="Mirror" setTransform={c => `scale(${c % 2 == 0 ? 1 : -1},1)`} style={subbed?style:"None"} setStyle={subbed?setStyle:animClick} disabled={loading} Img={ImgButterfly}/>
                        <AnimButton className="Lake" setTransform={c => `scale(1,${c % 2 == 0 ? 1 : -1})`} style={subbed?style:"None"} setStyle={subbed?setStyle:animClick} disabled={loading} Img={ImgBoat}/>
                    </div>
                </div>

                <br/>

                <div className="formButtons">
                    <div className="buttons">
                        <Button submit loading={loading}>Save</Button>
                    </div>
                    <div className="error">
                        {serverError}
                    </div>
                </div>
            </form>
        </div>
    </div>
}