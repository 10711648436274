import React, { useState, useContext, useRef, useEffect } from 'react';
import { ModalContext, AccountContext, useTransition, useTimeout, useForceRender, useGetState} from '../../hooks';
import Btn from '../controls/Btn';
import Login from '../forms/Login';
import Signup from '../forms/Signup';
import VerificationMessage from '../forms/VerificationMessage';
import axios from 'axios';

import { ImgQuestion, ImgCross, ImgTick } from '../../pictures/SVG';

export default function Legibility({ votes, post }) {
    let [ups, setUps] = useState(votes.reduce((a, b) => a + b.rating ? 1 : 0, 0));
    let [downs, setDowns] = useState(votes.reduce((a, b) => a + b.rating ? 0 : 1, 0));
    let [iconScale, setIconScale] = useState(1);
    let [voted, setVoted, getVoted] = useGetState(false);
    let {user} = useContext(AccountContext);
    let Modals = useContext(ModalContext);
    let Icon = useTransition("iconTransition");
    let [hovering, setHovering, getHovering] = useGetState(false);
    let { doTimeout, undoTimeout } = useTimeout();
    let timeout = useRef();
    let [force, forceRender] = useForceRender();
    let [recentlyClicked, setRecentlyClicked] = useState();

    let login = () => Modals.create(localStorage.getItem('happy') ? Login : Signup, { feature: true });
    
    let setTick = () => Icon.set(ImgTick, { className: 'BLUE' },'blue');
    let setCross = () => Icon.set(ImgCross, { className: 'RED' },'red');
    let setQuestion = () => Icon.set(ImgQuestion, { className: 'LIGHTISHGREY' },'grey');

    let idle = useRef(setQuestion);

    useEffect(() => {
        let ups = votes.reduce((a, b) => a + (b.rating > 0 ? 1 : 0),0);
        let downs = votes.reduce((a, b) => a + (b.rating < 0 ? 1 : 0), 0);
        let voted = user && votes.find(vote => user.username == vote.username);
        let idleClass = voted ? voted.rating > 0 ? setTick : setCross : setQuestion;
        setUps(ups);
        setDowns(downs);
        setVoted(voted);
        idle.current = idleClass;
        idle.current();
    }, [force])
    
    function vote(rating) {
        if (!user) {
            login();
        } else {
            if (user.verified) {
                let voted = getVoted();
                if (voted) {
                    if (voted.rating == rating) {
                        setVoted(null);
                        rating = null;
                        for (let i = votes.length-1; i >= 0; i--) {
                            if (votes[i].username == user.username) {
                                votes.splice(i, 1);
                                break;
                            }
                        }
                    } else {
                        voted.rating = rating;
                    }
                } else {
                    votes.push({ username: user.username, rating });
                }
                axios.post('/api/rate-legibility', { rating, post: { _id: post._id }, user });
                setIconScale(1.2);
                doTimeout(() => setIconScale(1), 100);
                setRecentlyClicked(true);
                forceRender();
            } else {
                Modals.create(VerificationMessage,{feature:true});
            }
        }
    }

    let recentTimeout = useRef();
    useEffect(() => {
        if (recentlyClicked) {
            undoTimeout(recentTimeout.current);
            recentTimeout.current = doTimeout(() => setRecentlyClicked(false), 5, 's');
        }
    },[recentlyClicked])


    return <div
        className="legibility"
        onMouseEnter={()=>setHovering(true)}
        onMouseLeave={() => {
            setHovering(false)
            undoTimeout(timeout.current);
            timeout.current = doTimeout(() => {
                if (!getHovering()) idle.current();
            }, 200)
        }}
    >
        <div>
            <Btn
                noPadding bold
                onClick={() => vote(1)}
                onMouseEnter={setTick}
            >Legible</Btn>

            <div
                className="icon"
                style={{
                    transform: `scale(${iconScale})`
                }}
            >
                {Icon.Render}
            </div>

            <Btn
                noPadding bold
                colour="red"
                onClick={() => vote(-1)}
                onMouseEnter={setCross}
            >Illegible</Btn>
        </div>
        <div className="bar">
            <div
                className="blue"
                style={{
                    width: ups == 0 ? 0 : downs == 0 ? '100%' : `calc(${(ups / (ups + downs)) * 100}% - 1px)`
                }}
            />
            <div
                className="red"
                style={{
                    width: downs == 0 ? 0 : ups == 0 ? '100%' : `calc(${(downs / (ups + downs)) * 100}% - 1px)`
                }}
            />
            <div className={hovering || recentlyClicked ?"ratings":"hidden ratings"}>
                <div className="ups">{ups}</div>
                <div className="downs">{downs}</div>
            </div>
        </div>
    </div>
}