import React, {useState, useContext} from 'react';
import { ModalContext, ToastContext, useSubmit, AccountContext } from '../../hooks';
import Input from '../controls/Input';
import Button from '../controls/Button';
import ModalHeading from '../modal/ModalHeading';
import ModalMessage from '../modal/ModalMessage';
import VerificationMessage from '../forms/VerificationMessage';
import Toast from '../modal/Toast';
import A from '../controls/A';
import Signup from './Signup';
import ResetPassword from './ResetPassword';

import { validateUsername, validatePassword } from '../../functions';

export default function Login({ close, feature, callback = () => { } }) {
    let { login } = useContext(AccountContext);
    let Toasts = useContext(ToastContext);

    let [username, setUsername] = useState('');
    let [password, setPassword] = useState('');

    let [serverError, setServerError] = useState('');
    let [usernameError, setUsernameError] = useState('');
    let [passwordError, setPasswordError] = useState('');
    let [loading, setLoading] = useState('');

    let [c, submit, onSubmit] = useSubmit();
    let Modals = useContext(ModalContext);

    onSubmit(async () => {
        if (!usernameError && !passwordError) {
            setLoading(true);
            setServerError('');
            let { user, error } = await login(username, password);
            if (error) {
                setUsernameError(error.username);
                setPasswordError(error.password);
                setServerError(error.server);
                if (error.server) Toasts.create(Toast, { preset: 'server error' });
            } else {
                Toasts.create(Toast, { text: `Welcome, ${user.username}!` });
                await close();
                if (user.verified) {
                    await new Promise(resolve => Modals.create(ModalMessage, { title: 'Success!', body: 'You have been logged in.', callback: resolve }));
                } else {
                    Modals.create(VerificationMessage);
                }
                callback();
            }
            setLoading(false);
        }
    })

    return <div className="Login modal">
        <ModalHeading title="Log in" close={close} />
        <div className="body">
        {feature ? <p>I'm sorry, need to be logged in to use that feature.</p> : null}
        <p>Not yet signed up? <A red onClick={async () => {
            await close();
            Modals.create(Signup);
            }}>Create an account!</A></p>
        <p>Forgotten your password? <A red onClick={async () => {
            await close();
            Modals.create(ResetPassword);
        }}>Reset your password!</A></p>
            
            <form onSubmit={submit}>
                <Input
                    label="Username"
                    value={username}
                    setValue={setUsername}
                    error={usernameError}
                    setError={setUsernameError}
                    validation={validateUsername}
                    attempts={c}
                    disabled={loading}
                />
                <Input
                    label="Password"
                    type="password"
                    value={password}
                    setValue={setPassword}
                    error={passwordError}
                    setError={setPasswordError}
                    validation={validatePassword}
                    attempts={c}
                    disabled={loading}
                />

                <div className="formButtons">
                    <div className="buttons">
                        <Button submit loading={loading}>Log in</Button>
                    </div>
                    <div className="error">
                        {serverError}
                    </div>
                </div>
            </form>
        </div>
    </div>
}