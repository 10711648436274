import React, { useContext, useEffect, useState } from 'react';
import { AccountContext, HistoryContext, ModalContext, useMount, useTitle } from '../../hooks';
import AmbigramPanel from '../ambigram/AmbigramPanel';
import UserPanel from '../user/UserPanel';
import Username from '../user/Username';
import P from '../controls/P';
import Pages from '../posts/Pages'
import axios from 'axios';
import Btn from '../controls/Btn';
import ArchivedContestComments from '../modal/ArchivedContestComments';

export default function Contest3({ post, page }) {
    let { user } = useContext(AccountContext);
    let [winner, setWinner] = useState();
    let { componentDidMount } = useMount();
    let Modals = useContext(ModalContext)
    let { navigate } = useContext(HistoryContext);
    let { setDocumentTitle } = useTitle();

    useEffect(() => {
        setDocumentTitle(`${post.theme} contest - $`);
    }, [post])
    
    componentDidMount(async () => {
        let { data } = await axios.post('/api/winner', { theme: post.theme, user });
        if (data) {
            console.log('Winner',data)
            setWinner(data);
        }
    })

    return <div className="Contest3">
        <div className="head PANEL">
            <h1>Contest #{post.count < 10 ? '0' + post.count : post.count} - <span>{post.theme}!</span></h1>
            {post.message ? <P>{post.message}</P>:null}
            <h4>And the winner is... <Username username={post.winner.username}/></h4>
            <div className="winner">
                <div className="postLeft">
                    <UserPanel post={winner?winner:post.winner} contest3/>
                </div>
                <div className="postRight">
                    <AmbigramPanel post={winner?winner:post.winner} contest3/>
                </div>
            </div>
        </div>
        {user?<div className="viewArchivedCommentsButton"><Btn colour="grey" onClick={()=>Modals.create(ArchivedContestComments,{theme:post.theme})}>View archived comments</Btn></div>:null}

        <Pages
            query={{ type: 'Entry', theme: post.theme, rank: { $ne: 0 } }}
            sort={{ rank:1 }}
            heading={<div className="congrats">
                <h3>Congratulations <Username username={post.winner.username} />!</h3>
                <h4>Here are the rest of the entries:</h4>
            </div>}
            startingPage={page}
            url={post.url + '/'}
            contest3
        />
    </div>
}