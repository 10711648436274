import React, { useState, useEffect, useContext } from 'react';
import { useAsync, useGetState, AccountContext, CentreKeyContext, ContestForceRenderContext } from '../../hooks';
import { NavLink } from 'react-router-dom';
import axios from 'axios';

import Btn from '../controls/Btn';

export default function ContestsPanel() {
    let centreKey = useContext(CentreKeyContext);
    let [selected, setSelected] = useState();
    let { user } = useContext(AccountContext);
    let [n, setN, getN] = useGetState(100);
    let [contests, setContests] = useState([]);
    let [current, setCurrent] = useState();
    let [reachedEnd, setReachedEnd] = useState();
    let [loading, setLoading] = useState(false);
    let [contestForce, contestForceRender] = useContext(ContestForceRenderContext);

    useAsync(async () => {
        let { data } = await axios.post('/api/current-contest', { user });
        setCurrent(data);
    },[contestForce])

    useAsync(async () => {
        if (!reachedEnd && contests.length < n) {
            setLoading(true);
            let { data } = await axios.post('/api/contests', { n });
            let { results, reachedEnd } = data;
            setContests(results);
            setReachedEnd(reachedEnd);
            setLoading(false);
        }
    }, [n, contestForce])

    useEffect(() => {
        if (centreKey && centreKey.indexOf('*') >= 0) {
            let split = centreKey.split('*');
            if (split[0] == 'Contest') {
                setSelected(split[1]);
            } else setSelected();
        } else setSelected();
    },[centreKey])

    return contests.length>0 ? <div className="ContestsPanel Panel">
        <h3 className="mb">All contests</h3>
        {current ? <NavLink
            className="noUnderline"
            to={current.url}
        ><div className={"current chainLink" + (selected == '' || current.url == selected ? ' selected' : '')}>
            <div className="count">{current.count < 10 ? '0' + current.count : current.count}</div>
            <div className="theme">
                <h4>{current.theme}</h4>
                {
                    current.type == 'Contest1' ? <>
                        <h3>Entries open!</h3>
                    </> :
                        current.type == 'Contest2' ? <>
                            <h3>Voting now!</h3>
                        </> :
                            current.type == 'Contest3' ? <>
                                <h3>Results in!</h3>
                            </> : null
                }
            </div>
        </div></NavLink> : null}
        {contests.map(({ count, theme, url }) => <NavLink
            key = {theme}
            className = "noUnderline"
            to = {url}
        ><div className={url == selected ? "chainLink selected" : "chainLink"}>
            <div className="count">{count < 10 ? '0' + count : count}</div>
            <div className="theme"><h4>{theme}</h4></div>
        </div></NavLink>)}
        {!reachedEnd ? <Btn colour="darkGrey" bold onClick={() => setN(n => n + 100)} loading={loading}>Load more</Btn> : null}
    </div> : null
}