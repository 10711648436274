import React, { useEffect, useState, useContext } from 'react';
import { ResponsiveContext, AccountContext, ToastContext, useAsync, ModalContext, useSubmit, useColours } from '../../../hooks';
import axios from 'axios';
import ReactJson, {ThemeObject} from 'react-json-view'
import Input from '../../controls/Input';
import Toast from '../../modal/Toast';
import SelectDropdown from '../../controls/SelectDropdown';
import Button from '../../controls/Button';



export default function Query({ }) {
    let { user, setUser } = useContext(AccountContext);
    let collections = ['Posts', 'Users', 'Userlog', 'Subscriptions', 'Adverts', 'Push', 'Vars'];

    let [query, setQuery] = useState('{}');
    let [sort, setSort] = useState('');
    let [collection, setCollection] = useState('Posts');
    let [page, setPage] = useState(0);
    let [resultsPerPage, setResultsPerPage] = useState(1);

    let [queryError, setQueryError] = useState();
    let [sortError, setSortError] = useState();
    let [serverError, setServerError] = useState();

    let [results, setResults] = useState();
    let [count, setCount] = useState(0);
    let [pages, setPages] = useState(0);


    let [loading, setLoading] = useState(false);

    let [c, submit, onSubmit] = useSubmit();

    let Toasts = useContext(ToastContext);
    let {colours} = useColours();

    function parse(str) {
        return JSON.parse(str.replace(/'(.*?)'/g, '"$1"').replace(/\$/g, 'DOLLARSIGN').replace(/(\w+):\s*/g, '"$1":').replace(/DOLLARSIGN/g, '$'));
    }

    onSubmit(async () => {
        if (!queryError && !sortError) {
            setServerError('');
            setLoading(true);
            let { data } = await axios.post('/api/query', { user, query: parse(query), sort: sort ? parse(sort) : null, collection, page, resultsPerPage })
            if (data.error) {
                Toasts.create(Toast, { type: "error", text: data.error });
                setServerError(data.error);
            } else {
                setResults(data.results);
                setCount(data.count);
                setPages(data.pages);
                console.log(data);
            }
            setLoading(false);
        }
    })

    return <div className="Panel QueryAdmin centrepanel">
        <h1>Query</h1>
        <form onSubmit={submit}>
            <Input
                label="Query"
                value={query}
                setValue={setQuery}
                error={queryError}
                setError={setQueryError}
                validation={() => {
                    try {
                        let q = parse(query);
                        if (!(typeof q === 'object' && q !== null && !Array.isArray(q))) {
                            return 'Invalid query!';
                        }
                    } catch (e) {
                        return 'Invalid query!';
                    }
                }}
                attempts={c}
                disabled={loading}
            />
            <div className="split">
                <div className="flex1">
                    <Input
                        label="Sort"
                        value={sort}
                        setValue={setSort}
                        error={sortError}
                        setError={setSortError}
                        validation={() => {
                            if (!sort) return false;
                            try {
                                let s = parse(sort);
                                if (!(typeof s === 'object' && s !== null && !Array.isArray(s))) {
                                    return 'Invalid query!';
                                }
                            } catch (e) {
                                return 'Invalid query!';
                            }
                        }}
                        attempts={c}
                        disabled={loading}
                    />
                </div>
                <div>
                    <Input
                        type="number"
                        label="Page"
                        value={page}
                        setValue={setPage}
                        min={0}
                    />
                </div>
                <div>
                    <Input
                        type="number"
                        label="Results per page"
                        value={resultsPerPage}
                        setValue={setResultsPerPage}
                        min={1}
                    />
                </div>
                <div>
                    <SelectDropdown options={collections} selected={collection} setSelected={setCollection} disabled={loading} />
                </div>
            </div>
            <div className="formButtons">
                <div className="buttons">
                    <Button submit loading={loading}>Query</Button>
                </div>
                <div className="error">
                    {serverError}
                </div>
            </div>
        </form>
        {results ? <div className="queryResults">
            <ReactJson
                src={results}
                enableClipboard={false}
                displayDataTypes={false}
                collapsed={1}
            />
        </div> : null}

    </div>
}
