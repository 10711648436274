import React, { useContext, useState } from 'react';
import { AccountContext, ModalContext, useAsync} from '../../hooks';
import ModalHeading from '../modal/ModalHeading';
import Button from '../controls/Button';
import axios from 'axios';
import Spinner from '../Logo/Spinner';
import Pagination from '../posts/ModalPagination';
import Thumbnail from '../controls/Thumbnail';
import UploadAmbigram from '../forms/UploadAmbigram';

export default function PickAmbigram({ close, callback, block }) {
    let [page, setPage] = useState(0);
    let [pages, setPages] = useState(0);
    let [count, setCount] = useState(0);
    let [posts, setPosts] = useState([]);
    let [nothing, setNothing] = useState(false);
    let [loading, setLoading] = useState(false);
    let { user } = useContext(AccountContext);
    let Modals = useContext(ModalContext);

    useAsync(async () => {
        setLoading(true);
        let blockArray = block.map(b => {
            return {
                url: {$ne: b}
            }
        })
        let query = {
            $or: [
                { type: 'ChallengeEntry' },
                { type: 'Entry' },
                { type: 'Ambigram' }
            ],
            username: user.username
        };
        if (block.length > 0) {
            if (block.length == 1) {
                query = {...query,...(blockArray[0])}
            } else {
                query.$and = blockArray;
            }
        }
        console.log(query);
        let { data } = await axios.post('/api/page', { page, user, query });
        setLoading(false);
        let { posts, pages, count } = data;
        setNothing(posts.length == 0);
        setPosts(posts);
        setPages(pages);
        setCount(count);
    }, [page])

    return <div className="PickAmbigram modal" >
        <ModalHeading title="Add ambigram" close={close} />
        <div className="body">
            {loading ? <div className="centreFlex llooaaddiinngg"><Spinner/></div> :
            posts.length > 0 ? <>
                    <div>
                        <Pagination page={page} pages={pages} setPage={setPage} />
                        <div className={"pages" + (pages == 0 ? ' marginTop' : '')} style={{ opacity: loading ? 0 : 1 }} >
                            {posts.map(post => <Thumbnail src={'/api/pictures/' + post.picture.path} key={post._id} onClick={() => {
                                callback(post);
                                close();
                            }} />)}
                        </div>
                        <Pagination page={page} pages={pages} setPage={setPage} />
                        <Button
                            onClick={() => {
                                Modals.create(UploadAmbigram, {
                                    callback: async post => {
                                        let {data} = await axios.post('/api/upload-ambigram', {...post, user});
                                        if (data) {
                                            callback(data);
                                            close();
                                            return true;
                                        } else {
                                            return false;
                                        }
                                    }
                                })
                            }}
                        >
                            Upload new ambigram
                        </Button>
                    </div>
                </> : null
            }
        </div>
    </div>
}