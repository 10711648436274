import React, {useState, useEffect, useContext} from 'react';
import Ambigram from './Ambigram';
import Comments from '../posts/Comments';
import { ResponsiveContext, ModalContext, AccountContext, ToastContext, useAreYouSure, useDynamic, useTimeout, useForceRender, useMount } from '../../hooks';
import P from '../controls/P';
import Btn from '../controls/Btn.js';
import Dropdown from '../controls/Dropdown.js';
import Toast from '../modal/Toast';
import UploadAmbigram from '../forms/UploadAmbigram';

import { ImgDropdown } from '../../pictures/SVG';

import axios from 'axios';
import EditChallenge from '../forms/EditChallenge';
import { dateString } from '../../functions';
import Login from '../forms/Login';
import Signup from '../forms/Signup';
import Button from '../controls/Button';
import VerificationMessage from '../forms/VerificationMessage';
import Shrinker from '../controls/Shrinker';
import Vote from '../top level/Vote';
import Slideshow from './Slideshow';
import { NavLink } from 'react-router-dom/cjs/react-router-dom.min';

export default function ChallengePanel({ post, single, page }) {
    if (post.failed) {
        return <Failed post={post} />
    } else if (post.open) {
        return <Challenge1 post={post} />
    } else if (post.voting) {
        return <Challenge2 post={post} />
    } else {
        return <Challenge3 post={post} single={single} page={page} />
    }
}

export function remainingDateString(date) {
    let today = new Date();
    let age = date - today.getTime();

    let s = Math.floor(age / 1000);
    let m = Math.floor(s / 60);
    s -= m * 60;
    let h = Math.floor(m / 60);
    m -= h * 60;
    
    let ss = `${s < 10 ? '0' : ''}${s}s`;
    let mm = `${m < 10 ? '0' : ''}${m}m`;
    let hh = `${h < 10 ? '0' : ''}${h}h`;

    if (m <= 0) return ss; 
    if (h <= 0) return `${mm} ${ss}`; 
    return `${hh} ${mm} ${ss}`; 
}

function RemainingTime({ stamp }) {
    let [string, setString] = useState('');
    let [live, setLive] = useState(false);
    let { doInterval } = useTimeout();
    useEffect(() => {
        let today = new Date();
        let age = stamp - today.getTime();
        if (age > 1000 * 60 * 60 * 24) {
            if (age < 1000 * 60 * 60 * 24 * 2) {
                setString('tomorrow');
            } else {
                setString(dateString(stamp));
            }
        } else {
            setLive(true);
            setString(remainingDateString(stamp));
            doInterval(() => {
                setString(remainingDateString(stamp));
            },1,'s')
        }
    },[])
    return <div className={`remainingTime ${live?'live':''}`}>{string}</div>;
}

function Challenge1({ post }) {
    let { device, bigPhone } = useContext(ResponsiveContext);
    let Dynamic = useDynamic('post', post._id);
    let SetDeleted, SetLoading, SetPost;
    if (Dynamic) {
        SetDeleted = Dynamic.SetDeleted;
        SetLoading = Dynamic.SetLoading;
        SetPost = Dynamic.SetPost;
    }
    let { user, setOpenChallenge, checkPermissions } = useContext(AccountContext);
    let [entry, setEntry] = useState(post.entry);
    let [entries, setEntries] = useState(post.entries);
    let [entryCount, setEntryCount] = useState(post.entryCount);
    let Modals = useContext(ModalContext);
    let Toasts = useContext(ToastContext);
    let areYouSure = useAreYouSure();
    let [ended, setEnded] = useState(() => post.deathday < new Date().getTime());
    let [force, forceRender] = useForceRender();
    let { doTimeout } = useTimeout();
    let [moderation, setModeration] = useState(user && post.moderators && post.moderators.indexOf(user.username) >= 0); //// YOU CAN EDIT THIS LATER (YOU KNOW WHAT TO DO)

    let login = () => Modals.create(localStorage.getItem('happy') ? Login : Signup, { feature: true });
    
    let me = user && (user.username == post.username);

    let dropdownOptions = [];

    if (post._id) {
        dropdownOptions.push({ title: 'Open post in new tab', link: post.url, newTab: true });
    }

    useEffect(() => {
        let now = new Date().getTime();
        let d = Math.max(0, post.deathday - now);
        doTimeout(() => {
            setEnded(true);
        }, d);
    },[])

    let [width, setWidth] = useState(600);
    useEffect(() => {
        if (device == 'phone') {
            setWidth(400);
        } else if (device == 'tablet') {
            setWidth(500);
        } else setWidth(600);
    }, [device])

    async function deletePost() {
        if ((me || checkPermissions('Remove posts')) && await areYouSure("Are you sure you want to delete this post?")) {
            SetLoading(true);
            let {data} = await axios.post('/api/delete-post', { post, user });
            if (data) {
                SetDeleted(true);
            } else {
                Toasts.create(Toast, {preset:'server error'});
            }
            setOpenChallenge(0);
            SetLoading(false);
        }
    }

    async function editPost() {
        Modals.create(EditChallenge, {
            post,
            callback: async post => {
                let {data} = await axios.post('/api/edit-post', { post, user });
                if (data) {
                    SetPost(data);
                    return true;
                } else {
                    return false;
                }
            }
        })
    }

    function uploadAmbigram() {
        Modals.create(UploadAmbigram, {
            callback: async entry => {
                let {data} = await axios.post('/api/submit-challenge-entry', {...entry, post:{ _id: post._id }, user});
                if (data) {
                    post.entry = data.entry;
                    post.entry.new = true;
                    setEntry(post.entry)
                    setEntryCount(entryCount + 1);
                    return true;
                } else {
                    return false;
                }
            },
            challenge: true
        })
    }

    if (SetDeleted) {
        if (me || checkPermissions('Edit posts')) dropdownOptions.push({title: 'Edit post', callback:editPost });
        if (me || checkPermissions('Remove posts')) dropdownOptions.push({ title: 'Delete post', callback:deletePost });
    }

    if (user) {
        dropdownOptions.push({
            title: 'Stop notifications', callback: async () => {
            await axios.post('/api/stop-notifications', { user, post: {_id:post._id} });
        } });
    }

    async function editEntry() {
        Modals.create(UploadAmbigram, {
            post: post.entry,
            callback: async entry => {
                delete entry.new;
                let {data} = await axios.post('/api/edit-challenge-submission', { post, entry, user });
                if (data) {
                    if (data.error) {
                        console.log(data.error)
                    } else {
                        post.entry = entry;
                        setEntry({...post.entry});
                        return true;
                    }
                } else {
                    return false;
                }
            },
            challenge: true
        })
    }

    async function deleteEntry(entry) {
        let mine = !entry;
        if (!entry) entry = post.entry;
        if (await areYouSure('Are you sure you want to remove this entry?')) {
            let { data } = await axios.post('/api/remove-challenge-entry', { post, entry, user });
            if (data) {
                entry.shrink = true;
                if (mine) {
                    setEntry({...entry});
                } else {
                    setEntries([...entries]);
                }
                doTimeout(() => {
                    if (mine) {
                        setEntry();
                    } else {
                        entries = entries.filter(e => e.username != entry.username);
                        setEntries([...entries]);
                    }
                    setEntryCount(entryCount - 1);
                }, 0.35, 's')
            } else {
                Toasts.create(Toast, { preset: "server error" });
            }
        }
    }

    return <div className="ChallengePanel">
        <div className="wordBit">
            <div className="flex1">
                <h2>Mini challenge</h2>
                <h1><span>{post.word}</span></h1>
                <h4>Deadline: <RemainingTime stamp={post.deathday} /></h4>
            </div>
            <div className="dropdownBit">
                <Dropdown
                    options={dropdownOptions}
                    btnProps={{className:"dropArrow",height:'1rem'}}
                ><ImgDropdown className="DARKGREY"/></Dropdown>
            </div>
        </div>
        {ended ? <h2>This challenge is closed to new entries!</h2> : <>
            <div className="instruction">
                {entry ? <Shrinker expanded={!entry.shrink} animateOn={entry.new}>
                    <h4>Your entry (only you can see this):</h4>
                    <div className="entry">
                        <div className="entryLeft">
                            <Ambigram picture={entry.picture} style={entry.style} bounds={width} />
                        </div>
                        <div className="entryRight">
                            <P>{entry.message}</P>
                            {!ended ? <>
                                {!moderation ? <Btn onClick={() => editEntry()}>Edit</Btn> : null}
                                {user.username != post.username ? <Btn colour="red" onClick={() => deleteEntry()}>Delete</Btn> : null}
                            </> : null}
                        </div>
                    </div>
                </Shrinker> : <h4>Enter your ambigram of <span>{post.word}</span> before the deadline!</h4>}
                {entries && moderation ? <div className="entries">
                    {entries.map((entry,i)=><Shrinker expanded={!entry.shrink} animateOn={entry.new} key={i+'_'+entry.username}>
                        <div className="entry">
                            <div className="entryLeft">
                                <Ambigram picture={entry.picture} style={entry.style} bounds={width} />
                            </div>
                            <div className="entryRight">
                                <NavLink to={'/user/'+entry.username.toLowerCase()}><h3>{entry.username}</h3></NavLink>
                                <P>{entry.message}</P>
                                {!ended && entry.username != post.username ? <>
                                    <Btn colour="red" onClick={() => deleteEntry(entry)}>Delete</Btn>
                                </> : null}
                            </div>
                        </div>
                    </Shrinker>)}
                </div>:null}
                <p>There {entryCount == 1 ? 'is' : 'are'} {entryCount} entr{entryCount == 1 ? 'y' : 'ies'} so far!</p>
                <Shrinker expanded={checkPermissions('Moderate challenges') ? !moderation : !entry }>
                    <div className="buttonsGap">
                        <Button
                            onClick={!user ? login : !user.verified ? () => Modals.create(VerificationMessage, { feature: true }) : uploadAmbigram}
                            disabled={!!entry || ended}
                        >
                            Submit an ambigram
                        </Button>
                        {checkPermissions('Moderate challenges') ?
                            <Button
                                colour="red"
                                onClick={async () => {
                                    if (await areYouSure('Are you sure you want to enter moderation mode?\nThis will mean you can not edit your existing entry!')) {
                                        let { data } = await axios.post('/api/moderate-challenge1', { post: { _id: post._id }, user });
                                        setEntries(data);
                                        setModeration(true);
                                    }
                                }}
                            >
                                Moderate entries
                            </Button>
                        : null}
                    </div>
                </Shrinker>

            </div>
            <div className="message">
                <P noMarginBottom>{post.message}</P>
            </div>
        </>}
        {post.comments?<Comments post={post} />:null}
    </div>
}

function Challenge2({ post }) {
    let Dynamic = useDynamic('post', post._id);
    let SetDeleted, SetLoading, SetPost;
    if (Dynamic) { SetDeleted = Dynamic.SetDeleted; SetLoading = Dynamic.SetLoading; SetPost = Dynamic.SetPost; }
    let { user, checkPermissions } = useContext(AccountContext);
    let Modals = useContext(ModalContext);
    let Toasts = useContext(ToastContext);
    let areYouSure = useAreYouSure();
    let [ended, setEnded] = useState(() => post.deathday < new Date().getTime());
    let dropdownOptions = [];
    let me = user && (user.username == post.username);
    if (post._id) dropdownOptions.push({ title: 'Open post in new tab', link: post.url, newTab: true });
    let { doTimeout } = useTimeout();
    useEffect(() => { doTimeout(() => setEnded(true), Math.max(0, post.deathday - new Date().getTime())) }, []);
    async function deletePost() {
        if ((me || checkPermissions('Remove posts')) && await areYouSure("Are you sure you want to delete this post?")) {
            SetLoading(true);
            let {data} = await axios.post('/api/delete-post', { post, user });
            if (data) { SetDeleted(true) } else { Toasts.create(Toast, {preset:'server error'}) }
            SetLoading(false);
        }
    }
    async function editPost() {
        Modals.create(EditChallenge, { post, callback: async post => {
            let {data} = await axios.post('/api/edit-post', { post, user });
            if (data) SetPost(data);
            return !!data;
        }})
    }

    if (SetDeleted) {
        if (me || checkPermissions('Edit posts')) dropdownOptions.push({title: 'Edit post', callback:editPost });
        if (me || checkPermissions('Remove posts')) dropdownOptions.push({ title: 'Delete post', callback:deletePost });
    }

    return <div className="ChallengePanel">
        <div className="wordBit">
            <div className="flex1">
                <h2>Mini challenge - <span>{post.word}</span></h2>
                <h1>Time to vote!</h1>
                <h4>Voting ends: <RemainingTime stamp={post.deathday} /></h4>
            </div>
            <div className="dropdownBit"><Dropdown options={dropdownOptions} btnProps={{className:"dropArrow",height:'1rem'}} ><ImgDropdown className="DARKGREY"/></Dropdown></div>
        </div>
        {ended ? <h2>Voting has ended!</h2> : <>
            <div className="message">
                <P noMarginBottom>{post.message}</P>
            </div>
            <div className="instruction">
                <h4>Please leave a vote on as many as you want.</h4>
                <p>There were {post.entries.length} entries!</p>
            </div>
            <Vote
                post={post}
            />
        </>}
        {post.comments?<Comments post={post} />:null}
    </div>
}

function Challenge3({ post, single, page }) {
    let { device, bigPhone } = useContext(ResponsiveContext);
    let Dynamic = useDynamic('post', post._id);
    let SetDeleted, SetLoading, SetPost;
    if (Dynamic) { SetDeleted = Dynamic.SetDeleted; SetLoading = Dynamic.SetLoading; SetPost = Dynamic.SetPost; }
    let { user, checkPermissions } = useContext(AccountContext);
    let [entry, setEntry] = useState(post.entry);
    let Modals = useContext(ModalContext);
    let Toasts = useContext(ToastContext);
    let areYouSure = useAreYouSure();
    let dropdownOptions = [];
    if (post._id) dropdownOptions.push({ title: 'Open post in new tab', link: post.url, newTab: true });
    let [width, setWidth] = useState(600);
    useEffect(() => setWidth(device == 'phone' ? 400 : device == 'tablet' ? 500 : 600), [device]);
    let [winner, setWinner] = useState();
    let me = user && (user.username == post.username);

    let { componentDidMount } = useMount();
    componentDidMount(async () => {
        let { data } = await axios.post('/api/challenge-winner', { word: post.word });
        if (data) setWinner(data);
    })
    
    async function deletePost() {
        if ((me || checkPermissions('Remove posts')) && await areYouSure("Are you sure you want to delete this post?")) {
            SetLoading(true);
            let {data} = await axios.post('/api/delete-post', { post, user });
            if (data) { SetDeleted(true) } else { Toasts.create(Toast, {preset:'server error'}) }
            SetLoading(false);
        }
    }
    async function editPost() {
        Modals.create(EditChallenge, { post, callback: async post => {
            let {data} = await axios.post('/api/edit-post', { post, user });
            if (data) SetPost(data);
            return !!data;
        }})
    }
    if (SetDeleted) {
        if (me || checkPermissions('Edit posts')) dropdownOptions.push({title: 'Edit post', callback:editPost });
        if (me || checkPermissions('Remove posts')) dropdownOptions.push({ title: 'Delete post', callback:deletePost });
    }

    return <div className="ChallengePanel">
        <div className="wordBit">
            <div className="flex1">
                <h2>Mini challenge - <span>{post.word}</span></h2>
                <h1>Results!</h1>
            </div>
            <div className="dropdownBit"><Dropdown options={dropdownOptions} btnProps={{className:"dropArrow",height:'1rem'}} ><ImgDropdown className="DARKGREY"/></Dropdown></div>
        </div>
        <div className="message">
            <P noMarginBottom>{post.message}</P>
        </div>
        {single ? null : <>
            <Slideshow query={{ type: 'ChallengeEntry', word: post.word }} link={{title:'View the entries',url:post.url}} />
        </>}
        {post.comments?<Comments post={post} />:null}
    </div>
}

function Failed({ post }) {
    let { device, bigPhone } = useContext(ResponsiveContext);
    let Dynamic = useDynamic('post', post._id);
    let SetDeleted, SetLoading, SetPost;
    if (Dynamic) { SetDeleted = Dynamic.SetDeleted; SetLoading = Dynamic.SetLoading; SetPost = Dynamic.SetPost; }
    let { user, checkPermissions } = useContext(AccountContext);
    let Modals = useContext(ModalContext);
    let Toasts = useContext(ToastContext);
    let areYouSure = useAreYouSure();
    let me = user && (user.username == post.username);
    let dropdownOptions = [];
    if (post._id) dropdownOptions.push({ title: 'Open post in new tab', link: post.url, newTab: true });
    let [width, setWidth] = useState(600);
    useEffect(() => setWidth(device == 'phone' ? 400 : device == 'tablet' ? 500 : 600), [device]);
    async function deletePost() {
        if ((me || checkPermissions('Remove posts')) && await areYouSure("Are you sure you want to delete this post?")) {
            SetLoading(true);
            let {data} = await axios.post('/api/delete-post', { post, user });
            if (data) { SetDeleted(true) } else { Toasts.create(Toast, {preset:'server error'}) }
            SetLoading(false);
        }
    }
    async function editPost() {
        Modals.create(EditChallenge, { post, callback: async post => {
            let {data} = await axios.post('/api/edit-post', { post, user });
            if (data) SetPost(data);
            return !!data;
        }})
    }
    if (SetDeleted) {
        if (me || checkPermissions('Edit posts')) dropdownOptions.push({title: 'Edit post', callback:editPost });
        if (me || checkPermissions('Remove posts')) dropdownOptions.push({ title: 'Delete post', callback:deletePost });
    }

    return <div className="ChallengePanel">
        <div className="wordBit">
            <div className="flex1">
                <h3>Mini challenge - <span>{post.word}</span></h3>
                {post.failed == 1?<h4>This challenge did not get enough entries.</h4>:<h4>Nobody voted on this challenge.</h4>}
            </div>
            <div className="dropdownBit"><Dropdown options={dropdownOptions} btnProps={{className:"dropArrow",height:'1rem'}} ><ImgDropdown className="DARKGREY"/></Dropdown></div>
        </div>
        <div className="message">
            <P noMarginBottom>{post.message}</P>
        </div>
        {post.comments?<Comments post={post} />:null}
    </div>
}