import React, { useState, useContext, useEffect } from 'react';
import { useStripe, useElements, CardElement } from '@stripe/react-stripe-js';

import { AccountContext, ModalContext, ToastContext } from '../../hooks';
import Button from '../controls/Button';
import A from '../controls/A';

import Toast from '../modal/Toast';
import Pounds from './Pounds';
import CardSection from './CardSection';
import axios from 'axios';
import ModalMessage from '../modal/ModalMessage';

export default function CheckoutForm({close, callback}) {
    let { user } = useContext(AccountContext);
    let Toasts = useContext(ToastContext);

    let [loading, setLoading] = useState(false);
    let [pounds, setPounds] = useState(5);
    let [cardError, setCardError] = useState();
    let [cardComplete, setCardComplete] = useState(false);
    let [complete, setComplete] = useState(false);
    let [error, setError] = useState(false);

    let Modals = useContext(ModalContext);

    const stripe = useStripe();
    const elements = useElements();
    const onSubmit = async (event) => {
        event.preventDefault();
        setLoading(true);

        if (!stripe || !elements) return;
        
        const result = await stripe.createPaymentMethod({
            type: 'card',
            card: elements.getElement(CardElement),
            billing_details: {
                email: user.email
            }
        })

        console.log(result);

        if (result.error) {
            setError(result.error.message);
            console.log(result.error.message);
            Toasts.create(Toast, { type:'error', text: result.error.message });
        } else {
            let { data } = await axios.post('/api/sub', { payment_method: result.paymentMethod.id, username:user.username, pounds })
            let { client_secret, status, error } = data;

            if (error) {
                setError(error);
                console.log(error);
                Toasts.create(Toast, { type:'error', text: error});
            } else {
                async function success() {
                    setComplete(true);
                    Toasts.create(Toast, { text: `Thank you for your donation!` });
                    callback(pounds);
                    axios.post('/api/sub-complete', { username:user.username, pounds })
                    await new Promise(resolve => setTimeout(resolve, 1000));
                    await close();
                }
        
                if (status == "requires_action" || status == "incomplete") {
                    let conf = await stripe.confirmCardPayment(client_secret);
                    if (conf.error) {
                        console.log(conf.error);
                        if (conf.error.message) {
                            Toasts.create(Toast, { type: 'error', text: conf.error.message });
                            setError("Authentication failed");
                        } else {
                            Toasts.create(Toast, { type:'error', text: conf.error });
                            setError("Authentication failed");
                        }
                    } else {
                        success();
                    }
                } else {
                    success();
                }
            }
        }
        setLoading(false);

    }

    return <form onSubmit={onSubmit}>
        <Pounds
            pounds={pounds}
            setPounds={setPounds}
            disabled={loading}
            onChange={()=>setError(false)}
        />
        <CardSection
            disabled={loading}
            error={error||cardError}
            setError={setCardError}
            setComplete={setCardComplete}
            onChange={()=>setError(false)}
        />


        <Button submit loading={loading} disabled={!stripe || cardError || !cardComplete || pounds <= 0 || complete}>Donate</Button>

        <div className="mt">
            <i><A blue onClick={() => {
                Modals.create(ModalMessage, {
                    className: "blueLinks",
                    title: 'Donate',
                    body: 
`Paying via the website is simplest, as it will automatically renew every month. But if my payment system doesn't work with your card, or you don't want to pay a sum every month, you can pay via any payment method you want, and I can grant you a subscription manually!
If this sounds good to you, just send me an email and I can give you some alternative payment methods: kai@ambigr.am
Keep in mind that you will have to donate again manually every time your subscription expires.`
                })
            }}>Click here to pay through alternative means.</A></i>
        </div>
    </form>
}