import React from 'react';
import Expand from '../../controls/Expand';

export default function SiteRules({  }) {
    return <div className="Info Panel centrepanel">
        <h1>Site rules</h1>
        <h4>Welcome to our ambigram community!</h4>
        <p>We are a passionate group of artists, enthusiasts, and learners dedicated to the unique world of ambigrams. Whether you are a seasoned artist or just starting out, we invite you to join us and experience the joy of this niche art form.</p>
        <p>To ensure a positive and engaging environment, here are some guidelines for all members to follow:</p>

        <ul>
            <li>
                <h4>Don’t be mean</h4>
                <p>Treat all members with respect and courtesy. Criticism is encouraged, but it must be constructive. Any form of harassment, discrimination, or derogatory behaviour will not be tolerated.</p>
            </li>

            <li>
                <h4>No plagiarism</h4>
                <p>Share only your original artwork or give proper credit to the creator if you are sharing someone else's work. Plagiarism in the contests is not accepted.</p>
            </li>

            <li>
                <h4>No spam</h4>
                <p>Refrain from posting spam or irrelevant content. Avoid long comment chains – if you can say something in 1 comment rather than 3, please be concise to avoid burdening others with notifications.</p>
            </li>

            <li>
                <h4>Do not share personal details</h4>
                <p>Exercise good judgment when sharing personal information. Protect your privacy and respect the privacy of others.</p>
            </li>

            <li>
                <h4>Collaborate and learn</h4>
                <p>This community is about growth and learning. Feel free to ask questions, seek advice, or collaborate on projects. Be aware that constructive criticism is not meant to offend but to help you learn.</p>
            </li>

            <li>
                <h4>Report inappropriate behaviour</h4>
                <p>Report any inappropriate behaviour, content, or potential rule violations to me, using the ‘contact’ option in the sidebar. Help us maintain a safe and welcoming environment for all members.</p>
            </li>

        </ul>




        <p>In short – keep it cool. Thank you for being a part of our ambigram community!</p>
    </div>
}