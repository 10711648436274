import React, { useEffect, useState, useRef } from 'react';
import Spinner from '../Logo/Spinner';
import { useTimeout, useMount } from '../../hooks';

export default function Loader({ children, loading, opaque, style="grey"}) {
    let [show, setShow] = useState(loading);
    let { componentDidMount } = useMount();
    let { doTimeout } = useTimeout();
    let animation = useRef(false);

    componentDidMount(() => {
        if (!loading) animation.current = true;
    })

    useEffect(() => {
        if (loading) {
            setShow(true);
        } else {
            doTimeout(() => {
                setShow(false);
            },0.2,'s')
        }
    }, [loading])
    
    return <div className="Loader">
        <div
            className={"children"}
            style={{ opacity: loading ? 0.4 : 1 }}
        >
            {children}
        </div>
        {show ? <div
            className={"loader" + (animation.current?' animated':'')}
            style={{ opacity: loading ? 1 : 0 }}
        >
            <Spinner margins style={style}/>
        </div>:null}
    </div>
}