import React, { useContext, useEffect, useRef, useState } from 'react';
import ModalHeading from './ModalHeading';
import Button from '../controls/Button';
import { AccountContext, HistoryContext, ResponsiveContext, useAsync, useTimeout } from '../../hooks';
import axios from 'axios';
import Btn from '../controls/Btn';
import Ambigram from '../ambigram/Ambigram';
import { NavLink } from 'react-router-dom';
import Username from '../user/Username';
import DelaySpinner from '../Logo/DelaySpinner';


export default function VoteAnalytics({ close, navigate }) {
    let { user } = useContext(AccountContext);
    let [entries, setEntries] = useState();
    let [voters, setVoters] = useState();
    let [index, setIndex] = useState(0);
    let [height, setHeight] = useState(0);
    let [width, setWidth] = useState(0);
    let [transitioning, setTransitioning] = useState(false);

    let { doTimeout, undoTimeout } = useTimeout();

    useAsync(async () => {
        let { data } = await axios.post('/api/vote-analytics', { user });
        setEntries(data);
        let voters = data[0].votes.map(v => v.username)
        setVoters(voters);
        console.log({data, voters});
    }, [user])

    useEffect(() => {
        if (entries) {
            let picture = entries[index].picture;
            let bounds = 200;
            let ratio = picture.width / picture.height;
            if (picture.width > picture.height) {
                let width = picture.width > bounds ? bounds : picture.width
                setWidth(width);
                setHeight(width / ratio);
            } else {
                let height = picture.height > bounds ? bounds : picture.height;
                setWidth(height * ratio);
                setHeight(height);
            }
        }
    }, [index, entries])
    
    useEffect(() => {
        setTransitioning(true);
        let timeout = doTimeout(() => setTransitioning(false), 0.3, "s");
        return () => undoTimeout(timeout);
    }, [height])
    
    return <div className="modal">
        <ModalHeading title="Vote analytics" close={async () => await close()} />
        <div className={`body blueLinks VoteAnalytics`}>
            <div className="PagesSpinner top"><DelaySpinner loading={!entries || !voters}/></div>
            {entries && voters ? <div className="voteGraphs">
                <div className="votes">
                    <div className="voteUsername"><Username username={entries[index].username} onClick={() => {
                        close();
                        navigate('/user/' + entries[index].username);
                    }} /></div>
                    <div className="prevnext">
                        <Btn fast onClick={() => setIndex(i=>i-1)} disabled={index<=0}>Prev</Btn>
                        <div className="nums">{index+1}/{entries.length}</div>
                        <Btn fast onClick={() => setIndex(i=>i+1)} disabled={index>=entries.length-1}>Next</Btn>
                    </div>
                    <div className="centreFlex"><div className={`votiewotie ${transitioning?"transitioning" : ""}`} style={{height, width}}>
                        <Ambigram picture={entries[index].picture} style={entries[index].style} key={entries[index].picture.path} bounds={200} />
                    </div></div>
                </div>
                <div className="voteAverage">Average: <span>{Math.floor((entries[index].votes.reduce((a,b)=>a+b.stars,0)/entries[index].votes.length)*100)/100}</span></div>
                <div className="VoteGraphs">
                    {voters.map((username, i) => <VoteGraph username={username} stars={entries.map(e => e.votes[i].stars)} index={index} setIndex={setIndex} key={username} navigate={navigate} close={close} />)}
                </div>
            </div> : null}
            {/* <div className="formButtons">
                <div className="buttons">
                    <Button onClick={close}>Close</Button>
                </div>
            </div> */}
        </div>
    </div>
}

function VoteGraph({ username, stars, index, setIndex, close, navigate }) {
    let [lines, setLines] = useState("");
    let [dotted, setDotted] = useState("");
    let [points, setPoints] = useState();
    let [selected, setSelected] = useState(-1);
    let indexRef = useRef();
    let [width, setWidth] = useState(0);
    let { innerWidth } = useContext(ResponsiveContext);

    let ref = useRef();

    let [hovering, setHovering] = useState(false);

    let height = 20;
    let padding = 4;
    let sx = width / stars.length;
    let sy = height / 5;

    useEffect(() => {
        setWidth(innerWidth > 550 ? 350 : innerWidth - 200);
    },[innerWidth])

    useEffect(() => {
        if (stars) {
            let lines = "";
            let dotted = "";
            let points = [];
            let prevX, prevY;
            let prevdX, prevdY;
            for (let i = 0; i < stars.length; i++) {
                let j = i + 1;
                let d = false;
                while (j < stars.length && stars[j] < 0) {
                    j++;
                    d = true;
                }
                if (stars[i] > 0) {
                    let x0 = i * sx;
                    let y0 = height - stars[i] * sy;
                    x0 += padding; y0 += padding;
                    points.push([x0, y0]);
                    if (j < stars.length && stars[j] > 0) {
                        let x1 = j * sx;
                        let y1 = height - stars[j] * sy;
                        x1 += padding; y1 += padding;
                        if (d) {
                            let str = x0 == prevdX && y0 == prevdY ? `L${x1},${y1}` : `M${x0},${y0}L${x1},${y1}`;
                            dotted += str
                            prevdX = x1;
                            prevdY = y1;
                        } else {
                            let str = x0 == prevX && y0 == prevY ? `L${x1},${y1}` : `M${x0},${y0}L${x1},${y1}`;
                            lines += str
                            prevX = x1;
                            prevY = y1;
                        }
                    }
                } else {
                    points.push(false);
                }
            }

            setLines(lines);
            setDotted(dotted);
            setPoints(points);
        }
    }, [stars])
    
    useEffect(() => {
        if (hovering) {
            let mousemove = (e) => {
                let rect = ref.current.getBoundingClientRect();
                let x = e.clientX - rect.left;
                let y = e.clientY - rect.top;
                let j = -1;
                for (let i = 0; i < points.length; i++) {
                    if (points[i] && i != indexRef.current) {
                        let [px, py] = points[i];
                        if (px > x - 8 && px < x + 8) j = i;
                    }
                }
                setSelected(j);
            }
            document.addEventListener('mousemove', mousemove);
            return ()=>document.removeEventListener('mousemove', mousemove);
        } else {
            setSelected(-1);
        }
    },[hovering])

    useEffect(() => {
        setHovering(false);
        setSelected(-1);
        indexRef.current = index;
    },[index])

    let w = width + padding * 2;
    let h = height + padding * 2;

    return <div className="VoteGraph">
        <div className="voteUsernameBit">
            <NavLink to={'/user/' + username} onClick={close}>{username}</NavLink>
        </div>
        <div className={`voteGraph ${selected >= 0 && hovering ? 'pointer' : ''}`} onClick={() => {
            if (selected >= 0) {
                setIndex(selected);
            }
        }} onMouseEnter={()=>setHovering(true)} onMouseLeave={()=>setHovering(false)} onMouseMove={()=>setHovering(true)} ref={ref}>
            {lines ? <>
                <svg width={w} height={h} viewBox={`0 0 ${w} ${h}`}>
                    <path d={lines} />
                    <path className="dotted" d={dotted} />
                    {/* {dots.map(([x, y]) => <circle className="dot" key={`${x} ${y}`} cx={x} cy={y} r={1.5} />)} */}
                    {stars[index] > 0 ? <circle className="selected" cx={index * sx + padding} cy={height-stars[index] * sy + padding} r={2} /> : null}
                    {selected >= 0 ? <circle className="blooble" cx={selected * sx + padding} cy={height-stars[selected] * sy + padding} r={3} /> : null}
                </svg>
                {stars[index] > 0 ? <div className="starLabel" style={{left:index * sx + padding +3, top:height-stars[index] * sy + padding +3}}>{stars[index]}</
                div> : null}
            </> : null}
        </div>
    </div>
}
