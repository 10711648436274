import React, {useState, useContext} from 'react';
import { ModalContext, ToastContext, useSubmit, AccountContext } from '../../hooks';
import Input from '../controls/Input';
import Button from '../controls/Button';
import Btn from '../controls/Btn';
import Checkbox from '../controls/Checkbox';
import Expand from '../controls/Expand';
import ModalHeading from '../modal/ModalHeading';
import Toast from '../modal/Toast';

import { validateEmail, validatePassword } from '../../functions';

import axios from 'axios';
import DeleteAccount from './DeleteAccount';

export default function AccountSettings({ close, profile }) {
    let Toasts = useContext(ToastContext);
    let { user, setUser } = useContext(AccountContext);

    let [email, setEmail] = useState(profile.email);
    let [dontEmailMeContests, setDontEmailMeContests] = useState(profile.dontEmailMe.contests)
    let [dontEmailMeDuels, setDontEmailMeDuels] = useState(profile.dontEmailMe.duels)
    let [oldPassword, setOldPassword] = useState('');
    let [password1, setPassword1] = useState('');
    let [password2, setPassword2] = useState('');

    let [passwordExpanded, setPasswordExpanded] = useState(false);

    let [serverError, setServerError] = useState('');
    let [emailError, setEmailError] = useState('');
    let [oldPasswordError, setOldPasswordError] = useState('');
    let [password1Error, setPassword1Error] = useState('');
    let [password2Error, setPassword2Error] = useState('');
    let [loading, setLoading] = useState('');

    let [c, submit, onSubmit] = useSubmit();

    let Modals = useContext(ModalContext);

    onSubmit(async () => {
        let ok = !emailError;
        if (passwordExpanded && oldPassword.length > 0) {
            if (oldPasswordError || password1Error || password2 != password1) ok = false;
        }
        if (ok) {
            setLoading(true);
            setServerError('');

            let dontEmailMe = { contests: dontEmailMeContests, duels: dontEmailMeDuels };

            let { data } = await axios.post('/api/change-account-settings', !passwordExpanded || oldPassword.length == 0 ? {
                email, dontEmailMe, user
            } : {
                email, dontEmailMe, oldPassword, newPassword:password1, user
            })
            
            let { error } = data;

            if (error) {
                setEmailError(error.email);
                setOldPasswordError(error.oldPassword);
                setPassword1Error(error.newPassword);
                setServerError(error.server);
            } else {
                setUser(data.user);
                Toasts.create(Toast, { text: 'Account updated!' });
                close();
            }

            setLoading(false);
        }
    })

    return <div className="Login modal">
        <ModalHeading title="Account settings" close={close} />
        <div className="body">
            <form onSubmit={submit}>
                <Input
                    label="Email"
                    value={email}
                    setValue={setEmail}
                    error={emailError}
                    setError={setEmailError}
                    validation={validateEmail}
                    attempts={c}
                    disabled={loading}
                />

                <Checkbox className="lilmb" label="Don't email me about contests!" checked={dontEmailMeContests} setChecked={setDontEmailMeContests} disabled={loading} />
                <Checkbox label="Don't email me about duels!" checked={dontEmailMeDuels} setChecked={setDontEmailMeDuels} disabled={loading} />
                
                <br/>

                <Expand label="Change password" disabled={loading} setExpanded={setPasswordExpanded}>
                    <Input
                        label="Old password"
                        type="password"
                        value={oldPassword}
                        setValue={setOldPassword}
                        error={oldPasswordError}
                        setError={setOldPasswordError}
                        validation={oldPassword.length == 0 || !passwordExpanded ? () =>  null : validatePassword}
                        attempts={c}
                        disabled={loading}
                    />
                    <Input
                        label="Password"
                        type="password"
                        value={password1}
                        setValue={setPassword1}
                        error={password1Error}
                        setError={setPassword1Error}
                        validation={oldPassword.length == 0 || !passwordExpanded ? () => null : validatePassword}
                        attempts={c}
                        disabled={loading}
                    />
                    <Input
                        label="Confirm password"
                        type="password"
                        value={password2}
                        setValue={setPassword2}
                        error={password2Error}
                        setError={setPassword2Error}
                        validation={oldPassword.length == 0 || !passwordExpanded ? () => null : p => {
                            if (p != password1) return 'Passwords do not match!';
                        }}
                        attempts={c}
                        disabled={loading}
                    />
                </Expand>

                <br/>

                {user&&user.admin?null:<Btn onClick={()=>Modals.create(DeleteAccount,{callback:close})}>Delete account</Btn>}

                <br/><br/>

                <div className="formButtons">
                    <div className="buttons">
                        <Button submit loading={loading}>Save</Button>
                    </div>
                    <div className="error">
                        {serverError}
                    </div>
                </div>
            </form>
        </div>
    </div>
}