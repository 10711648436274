import React, {useState, useRef, useEffect, useContext, memo} from 'react'
import { useTimeout, useGetState, useChange, DarkContext } from '../../hooks';
import Loader from '../controls/Loader';

export default function Ambigram({ picture, style, bounds = 6800 }) {
    let { doTimeout, undoTimeout } = useTimeout();
    let [path, setPath] = useState(picture.lowres ? picture.lowres : picture.path);
    let [width, setWidth] = useState(0);
    let [clicks, setClicks, getClicks] = useGetState(0);
    let [rotation, setRotation] = useState(0);
    let [horizontal, setHorizontal] = useState(1);
    let [vertical, setVertical] = useState(1);
    let [invert, setInvert] = useState(false);
    let [tooltip, setTooltip] = useState(false);
    let [loading, setLoading] = useState(true);
    let timeout = useRef();
    let timeout2 = useRef();
    let specials = useRef([]);
    let change = useRef({});
    let [animating, setAnimating] = useState(false);
    let { dark } = useContext(DarkContext);
    let [secondPic, setSecondPic] = useState(false);
    let ambi = useRef();

    let [wiggle, setWiggle, getWiggle] = useGetState(0);

    useEffect(() => {
        setPath(picture.lowres ? picture.lowres : picture.path);
        if (picture.lowres) {
            let img = new Image();
            img.src = '/api/pictures/' + picture.path;
            img.onload = () => {
                setPath(picture.path);
            }
        }
    }, [picture])
    
    useEffect(() => {
        if (style.substr(0, 7) == 'Special') {
            specials.current = style.split('|');
            specials.current.shift();
            specials.current = specials.current.map(item => {
                let actions = item.split('/');
                let r = parseInt(actions[0]);
                let h = parseInt(actions[1]);
                let v = parseInt(actions[2]);
                return { r, h, v };
            });
        } else if (style.substr(0, 6) == 'Change') {
            change.current = style.substr(7, style.length - 7);
        }
        setClicks(0);
        setRotation(0);
        setHorizontal(1);
        setVertical(1);
        setInvert(false);
        setSecondPic(false);
        // console.log(style);
    },[style])

    useEffect(() => {
        if (picture.width > picture.height) {
            setWidth(picture.width > bounds ? bounds : picture.width);
        } else {
            let height = picture.height > bounds ? bounds : picture.height;
            let ratio = picture.width / picture.height;
            setWidth(height * ratio);
        }
    },[bounds])

    function wigglewiggle() {
        if (getWiggle()==0) {
            let direction = Math.random() < 0.5 ? -1 : 1;
            setWiggle(1 * direction);
            doTimeout(() => {
                direction *= -1;
                setWiggle(2 * direction);
            }, 120)
            doTimeout(() => {setWiggle(0)}, 240)
        }
    }

    useChange(() => {
        if (style == "Rotational") {
            setRotation(clicks * 180);
        } else if (style == "Mirror") {
            setHorizontal(clicks % 2 == 0 ? 1 : -1);
        } else if (style == "Lake") {
            setVertical(clicks % 2 == 0 ? 1 : -1);
        } else if (style == "Invert") {
            setInvert(i => !i);
        } else if (style == 'Ottogram') {
            let step = (clicks - 1) % 2;
            if (step == 0) setRotation(r => r + 180);
            if (step == 1) setHorizontal(h => 0 - h);
        } else if (style.substr(0, 6) == 'Custom') {
            let angle = parseInt(style.substr(6, style.length - 6));
            setRotation(clicks * angle);
        } else if (style.substr(0, 7) == 'Special') {
            if (specials.current.length > 0) {
                let i = (clicks - 1) % specials.current.length;
                if (i >= 0) {
                    setRotation(r => r + specials.current[i].r);
                    if (specials.current[i].h < 0) setHorizontal(h => 0 - h);
                    if (specials.current[i].v < 0) setVertical(v => 0 - v);
                }
            }
        } else if (style.substr(0, 6) == 'Change') {
            if (change.current) {
                setSecondPic(c => !c);
            }
        }
        setAnimating(true);
        undoTimeout(timeout2.current);
        timeout2.current = doTimeout(() => {
            setAnimating(false);
        },1,'s')
    },[clicks])

    return <div className="Ambigram">
        <div
            className="container"
            onMouseEnter={() => {
                timeout.current = doTimeout(() => setTooltip(clicks == 0), 0.5,'s');
            }}
            onMouseLeave={() => {
                setTooltip(false);
                undoTimeout(timeout.current);
            }}
            onClick={() => setTooltip(false)}
            style={{zIndex:animating?2:1}}
        >
            <Loader loading={loading} opaque>
                <div
                    className="wiggler"
                    onMouseEnter={style=="None"?null:wigglewiggle}
                    style={{ transform: `rotate(${wiggle}deg)` }}
                    onClick={()=>setClicks(c=>c+1)}
                >
                    <img
                        className={"img"+(style=="None"?' None':'')}
                        key={path+'*'+style}
                        src={'/api/pictures/' + path}
                        width={width}
                        style={{
                            transform:`rotate(${rotation}deg) scale(${horizontal}, ${vertical})`,
                            filter: invert ? "invert(100%)" : null,
                            display: loading ? 'none' : null,
                            backgroundColor: dark ? 'white' : null
                        }}
                        onLoad={() => setLoading(false)}
                        ref={ambi}
                    />
                    {style.substr(0, 6) == 'Change'?<img
                        className={"img change"}
                        key={path+'*'+style+'*'}
                        src={'/api/pictures/' + change.current}
                        width={ambi.current?ambi.current.width:0}
                        height={ambi.current?ambi.current.height:0}
                        style={{
                            opacity: secondPic ? 1 : 0,
                            display: loading ? 'none' : null,
                            backgroundColor: dark ? 'white' : null
                        }}
                        onLoad={()=>setLoading(false)}
                    />:null}
                    {loading ? <canvas width={picture.width} height={picture.height}></canvas>:null}
                </div>
            </Loader>
            {style=="None"?null:<div
                className={tooltip && clicks == 0 ? "tooltip" : "hidden tooltip"}
                onClick={()=>setClicks(c=>c+1)}
            >
                {style == "Rotational" || style=='Custom' ? 'Click to rotate!'
                : style == "Mirror" || style=="Lake" ? 'Click to flip!'
                : 'Click to animate!' }
            </div>}
        </div>
    </div>
}