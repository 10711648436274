import React, {useState, useEffect, useContext} from 'react';
import { HistoryContext, ResponsiveContext } from '../../hooks';
import Btn from '../controls/Btn';

export default function Pagination({ page, pages, setPage, url }) {
    let { noNavigate } = useContext(HistoryContext);
    let [arr, setArr] = useState([]);
    let [width, setWidth] = useState(0);
    let [offset, setOffset] = useState(0);
    let { device } = useContext(ResponsiveContext);

    useEffect(() => {
        let x = device=="phone" ? 3 : device=="bigPhone"?4: 5;
        let min = page - x;
        let max = page + x;
        if (min < 0) min = 0;
        if (max > pages) max = pages;
        setWidth((max - min + 1) * 2);
        setOffset(-min * 2);

        let arr = [];
        for (let i = 0; i <= pages; i++) arr.push(i);
        setArr(arr);
    }, [pages, page, device])
    
    return <div className="centreFlex">
        {width>2?<div className="Pagination" style={{ width: width + 'rem' }}>
            <div
                className="scrollable"
                style={{
                    width: arr.length * 2 + 'rem',
                    left: offset + 'rem'
                }}
            >
                {arr.map(i => 
                    <Btn
                        key={i}
                        className={page==i?'red':'darkGrey'}
                        onClick={(e) => {
                            e.preventDefault();
                            setPage(i);
                            if (url) noNavigate(url + (i + 1));
                        }}
                        to={url?url+(i+1):null}
                        disabled={page == i}
                        fast
                    >
                        <div className="cont">{i + 1}</div>
                    </Btn>
                )}
            </div>
        </div>:null}
    </div>
}