import React, {useState, useContext, useRef} from 'react';
import { ModalContext, ToastContext, useSubmit, useForceRender, useGetState, AccountContext } from '../../hooks';
import Input from '../controls/Input';
import TextArea from '../controls/TextArea';
import SelectDropdown from '../controls/SelectDropdown';
import Button from '../controls/Button';
import ModalHeading from '../modal/ModalHeading';
import Uploader from '../controls/Uploader';
import Toast from '../modal/Toast';
import Expand from '../controls/Expand';
import Radiobox from '../controls/Radiobox';
import Shrinker from '../controls/Shrinker';
import AnimButton from '../controls/AnimButton';
import Special from './Special';
import Change from './Change';

import { ImgBoat, ImgButterfly, ImgNone, ImgYinyang } from '../../pictures/animations/SVG';

import { validateWord, validateGlyphs, validateDescription } from '../../functions';
import Ambigram from '../ambigram/Ambigram';

export default function StartChallenge({ close, callback, blockChangePicture, feed }) {
    let Modals = useContext(ModalContext);
    let Toasts = useContext(ToastContext);
    let { user } = useContext(AccountContext);

    let [c, submit, onSubmit] = useSubmit();
    let [loading, setLoading] = useState(false);
    let [loadingUpload, setLoadingUpload] = useState(false);

    let [word, setWord] = useState('');
    let [picture, setPicture] = useState({});
    let [removePic, setRemovePic] = useForceRender();
    let [message, setMessage] = useState('');
    let [glyphs, setGlyphs] = useState('');

    let [wordError, setWordError] = useState('');
    let [pictureError, setPictureError] = useState('');
    let [messageError, setMessageError] = useState('');
    let [glyphsError, setGlyphsError] = useState('');

    let [style, setStyle, getStyle] = useGetState('Rotational');
    let [special, setSpecial, getSpecial] = useGetState('Special');
    let [change, setChange, getChange] = useGetState('');
    let [custom, setCustom, getCustom] = useGetState('90°');

    let [serverError, setServerError] = useState('');


    onSubmit(async () => {
        if (!picture.path) {
            setPictureError('Please upload a picture!');
        } else if (!wordError && !pictureError && !messageError && !glyphsError) {
            setLoading(true);
            let post = {word, message, picture, style: formatStyle(), glyphs:glyphs.trim().split(' ').map(i=>i.toUpperCase())};
            let res = await callback(post);
            if (res.error) {
                if (res.error === true) {
                    Toasts.create(Toast, { type: "error", text: 'Upload failed! Maybe try again later.' });
                    setServerError('Upload failed');
                } else {
                    Toasts.create(Toast, { type: "error", text: res.error });
                    setServerError(res.error);
                }
            } else {
                close();
            }
            setLoading(false);
        }
    })

    let formatStyle = () => {
        if (style == 'Custom') {
            let custom = getCustom();
            custom = custom.substr(0, custom.length - 1);
            return 'Custom' + custom;
        } else if (style == 'Special') {
            let special = getSpecial();
            if (special == 'Special') {
                return 'None'
            } else {
                return special;
            }
        } else if (style=='Change') { 
            if (change == '') {
                return 'None';
            } else {
                return 'Change_' + change;
            }
        } else return style;
    }

    let uploader = useRef();

    return <div className="UploadAmbigram modal">
        <ModalHeading title={'Start challenge'} close={close} />
        <div className="body">
            <h4>Start a challenge for other users on the site!</h4>
            <p>Here, you will submit an ambigram to get things going. Then, other entrants will need to make an ambigram of the same word.</p>
            <p>After 1 week, the highest rated ambigram wins!</p>
            <form onSubmit={submit}>

                <Input
                    label="Word"
                    value={word}
                    setValue={setWord}
                    error={wordError}
                    setError={setWordError}
                    validation={validateWord}
                    attempts={c}
                    disabled={loading}
                />

                <TextArea
                    label="Challenge description"
                    value={message}
                    setValue={setMessage}
                    error={messageError}
                    setError={setMessageError}
                    validation={validateDescription}
                    attempts={c}
                    disabled={loading}
                />

                <br />
                
                <div className="centre buttonBit">
                    {picture.path ?
                        <>
                            <Ambigram picture={picture} style={formatStyle()} bounds={200} />
                            {blockChangePicture?null:<Button fast onClick={()=>document.getElementById(uploader.current).click()} loading={loadingUpload} disabled={loading}>
                                Change picture
                            </Button>}
                        </> : <>
                            <div className="nopic">No image!</div>
                            <br/>
                            <Button fast onClick={()=>document.getElementById(uploader.current).click()} loading={loadingUpload} disabled={loading}>
                                Upload your entry
                            </Button>
                        </>
                    }
                    <Uploader
                        setError={setPictureError}
                        setPicture={setPicture}
                        remove={removePic}
                        setId={id=>uploader.current = id}
                        setLoading={setLoadingUpload}
                    />
                </div>

                {pictureError?<div className="err">{pictureError}</div>:<br/>}

                <label>Animation</label>
                <div className="AnimationSelector">
                    <div className="AnimButtons">
                        <AnimButton className="None" setTransform={c => null} style={style} setStyle={setStyle} disabled={loading} Img={ImgNone}/>
                        <AnimButton className="Rotational" setTransform={c => `rotate(${c * 180}deg)`} style={style} setStyle={setStyle} disabled={loading} Img={ImgYinyang}/>
                        <AnimButton className="Mirror" setTransform={c => `scale(${c % 2 == 0 ? 1 : -1},1)`} style={style} setStyle={setStyle} disabled={loading} Img={ImgButterfly}/>
                        <AnimButton className="Lake" setTransform={c => `scale(1,${c % 2 == 0 ? 1 : -1})`} style={style} setStyle={setStyle} disabled={loading} Img={ImgBoat}/>
                    </div>
                </div>

                <br/>

                <Expand label="Advanced animations" disabled={loading}>
                    <div className="advancedAnimations">
                        <Radiobox option={style} setOption={setStyle} name="Animation" label="4-way animation" id="Ottogram" disabled={loading}/>
                        <Radiobox option={style} setOption={setStyle} name="Animation" label="Rotate by:" id="Custom" disabled={loading}><SelectDropdown
                            selected={custom}
                            setSelected={setCustom}
                            disabled={loading}
                            options={[ '120°', '90°', '72°', '60°' ]}
                        /></Radiobox>
                        <Radiobox option={style} setOption={setStyle} name="Animation" label="Invert colours" id="Invert" disabled={loading}/>
                        <Radiobox option={style} setOption={setStyle} name="Animation" label="Change picture" id="Change" disabled={loading} />
                        <Shrinker expanded={style == "Change"} speed={0.2}>
                            <div className="radioMargin">
                                <Button onClick={()=>Modals.create(Change, { change, setChange })} disabled={loading}>
                                    {change ? 'Edit' : 'Set'} second picture
                                </Button>
                            </div>
                        </Shrinker>
                        <Radiobox option={style} setOption={setStyle} name="Animation" label="Custom animation" id="Special" disabled={loading}/>
                        <Shrinker expanded={style == "Special"} speed={0.2}>
                            <div className="radioMargin">
                                <Button onClick={()=>Modals.create(Special, { special, setSpecial, picture:picture.path })} disabled={loading}>
                                    {special && special != 'Special' ? 'Edit' : 'Define'} custom animation
                                </Button>
                            </div>
                        </Shrinker>
                    </div>
                </Expand>

                <br />
                
                <Input
                    label="Glyphs (e.g. E/E XA/PL M/M)"
                    value={glyphs}
                    setValue={setGlyphs}
                    error={glyphsError}
                    setError={setGlyphsError}
                    validation={validateGlyphs}
                    attempts={c}
                    disabled={loading}
                />

                
                <br/>

                <div className="formButtons">
                    <div className="buttons">
                        <Button submit loading={loading}>Submit</Button>
                        {feed=='Sketches'?<div className="note">* This will be posted among the sketches.</div>:null}
                    </div>
                    <div className="error">
                        {serverError}
                    </div>
                </div>
            </form>
        </div>
    </div>
}