import React, {useState} from 'react';
import { useSubmit } from '../../hooks';
import Button from '../controls/Button';
import Radiobox from '../controls/Radiobox';
import SliderOptions from '../controls/SliderOptions';
import ModalHeading from '../modal/ModalHeading';


export default function AnimationSettings({ close, dimensions: dimensions0, framerate: framerate0, motionBlur: motionBlur0, secondsGap: secondsGap0, callback }) {
    let [c, submit, onSubmit] = useSubmit();
    let [dimensions, setDimensions] = useState(dimensions0);
    let [framerate, setFramerate] = useState(framerate0);
    let [motionBlur, setMotionBlur] = useState(motionBlur0);
    let [secondsGap, setSecondsGap] = useState(secondsGap0);

    onSubmit(() => {
        callback(dimensions, framerate, motionBlur, secondsGap);
        close();
    })

    return <div className="AnimationSettings modal">
        <ModalHeading title="Animation settings" close={close} />
        <div className="body">
            <form onSubmit={submit}>

                <label>Dimensions</label>
                <div>
                    <SliderOptions
                        selected={dimensions0}
                        setSelected={setDimensions}
                        options={[
                            '480×480px',
                            '640×640px',
                            '720×720px',
                            '1080×1080px',
                            '1280×1280px',
                            '1920×1920px',
                        ]}
                    />
                </div>
                <label>Frame rate</label>
                <div className="framerate">
                    <Radiobox option={framerate} setOption={setFramerate} name="frame rate" label="25 fps"/>
                    <Radiobox option={framerate} setOption={setFramerate} name="frame rate" label="30 fps"/>
                </div>
                <label>Motion blur</label>
                <div>
                    <SliderOptions
                        selected={motionBlur0}
                        setSelected={setMotionBlur}
                        options={[
                            'None',
                            'Basic quality',
                            'Good quality',
                            'Best quality',
                        ]}
                    />
                </div>
                <label>Gap between rotations</label>
                <div>
                    <SliderOptions
                        selected={secondsGap0}
                        setSelected={setSecondsGap}
                        options={[
                            '1 second',
                            '2 seconds',
                            '3 seconds',
                            '4 seconds',
                            '5 seconds',
                            '6 seconds',
                            '7 seconds',
                            '8 seconds',
                            '9 seconds',
                            '10 seconds',
                            '11 seconds',
                            '12 seconds',
                        ]}
                    />
                </div>

                <br/>

                <div className="formButtons">
                    <div className="buttons">
                        <Button submit>Submit</Button>
                    </div>
                </div>
            </form>
        </div>
    </div>
}