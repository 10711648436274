import React, { useEffect, useRef, useState, memo } from 'react';
import { useTimeout, useMount, useGetState } from '../../hooks';

import { ImgEye1,ImgEye6,ImgEye5,ImgEye4,ImgEye3,ImgEye2,ImgEye7 } from '../../pictures/eye/SVG';

function Eye({ open }) {
    let { componentWillUnmount } = useMount();
    let [frame, setFrame, getFrame] = useGetState(open ? 6 : 0);
    let interval = useRef();

    useEffect(() => {
        clearInterval(interval.current);
        interval.current = setInterval(() => {
            let frame = getFrame();
            frame = open ? frame + 1 : frame - 1;
            if (frame < 0 || frame >= 7) {
                clearInterval(interval.current);
            } else {
                setFrame(frame);
            }
        }, 20);
    }, [open]);

    componentWillUnmount(() => clearInterval(interval.current));

    if (frame==0) return <ImgEye1/>
    if (frame==1) return <ImgEye2/>
    if (frame==2) return <ImgEye3/>
    if (frame==3) return <ImgEye4/>
    if (frame==4) return <ImgEye5/>
    if (frame==5) return <ImgEye6/>
    if (frame==6) return <ImgEye7/>
}

export default memo(function Word({ word }) {
    let { componentDidMount, componentWillUnmount } = useMount();
    let { doTimeout } = useTimeout();
    let [cover, setCover] = useState(1);
    let container = useRef();
    let [width, setWidth] = useState('100%');
    let [pointer, setPointer] = useState(true);
    let [tooltip, setTooltip] = useState(false);
    let timeout = useRef();

    componentWillUnmount(() => clearTimeout(timeout.current));

    useEffect(() => {
        setWidth(cover*100+'%');
    }, [cover])

    return <div
        className="word"
        onMouseEnter={() => {
            if (cover > 0) {
                setCover(0.5);
                timeout.current = setTimeout(() => setTooltip(true), 1000)
            }
        }}
        onMouseLeave={() => {
            if (cover > 0) setCover(1);
            clearTimeout(timeout.current);
            setTooltip(false);
        }}
        onClick={() => {
            if (cover > 0) setCover(0);
            doTimeout(() => setPointer(false), 200);
        }}
        style={{
            cursor: pointer ? 'pointer' : 'auto',
        }}
    >
        <div className="wordLeft" ref={container}>
            <div
                className="black"
                style={{width}}
            />
            {word}
        </div>
        <div className="wordRight">
            <Eye open={cover <= 0.5} />
            <Eye open={cover == 0} />
        </div>
        <div className={tooltip && cover > 0 ? "tooltip" : "hidden tooltip"} style={{
            left: container.current? container.current.clientWidth / 2:null
        }} >
            Click to reveal!
        </div>
    </div>
})