import React, {useState, useContext } from 'react';
import { useSubmit, ToastContext } from '../../hooks';
import Input from '../controls/Input';
import TextArea from '../controls/TextArea';
import Button from '../controls/Button';
import ModalHeading from '../modal/ModalHeading';
import Toast from '../modal/Toast';

let validateTitle = title => {
    if (title.length > 100) return 'Title too long!';
}

let validateMessage = message => {
    if (message.length == 0) return 'Please provide a message!';
    if (message.length < 6) return 'Message too short!';
    if (message.length > 5000) return 'Message too long!';
}

export default function ShareThought({ close, post, callback, feed }) {
    let Toasts = useContext(ToastContext);

    let [c, submit, onSubmit] = useSubmit();
    let [loading, setLoading] = useState(false);

    let [title, setTitle] = useState(post ? post.title : '');
    let [message, setMessage] = useState(post ? post.message : '');

    let [titleError, setTitleError] = useState('');
    let [messageError, setMessageError] = useState('');

    let [serverError, setServerError] = useState('');

    onSubmit(async () => {
        if (!titleError && !messageError) {
            setLoading(true);
            if (!post) post = {};
            post.message = message;
            post.title = title;
            let res = await callback(post);
            if (res) {
                close();
            } else {
                Toasts.create(Toast, { type: "error", preset: 'server error' });
                setServerError('Server error!');
            }
            setLoading(false);
        }
    })

    return <div className="ShareThought modal">
        <ModalHeading title="Share text post" close={close} />
        <div className="body">
            <form onSubmit={submit}>

                <Input
                    label="Title (optional)"
                    value={title}
                    setValue={setTitle}
                    error={titleError}
                    setError={setTitleError}
                    validation={validateTitle}
                    attempts={c}
                    disabled={loading}
                />

                <TextArea
                    label="Message"
                    value={message}
                    setValue={setMessage}
                    error={messageError}
                    setError={setMessageError}
                    validation={validateMessage}
                    attempts={c}
                    disabled={loading}
                />

                <br/>

                <div className="formButtons">
                    <div className="buttons">
                        <Button submit loading={loading}>Submit</Button>
                        {feed=='Sketches'?<div className="note">* This will be posted among the sketches.</div>:null}
                    </div>
                    <div className="error">
                        {serverError}
                    </div>
                </div>
            </form>
        </div>
    </div>
}