import React, { useContext } from 'react';
import { ToastContext, ModalContext, AccountContext, useDynamic, useAreYouSure } from '../../hooks';
import UserPanel from '../user/UserPanel';
import Dropdown from '../controls/Dropdown';
import P from '../controls/P';
import Toast from '../modal/Toast';
import ShareThought from '../forms/ShareThought';
import Comments from './Comments';

import axios from 'axios';

import { ImgDropdown } from '../../pictures/SVG';

export default function BlogPost({ post }) {
    let { user, checkPermissions } = useContext(AccountContext);
    let Dynamic = useDynamic('post', post._id);
    let SetDeleted, SetLoading, SetPost;
    if (Dynamic) {
        SetDeleted = Dynamic.SetDeleted;
        SetLoading = Dynamic.SetLoading;
        SetPost = Dynamic.SetPost;
    }
    let Toasts = useContext(ToastContext);
    let Modals = useContext(ModalContext);
    let areYouSure = useAreYouSure();

    let me = user && (user.username == post.username);

    async function deletePost() {
        if ((me || checkPermissions('Remove posts')) && await areYouSure("Are you sure you want to delete this post?")) {
            SetLoading(true);
            let {data} = await axios.post('/api/delete-post', { post, user });
            if (data) {
                SetDeleted(true);
            } else {
                Toasts.create(Toast, {preset:'server error'});
            }
            SetLoading(false);
        }
    }

    async function editPost() {
        Modals.create(ShareThought, {
            post,
            callback: async post => {
                let {data} = await axios.post('/api/edit-post', { post, user });
                if (data) {
                    SetPost(data);
                    return true;
                } else {
                    return false;
                }
            }
        })
    }

    let dropdownOptions = []

    if (me || checkPermissions('Edit posts')) dropdownOptions.push({title: 'Edit post', callback:editPost });
    if (me || checkPermissions('Remove posts')) dropdownOptions.push({ title: 'Delete post', callback: deletePost });
    
    if (user) {
        dropdownOptions.push({
            title: 'Stop notifications', callback: async () => {
            await axios.post('/api/stop-notifications', { user, post: {_id:post._id} });
        } });
    }

    return <div className="Blog Post" >
        <div className="postLeft">
            <UserPanel post={post}/>
        </div>
        <div className="postRight">
            <div className="title">
                <div className="flex1">{post.title ? post.title : "Thought..."}</div>
                {dropdownOptions.length>0?<Dropdown
                    options={dropdownOptions}
                    btnProps={{className:"dropArrow"}}
                >
                    <ImgDropdown className="DARKGREY"/>
                </Dropdown>:null}
            </div>
            <div className="body">
                <P noMarginBottom>{post.message}</P>
            </div>
            <Comments post={post}/>
        </div>
    </div>
}
