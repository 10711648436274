export function dateString(date, options = {}) {
    let { ago, time, agePrefix, datePrefix } = options;
    let d = new Date(date);
    let today = new Date();
    let age = today.getTime() - date;
    age = Math.floor(age / (1000));
    if (age >= 0 && age < 60) return `${agePrefix?agePrefix+' ':''}${age} second${age==1?'':'s'}${ago?' ago':''}`;
    age = Math.floor(age / (60));
    if (age >= 0 && age < 60) return `${agePrefix?agePrefix+' ':''}${age} minute${age==1?'':'s'}${ago?' ago':''}`;
    age = Math.floor(age / (60));
    if (age >= 0 && age < 24) return `${agePrefix?agePrefix+' ':''}${age} hour${age==1?'':'s'}${ago?' ago':''}`;
    age = Math.floor(age / (24));
    if (age >= 0 && age < 30) return `${agePrefix?agePrefix+' ':''}${age} day${age==1?'':'s'}${ago?' ago':''}`;
    let dd = d.getDate(); let mm = d.getMonth(); let yy = d.getFullYear(); let YY = today.getFullYear();
    let suffix = ['th','st','nd','rd','th','th','th','th','th','th']
    let month = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
    let str = dd + (dd > 10 && dd < 19 ? 'th' : suffix[dd % 10]) + ' ' + month[mm];
    if (yy != YY) str += ' ' + yy;
    if (time) {
        let hours = d.getHours();
        hours = (hours < 10 ? '0' : '') + hours;
        let mins = d.getMinutes();
        mins = (mins < 10 ? '0' : '') + mins;
        str = str + ` ${hours}:${mins}`
    }
    if (datePrefix) str = datePrefix + ' ' + str;
    return str;
}

export function pluralise(str) {
    return `${str}'${str.substr(str.length-1,1).toLowerCase()=='s'?'':'s'}`
}

export function remainingDateString(date) {
    let today = new Date();
    let age = date - today.getTime();

    if (age > 1000 * 60 * 60 * 24) {
        return dateString(date);
    } else {
        let s = Math.floor(age / 1000);
        let m = Math.floor(s / 60);
        s -= m * 60;
        let h = Math.floor(m / 60);
        m -= h * 60;
        
        let ss = `${s < 10 ? '0' : ''}${s}s`;
        let mm = `${m < 10 ? '0' : ''}${m}m`;
        let hh = `${h < 10 ? '0' : ''}${h}h`;

        if (m <= 0) return ss; 
        if (h <= 0) return `${mm} ${ss}`; 
        return `${hh} ${mm} ${ss}`; 
    }
}







export function tidyGlyphs(glyphs) {
    let result = [];
    glyphs.forEach(glyph => {
        if (glyph.indexOf('/') > 0) {
            let split = glyph.split('/');
            if (split.length == 2) {
                split = split.map(s => s.trim().toUpperCase()).sort();
                if (split[0] && split[1]) {
                    let join = split[0] + '/' + split[1];
                    if (result.indexOf(join) < 0) result.push(join);
                }
            }
        }
    })
    return result;
}

export function specialToString(specials) {
    let style = 'Special';
    let r = 0;
    let hf = false;
    let vf = false;
    specials.push({ rotation: 0, hflip: false, vflip: false });
    for (let i = 1; i < specials.length; i++) {
        let rotation = specials[i].rotation - r;
        rotation = rotation % 360;
        while (rotation < 0) rotation += 360;
        while (rotation > 180) rotation -= 360;
        let hflip = specials[i].hflip != hf;
        let vflip = specials[i].vflip != vf;
        if (hflip && vflip) {
            hflip = false;
            vflip = false;
            rotation += 180;
            rotation = rotation % 360;
            while (rotation > 180) rotation -= 360;
        }
        if (rotation == 180 && hflip) {
            rotation = 0;
            hflip = false;
            vflip = true;
        }
        if (rotation == 180 && vflip) {
            rotation = 0;
            hflip = true;
            vflip = false;
        }
        hf = hflip ? !hf : hf;
        vf = vflip ? !vf : vf;
        r += rotation;
        if (rotation != 0 || hflip || vflip) style += '|' + rotation + '/' + (hflip ? '-1' : '1') + '/' + (vflip ? '-1' : '1');
    }
    specials.pop();
    return style;
}

export function stringToSpecial(style) {
    let specials = style.split('|');
    specials.shift();
    let r = 0;
    let hf = false;
    let vf = false;
    specials = specials.map(item => {
        let actions = item.split('/');
        let rotation = parseInt(actions[0]);
        let hflip = parseInt(actions[1]);
        let vflip = parseInt(actions[2]);
        r += rotation;
        hf = hflip < 0 ? !hf : hf;
        vf = vflip < 0 ? !vf : vf;
        return { rotation: r, hflip: hf, vflip: vf };
    });
    specials.pop();
    specials.unshift({ rotation: 0, hflip: false, vflip: false });
    specials.forEach((s, i) => s.key = -i);
    return specials;
}






export function validateWord(word) {
    word = word.trim();
    if (word.length > 100) return 'Word too long!';
    if (word.length == 0) return 'Please provide a word!'
}

export function validateMessage(message) {
    if (message.length > 1000) return 'Message too long!';
}

export function validateDescription(description) {
    if (description.length == 0) return 'Please provide a description!'
    if (description.length > 1000) return 'Description too long!';
}

export function validateBio(bio) {
    if (bio.length > 5000) return 'Bio too long!';
}

export function validateGlyphs(glyphs) {
    if (glyphs.trim().length > 0) {
        if (glyphs.length > 1000) {
            return 'Too long!';
        } else {
            let gs = glyphs.trim().split(' ');
            for (let i = 0; i < gs.length; i++) {
                if (gs[i].indexOf('/') < 0) {
                    return 'Invalid format!';
                } else {
                    let s = gs[i].split('/');
                    if (s.length != 2) {
                        return 'Invalid format!';
                    } else {
                        if (s[0].length < 1 || s[1].length < 1) {
                            return 'Invalid format!';
                        }
                    }
                }
            }
        }
    }
}






export function validateUrl(url) {
    let re = /\b((([A-Za-z]{3,9}:(?:\/\/)?)(?:[\-;:&=\+\$,\w]+@)?[A-Za-z0-9\.\-]+|(?:www\.|[\-;:&=\+\$,\w]+@)[A-Za-z0-9\.\-]+)((?:\/[\+~%\/\.\w\-_]*)?\??(?:[\-\+=&;%@\.\w_]*)#?(?:[\.\!\/\\\w]*))?)\b/g;
    if (!re.test(url)) return 'Invalid URL!' ;
    if (url.length > 100) return'url too long!';
}

export function validateUsername(username) {
    let allowed = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ1234567890_';
    if (username.length < 3) return 'Username too short!';
    if (username.length > 12) return 'Username too long!';
    for (let i = 0; i < username.length; i++) {
        if (allowed.indexOf(username[i]) < 0) return 'Only letters, numbers and _ allowed!'
    }
}

export function validateName(name) {
    if (name.length < 3) return 'Name too short!';
    if (name.length > 40) return 'Name too long!';
}

export function validateInsta(insta) {
    if (insta.length == 0) return;
    let tmp = insta.substr(1, insta.length - 1);
    if (insta[0] !== '@') return 'Must start with @';
    let allowed = '1234567890abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ._';
    for (let i = 0; i < tmp.length; i++) {
        if (allowed.indexOf(tmp[i]) < 0) return 'Invalid Instagram handle!'
    }
    if (tmp.length < 1) return 'Too short!';
    if (tmp.length > 30) return 'Too long!';
}

export function validateEmail(email){
    let re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (!re.test(email)) return 'Invalid email!' ;
    if (email.length > 100) return'Email too long!';
}

export function validatePassword(password) {
    if (password.length < 6) return 'Password too short!';
    if (password.length > 100) return 'Password too long!';
}

export function noErrors(error) {
    for (let k in error) {
        if (error[k]) return false;
    }
    return true;
}

export function shuffle(array) {
    for (let i = array.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [array[i], array[j]] = [array[j], array[i]];
    }
}