import React, {useState} from 'react';

export default function A({ className, children, onClick, onMouseEnter, onMouseLeave, red}) {
    let [focussed, setFocussed] = useState(false);

    return <button
            onClick={onClick}
            onFocus={() => setFocussed(true)}
            onBlur={() => setFocussed(false)}
            className={"A"
            + (className ? ' ' + className : '')
            + (focussed ? ' focussed' : '')
            + (red ? ' red' : '')
        }
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
        >{children}
    </button>
}