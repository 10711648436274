import React, { useState, useContext, useEffect } from 'react';
import { AccountContext, SocketContext, NotificationsContext, ToastContext, HistoryContext, ModalContext, useAreYouSure, DarkContext, ResponsiveContext, ExpandLeftMenuContext } from '../../hooks';
import Logo from '../Logo/Logo';
import Input from '../controls/Input';
import Btn from '../controls/Btn';
import Dropdown from '../controls/Dropdown';
import Toast from '../modal/Toast';
import Login from '../forms/Login';
import Signup from '../forms/Signup';
import ModalMessage from '../modal/ModalMessage';
import axios from 'axios';
import { ImgBell, ImgMenu, ImgSearch } from '../../pictures/SVG';
import VerificationMessage from '../forms/VerificationMessage';

export default function Nav({ }) {
    let {expandLeftMenu, setExpandLeftMenu} = useContext(ExpandLeftMenuContext);
    let [query, setQuery] = useState('');
    let { user, logout, subbed } = useContext(AccountContext);
    let socket = useContext(SocketContext);
    let { notifications, setNotifications, getNotifications } = useContext(NotificationsContext);
    // if (!notifications) notifications = [];
    let Toasts = useContext(ToastContext);
    let [hover, setHover] = useState(false);
    let { navigate, forceNavigate } = useContext(HistoryContext);
    let Modals = useContext(ModalContext);
    let areYouSure = useAreYouSure();
    let { device, tablet } = useContext(ResponsiveContext);

    let login = () => Modals.create(localStorage.getItem('happy') ? Login : Signup, { feature: true });

    let { dark, setDark } = useContext(DarkContext);

    let callbackVerify = (f) => {
        if (user) {
            if (user.verified) {
                return f
            } else return () => Modals.create(VerificationMessage,{feature:true})
        } else return login;
    }

    let callbackDonate = (f) => {
        if (user) {
            if (user.verified) {
                if (subbed) {
                    return f
                } else {
                    return () => Modals.create(ModalMessage, { preset: 'Donate' });
                }
            } else return () => Modals.create(VerificationMessage, { feature: true });
        } else return login;
    }

    useEffect(() => {
        if (socket) {
            socket.on('notification', data => {
                let notifications = getNotifications();
                let l = notifications.length;
                notifications = notifications.filter(item => item.url != data.url);
                if (notifications.length == l) {
                    Toasts.create(Toast, {text:'New comment on '+data.title, to:data.url})
                }
                notifications.unshift(data);
                setNotifications(notifications);
            })
            // socket.on('unnotify', url => {
            //     let notifications = getNotifications();
            //     notifications = notifications.filter(item => item.url != url);
            //     setNotifications(notifications);
            // })
        } else {
            setNotifications([]);
        }
    }, [socket])


    let notificationDropdown = notifications.map(({ title, url }) => {
        return {title, link:url}
    })

    if (user && notificationDropdown.length > 0) {
        notificationDropdown.unshift({
            title: 'Clear notifications', colour:'red', callback: async () => {
                axios.post('/api/clear-notifications', { user });
                setNotifications([]);
            }
        })
    }
    
    return <div
        className="Nav"
        onMouseEnter={() => {
            setHover(true);
            // console.log('setHover(true)');
        }}
        onMouseLeave={() =>{
            setHover(false);
            // console.log('setHover(false)');
        }}>
        <div className="nav">
            {tablet ? <Btn onClick={()=>setExpandLeftMenu(!expandLeftMenu)}><ImgMenu /></Btn>:null}
            <Logo auto={false} normal={hover}/>
            {!tablet?<div className="searchBit">
                <Input
                    icon={<ImgSearch/>}
                    value={query}
                    setValue={setQuery}
                    red
                    onKeyDown={e => {
                        if (e.key == "Enter" && query.length > 0) {
                            e.preventDefault();
                            navigate('/search/'+encodeURIComponent(query));
                        }
                    }}
                />
            </div>:null}
            {user?<div className="Icon bell">
                <Dropdown title={notifications.length>0?null:'No new comments!'} key={notifications} options={notificationDropdown}>
                    <ImgBell />
                    <div className="num">
                        {notifications.length > 0 ? <h3>{notifications.length < 10 ? notifications.length : '9+'}</h3> : null}
                    </div>
                </Dropdown>
            </div>: null}
        </div>
    </div>
}