import React, {useState, useContext} from 'react';
import { ModalContext, useSubmit } from '../../hooks';
import Button from '../controls/Button';
import ModalHeading from '../modal/ModalHeading';
import ModalMessage from '../modal/ModalMessage';
import Input from '../controls/Input';
import axios from 'axios';

function validateTextbox(text) {
    if (text.length == 0) {
        return 'Please no empty.'
    }
}

export default function ResetPassword({ close }) {
    let Modals = useContext(ModalContext);
    let [loading, setLoading] = useState(false);
    let [textbox, setTextbox] = useState('');
    let [error, setError] = useState('');
    let [c, submit, onSubmit] = useSubmit();

    onSubmit(async () => {
        if (!error) {
            setLoading(true);
            let { data } = await axios.post('/api/reset-password', { textbox });
            let { error } = data;
            setLoading(false);
            if (error) {
                setError(error);
            } else {
                await close();
                Modals.create(ModalMessage, { title: 'Reset Password', body: 'An email has been sent with instructions for your password reset. Check your spam folder!' });
            }
        }
    })

    return <div className="modal">
        <ModalHeading title={"Reset password"} close={close} />
        <div className="body">
            <p>Please provide your email or your username.</p>
            <form onSubmit={submit}>
                <Input
                    label="Email/username"
                    value={textbox}
                    setValue={setTextbox}
                    error={error}
                    setError={setError}
                    validation={validateTextbox}
                    attempts={c}
                    disabled={loading}
                />
                <div className="formButtons">
                    <div className="buttons">
                        <Button submit loading={loading}>Reset password</Button>
                    </div>
                </div>
            </form>
        </div>
    </div>
}
