import React, { useEffect, useState, useContext } from 'react';
import { ResponsiveContext, AccountContext, ToastContext, useAsync } from '../../hooks';
import Button from '../controls/Button';
import axios from 'axios';
import Toast from '../modal/Toast';
import Duel from './Duel';
import Spinner from '../Logo/Spinner';


export default function DuelArena({ }) {
    let { desktop } = useContext(ResponsiveContext);
    let { user, setUser } = useContext(AccountContext);
    let [duelist, setDuelist] = useState(user ? user.duelist : null);
    let Toasts = useContext(ToastContext);
    let [post, setPost] = useState();


    useAsync(async () => {
        let { data } = await axios.post('/api/current-duel', { user });
        if (data) {
            console.log(data);
            setPost(data);
        }
    }, [])
    
    if (!post) return <div className="Duel Panel centrepanel"><div className="centreFlex">
        <Spinner/>
    </div></div>
    return <Duel post={post}/>
}