import React, {useState, useRef, useEffect, useContext} from 'react';
import { useMount, useForceRender, useTimeout, useGetState, useChange, VisibilityContext } from '../../hooks';

import {Img1,Img2,Img3,Img4,Img5,Img6,Img7,Img8,Img9} from '../../pictures/logo/SVG';

export default function Logo({ scale = 0.5, style = "RED", auto = true, normal = true }) {
    style = style.toUpperCase();
    let {componentDidMount} = useMount();
    let { doTimeout, doInterval, undoInterval } = useTimeout();
    let [force, forceRender] = useForceRender();
    let [height, setHeight] = useState(0);
    let [width, setWidth] = useState(0);
    let [parts, setParts, getParts] = useGetState([])
    let clockwise = useRef(false);
    let interval = useRef();
    let {getPageVisible} = useContext(VisibilityContext);

    useEffect(() => {
        let parts = [
            { x: 0,   y: 0,  w: 96, h: 46, r: -180, r2:0, s:-0.2, i: 1, a: false, c: false, R: Img1 },
            { x: 46,  y: 0,  w: 52, h: 46, r:  180, r2:0, s:0.2, i: 2, a: false, c: true,  R: Img2 },
            { x: 98,  y: 0,  w: 56, h: 46, r: -180, r2:0, s:-0.2, i: 3, a: false, c: false, R: Img3 },
            { x: 98,  y: 0,  w: 40, h: 46, r:  180, r2:0, s:0.2, i: 4, a: false, c: true,  R: Img4 },
            { x: 152, y: 0,  w: 80, h: 40, r: -180, r2:0, s:-0.2, i: 5, a: false, c: false, R: Img5 },
            { x: 176, y: 16, w: 32, h: 30, r:  180, r2:0, s:0.2, i: 6, a: false, c: true,  R: Img6 },
            { x: 204, y: 30, w: 48, h: 16, r: -180, r2:0, s:-0.2, i: 7, a: false, c: false, R: Img7 },
            { x: 250, y: 0,  w: 96, h: 46, r:  180, r2:0, s:0.2, i: 8, a: false, c: true,  R: Img8 },
            { x: 296, y: 0,  w: 52, h: 46, r: -180, r2:0, s:-0.2, i: 9, a: false, c: false, R: Img9 }
        ]
        let w = parts[8].x+parts[8].w;
        let h = parts[0].h;
        parts.forEach(part => {
            part.x *= scale;
            part.y *= scale;
            part.w *= scale;
            part.h *= scale;
        })

        setHeight(h * scale);
        setWidth(w * scale);
        setParts(parts);
    }, [scale])
    
    useChange(() => {
        if (normal) {
            undoInterval(interval.current);
            let parts = getParts();
            parts.forEach(part => part.r2 = Math.round(part.r2));
            setParts(parts);
            forceRender();
        } else {
            startSlow()
        }
    }, [normal])
    
    function startSlow() {
        undoInterval(interval.current);
        interval.current = doInterval(() => {
            if (getPageVisible()) {
                let parts = getParts();
                parts.forEach((part, i) => {
                    // if (Math.abs(part.r2 - Math.floor(part.r2)) < 0.01) {
                    //     part.s = 1 / (Math.floor(Math.random() * 3) + 3);
                    //     if (i % 2==0) part.s = 0 - part.s
                    // }
                    part.r2 += part.s;
                })
                setParts(parts);
                forceRender();
            }
        },20,'s')
    }

    componentDidMount(() => {
        if (auto) {
            doTimeout(rotateAll, 100);
            doInterval(rotateAll, 1, 'm');
        } else {
            startSlow();
        }
    })

    function rotateAll() {
        let parts = getParts();
        let delay = 100;
        clockwise.current = !clockwise.current;
        for (let i = 0; i < parts.length; i++) {
            doTimeout(() => {
                let r = clockwise.current ? i % 2 == 0 ? -1 : 1 : i % 2 == 0 ? 1 : -1;
                rotate(i,r);
            }, i*delay)
        }
    }

    function rotateSome() {
        let parts = getParts();
        let delay = 200;
        let n = 1 + Math.floor(Math.random() * 4);
        let ns = [];
        while (ns.length < n) {
            let r = Math.floor(Math.random() * parts.length);
            if (ns.indexOf(r) < 0) ns.push(r);
        }
        ns.forEach((i,j) => {
            doTimeout(() => {
                rotate(i, parts[i].c ? 1 : -1);
                parts[i].c = !parts[i].c;
            }, [j * delay])
        })
    }

    function rotate(i, r) {
        let parts = getParts();
        if (parts[i] && !parts[i].a) {
            parts[i].a = true;
            parts[i].r += r * 180;
            forceRender();
            doTimeout(() => {
                parts[i].a = false;
                setParts(parts);
                forceRender();
            },1,'s')
        }
    }

    let reordered = parts.length>0 ? [
        parts[1],
        parts[0],
        parts[3],
        parts[2],
        parts[6],
        parts[4],
        parts[5],
        parts[8],
        parts[7]
    ] : []

    return <div
            className='Logo'
            style={{ width, height }}
            onClick={rotateAll}    
        >
            
        {reordered.map(({ a, x, y, w, h, r, r2, i, R }, j) => <R
            key={i}
            className={style + (a ? i % 2 == 0 ? ' DARKEN' : ' LIGHTEN' : '')}
            style={{
                width: w,
                left: x,
                top: y,
                transform: `rotate(${r+(r2*180)}deg)`,
                transition: normal ? 'transform 1.5s ease, fill 1s linear' : 'transform 20s linear, fill 1s linear'
            }}
        />)}

        {parts ? parts.map((part, i) => <div
            key={i}
            className="panel"
            style={{
                top: 0,
                left: i * width / parts.length,
                height,
                width: width/parts.length
            }}
            onMouseEnter={() => rotate(i, i % 2 == 0 ? 1 : -1)}
        />):null}
    </div>
}