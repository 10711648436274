import React, { useContext, useState } from 'react';
import { AccountContext, useAsync, NotificationsContext, ContestForceRenderContext } from '../../hooks';
import axios from 'axios';
import { NavLink } from 'react-router-dom';

import Contest1 from './Contest1';
import Contest2 from './Contest2';
import Contest3 from './Contest3';
import Spinner from '../Logo/Spinner';

import { ImgLeft, ImgRight } from '../../pictures/SVG';

export default function Contest({ url, page }) {
    let { user } = useContext(AccountContext);
    let [contest, setContest] = useState();
    let { getNotifications, setNotifications } = useContext(NotificationsContext);
    let [contestForce, contestForceRender] = useContext(ContestForceRenderContext);

    useAsync(async () => {
        let { data } = await axios.post('/api/contest', { url, user });
        setContest(data);
        console.log(data);
    }, [user, contestForce])

    useAsync(async () => {
        if (user && contest) {
            let notifications = getNotifications();
            let i = notifications.findIndex(n => n.url == contest.url);
            if (i >= 0) {
                axios.post('/api/seen', { url: contest.url, user });
                notifications.splice(i, 1);
                setNotifications(notifications.slice());
            }
        }
    }, [user, contest])

    let render;
    
    if (contest) {
        if (contest.type == 'Contest1') {
            render = <Contest1 post={contest}/>
        } else if (contest.type == 'Contest2') {
            render = <Contest2 post={contest}/>
        } else if (contest.type == 'Contest3') {
            render = <Contest3 post={contest} page={page}/>
        }
    } else render = <div className="centreFlex">
        <Spinner/>
    </div>

    return <div className="Contest Panel centrepanel">
        {
            contest ? <div className="prevNext">
                <div>{contest.prev ? <NavLink to={contest.prev.url}><ImgLeft/>Contest #{contest.count - 1}: {contest.prev.theme}</NavLink> : null}</div>
                <div>{contest.next ? <NavLink to={contest.next.url}>Contest #{contest.count + 1}: {contest.next.theme}<ImgRight/></NavLink> : null}</div>
            </div>:null
        }

        {render}
    </div>
}