import React, { useContext, useState } from 'react';
import ModalHeading from './ModalHeading';
import Button from '../controls/Button';
import { AccountContext, ToastContext } from '../../hooks';
import Toast from './Toast';
import axios from 'axios';

export default function DuelEnlisting({ callback = ()=>{}, close }) {
    let { user, setUser } = useContext(AccountContext);
    let Toasts = useContext(ToastContext);
    let [loading, setLoading] = useState(false);
    return <div className="modal">
        <ModalHeading title={"Duel arena"} close={close} />
        <div className={`body blueLinks`}>
            <h4>So, you wish to duel?</h4>
            <p>Here's how it works: each round, two new artists are chosen. They are both given the same word and just one week to craft their own unique ambigram.</p>
            <p>After enlisting you may be chosen for any future duels, until the moment you decide to unenlist yourself.</p>
            <p>What are you waiting for? Click the button below!</p>
            <div className="formButtons">
                <div className="buttons">
                    <Button loading={loading} onClick={async () => {
                        setLoading(true);
                        await axios.post('/api/enlist-in-duels', { user });
                        setUser({ ...user, duelist: true });
                        Toasts.create(Toast, { text: `You have been enlisted!` });
                        close();
                        callback();
                    }}>Enlist</Button>
                </div>
            </div>
        </div>
    </div>
}