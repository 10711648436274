import React, { useContext } from 'react';
import { AccountContext } from '../../hooks';
import ProfilePic from '../user/ProfilePic';
import Username from '../user/Username';
import ShareButtons from '../posts/ShareButtons';

export default function PostsPanel({ }) {
    let { user } = useContext(AccountContext);

    return <div className="PostsPanel Panel">
        {user ? <>
            <ProfilePic username={user.username} width={150} />
            <h3><Username username={user.username}/></h3>
        </> : null}

        <ShareButtons buttons={['Challenge']}/>

    </div>
}