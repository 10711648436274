import React, { useRef, useState, useEffect, useContext } from 'react';
import { useGetState, DarkContext } from '../../hooks';

export default function Thumbnail({src, height = 100, ratio = 4/3, onClick}) {
    let img = useRef();
    let [width, setWidth] = useState();
    let [imgWidth, setImgWidth] = useState();
    let [imgHeight, setImgHeight] = useState();
    let [picWidth, setPicWidth] = useState();
    let [picHeight, setPicHeight, getPicHeight] = useGetState();
    let { dark } = useContext(DarkContext);

    useEffect(() => {
        let picHeight = getPicHeight();
        if (picHeight) {
            let picRatio =  picWidth / picHeight;
            let imgHeight = height;
            let imgWidth = imgHeight * picRatio;

            if (picRatio > ratio) picRatio = ratio;
            let width = height * picRatio;

            setWidth(width);
            setImgHeight(imgHeight);
            setImgWidth(imgWidth);
        }
    },[picWidth])

    return <div className={`Thumbnail ${!picHeight ? 'unloaded':''}`}>
        {width ? onClick ? <button onClick={()=>onClick()}>
            <div className="container" style={{ width, height }}>
                <img
                    src={src}
                    width={imgWidth}
                    style={{
                        left: (width-imgWidth)/2,
                        top: (height - imgHeight) / 2,
                        backgroundColor:dark?'white':null
                    }}
                />
            </div>
        </button> : <div className="container" style={{ width, height }}>
            <img
                src={src}
                width={imgWidth}
                style={{
                    left: (width-imgWidth)/2,
                    top: (height - imgHeight) / 2,
                    backgroundColor:dark?'white':null
                }}
            />
        </div> : <div className="hide">
            <img 
                src={src} 
                ref={img} 
                height={height}
                onLoad={()=>{
                    setPicHeight(img.current.clientHeight);
                    setPicWidth(img.current.clientWidth);
                }}
            />
        </div>}
    </div>
}