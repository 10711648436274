import React from 'react'
import { ImgInstagram, ImgYoutube, ImgTwitter, ImgLink, ImgFacebook } from '../../pictures/SVG';

let icons = [
    {string:'INSTAGRAM', render:<ImgInstagram/>},
    {string:'YOUTUBE', render:<ImgYoutube/>},
    {string:'TWITTER', render:<ImgTwitter/>},
    {string:'FACEBOOK', render:<ImgFacebook/>},
]

export default function Link({ link }) {
    let { url, title } = link;
    if (!url || !title) return null;
    let icon;
    let uppercase = url.toUpperCase();
    for (let i = 0; i < icons.length; i++) {
        if (uppercase.indexOf(icons[i].string) >= 0) {
            icon = icons[i].render;
            break;
        }
    }
    if (!icon) icon = <ImgLink />;
    let relative = url.indexOf('//') < 0;
    url = relative ? '//' + url : url;
    return <a className="Link" href={url} target="_blank" rel="noopener noreferrer external">{icon}{title}</a>
}