import React from 'react';

import {ImgRadio,ImgDot} from '../../pictures/controls/SVG';

export default function Radiobox({ label, id, name, option, setOption, children, disabled }) {
    if (!id) id = label;
    return <label className="Radiobox">
        <input
            type="radio"
            name={name}
            checked={id == option}
            onChange={e => { if (e.target.checked) setOption(id) }}
        />
        <div className="box">
            <ImgRadio className={disabled?'LIGHTGREY':'BLUE'} />
            <ImgDot className={"tick "+(disabled?'LIGHTGREY':'BLUE')} style={{transform:`scale(${id == option?1:0})`}}/>
        </div>
        {label}{children ? <div onClick={()=>setOption(id)} style={{marginLeft:'0.25rem'}} className="inline-block">{children}</div>:null}
    </label>
}