import React, { useEffect, useState } from 'react';
import ModalHeading from './ModalHeading';
import Button from '../controls/Button';
import P from '../controls/P';
import { useAsync } from '../../hooks';
import axios from 'axios';
import DelaySpinner from '../Logo/DelaySpinner';
import Btn from '../controls/Btn';
import { Comment } from '../posts/Comment';

export default function ArchivedContestComments({ close, theme }) {
    let [loading, setLoading] = useState(true);
    let [comments, setComments] = useState();

    useAsync(async () => {
        setLoading(true);
        let {data} = await axios.post('/api/archived-contest-comments', { theme });
        setComments(data);
        setLoading(false);
    },[])

    return <div className="modal">
        <ModalHeading title="Archived comments" close={close} />
        <div className={`body blueLinks ArchivedContestComments`}>
            <div className="PagesSpinner top"><DelaySpinner loading={loading} /></div>
            <div className={`fader ${!loading?'':'hidden'}`}>
                {comments ? <>
                    {comments[0].length > 0 ? <>
                        <h4>Comments from the submission stage:</h4>
                        <div className={`Comments`}>
                            {comments[0].map((comment, i) => <Comment key={i} comment={comment} />)}
                        </div> 
                    </> : null}

                    {comments[1].length > 0 ? <>
                        <h4>Comments from the voting stage:</h4>
                        <div className={`Comments`}>
                        {comments[1].map((comment, i) => <Comment key={i} comment={comment} />)}
                        </div> 
                    </> : null}
                </>: null}
            </div>
        </div>
    </div>
}