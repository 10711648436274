import React, {useState, useRef, useContext} from 'react';
import { ResponsiveContext, useMount, useTimeout } from '../../hooks';

import {Img0,Img1,Img2,Img3,Img4,Img5,Img6} from '../../pictures/digital/SVG';

export function Digital({ n }) {
    let lights =
        n == 0 ? [1, 1, 1, 0, 1, 1, 1] :
            n == 1 ? [0, 0, 1, 0, 0, 1, 0] :
                n == 2 ? [1, 0, 1, 1, 1, 0, 1] :
                    n == 3 ? [1, 0, 1, 1, 0, 1, 1] :
                        n == 4 ? [0, 1, 1, 1, 0, 1, 0] :
                            n == 5 ? [1, 1, 0, 1, 0, 1, 1] :
                                n == 6 ? [1, 1, 0, 1, 1, 1, 1] :
                                    n == 7 ? [1, 0, 1, 0, 0, 1, 0] :
                                        n == 8 ? [1, 1, 1, 1, 1, 1, 1] :
                                            n == 9 ? [1, 1, 1, 1, 0, 1, 1] :
                                                [0, 0, 0, 0, 0, 0, 0];
    return <div className="Digital">
        <Img0 style={{opacity:lights[0]}}/>
        <Img1 style={{opacity:lights[1]}}/>
        <Img2 style={{opacity:lights[2]}}/>
        <Img3 style={{opacity:lights[3]}}/>
        <Img4 style={{opacity:lights[4]}}/>
        <Img5 style={{opacity:lights[5]}}/>
        <Img6 style={{opacity:lights[6]}}/>
    </div>
}

export function Countdown({ time, setEnded, hideSecondsOnPhone }) {
    let [numbers, setNumbers] = useState({ days: [0, 0], hours: [0, 0], minutes: [0, 0], seconds: [0, 0] });
    let { doInterval, undoInterval } = useTimeout();
    let { componentDidMount } = useMount();
    let interval = useRef();
    let {phone} = useContext(ResponsiveContext)

    componentDidMount(() => {
        update();
        interval.current = doInterval(update, 1, 's');
    })

    function update() {
        let milliseconds = time - new Date().getTime();
        if (milliseconds < 0) {
            milliseconds = 0;
            setEnded(true);
            undoInterval(interval.current);
            setNumbers({ days: [0, 0], hours: [0, 0], minutes: [0, 0], seconds: [0, 0] });
        }
        let seconds = Math.floor(milliseconds / 1000);
        milliseconds -= seconds * 1000;
        let minutes = Math.floor(seconds / 60);
        seconds -= minutes * 60;
        let hours = Math.floor(minutes / 60);
        minutes -= hours * 60;
        let days = Math.floor(hours / 24);
        hours -= days * 24;
        let seconds1 = Math.floor(seconds / 10);
        let seconds2 = seconds - seconds1 * 10;
        let minutes1 = Math.floor(minutes / 10);
        let minutes2 = minutes - minutes1 * 10;
        let hours1 = Math.floor(hours / 10);
        let hours2 = hours - hours1 * 10;
        let days1 = Math.floor(days / 10);
        let days2 = days - days1 * 10;
        setNumbers({ days: [days1, days2], hours: [hours1, hours2], minutes: [minutes1, minutes2], seconds: [seconds1, seconds2] });
    }

    return <div className="Countdown">
        <div className="num">
            <Digital n={numbers.days[0]}/>
            <Digital n={numbers.days[1]} />
            <div className = "centre">Days</div>
        </div>

        <div className="num">
            <Digital n={numbers.hours[0]}/>
            <Digital n={numbers.hours[1]} />
            <div className = "centre">Hours</div>
        </div>
        
        <div className="num">
            <Digital n={numbers.minutes[0]}/>
            <Digital n={numbers.minutes[1]} />
            <div className = "centre">Mins</div>
        </div>
        
        {phone && hideSecondsOnPhone ? null : <div className="num">
            <Digital n={numbers.seconds[0]}/>
            <Digital n={numbers.seconds[1]} />
            <div className = "centre">Secs</div>
        </div>}
    </div>
}