import React, { useContext, useEffect, useRef, useState } from 'react';
import { AccountContext, ModalContext, ResponsiveContext, useForceRender, useGetState, useTimeout } from '../../hooks';
import { Img0,Img1,Img2,Img3,Img4,Img5,Img6,Img7,Img8,Img9,Img10,Img11,Img12,ImgO } from '../../pictures/face/SVG';
import { ImgStar } from '../../pictures/SVG';
import Button from '../controls/Button';
import Shrinker from '../controls/Shrinker';
import VerificationMessage from '../forms/VerificationMessage';
import axios from 'axios';
import Word from '../ambigram/Word';
import Ambigram from '../ambigram/Ambigram';
import Login from '../forms/Login';
import Signup from '../forms/Signup';
import Comments from '../posts/Comments';

function Stars({ inc, timeout2, user, login, vote, s, mine, first, last }) {
    let arr = [0, 1, 2, 3, 4];
    let [display, setDisplay] = useState(s);
    let [stars, setStars] = useState(s);
    let [hovering, setHovering, getHovering] = useGetState(false);
    let { doTimeout, undoTimeout } = useTimeout();
    let [clicks, setClicks, getClicks] = useGetState(0);
    let [rotation, setRotation] = useState([0,0,0,0,0])
    let timeout = useRef();
    let Modals = useContext(ModalContext);
    let [cheeky, setCheeky] = useState(false);

    let starRender = <>
        {first ? <h4>Leave a rating!</h4> : null}
        <div className="Stars" onMouseEnter={() => setHovering(true)}
            onMouseLeave={() => {
                setHovering(false);
                undoTimeout(timeout.current);
                timeout.current = doTimeout(() => {
                    if (!getHovering()) setDisplay(stars);
                },0.3,'s')
            }}
        >
            {arr.map(i => <ImgStar className="LIGHTISHGREY" key={'l'+i} style={{ left: (i * 2 + 0.5) + 'rem', opacity: display >= 0 ? 0 : 1 }} />)}
            {arr.map(i => <ImgStar className="RED" key={'r'+i} style={{ left: (i * 2 + 0.25) + 'rem', transform: `scale(${display >= i ? 1 : 0}) rotate(${rotation[i] * 360 / 5 * 2}deg)` }} />)}
            {arr.map(i => <button
                style={{ left: i * 2 + 'rem' }}
                key={'b'+i}
                onMouseEnter={() => setDisplay(i)}
                onClick={mine ? () => {
                    setCheeky(true);
                } : user ? user.verified ? () => {
                    setStars(display);
                    vote(display+1);
                    let delay = 0.03;
                    if (clicks == 0) {
                        undoTimeout(timeout2.current);
                        timeout2.current = doTimeout(()=>inc(1,true),0.2 + delay * 5,'s');
                    }
                    let c = getClicks();
                    c++;
                    setClicks(c); 
                    setRotation([c, c - 1, c - 1, c - 1, c - 1]);
                    doTimeout(() => setRotation([c, c, c - 1, c - 1, c - 1]), delay, 's');
                    doTimeout(() => setRotation([c, c, c, c - 1, c - 1]), delay * 2, 's');
                    doTimeout(() => setRotation([c, c, c, c, c - 1]), delay * 3, 's');
                    doTimeout(() => setRotation([c, c, c, c, c]), delay * 4, 's'); 
                } : () => Modals.create(VerificationMessage, { feature: true })
                : login}
            />)}
        </div>
    </>

    return <>
        {mine ? <>
            <Shrinker expanded={!cheeky} speed={0.2}>{starRender}</Shrinker>
            <Shrinker expanded={cheeky} speed={0.2}>
                <div className="cheeky">
                    <h2>Oi, cheeky!</h2>
                    <h4>You cannot vote on your own ambigram.</h4>
                    {last?null:<Button fast onClick={()=>inc(1)} force>Next ambigram</Button>}
                </div>
            </Shrinker>
        </>:starRender}
        <Smile n={display} visible={display >= 0 || cheeky} cheeky={cheeky} />
        <br/>
    </>
}

function Smile({n, visible, cheeky}) {
    let [frame, setFrame, getFrame] = useGetState(6);
    let [aim, setAim, getAim] = useGetState(6);
    let { doTimeout, undoTimeout } = useTimeout();
    let timeout = useRef();
    useEffect(() => {
        setAim(n < 0 ? 6 : n * 3);
        animate();
    }, [n])
    function animate() {
        undoTimeout(timeout.current);
        timeout.current=doTimeout(() => {
            let aim = getAim();
            let frame = getFrame();
            if (aim != frame) {
                setFrame(frame > aim ? frame - 1 : frame + 1);
                animate();
            }
        },1/50,'s')
    }
    let f = frame < 13 ? frame : 12;
    return <div className={"Smile" + (visible ? ' visible' : '')}>
        {cheeky?<Img0/>:f==0?<Img0/>:f==1?<Img1/>:f==2?<Img2/>:f==3?<Img3/>:f==4?<Img4/>:f==5?<Img5/>:f==6?<Img6/>:f==7?<Img7/>:f==8?<Img8/>:f==9?<Img9/>:f==10?<Img10/>:f==11?<Img11/>:f==12?<Img12/>:null}
    </div>
}

export default function Vote({ post, PANEL}) {
    let { user } = useContext(AccountContext);
    let { doTimeout, undoTimeout } = useTimeout();
    let [transitioning, setTransitioning] = useState(false);
    let [index, setIndex] = useState(0);
    let timeout = useRef();
    let timeout2 = useRef();
    let [force, forceRender] = useForceRender();
    let timeout3 = useRef();
    let refs = useRef({});
    let [width, setWidth] = useState(600);
    let login = () => Modals.create(localStorage.getItem('happy') ? Login : Signup, {feature:true});
    let Modals = useContext(ModalContext);
    let { device, bigPhone } = useContext(ResponsiveContext);

    useEffect(() => {
        if (device == 'phone') {
            setWidth(400);
        } else if (device == 'tablet') {
            setWidth(500);
        } else setWidth(600);
    }, [device])

    function jump(i) {
        setTransitioning(true);
        undoTimeout(timeout2.current);
        timeout2.current = doTimeout(() => {
            setIndex(i)
            undoTimeout(timeout3.current);
            timeout3.current = doTimeout(() => setTransitioning(false),0.35,'s')
        }, 50);
    }
    
    function inc(n = 1, force) {
        undoTimeout(timeout3.current);
        let i = index + n;
        let max = force ? post.entries.length : post.entries.length - 1;
        if (i > max) i = max;
        if (i < 0) i = 0;
        jump(i);
    }

    async function vote(stars) {
        if (user && user.verified) await axios.post('/api/vote', { stars, entry: post.entries[index], post, user });
    }

    async function disqualify(entry) {
        if (post.type != 'Challenge') {
            await axios.post('/api/disqualify', { entry:{picture:entry.picture}, post: {_id:post._id}, user });
            entry.disqualified = true;
            forceRender();
        }
    }

    return <div className={`votes ${PANEL?'PANEL':''}`}>
        <Shrinker expanded={index < post.entries.length}>
            <div className="prevnext">
                <Button fast onClick={() => inc(-1)} disabled={index<=0}>Prev</Button>
                <div className="nums">{index+1}/{post.entries.length}</div>
                <Button fast onClick={()=>inc(1)} disabled={index>=post.entries.length-1}>Next</Button>
            </div>
        </Shrinker>
        <div className={"voteBox" + (transitioning ? ' transitioning' : '')} style={transitioning ? {height:refs.current[index].clientHeight} : null}>
            <div
                className="scrollable"
                style={{ width: transitioning?`${(post.entries.length+1) * 100}%`:null, left:`-${index*100}%` }}
            >
                {post.entries.map((entry, i) => <div
                    className={"votePost" + (i != index && !transitioning ? ' hidden' : '')}
                    key={i}
                    ref={ref => refs.current[i] = ref}
                    style={{opacity:index==i?1:0}}
                >
                    {post.type == 'Challenge' ? null : <Word word={entry.word} />}
                    {Math.abs(index - i) < 5 ? <>
                        <Ambigram picture={entry.picture} style={entry.style} bounds={width} />
                    </> : null}

                    <div className="voteBit">
                        {post.type != 'Challenge' && user && user.admin? entry.disqualified?<h2>DISQUALIFIED</h2>:<Button className="mb" onClick={()=>disqualify(entry)}>Disqualify</Button> : null}
                        <Stars
                            inc={inc}
                            timeout2={timeout3}
                            user={user}
                            login={login}
                            vote={vote} 
                            s={entry.stars-1}
                            mine={user && user.username == entry.username}
                            first={i == 0}
                            last={i==post.entries.length-1}
                        />
                    </div>

                </div>)}
                <div
                    className={"endscreen votePost" + (post.entries.length != index && !transitioning ? ' hidden' : '')}
                    ref={ref => refs.current[post.entries.length] = ref}
                    style={{opacity:index==post.entries.length?1:0}}
                >

                    <h1 className="thankYouForYourVotes">Thank you for your votes!</h1>
                    <Button onClick={() => jump(0)} disabled={index <= 0}>Go back to the start</Button>
                    
                </div>
            </div>
        </div>
        {post.type=="Challenge"?null:<><div className="leaveacomment">
            <h4>What did you think of this month's entries? Leave a comment!</h4>
        </div>
        <Comments post={post} n={8}/></>}
    </div>
}