import React, { useContext, useState, useEffect } from 'react';
import { useMount, AccountContext, useAsync, ResponsiveContext, ModalContext, CurrencyContext, useAreYouSure } from '../../hooks';
import axios from 'axios';

import Spinner from '../Logo/Spinner';
import ProfilePic from '../user/ProfilePic';
import P from '../controls/P';
import Btn from '../controls/Btn';
import ModalMessage from '../modal/ModalMessage';
import EditAd from './EditAd';
import Ambigram from '../ambigram/Ambigram';
import Link from '../controls/Link';
import Button from '../controls/Button';

export default function Ad({ advert, setAdvert, profile }) {
    let { user, setUser } = useContext(AccountContext);
    let { currency, convertCurrency } = useContext(CurrencyContext);
    let { bigPhone, device } = useContext(ResponsiveContext);
    let Modals = useContext(ModalContext);
    let { componentDidMount } = useMount();
    let areYouSure = useAreYouSure();

    let [ambigrams, setAmbigrams] = useState();

    let [prices, setPrices] = useState();

    let [width, setWidth] = useState(600);

    useEffect(() => {
        if (device == 'phone') {
            setWidth(400);
        } else if (device == 'tablet') {
            setWidth(500);
        } else setWidth(600);
    }, [device])

    useAsync(async () => {
        setAmbigrams();
        let { data } = await axios.post('/api/portfolio', { username: profile.username });
        setAmbigrams(data);
    }, [advert]);

    useAsync(async () => {
        console.log(currency);
        if (currency.code == advert.currency.code) {
            setPrices({ personalPrice: advert.personalPrice, commercialPrice: advert.commercialPrice });
        } else if (currency.code == 'GBP') {
            setPrices({ personalPrice: advert.personalPounds, commercialPrice: advert.commercialPounds });
        } else {
            setPrices();
            let personalPrice = await convertCurrency(advert.personalPounds);
            let commercialPrice = await convertCurrency(advert.commercialPounds);
            setPrices({ personalPrice, commercialPrice });
        }
    },[currency, advert])

    return <div className="Ad Profile Panel">
        <div className="profile">
            <div className="profileLeft"><ProfilePic clickable={false} picture={profile.picture?profile.picture:profile.username} width={bigPhone?100:150} square={bigPhone} /></div>
            <div className="profileRight">
                <h1>{profile.name}</h1>
                <div className="adLinksBit">
                    {advert.links.map((link, i) => <div className={"hellothere"} key={i}>
                        <Link link={link} />
                    </div>)}
                </div>
            </div>
        </div>
        <div className="bioBit">
            {<P>{advert.introduction}</P>}
            <h4>Prices ({currency.code})</h4>
            <div className="adPrices">
                <div className="aPrice PANEL">
                    <div className="priceBit">
                        {prices?<>{currency.symbol}<span>{Math.round(prices.personalPrice)}</span></>:<>{advert.currency.symbol}<span>{advert.personalPrice}</span></>}
                    </div>
                    For personal use*
                </div>
                <div className="aPrice PANEL">
                    <div className="priceBit">
                        {prices?<>{currency.symbol}<span>{Math.round(prices.commercialPrice)}</span></>:<>{advert.currency.symbol}<span>{advert.commercialPrice}</span></>}
                    </div>
                    For commercial use*
                </div>
            </div>
            <p><i className="asterisk">*Prices may vary depending on your requirements!</i></p>

            {user && (profile.username == user.username || user.admin) ? <div className="profileButtons">
                <Btn onClick={() => Modals.create(EditAd, {
                    advert, ambigrams, callback: advert => setAdvert(advert)
                })}>Edit advert</Btn>
                <Btn onClick={async () => {
                    if (await areYouSure('Are you sure you want to delete your advert?')) {
                        setAdvert();
                        setUser({ ...user, ad: null });
                        await axios.post('/api/delete-promo', { user });
                    }
                }}>Delete advert</Btn>
            </div> : <div className="profileButtons">
                <Button onClick={()=>Modals.create(ModalMessage,{title:'Contact '+profile.name, body:
`You may contact ${profile.name} using the email address: ${profile.email}
Please be clear in your requirements - explain your use cases so an appropriate quote can be given!
Be aware that not every word/phrase can be successfully turned into an ambigram - depending on your request, you may need to grant a great deal of creative freedom for a legible result.`
            })}>Contact {profile.name}</Button>
            </div>}
        </div>
        {ambigrams ? <div className="Pages">
            <h3>{profile.name}'s portfolio</h3>
            <div className="pages marginTop">
                {ambigrams.map(post => <div className="adPost PANEL" key={post._id}>
                    {/* <Word word={post.word} /> */}
                    <h4>{post.word}</h4>
                    <Ambigram picture={post.picture} style={post.style} bounds={width} />
                    <P>{post.message}</P>
                </div>)}
            </div>
        </div>: <div className="centreFlex"><Spinner/></div>}


    </div>
}