import React, {useState, useContext } from 'react';
import { ToastContext, AccountContext } from '../../hooks';

import ModalHeading from '../modal/ModalHeading';
import Toast from '../modal/Toast';
import Pounds from './Pounds';
import Button from '../controls/Button';

import axios from 'axios';

export default function EditSub({ close, callback, pounds: pounds0 }) {
    let Toasts = useContext(ToastContext);
    let { user } = useContext(AccountContext);
    let [loading, setLoading] = useState(false);

    let [complete, setComplete] = useState(false);
    let [pounds, setPounds] = useState(pounds0)

    const onSubmit = async (event) => {
        event.preventDefault();
        setLoading(true);
        let { data } = await axios.post('/api/edit-sub', { username: user.username, pounds })
        setLoading(false);
        if (data.error) {
            Toasts.create(Toast, { type:'error', text: data.error });
        } else {
            setComplete(true);
            Toasts.create(Toast, { text: `Thank you for your donation!` });
            callback(pounds);
            await new Promise(resolve => setTimeout(resolve, 1000));
            await close();
        }
    }

    return <div className="EditSub modal">
        <ModalHeading title={'Edit Donation'} close={close} />
        <div className="body">
            <form onSubmit={onSubmit}>
                <Pounds
                    pounds={pounds}
                    setPounds={setPounds}
                    disabled={loading}
                />

                <Button submit loading={loading} disabled={pounds <= 0 || complete}>Donate</Button>
            </form>
        </div>
    </div>
}