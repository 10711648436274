import React, {useState} from 'react';
import { CardElement } from '@stripe/react-stripe-js';
// import './CardSectionStyles.css';

export default function CardSection({disabled, error, setError, setComplete, onChange}) {
    let [focussed, setFocussed] = useState();
    return <div className={"CardSection "+(disabled ? "disabled" : "")}>
        <label className={error?"label cardError":"label"}>
            Card details
            <CardElement
                onFocus={() => {
                    setFocussed(true);
                }}
                onBlur={() => {
                    setFocussed(false);
                }}
                options={{ disabled }}
                onChange={e => {
                    console.log(e);
                    setError(e.error ? e.error.message : null);
                    setComplete(e.complete);
                    if (onChange) onChange();
                }}
            />
        </label>
            <div className={'underline'}>
                <div className={error ? "dottedRed" : "dottedBlue"} />
                <div className={error ? "solidRed" : "solidBlue"} style={{ width: focussed ? '100%' : '0%' }} />
                <div className={error ? "error" : "hidden error"}>{error}</div>
            </div>
    </div>
}