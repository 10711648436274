import React, { useContext, useEffect, useState } from 'react';
import { AccountContext, ModalContext, ToastContext, useMount, useSubmit } from '../../hooks';
import TextArea from '../controls/TextArea';
import ModalHeading from '../modal/ModalHeading';
import Toast from '../modal/Toast';
import Pounds from '../checkout/Pounds';
import Currencies from './Currencies';
import Button from '../controls/Button';
import SampleAmbigrams from './SampleAmbigrams';
import axios from 'axios';
import Spinner from '../Logo/Spinner';
import Arranger from '../controls/Arranger';
import AddLink from './AddLink';
import Link from '../controls/Link';
import FormError from '../controls/FormError';

function RenderLink(link) {
    return <div className="linkItem"><Link link={link}/></div>
}

export default function EditAd({ advert, ambigrams: ambigrams0, callback, close }) {
    let Modals = useContext(ModalContext);
    let Toasts = useContext(ToastContext);
    let { componentDidMount } = useMount();
    let [c, submit, onSubmit] = useSubmit();

    let [loading, setLoading] = useState();
    let { user, setUser } = useContext(AccountContext);

    let [introduction, setIntroduction] = useState(advert.introduction);
    let [introductionError, setIntroductionError] = useState('');
    let [ambigramsError, setAmbigramsError] = useState('');
    let [personalPrice, setPersonalPrice] = useState(advert.personalPrice);
    let [commercialPrice, setCommercialPrice] = useState(advert.commercialPrice);
    let [currency, setCurrency] = useState(advert.currency)
    let [links, setLinks] = useState(advert.links);

    let [ambigrams, setAmbigrams] = useState(ambigrams0?JSON.parse(JSON.stringify(ambigrams0)):null);

    let [serverError, setServerError] = useState('');

    useEffect(() => {
        console.log(links);
    },[links])

    componentDidMount(async () => {
        if (!ambigrams0) {
            let { data } = await axios.post('/api/portfolio', { username: user.username });
            console.log(data);
            setAmbigrams(data);
        }
    })

    onSubmit(async () => {
        if (!ambigramsError && !introductionError) {
            setLoading(true);
            setServerError('');
            let { data } = await axios.post('/api/edit-promo', { user, advert: { introduction, personalPrice, commercialPrice, currency, links, ambigrams: ambigrams.map(a=>a._id) } })
            if (data.error) {
                setServerError('Server error!');
            } else {
                Toasts.create(Toast, { text: `Your advert has been published!` });
                close();
                setUser({ ...user, ad: true });
                callback(data);
            }
            setLoading(false);
        }
    })

    return <div className="EditAd modal" >
        <ModalHeading title="Edit advert" close={close} />
        <div className="body">
            <form onSubmit={submit}>
                <TextArea
                    label="Introduction"
                    value={introduction}
                    setValue={setIntroduction}
                    error={introductionError}
                    setError={setIntroductionError}
                    validation={(introduction) => {
                        if (introduction.length < 50) return 'Introduction too short!';
                        if (introduction.length > 5000) return 'Introduction too long!';
                    }}
                    attempts={c}
                    disabled={loading}
                />
                <label>Currency</label>
                <Currencies currency={currency} setCurrency={setCurrency}/>
                <Pounds
                    label={'Base price for personal use'}
                    pounds={personalPrice}
                    setPounds={setPersonalPrice}
                    disabled={loading}
                    monthly={false}
                    symbol={currency.symbol}
                />
                <Pounds
                    label={'Base price for commercial use'}
                    pounds={commercialPrice}
                    setPounds={setCommercialPrice}
                    disabled={loading}
                    monthly={false}
                    symbol={currency.symbol}
                />

                <label>Sample ambigrams</label>
                {ambigrams ? <>
                    <SampleAmbigrams
                        ambigrams={ambigrams}
                        setAmbigrams={setAmbigrams}
                    />
                    <FormError
                        error={ambigramsError}
                        setError={setAmbigramsError}
                        value={ambigrams}
                        validation={ambigrams => {
                            if (ambigrams.length == 0) return 'Please provide at least 1 ambigram!'
                        }}
                        attempts={c}
                    />
                </>: <div className="centreFlex">
                    <Spinner/>
                </div>}

                <label>Links</label>
                <Arranger
                    renders={links.map(link => { return { render: RenderLink(link), data:link}})}
                    addButtonText="Add link"
                    number
                    add={async () => {
                        let link = await new Promise(resolve => Modals.create(AddLink, { callback: resolve }))
                        console.log(link);
                        return {render: RenderLink(link), data:link};
                    }}
                    callback={links => setLinks(links)}
                />

                <div className="formButtons">
                    <div className="buttons">
                        <Button submit disabled={!ambigrams} loading={loading}>Save</Button>
                    </div>
                    <div className="error">
                        {serverError}
                    </div>
                </div>
            </form>
        </div>
    </div>
}