import React, { useContext, useState, useEffect } from 'react';
import { AccountContext, useAsync, NotificationsContext, ResponsiveContext, ModalContext, useAreYouSure, ToastContext, HistoryContext, useChange, useModalCallback, useTitle } from '../../hooks';
import axios from 'axios';

import Spinner from '../Logo/Spinner';
import ProfilePic from '../user/ProfilePic';
import P from '../controls/P';
import Btn from '../controls/Btn';
import ProfileSettings from '../forms/ProfileSettings';
import AccountSettings from '../forms/AccountSettings';
import Pages from '../posts/Pages';

import { dateString } from '../../functions';
import ModalMessage from '../modal/ModalMessage';
import BlockList from '../forms/BlockList';
import Checkbox from '../controls/Checkbox';
import Toast from '../modal/Toast';
import A from '../controls/A';
import ManualSubscription from '../forms/ManualSubscription';
import NumberModal from '../modal/NumberModal';
import BanModal from '../modal/BanModal';
import IPLookupModal from '../modal/IPLookupModal';
import ProfileTags from '../forms/ProfileTags';
import AreYouSure from '../modal/AreYouSure';

export default function Profile({ username:username0, page, feed:_feed }) {
    let { user, checkSubbed, subbed, logout, checkPermissions } = useContext(AccountContext);
    let { noNavigate, navigate } = useContext(HistoryContext);
    let Toasts = useContext(ToastContext);
    let [profile, setProfile] = useState();
    let [nothing, setNothing] = useState(false);
    let { bigPhone } = useContext(ResponsiveContext);
    let Modals = useContext(ModalContext);
    let [username, setUsername] = useState(username0);
    let areYouSure = useAreYouSure();
    let { setDocumentTitle } = useTitle();

    let [isSubbed, setIsSubbed] = useState()
    let [blocked, setBlocked] = useState();
    let [following, setFollowing] = useState();
    let [followers, setFollowers] = useState();
    let [banned, setBanned] = useState();
    let [tags, setTags] = useState([]);

    let [loadingMessage, setLoadingMessage] = useState(false);

    let [deleted, setDeleted] = useState();

    let [feed, setFeed] = useState(_feed);

    useAsync(async () => {
        if (!username && user) setUsername(user.username);
    }, [username, user])
    
    useEffect(() => {
        setDocumentTitle(`${username || username0} - $`);
    },[username])

    useAsync(async () => {
        if (username) {
            let { data } = user ? await axios.post('/api/user', { username, user }) : await axios.post('/api/profile', { username });

            if (data) {
                console.log(data);
                if (data.deleted) {
                    setNothing(true);
                    setProfile();
                    setDeleted(true);
                } else {
                    setBlocked(data.blocked);
                    setFollowing(data.following);
                    setFollowers(data.followers);
                    
                    if (data.tags) {
                        setTags(data.tags.map(tag => tag.split(' -> ').map((t,i) => i==0?t.trim():JSON.parse(t))));
                        setBanned(data.banned);
                    }
                    
                    setNothing(false);
                    setProfile(data);
                }
            } else {
                setNothing(true);
                setProfile();
            }
        }
    }, [user, username])

    useEffect(() => {
        setIsSubbed(checkSubbed(profile));
        console.log(profile);
    }, [profile])
    
    // if (profile) console.log({ad:profile.ad, isSubbed})

    let feedOptions = [{ title: 'Ambigrams', key: 'Main' }, { title: 'Sketches', key: 'Sketches' }, {title:'Text posts', key:'Thoughts'}]
    
    useChange(() => {
        if (feed == 'Sketches') {
            noNavigate(`/user/${username}/sketches/`);
        } else if (feed == 'Thoughts') {
            noNavigate(`/user/${username}/text/`);
        } else {
            noNavigate(`/user/${username}/`);
        }
    }, [feed])
    
    let inputModal = useModalCallback(NumberModal);


    return <div className="Profile Panel centrepanel">
        {profile ? <>
            <div className="profile">
                <div className="profileLeft">
                    <ProfilePic clickable={false} picture={profile.picture?profile.picture:profile.username} width={bigPhone?100:150} square={bigPhone} />
                    
                </div>
                <div className="profileRight">
                    <h1>{isSubbed ? <img className="crown" src={require('../../pictures/crown.svg')} /> : null}{profile.username}{banned?<> - <span>BANNED!</span></>:null}</h1>
                    <h4>{profile.name}</h4>
                    <div className="medals">
                        {profile.medals > 0 ? <div className='gold'>
                            <img src={require('../../pictures/gold.svg')} />
                            {profile.medals > 1 ? "×" + profile.medals : null}
                        </div>:null}
                        {profile.silver > 0 ? <div className='silver'>
                            <img src={require('../../pictures/silver.svg')} />
                            {profile.silver > 1 ? "×" + profile.silver : null}
                        </div>:null}
                        {profile.bronze > 0 ? <div className='bronze'>
                            <img src={require('../../pictures/bronze.svg')} />
                            {profile.bronze > 1 ? "×" + profile.bronze : null}
                        </div>:null}
                    </div>
                    {followers > 0 ? <div className="followerCount">
                        {followers} follower{followers==1?'':'s'}
                    </div> : null}
                    {bigPhone?null:<div className="accountCreated">
                        {dateString(profile.birthday,{ago:true, agePrefix:'Account created', datePrefix:'Member since'})}
                    </div>}
                    {profile.insta ? <div className="accountCreated">Instagram: <a target="_blank" href={'https://www.instagram.com/'+profile.insta.substr(1,profile.insta.length-1)+'/'}>{profile.insta}</a></div>:null}
                    {user && checkPermissions('View email addresses') ? <div className="accountCreated">Email: <a href={'mailto:' + profile.email} >{profile.email}</a></div> : null}
                    {user && user.admin && !profile.admin ? <>
                        <div className="adminOptions">
                            {tags.map(([tag, value]) => <div key={tag}><b>{tag}</b>{value === true ? '' : <>: {value}</>}</div>)}
                            <Btn onClick={()=>Modals.create(ProfileTags, {profile, tags, setTags})}>Edit tags</Btn>
                        </div>
                        <div className="accountCreated">
                            {isSubbed ? `${profile.username}'s subscription expires on ${dateString(profile.sub.expires)}` : <A blue onClick={() => {
                                Modals.create(ManualSubscription,{username:profile.username})
                            }}>{profile.username} has no subscription!</A>}
                        </div>
                    </> : null}
                </div>
            </div>
            <div className="bioBit">
                {profile.bio ? <P noMarginBottom>{profile.bio}</P> : user && user.username == profile.username ? <i>No bio!</i> : null}
                <div className="profileButtons">
                    {user && (profile.username == user.username || user.admin) ? <>
                        {profile.blocked && profile.blocked.length > 0 ? <Btn onClick={() => Modals.create(BlockList, { blocked: profile.blocked })}>Block list</Btn> : null}
                        {/* {profile.username == user.username?<Btn to={subbed ? '/advert/'+user.username.toLowerCase() : null} onClick={subbed ? null : () => Modals.create(ModalMessage, { preset: 'Donate' })}>Your advert</Btn>:null} */}
                        <Btn onClick={()=>Modals.create(ProfileSettings,{profile})}>Edit profile</Btn>
                        <Btn onClick={() => Modals.create(AccountSettings, { profile })}>Account settings</Btn>
                        {profile.username == user.username?<Btn onClick={async () => {
                            if (await areYouSure('Are you sure you want to log out?')) {
                                logout();
                                Modals.create(ModalMessage, { title: 'Goodbye!', body: 'You have successfully logged out!' });
                            }
                        }}>Log out</Btn>:null}
                    {/* </> : profile.ad && isSubbed ? <>
                        <Btn to={'/advert/'+profile.username.toLowerCase()}>Commission an ambigram</Btn> */}
                    </> : null}
                    {user && (profile.username != user.username) ? <>
                        <Btn onClick={async () => {
                            setLoadingMessage(true);
                            let { data } = await axios.post('/api/new-chat', { username: profile.username, user });
                            navigate(data.url);
                        }} loading={loadingMessage}>Send message</Btn>
                        {!following?<Btn onClick={async () => {
                            setFollowing(true);
                            axios.post('/api/follow', { username: profile.username, user });
                            setFollowers(followers + 1);
                            Toasts.create(Toast, { text:`You have followed ${profile.username}` });
                        }}>Follow</Btn>:<Btn onClick={async () => {
                            setFollowing(false);
                            axios.post('/api/unfollow', { username:profile.username, user })
                            setFollowers(followers - 1);
                            Toasts.create(Toast, { text:`You have unfollowed ${profile.username}` });
                        }}>UnFollow</Btn>}
                    </> : null}
                    {user && (profile.username != user.username) && !profile.admin && !user.admin ? <>
                        {!blocked?<Btn onClick={async () => {
                            if (await areYouSure(`Are you sure you want to block ${profile.username}? You will not see their posts or comments.`)) {
                                setBlocked(true);
                                axios.post('/api/block', { username:profile.username, user })
                                Modals.create(ModalMessage, { title: 'Success!', body: `${profile.username} has been blocked!` });
                            }
                        }}>Block</Btn>:<Btn onClick={async () => {
                            setBlocked(false);
                            axios.post('/api/unblock', { username:profile.username, user })
                            Modals.create(ModalMessage, { title: 'Success!', body: `${profile.username} has been unblocked!` });
                        }}>Unblock</Btn>}
                    </> : null}
                    {user && user.admin && !profile.admin ? <>
                        <Btn onClick={async () => {
                            Modals.create(IPLookupModal, { profile });
                        }}>Alt accounts</Btn>
                        {!banned ? <Btn onClick={async () => {
                            Modals.create(BanModal, { profile, callback:()=>{setBanned(true)}});
                        }}>Ban {profile.username}</Btn> : <Btn onClick={async () => {
                                Modals.create(AreYouSure, {
                                    body: `Are you sure you want to unban ${profile.username}?`, callback: async (confirmed) => {
                                        if (confirmed) {
                                            await axios.post('/api/unban', { username: profile.username, user });
                                            setBanned(false);
                                        }
                                    }
                                });
                        }}>Unban {profile.username}</Btn>}
                    </> : null}
                </div>
            </div>
            <div className="congrats">
                    <h3>{profile.username}'s portfolio</h3>
                    <div className="feedButtons">
                        {feedOptions.map(({title,key,url})=><label className="feedButton" key={title}>
                            <div className={`feedButtonInner ${feed==key ? 'selected' : ''}`}>
                                {title}
                                <input
                                    type="radio"
                                    name={"feed"}
                                    checked={feed==title}
                                    onChange={e => { if (e.target.checked) setFeed(key) }}
                                />
                            </div>
                        </label>)}
                    </div>
                </div>
            <Pages
                query={
                    feed == 'Thoughts' ? { $or: [{ type: 'Blog' }, { type: 'Poll' }], username: profile.username} : user && (user.username == profile.username || user.admin) ? { $or: [{ type: 'Entry' }, { type: 'ChallengeEntry' }, { type: 'DuelEntry' }, { type: 'Ambigram' }], username: profile.username, feed }
                        : { $or: [{ type: 'Entry' }, { type: 'ChallengeEntry' }, { type: 'DuelEntry' }, { type: 'Ambigram' }], visProfile: true, username: profile.username, feed }
                }
                startingPage={page}
                url={feed=='Sketches' ? `/user/${username}/sketches/` : `/user/${username}/`}
                validateHidden={post => (post.type!='Blog' && post.type!='Poll') && user && (user.username == post.username || user.admin) && !post.visProfile}
                feed={feed}
                noPosts={"There are no posts to show..."}
                force={feed}
            />

        </> : nothing ? <div>
            <h1 className="mb">User not found!</h1>
            {deleted?<A onClick={async()=>{
                await axios.post('/api/undelete-account', { username, user });
                window.location.reload();
            }}>Revive account</A>:null}
        </div> : <div className="centreFlex">
            <Spinner/>
        </div>}
    </div>
}