import React, { useContext } from 'react';
import Pages from '../posts/Pages'
import { AccountContext } from '../../hooks';

export default function Following({ page }) {
    let { user } = useContext(AccountContext);
    return <div className="Panel MainFeed centrepanel">
        {user ? <Pages
            following
            startingPage={page}
            url={'/following/'}
            noPosts={<div className="noPosts">
                <h1>Following</h1>
                <h4>Posts by your favourite artists will appear here.</h4>
                <p>It appears that you have not followed any other users.</p>
                <p>To follow a user, you must click on their username to visit their profile. Then under their profile picture, you will find a button to follow them.</p>
                <p>When you have followed your favourite artists, their posts can be viewed here!</p>
            </div>}
        /> : <h1>You must log in to see these posts!</h1>}
    </div>
}