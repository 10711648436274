import React, {useState, useContext} from 'react';
import { AccountContext, ModalContext, ToastContext, useSubmit } from '../../hooks';
import Button from '../controls/Button';
import ModalHeading from '../modal/ModalHeading';
import Toast from '../modal/Toast';
import DateSelect from '../controls/DateSelector';
import axios from 'axios';
import Pounds from '../checkout/Pounds';

export default function ManualSubscription({ close, username }) {
    let { user } = useContext(AccountContext);
    let Toasts = useContext(ToastContext);

    let oneMonthFromNow = new Date().getTime() + 30 * 1000 * 60 * 60 * 24
    let [expires, setExpires] = useState(oneMonthFromNow);
    let [error, setError] = useState('');
    let [loading, setLoading] = useState('');

    let [pounds, setPounds] = useState(5);

    let [c, submit, onSubmit] = useSubmit();
    let Modals = useContext(ModalContext);

    onSubmit(async () => {
        setLoading(true);
        let { data } = await axios.post('/api/grant-subscription', { username, pounds, expires, user });
        if (data.error) {
            setError(data.error);
        } else {
            Toasts.create(Toast, { text: `The subscription has been granted!` });
            await close();
        }
        setLoading(false);
    })

    return <div className="ManualSubscription modal">
        <ModalHeading title="Grant subscription" close={close} />
        <div className="body">
            <form onSubmit={submit}>
                <div className="subsplit">
                    <Pounds
                        label='Donation amount'
                        monthly={false}
                        pounds={pounds}
                        setPounds={setPounds}
                        disabled={loading}
                    />
                    <div>
                        <label>Expires</label>
                        <DateSelect date={expires} setDate={setExpires} />
                    </div>
                </div>

                <div className="formButtons">
                    <div className="buttons">
                        <Button submit loading={loading} disabled={expires < new Date().getTime()}>Grant subscription</Button>
                    </div>
                    <div className="error">
                        {error}
                    </div>
                </div>
            </form>
        </div>
    </div>
}