import React, { useState,useRef, useEffect } from 'react';
import { useMount, useResize } from '../../hooks';

export default function TextArea({ id, onChangeHeight, setFocussed, label, setError = () => { }, error, onKeyDown, value, setValue, type = 'text', attempts, validation, disabled, hideLabel, showErrors }) {
    let { componentDidMount } = useMount();
    let [focus, setFocus] = useState(false);
    let [height, setHeight] = useState('1rem');
    let input = useRef();
    let [lastError, setLastError] = useState('');

    componentDidMount(() => {
        if (document.activeElement === input.current) setFocus(true);
    })

    useEffect(() => {
        if (validation) {
            let error = validation(value)
            setError(error);
        }
        if (value.length==0) setHeight('1rem')
    }, [value, attempts])

    useEffect(() => {
        if (error) setLastError(error)
    },[error])

    useEffect(() => {
        input.current.disabled = disabled;
    }, [disabled])
    
    useEffect(() => {
        if (setFocussed) setFocussed(focus);
    },[focus])

    if (attempts > 0) showErrors = true;

    let labelClass = 'label';
    if (!focus && !value) labelClass += ' empty';
    if (error && showErrors) labelClass += ' red';
    if (hideLabel) labelClass += ' noLabel'

    return <div className={disabled?"disabled Input" : "Input"}>
        <label className="textLabel" >
            <div className={labelClass}>{label}</div>
            <textarea
                id={id}
                ref={input}
                type={type}
                onChange={e => {
                    let { value } = e.target;
                    setValue(value);
                }}
                onFocus={()=>setFocus(true)}
                onBlur={() => setFocus(false)}
                value={value}
                style={{ height }}
                onKeyDown={e => {
                    if (onKeyDown) onKeyDown(e);
                }}
            ></textarea>
        </label>
        <br/>
        <div className={'underline'}>
            <div className={error && showErrors ? "dottedRed" : "dottedBlue"} />
            <div className={error && showErrors ? "solidRed" : "solidBlue"} style={{ width: focus ? '100%' : '0%' }} />
            <div className={error && showErrors ? "error" : "hidden error"}>{lastError}</div>
        </div>
        {value.length > 0 ? <Resizer setHeight={h => {
            setHeight(h);
            if (onChangeHeight) onChangeHeight(h)
        }} value={value} /> : null}
    </div>
}

function Resizer({ setHeight, value }) {
    let [width] = useResize();
    let ref = useRef();
    let height = useRef(0);
    useEffect(() => {
        if (ref.current.clientHeight != height.current) {
            height.current = ref.current.clientHeight;
            setHeight(ref.current.clientHeight);
        }
    },[width, value])
    return <div className="hide">
        <div className="text" ref={ref}>
            {value.split('\n').map((t,i) => <div key={i} className="p">
                {t ? t : '.'}
            </div>)}
        </div>
    </div>
}