import React, { useEffect, useState } from 'react';
import { useMount, useTitle } from '../../hooks';
import axios from 'axios';
import Btn from '../controls/Btn';
import DelaySpinner from '../Logo/DelaySpinner';
import { ImgStar } from '../../pictures/SVG';
import UserPanel from '../user/UserPanel';
import Ambigram from '../ambigram/Ambigram';

export default function Tutorial({ URL }) {
    let [tutorial, setTutorial] = useState();
    let { componentDidMount } = useMount();
    let [error, setError] = useState();
    let { setDocumentTitle } = useTitle();

    useEffect(() => {
        if (tutorial) setDocumentTitle(`${tutorial.title} tutorial - $`)
    },[tutorial])

    componentDidMount(async () => {
        let { data } = await axios.post('/api/tutorial', { URL });
        if (data.error) {
            setError(data.error);
        } else {
            let difficulty = [];
            for (let i = 0; i < data.difficulty; i++) difficulty.push(i);
            data.difficulty = difficulty;
            data.body = data.body.split('\n').map(t => {
                if (t.startsWith(':')) {
                    let args = t.substring(1, t.length).split('|');
                    let type = args.shift();
                    if (type == 'ambigram') args[0] = JSON.parse(args[0]);
                    if (type == 'image') args[0] = JSON.parse(args[0]);
                    return {type, args}
                } else {
                    return {type:'p',body:t}
                }
            })
            setTutorial(data);
        }
    })


    return <div className="Panel Tutorials centrepanel">
        <div className="PagesSpinner top"><DelaySpinner loading={!tutorial&&!error} /></div>
        {error ? <h1>{error}</h1>:null}
        {tutorial ? <div className="tutorial Post">
            <div className="postLeft">
                <UserPanel post={tutorial}/>
            </div>
            <div className="postRight">
                <h2>{tutorial.title}</h2>
                <div className="difficulty">
                    <h4>Difficulty:</h4>
                    <div className="stars">
                        {tutorial.difficulty.map(i => <ImgStar key={i} style={{left: (i * 2 + 0.5) + 'rem'}} />)}
                    </div>
                </div>
                {tutorial.body.map(({ type, args, body }, i) =>
                    type == 'p' ? <p key={i} dangerouslySetInnerHTML={{ __html: body }} /> :
                    type == 'ambigram' ? <Ambigram key={i} picture={args[0]} style={args[1]} bounds={args[2]}/> : 
                    type == 'image' ? <img key={i} src={'/api/pictures/' + args[0].path} width={args[2]} alt={args[1]}/>: 
                    null
                )}
                <div className="prevNext">
                    <Btn to="/tutorials">Back</Btn>
                    {tutorial.next ? <Btn to={tutorial.next}>Next Tutorial</Btn> : null}
                </div>
            </div>
        </div> : null}
    </div>
}
