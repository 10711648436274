import React from 'react';
import ModalHeading from '../modal/ModalHeading';
import Ambigram from '../ambigram/Ambigram';

export default function Versions({close, post}) {
    let versions = post.versions.slice();
    versions.push(post.picture);
    return <div className="Versions modal">
        <ModalHeading title={"Version history"} close={close} />
        <div className="body">
            {versions.map((version,i) => <div className="version">
                <h4>Version {i + 1}</h4>
                <Ambigram picture={version} style={post.style} bounds={300} />
                </div>
            )}
        </div>
    </div>
}