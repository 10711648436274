import React, { useState } from 'react';
import { useSubmit } from '../../hooks';
import ModalHeading from '../modal/ModalHeading';

import { validateUrl } from '../../functions';
import Input from '../controls/Input';
import Button from '../controls/Button';
import Link from '../controls/Link';

export function validateTitle(title) {
    if (title.length < 3) return 'Title too short!';
    if (title.length > 40) return 'Title too long!';
}

export default function AddLink({ close, callback }) {
    let [url, setUrl] = useState('');
    let [urlError, setUrlError] = useState('');
    let [title, setTitle] = useState('');
    let [titleError, setTitleError] = useState('');

    let [c, submit, onSubmit] = useSubmit();

    onSubmit(() => {
        if (!titleError && !urlError) { 
            callback({ url, title });
            close();
        }
    })

    return <div className="AddLink modal" >
        <ModalHeading title="Add link" close={close} />
        <div className="body">
            <p>Add a link to your website, or to some social media profile!</p>
            <form onSubmit={submit}>
                <Input
                    label="URL"
                    value={url}
                    setValue={setUrl}
                    error={urlError}
                    setError={setUrlError}
                    validation={validateUrl}
                    attempts={c}
                />

                <Input
                    label="Title"
                    value={title}
                    setValue={setTitle}
                    error={titleError}
                    setError={setTitleError}
                    validation={validateTitle}
                    attempts={c}
                />

                <p>Link preview: <Link link={{ url, title }}/></p>

                <div className="formButtons">
                    <div className="buttons">
                        <Button submit>Add link</Button>
                    </div>
                </div>
            </form>
        </div>
    </div>
}