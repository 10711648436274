import React, { useState } from 'react';
import { useMount } from '../../hooks';
import axios from 'axios';
import Btn from '../controls/Btn';
import DelaySpinner from '../Logo/DelaySpinner';
import { ImgStar } from '../../pictures/SVG';
import { NavLink } from 'react-router-dom';
import UserPanel from '../user/UserPanel';

export default function Tutorials({  }) {
    let [tutorials, setTutorials] = useState();
    let { componentDidMount } = useMount();

    componentDidMount(async () => {
        let { data } = await axios.get('/api/tutorials');
        setTutorials(data.map(d => {
            let difficulty = [];
            for (let i = 0; i < d.difficulty; i++) difficulty.push(i);
            return { ...d, difficulty };
        }));
    })

    return <div className="Panel Tutorials centrepanel">
        <div className="PagesSpinner top"><DelaySpinner loading={!tutorials} /></div>
        {tutorials ? <div>
            <h1>Tutorials</h1>
            <p>Making ambigrams can be a bit confusing, so here are some tutorials to help you get started!</p>
            <div className="tutorialsList">
                {tutorials.map(({ username, title, URL, difficulty, description, birthday }, i) => <div key={i} className="tutorialItem Post">
                    <div className="postLeft">
                        <UserPanel post={{username,birthday}}/>
                    </div>
                    <div className="postRight">
                        <NavLink to={'/tutorial/'+URL}><h2>{title}</h2></NavLink>
                        <div className="difficulty">
                            <h4>Difficulty:</h4>
                            <div className="stars">
                                {difficulty.map(i => <ImgStar
                                    key={i}
                                    style={{
                                        left: (i * 2 + 0.5) + 'rem',
                                    }}
                                />)}
                            </div>
                        </div>
                        <p>{description}</p>
                        <Btn to={'/tutorial/'+URL}>Read more</Btn>
                    </div>
            
                </div>)}
            </div>
        </div>:null}
    </div>
}
