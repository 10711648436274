import React, {useRef, useState} from 'react';
import { useForceRender } from '../../hooks';

import Button from '../controls/Button';
import Uploader from '../controls/Uploader';
import ModalHeading from '../modal/ModalHeading';

export default function Change({ change, setChange, close }) {
    let [picture, setPicture] = useState(change ? {path:change} : {});
    let [removePic, setRemovePic] = useForceRender();
    let uploader = useRef();
    let [loading, setLoading] = useState(false);
    let [error, setError] = useState('');

    return <div className="Change modal">
        <ModalHeading title="Second picture" close={close} />
        <div className="body">
            <div className="centre pictureBit">
                {picture.path ?
                    <>
                        <div className="pic"><img src={'/api/pictures/'+picture.path}/></div>
                        <Button fast onClick={()=>document.getElementById(uploader.current).click()} loading={loading} disabled={loading}>
                            Change picture
                        </Button>
                        <Button colour="red" fast onClick={setRemovePic} loading={loading} disabled={loading}>
                            Remove picture
                        </Button>
                    </> : <>
                        <div className="nopic">No image!</div>
                        <br/>
                        <Button fast onClick={()=>document.getElementById(uploader.current).click()} loading={loading} disabled={loading}>
                            Upload image
                        </Button>
                    </>
                }
                <Uploader
                    setError={setError}
                    setPicture={setPicture}
                    remove={removePic}
                    setId={id=>uploader.current = id}
                    setLoading={setLoading}
                />
            </div>
            <div className="formButtons">
                <div className="buttons">
                    <Button onClick={() => {
                        setChange(picture.path ? picture.path :'');
                        close();
                    }} loading={loading}>Save</Button>
                </div>
                <div className="error">
                    {error}
                </div>
            </div>
        </div>
    </div>
}