import React, { useEffect, useState, useContext } from 'react';
import { ResponsiveContext, AccountContext, ToastContext, useAsync, ModalContext } from '../../../hooks';
import axios from 'axios';
import Button from '../../controls/Button';
import StartDuel from '../../forms/StartDuel';
import Username from '../../user/Username';
import { dateString, pluralise } from '../../../functions';
import { Countdown } from '../../controls/Countdown';
import Ambigram from '../../ambigram/Ambigram';
import P from '../../controls/P';



export default function DuelsAdmin({ }) {
    let { desktop, device } = useContext(ResponsiveContext);
    let { user, setUser } = useContext(AccountContext);
    let Modals = useContext(ModalContext);
    let [width, setWidth] = useState(600);
    let [notEnough, setNotEnough] = useState(false);

    let [post, setPost] = useState();
    let [noPost, setNoPost] = useState();

    useEffect(() => {
        if (device == 'phone') {
            setWidth(400);
        } else if (device == 'tablet') {
            setWidth(500);
        } else setWidth(600);
    }, [device])

    useAsync(async () => {
        let { data } = await axios.post('/api/open-duel', { user });
        console.log(data);
        if (data && !data.closed) {
            console.log(data);
            if (data.pool) data.pool.reverse();
            {
                let { data } = await axios.post('/api/duel-pool', { user });
                if (data.length < 4) setNotEnough(true);
            }
            setPost(data);
        } else setNoPost(true);
    },[])


    let [ended, setEnded] = useState(false);
    if (!user || !user.admin) return null;

    return <div className="Panel DuelsAdmin centrepanel">
        <h1 className="lilmb">{post?<>Current duel - <span>{post.word}!</span></>:"Duel arena"}</h1>
        {noPost ? <>
            <h4 className="lilmb">There are currently no open duels.</h4>
            {notEnough ? <p>And there are not enough duel participants :(</p> : <Button onClick={() => {
                Modals.create(StartDuel, {
                    callback: async (post) => {
                        let { data } = await axios.post('/api/create-duel', { ...post, user });
                        if (data) {
                            setNoPost(false);
                            setPost(data);
                            return true;
                        } else {
                            return false;
                        }
                    }
                })
            }}
            >New duel</Button>}
        </> : post ? <div>
            {post.open?<>
                {ended ? <h4>The submission period is over!</h4> : <h4>Deadline: {dateString(post.deathday)} 13:00 GMT</h4>}
                <Countdown time={post.deathday} setEnded={setEnded} />
            </>:null}
            <h2>Participants:</h2>
            <ol className="participants">
                {post.participants.map(p=><li className="participant" key={p.username}>
                    <Username username={p.username} /> {post.initialising && !p.confirmed ? <i>Uncomfirmed {p.ignored > 0 ? ` (${p.ignored} strikes!)` : null}</i> : null}
                </li>)}
            </ol>
            {post.initialising ? <>
                <h2>Pool:</h2>
                <ol className="participants">
                    {post.pool.map(p=><li className="participant" key={p}>
                        <Username username={p} />
                    </li>)}
                </ol>
            </> : post.open ? <>
                {post.participants.map((participant, i) => <div className="PANEL" key={i}>
                    <h2 className="lilmb">{pluralise(participant.username)} entry</h2>
                    {!participant.entry ? <h4 className="mb">There is nothing...</h4> : <div className="entry bigmb">
                        <div className="entryLeft">
                            <Ambigram picture={participant.entry.picture} style={participant.entry.style} bounds={width} />
                        </div>
                        <div className="entryRight">
                            <div>
                                <P>{participant.entry.message}</P>
                            </div>
                        </div>
                    </div>}

                    <h2 className="lilmb">{pluralise(participant.username)} progress pictures</h2>
                    {participant.progress.length == 0 ? <h4>There is nothing...</h4> :<div className="entries">
                        {participant.progress.map((entry, i) => <div className="entry">
                            <div className="entryLeft">
                                <Ambigram picture={entry.picture} style={entry.style} bounds={width} />
                            </div>
                            <div className="entryRight">
                                <div>
                                    <P>{entry.message}</P>
                                </div>
                            </div>
                        </div>)}
                
                    </div>}
                </div>)}
            </> : null}
        </div> : null}
    </div>
}