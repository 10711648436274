import React, {useState, useContext} from 'react';
import { ModalContext, ToastContext, useSubmit, AccountContext, useAreYouSure } from '../../hooks';
import Input from '../controls/Input';
import Button from '../controls/Button';
import ModalHeading from '../modal/ModalHeading';
import ModalMessage from '../modal/ModalMessage';
import Toast from '../modal/Toast';

import axios from 'axios';

import { validatePassword } from '../../functions';

export default function DeleteAccount({ close, callback }) {
    let { user, logout } = useContext(AccountContext);
    let Toasts = useContext(ToastContext);

    let [password, setPassword] = useState('');

    let [serverError, setServerError] = useState('');
    let [passwordError, setPasswordError] = useState('');
    let [loading, setLoading] = useState('');

    let [c, submit, onSubmit] = useSubmit();
    let Modals = useContext(ModalContext);

    let areYouSure = useAreYouSure();

    onSubmit(async () => {
        if (!passwordError) {
            setLoading(true);
            setServerError('');
            if (await areYouSure('Are you sure you want to delete your account?')) {
                let { data } = await axios.post('/api/delete-account', { user, password });
                console.log(data);
                let { success, error } = data;
                if (error) {
                    setPasswordError(error.password);
                    setServerError(error.server);
                    if (error.server) Toasts.create(Toast, { preset: 'server error' });
                } else {
                    await close();
                    Modals.create(ModalMessage, { title: 'Goodbye forever...', body: 'Your account has been deleted!' });
                    logout();
                    callback();
                }
            }
            setLoading(false);
        }
    })

    return <div className="modal">
        <ModalHeading title="Delete account" close={close} />
        <div className="body">
            <form onSubmit={submit}>
                <Input
                    label="Password"
                    type="password"
                    value={password}
                    setValue={setPassword}
                    error={passwordError}
                    setError={setPasswordError}
                    validation={validatePassword}
                    attempts={c}
                    disabled={loading}
                />

                <div className="formButtons">
                    <div className="buttons">
                        <Button submit loading={loading}>Delete account</Button>
                    </div>
                    <div className="error">
                        {serverError}
                    </div>
                </div>
            </form>
        </div>
    </div>
}