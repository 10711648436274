import React, { useRef, useContext } from 'react';
import { useMount, useGetState, useChange, IdContext } from '../../hooks';
import axios from 'axios';

export default function Uploader({ setError, setPath = () => { }, setPicture = () => { }, upload, remove, setLoading, setId }) {
    let { componentDidMount } = useMount();
    let [pic, setPic, getPic] = useGetState(null);
    let ref = useRef();
    let getId = useContext(IdContext);

    let id = useRef(getId());

    componentDidMount(() => {
        setId(id.current);
    })

    
    useChange(async () => {
        setPath('');
        setPicture({});
        setPic(null);
    },[remove])

    
    async function onChange(e) {
        let picture = e.target.files[0];
        let error;
        if (picture) {
            setLoading(true);
            let { size, name } = picture;
            let split = name.split('.');
            let ext = split[split.length - 1].toUpperCase();
            let maxSize = ext == 'GIF' ? 10000000 : 1000000;
            if (ext != 'JPG' && ext != 'JPEG' && ext != 'BMP' && ext != 'PNG' && ext != 'GIF') {
                error = 'Only jpg, png, bmp and gif allowed!';
            } else if (size > maxSize) {
                error = 'File too big!';
            }
            if (error) {
                setError(error);
                setLoading(false);
            } else {
                let fd = new FormData();
                fd.append('picture', picture);
                axios({
                    method: 'post',
                    url: '/api/upload-temp',
                    data: fd,
                    config: { headers: { 'Content-Type': 'multipart/form-data' } }
                }).then(async (r) => {
                    let picture = r.data;
                    if (picture) {
                        // let pic = getPic();
                        // if (pic) axios.post('/api/delete-temp', { path: pic.path });
                        setPath(picture.path);
                        setPicture(picture);
                        setPic(picture);
                        setError('');
                    } else {
                        setError("Upload failed!")
                    }
                    setLoading(false);
                });
            }
        }
    }

    return <div className='Uploader' ><input
        ref={ref}
        type="file"
        onChange={onChange}
        id={id.current}
    /></div>
}