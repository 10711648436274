import React, {
    forwardRef,
    useImperativeHandle,
    useState,
    useRef,
    useEffect
} from 'react';
import { useGetState, useTimeout } from '../../hooks';

export default forwardRef(({ renders }, ref) => {
    let [items, setItems, getItems] = useGetState(renders);
    let k = useRef(0);
    useImperativeHandle(ref, () => ({
        push: render => {
            console.log(render)
            let items = getItems();
            items.push(<Shrinker key={k.current++}>{render}</Shrinker>);
            setItems(items.slice());
        },
        unshift: render => {
            let items = getItems();
            items.unshift(<Shrinker unshift key={k.current++}>{render}</Shrinker>);
            setItems(items.slice());
        }
    }));
    return <div className="Appender">
        {items}
    </div>
});

function Shrinker({ children, unshift }) {
    let ref = useRef();
    let { doTimeout } = useTimeout();
    let [height, setHeight] = useState(0);
    let [shrunk, setShrunk] = useState(true);

    function expandAction() {
        if (!ref.current) {
            doTimeout(() => {
                expandAction()
            },10)
        } else {
            setHeight(ref.current.clientHeight);
            doTimeout(() => {
                setHeight(null);
                setShrunk(false);
            },0.3,'s')
        }
    }

    useEffect(() => {
        doTimeout(() => {
            expandAction();
        },50)
    }, [])
    
    let className = "Shrinker ";
    if (shrunk) className += "shrunk ";
    if (unshift) className += "unshift";

    return <div className={className} style={{ height, transition: `height 0.3s ease-in-out` }}>
        <div ref={ref} style={{ border: '0.1px solid rgba(0,0,0,0)'}}>
            {children}
        </div>
    </div>
}