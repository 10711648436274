import React, {useState, useContext, useRef, useEffect} from 'react';
import { ModalContext, ToastContext, useSubmit, useForceRender, useGetState, AccountContext } from '../../hooks';
import Input from '../controls/Input';
import TextArea from '../controls/TextArea';
import SelectDropdown from '../controls/SelectDropdown';
import Button from '../controls/Button';
import ModalHeading from '../modal/ModalHeading';
import Uploader from '../controls/Uploader';
import Toast from '../modal/Toast';
import Expand from '../controls/Expand';
import Radiobox from '../controls/Radiobox';
import Checkbox from '../controls/Checkbox';
import Shrinker from '../controls/Shrinker';
import AnimButton from '../controls/AnimButton';
import Special from './Special';
import Change from './Change';

import { ImgBoat,ImgButterfly,ImgNone,ImgYinyang } from '../../pictures/animations/SVG';

import { validateWord, validateMessage, validateGlyphs } from '../../functions';
import Ambigram from '../ambigram/Ambigram';

export default function UploadAmbigram({ close, post, callback, blockChangePicture, entry, challenge, feed:_feed, duel, noGlyphs }) {
    let Modals = useContext(ModalContext);
    let Toasts = useContext(ToastContext);

    let [c, submit, onSubmit] = useSubmit();
    let [loading, setLoading] = useState(false);
    let [loadingUpload, setLoadingUpload] = useState(false);

    let [word, setWord] = useState(post ? post.word : '');
    let [picture, setPicture] = useState(post ? post.picture : {});
    let [removePic, setRemovePic] = useForceRender();
    let [message, setMessage] = useState(post ? post.message : '');
    let [glyphs, setGlyphs] = useState(post && post.glyphs ? post.glyphs.join(' ') : '');

    let [wordError, setWordError] = useState('');
    let [pictureError, setPictureError] = useState('');
    let [messageError, setMessageError] = useState('');
    let [glyphsError, setGlyphsError] = useState('');

    let [feed, setFeed] = useState(post?post.feed:_feed);

    let [style, setStyle, getStyle] = useGetState(post ?
        post.style.substr(0, 7) == 'Special' ? 'Special' :
        post.style.substr(0, 6) == 'Custom' ? 'Custom' :
        post.style.substr(0, 6) == 'Change' ? 'Change' :
        post.style : 'Rotational');
        
    let [special, setSpecial, getSpecial] = useGetState(post && post.style.substr(0, 7) == 'Special' ? post.style : 'Special');
    let [change, setChange, getChange] = useGetState(post && post.style.substr(0, 6) == 'Change' ? post.style.substr(7, post.style.length - 7) : '');
    let [custom, setCustom, getCustom] = useGetState(post && post.style.substr(0,6) == 'Custom' ? post.style.substr(6, post.style.length - 6) + '°' :'90°');

    let [visGlyphs, setVisGlyphs] = useState(post ? post.visGlyphs : true);
    let [visProfile, setVisProfile] = useState(post ? post.visProfile : true);
    let [visFeed, setVisFeed] = useState(post ? post.visFeed : true);

    let getLocalConstructiveCriticism = () => !(localStorage.getItem('cc') == ':(');

    let [constructiveCriticism, setConstructiveCriticism] = useState(post ? !!post.constructiveCriticism : getLocalConstructiveCriticism());

    let [serverError, setServerError] = useState('');

    useEffect(() => {
        localStorage.setItem('cc',constructiveCriticism ? ':)' : ':(')
    },[constructiveCriticism])

    onSubmit(async () => {
        let WORDERROR = challenge||duel ? false : wordError;
        if (!picture.path) {
            setPictureError('Please upload a picture!');
        } else if (!WORDERROR && !pictureError && !messageError && !glyphsError) {
            setLoading(true);
            if (!post) post = {};
            post.word = word;
            post.message = message;
            post.picture = picture;
            post.style = formatStyle();
            post.glyphs = glyphs.trim().split(' ').map(i=>i.toUpperCase());
            post.visFeed = visFeed;
            post.visGlyphs = visGlyphs;
            post.visProfile = visProfile;
            if (!(entry || challenge || duel)) post.constructiveCriticism = constructiveCriticism;
            if (feed) post.feed = feed;
            let res = await callback(post);
            if (res) {
                close();
            } else {
                Toasts.create(Toast, { type: "error", text: 'Upload failed! Maybe try again later.' });
                setServerError('Upload failed');
            }
            setLoading(false);
        }
    })

    let formatStyle = () => {
        if (style == 'Custom') {
            let custom = getCustom();
            custom = custom.substr(0, custom.length - 1);
            return 'Custom' + custom;
        } else if (style == 'Special') {
            let special = getSpecial();
            if (special == 'Special') {
                return 'None'
            } else {
                return special;
            }
        } else if (style=='Change') { 
            if (change == '') {
                return 'None';
            } else {
                return 'Change_' + change;
            }
        } else return style;
    }

    let uploader = useRef();

    return <div className="UploadAmbigram modal">
        <ModalHeading title={post?'Edit ambigram':'Upload ambigram'} close={close} />
        <div className="body">
            <form onSubmit={submit}>

                {challenge||duel?null:<Input
                    label="Word"
                    value={word}
                    setValue={setWord}
                    error={wordError}
                    setError={setWordError}
                    validation={validateWord}
                    attempts={c}
                    disabled={loading}
                />}

                <TextArea
                    label="Message (optional)"
                    value={message}
                    setValue={setMessage}
                    error={messageError}
                    setError={setMessageError}
                    validation={validateMessage}
                    attempts={c}
                    disabled={loading}
                />
                
                {entry || challenge || duel ? null : <Checkbox label="Looking for constructive criticism" checked={constructiveCriticism} setChecked={setConstructiveCriticism} disabled={loading}/>}

                <br />
                
                <div className="centre buttonBit">
                    {picture.path ?
                        <>
                            <Ambigram picture={picture} style={formatStyle()} bounds={200} />
                            {blockChangePicture?null:<Button fast onClick={()=>document.getElementById(uploader.current).click()} loading={loadingUpload} disabled={loading}>
                                Change picture
                            </Button>}
                        </> : <>
                            <div className="nopic">No image!</div>
                            <br/>
                            <Button fast onClick={()=>document.getElementById(uploader.current).click()} loading={loadingUpload} disabled={loading}>
                                Upload image
                            </Button>
                        </>
                    }
                    <Uploader
                        setError={setPictureError}
                        setPicture={setPicture}
                        // upload={uploadPic}
                        remove={removePic}
                        setId={id=>uploader.current = id}
                        setLoading={setLoadingUpload}
                    />
                </div>

                {pictureError ? <div className="err">{pictureError}</div> : <br />}
                
                {feed ? <div className="feed centreFlex gap">
                    <Radiobox option={feed} setOption={setFeed} name="Feed" label="Sketch" id="Sketches" disabled={loading}/>
                    <Radiobox option={feed} setOption={setFeed} name="Feed" label="Final" id="Main" disabled={loading} />
                </div>:null}

                <label>Animation</label>
                <div className="AnimationSelector">
                    <div className="AnimButtons">
                        <AnimButton className="None" setTransform={c => null} style={style} setStyle={setStyle} disabled={loading} Img={ImgNone}/>
                        <AnimButton className="Rotational" setTransform={c => `rotate(${c * 180}deg)`} style={style} setStyle={setStyle} disabled={loading} Img={ImgYinyang}/>
                        <AnimButton className="Mirror" setTransform={c => `scale(${c % 2 == 0 ? 1 : -1},1)`} style={style} setStyle={setStyle} disabled={loading} Img={ImgButterfly}/>
                        <AnimButton className="Lake" setTransform={c => `scale(1,${c % 2 == 0 ? 1 : -1})`} style={style} setStyle={setStyle} disabled={loading} Img={ImgBoat}/>
                    </div>
                </div>

                <br />

                <Expand label="Advanced animations" disabled={loading}>
                    <div className="advancedAnimations">
                        <Radiobox option={style} setOption={setStyle} name="Animation" label="4-way animation" id="Ottogram" disabled={loading}/>
                        <Radiobox option={style} setOption={setStyle} name="Animation" label="Rotate by:" id="Custom" disabled={loading}><SelectDropdown
                            selected={custom}
                            setSelected={setCustom}
                            disabled={loading}
                            options={[
                                '120°',
                                '90°',
                                '72°',
                                '60°'
                            ]}
                        /></Radiobox>
                        <Radiobox option={style} setOption={setStyle} name="Animation" label="Invert colours" id="Invert" disabled={loading}/>
                        <Radiobox option={style} setOption={setStyle} name="Animation" label="Change picture" id="Change" disabled={loading} />
                        <Shrinker expanded={style == "Change"} speed={0.2}>
                            <div className="radioMargin">
                                <Button onClick={()=>Modals.create(Change, { change, setChange })} disabled={loading}>
                                    {change ? 'Edit' : 'Set'} second picture
                                </Button>
                            </div>
                        </Shrinker>
                        <Radiobox option={style} setOption={setStyle} name="Animation" label="Custom animation" id="Special" disabled={loading}/>
                        <Shrinker expanded={style == "Special"} speed={0.2}>
                            <div className="radioMargin">
                                <Button onClick={()=>Modals.create(Special, { special, setSpecial, picture:picture.path })} disabled={loading}>
                                    {special && special != 'Special' ? 'Edit' : 'Define'} custom animation
                                </Button>
                            </div>
                        </Shrinker>
                    </div>
                </Expand>

                <br />
                
                {!entry && !challenge && !duel? <>
                    <Expand label="Visibility options" disabled={loading}>
                        <div className="visibility">
                            <Checkbox label="Visible in glyph searches" checked={visGlyphs} setChecked={setVisGlyphs} disabled={loading}/>
                            <Checkbox label="Visible in your profile" checked={visProfile} setChecked={setVisProfile} disabled={loading}/>
                            <Checkbox label="Visible in the main feed" checked={visFeed} setChecked={setVisFeed} disabled={loading}/>
                        </div>
                    </Expand>

                    <br />
                </> : null}
                
                {!noGlyphs ?<>
                    <Input
                        label="Glyphs (e.g. E/E XA/PL M/M)"
                        value={glyphs}
                        setValue={setGlyphs}
                        error={glyphsError}
                        setError={setGlyphsError}
                        validation={validateGlyphs}
                        attempts={c}
                        disabled={loading}
                    />
                    <br />
                </>:null}

                

                <div className="formButtons">
                    <div className="buttons">
                        <Button submit loading={loading}>Submit</Button>
                    </div>
                    <div className="error">
                        {serverError}
                    </div>
                </div>
            </form>
        </div>
    </div>
}