import React, { useState, useContext, useEffect } from 'react';
import { AccountContext, useSubmit } from '../../hooks';
import Button from '../controls/Button';
import ModalHeading from '../modal/ModalHeading';
import UsernamesInput from './UsernamesInput';
import Input from '../controls/Input';
import Expand from '../controls/Expand';

export default function EditChat({ close, chat, callback }) {
    let { user } = useContext(AccountContext);
    let [name, setName] = useState(chat.name);
    let [usernames, setUsernames] = useState(chat.users);
    let [loading, setLoading] = useState(false);
    let [c, submit, onSubmit] = useSubmit();
    let [usernamesError, setUsernamesError] = useState();

    let [nameError, setNameError] = useState();

    onSubmit(async () => {
        if (!usernamesError && !nameError) {
            setLoading(true);
            await callback(usernames, name);
            close();
            setLoading(false);
        }
    })

    useEffect(() => {
        setUsernamesError(usernames.length < 2 ? 'Please add a user!' : null);
    },[usernames])

    return <div className="AddUsers modal">
        <ModalHeading title="Edit chat" close={close} />
        <div className="body">
            <form onSubmit={submit}>
                <Input
                    label="Chat name"
                    value={name}
                    setValue={setName}
                    error={nameError}
                    setError={setNameError}
                    validation={(name) => {
                        name = name ? name.trim() : '';
                        if (name.length > 20) return 'Name too long!';
                    }}
                    attempts={c}
                    disabled={loading}
                />
                <Expand label="Edit users" disabled={loading}>
                    <UsernamesInput disabled={loading} usernames={usernames.filter(u=>u!=user.username)} callback={u => {
                        setUsernames([...u, user.username]);
                    }} />
                </Expand>
                {usernamesError&& c > 0 ? <div className="err">{usernamesError}</div> : <br />}

                <div className="formButtons">
                    <div className="buttons">
                        <Button submit loading={loading} >Save</Button>
                    </div>
                </div>
            </form>
        </div>
    </div>
}
