import React from 'react';
import ModalHeading from '../modal/ModalHeading';

export default function Embed({ close, post }) {
    let maxWidth = post.picture.width > post.picture.height ? 600 : (600 / post.picture.height) * post.picture.width;
    if (maxWidth > post.picture.width) maxWidth = post.picture.width;
    let r = post.picture.height / post.picture.width;
    let style = post.style.substr(0, 7) == 'Special' ? post.style.replace(/\|/g, '_').replace(/\//g, 'x') : post.style;
    let code = '';

    let colour = 'rgb(19, 185, 185)';

    code += '<div style="max-width: '+maxWidth+'px">\r\n';
    code += '  <div style="border: 1px solid '+colour+'; box-sizing: border-box; width: 100%; height: 0px; padding-bottom: calc(' + (100 * r ) + '% - '+(16 * r - 16)+'px); position: relative">\r\n'; 
    code += '    <iframe src="https://ambigr.am/e/' + post.picture.path + '/' + (post.picture.lowres ? post.picture.lowres :  '_') + '/'+style+'/' + post.picture.width + '/' + post.picture.height + '"frameBorder="0"scrolling="no"style="width: 100%; height: 100%; position: absolute; left: 0; top: 0"></iframe>\r\n'; 
    code += '  </div>\r\n'; 
    code += '  <a target="_blank" rel="noopener noreferrer" href="https://ambigr.am' + post.url + '" style="color: white; text-decoration: none">\r\n'
    code += '    <div style="text-align: right; background-color: '+colour+'; font-size: small; font-family: sans-serif; padding: 0.25rem 0.5em">\r\n'
    code += '      <i>Powered by ambigr.am</i>\r\n'
    code += '    </div>\r\n'
    code += '  </a>\r\n'
    code += '</div>'; 

    return <div className="Embed modal">
        <ModalHeading title="Embed post" close={close} />
        <div className="body">
            <p>Copy this code into the HTML of your website</p>
            <div className="code">
                <pre>{code}</pre>
            </div>
        </div>
    </div>
}