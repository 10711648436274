import React, {useState, useContext, useRef, useEffect} from 'react';
import { AccountContext, useAsync } from '../../hooks';
import Input from '../controls/Input';
import axios from 'axios';
import Shrinker from '../controls/Shrinker';
import Username from '../user/Username';

export default function UsernamesInput({ callback, usernames: usernames0, disabled }) {
    let [usernames, setUsernames] = useState(usernames0?usernames0:[]);
    let [username, setUsername] = useState('');
    let [results, setResults] = useState([]);
    let { user } = useContext(AccountContext);
    let ref = useRef();
    let ref1 = useRef();
    let [width, setWidth] = useState(0);
    let [width1, setWidth1] = useState(0);

    useAsync(async () => {
        if (username.length > 0) {
            let { data } = await axios.post('/api/users', {query:username, user});
            setResults(data.filter(u=>![user.username,...usernames].includes(u)));
        } else {
            setResults([]);
        }
    }, [username])

    useEffect(() => {
        callback(usernames);
        setTimeout(() => {
            if (ref.current) {
                let width = ref.current.getBoundingClientRect().width;
                setWidth(width);
            }
            if (ref1.current) {
                let width1 = ref1.current.getBoundingClientRect().width;
                setWidth1(width1);
            }
        },0)
    },[usernames])
    
    function addUsername(username) {
        setUsernames(u => [...u, username]);
        setUsername('');
    }

    return <div className="UsernameInput">
        <Input label="Add user" disabled={disabled} value={username} setValue={setUsername} onKeyDown={e => {
            if (e.key == 'Enter') {
                if (results.length > 0) {
                    addUsername(results[0]);
                }
            } else if (e.key == 'Backspace') {
                if (username.length == 0) {
                    username = usernames.pop();
                    setUsername(username);
                    setUsernames(usernames.slice());
                }
            }
        }}>{usernames.length > 0 ? <div className="usernamesListContainer" ref={ref1}><div className="usernamesList" style={{width, left:width1-width}}>{usernames.map(u => <Username key={u} username={u} unClickable />)}</div></div> : null}</Input>
        <div className="HIDDEN">
            <div className="usernamesList" ref={ref}>{usernames.map(u => <Username key={u} username={u} unClickable />) }</div>
        </div>
        <Shrinker expanded={results.length > 0 && !disabled}>
            <div className="userList">
                {results.map(u => <div className="userListItem" key={u}><Username username={u} onClick={()=>addUsername(u)} /></div>)}
            </div>
        </Shrinker>
    </div>
}
