import React from 'react'
import ProfilePic from './ProfilePic';

export default function ProfilePics({ usernames, width=88 }) {
    let gap = width/4;
    let total = width + (usernames.length - 1) * gap;
    let scale = width / total;
    return <div className="ProfilePics" style={{width, height:width}}>
        {usernames.map((u, i) => <div className="PP" key={i} style={{left:gap*i*scale, top:gap*i*scale}}>
            <ProfilePic alpha={false} width={width * scale} username={u} key={u} square />
        </div>)}
    </div>
}