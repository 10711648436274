import React, { useContext } from 'react';
import { AccountContext, CentreKeyContext, ModalContext, DarkContext, useTitle } from '../../hooks';
import { NavLink } from 'react-router-dom';
import Login from '../forms/Login';
import Signup from '../forms/Signup';
import A2 from '../controls/A2';
import { ImgTrophy, ImgPost, ImgKey, ImgTutorials, ImgUser, ImgSunglasses } from '../../pictures/SVG';
import Btn from '../controls/Btn';
import Contact from '../forms/Contact';

export default function LeftMenu() {
    let { user } = useContext(AccountContext);
    let centreKey = useContext(CentreKeyContext);
    if (centreKey.indexOf('*') >= 0) centreKey = centreKey.split('*')[0];
    let Modals = useContext(ModalContext);
    let { setDocumentTitle } = useTitle();

    let { dark, setDark } = useContext(DarkContext);

    return <div className="LeftMenu">
        <div>
            <ul>
                <li className="link">
                    <NavLink to={'/contest'} className={centreKey == 'Contest' ? "nl noUnderline" : "nl"}>
                        <ImgTrophy />
                        <h2>Contests</h2>
                    </NavLink>
                </li>
                <li className="link">
                    <NavLink to={'/duel-arena'} className={centreKey == 'Duel' ? "nl noUnderline" : "nl"}>
                        <h4>Duel arena</h4>
                    </NavLink>
                </li>
                <li className="link">
                    <NavLink to={'/challenges'} className={centreKey == 'Challenges' ? "nl noUnderline" : "nl"}>
                        {/* <ImgChallenge /> */}
                        <h4>Mini challenges</h4>
                    </NavLink>
                </li>
                <li className="link">
                    <NavLink to={'/hall-of-fame'} className={centreKey == 'HallOfFame' ? "nl noUnderline" : "nl"}>
                        {/* <ImgHalloffame /> */}
                        <h4>Hall of fame</h4>
                    </NavLink>
                </li>
            </ul>
        </div>



        <div>
            <ul>
                <li className="link">
                    <NavLink to={user?'/':'/posts'} onClick={()=>setDocumentTitle('$')} className={centreKey == 'MainFeed' ? "nl noUnderline" : "nl"}>
                        <ImgPost />
                        <h2>Posts</h2>
                    </NavLink>
                </li>
                <li className="link">
                    <NavLink to={'/sketches'} className={centreKey == 'Sketches' ? "nl noUnderline" : "nl"}>
                        <h4>Sketch area</h4>
                    </NavLink>
                </li>
                {user ?<>
                    <li className="link">
                        <NavLink to={'/following'} className={centreKey == 'Following' ? "nl noUnderline" : "nl"}>
                            {/* <ImgHeart /> */}
                            <h4>Following</h4>
                        </NavLink>
                    </li>
                    <li className="link">
                        <NavLink to={'/hearted'} className={centreKey == 'Hearted' ? "nl noUnderline" : "nl"}>
                            {/* <ImgHeart /> */}
                            <h4>Hearted</h4>
                        </NavLink>
                    </li>
                </> : null}
                <li className="link">
                    <NavLink to={'/text'} className={centreKey == 'Text' ? "nl noUnderline" : "nl"}>
                        <h4>Text posts</h4>
                    </NavLink>
                </li>
                <li className="link">
                    <NavLink to={'/search'} className={centreKey == 'Search$' ? "nl noUnderline" : "nl"}>
                        <h4>Search</h4>
                    </NavLink>
                </li>
            </ul>
        </div>


        <div>
            <ul>
                {user ? <>
                    <li className="link">
                        <NavLink to={'/user/'+user.username.toLowerCase()} className={centreKey == 'User$' + user.username.toLowerCase() ? "nl noUnderline" : "nl"}>
                            <ImgUser />
                            <h2>Account</h2>
                        </NavLink>
                    </li>
                    {user ? <>
                        <li className="link">
                            <NavLink to={'/messages'} className={centreKey == 'Messages$' ? "nl noUnderline" : "nl"}>
                                <h4>Messages</h4>
                            </NavLink>
                        </li>
                        <li className="link">
                            <NavLink to={'/performance'} className={centreKey == 'Performance' ? "nl noUnderline" : "nl"}>
                                <h4>Performance</h4>
                            </NavLink>
                        </li>
                    </> : null}
                    <li className="link">
                        <NavLink to={'/advert/' + user.username.toLowerCase()} className={centreKey == 'Advert$' + user.username.toLowerCase() ? "nl noUnderline" : "nl"}>
                            <h4>Your advert</h4>
                        </NavLink>
                    </li>
                    <li className="link">
                        <NavLink to={'/subscription'} className={centreKey == 'Subscription' ? "nl noUnderline" : "nl"}>
                            <h4>Donate</h4>
                        </NavLink>
                    </li>
                </> : <>
                    <li className="link">
                        <A2 className="nl" onClick={()=> Modals.create(localStorage.getItem('happy') ? Login : Signup)}>
                            <ImgKey />
                            <h2>{localStorage.getItem('happy')?"Log in":"Sign up"}</h2>
                        </A2>
                    </li>
                </>}
            </ul>
        </div>

        {user && user.admin ? <div>
            <ul>
                <li className="link">
                    <NavLink to={'/admin'} className={centreKey == 'Admin' ? "nl noUnderline" : "nl"}>
                        <ImgSunglasses />
                        <h2>Admin</h2>
                    </NavLink>
                </li>
                <li className="link">
                    <NavLink to={'/admin/analytics'} className={centreKey == 'AdminAnalytics' ? "nl noUnderline" : "nl"}>
                        <h4>Analytics</h4>
                    </NavLink>
                </li>
                <li className="link">
                    <NavLink to={'/admin/duel-arena'} className={centreKey == 'AdminDuelArena' ? "nl noUnderline" : "nl"}>
                        <h4>Duel arena</h4>
                    </NavLink>
                </li>
                <li className="link">
                    <NavLink to={'/admin/query'} className={centreKey == 'Query' ? "nl noUnderline" : "nl"}>
                        <h4>Query</h4>
                    </NavLink>
                </li>
            </ul>
        </div> : null}


        <div>
            <ul>
                <li className="link">
                    <NavLink to={'/tutorials'} className={centreKey == 'Tutorials' ? "nl noUnderline" : "nl"}>
                        <ImgTutorials />
                        <h2>Tutorials</h2>
                    </NavLink>
                </li>
                <li className="link">
                    <NavLink to={'/glossary'} className={centreKey == 'Glossary' ? "nl noUnderline" : "nl"}>
                        <h4>Glossary</h4>
                    </NavLink>
                </li>
                <li className="link">
                    <NavLink to={'/glyphs'} className={centreKey == 'Glyphs' ? "nl noUnderline" : "nl"}>
                        <h4>Glyphs</h4>
                    </NavLink>
                </li>
            </ul>
        </div>

        <div className="middleOptions">
            <ul>
                <li className="link">
                    <Btn onClick={()=>setDark(d=>!d)}>
                        <h4>{dark?'Light mode':'Dark mode'}</h4>
                    </Btn>
                </li>
                <li className="link">
                    <Btn to="/site-rules">
                        <h4>Site rules</h4>
                    </Btn>
                </li>
                <li className="link">
                    <Btn onClick={()=>Modals.create(Contact)}>
                        <h4>Contact</h4>
                    </Btn>
                </li>
            </ul>
        </div>
        
        {/* <div className="OrderACustomAmbigram">
            <Btn to={'/adverts'} colour={'darkGrey'}>
                <h2>Commission a custom ambigram!</h2>
            </Btn>
        </div> */}
    </div>
}