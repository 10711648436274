import React from 'react';
import AmbigramPanel from '../ambigram/AmbigramPanel';
import UserPanel from '../user/UserPanel';

export default function AmbigramPost({ post, contest3 }) {
    return <div className="Post" >
        <div className="postLeft">
            <UserPanel post={post} contest3={contest3} />
        </div>
        <div className="postRight">
            <AmbigramPanel post={post}/>
        </div>
    </div>
}