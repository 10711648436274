import React, { useState } from 'react';
import ModalHeading from './ModalHeading';
import Button from '../controls/Button';
import P from '../controls/P';

export default function AreYouSure({ title = "Are you sure?", body, callback = () => { }, close }) {
    let [loading, setLoading] = useState(false);
    return <div className="modal AreYouSure">
        <ModalHeading title={title} close={async () => {
            await close();
            callback(false);
        }} />
        <div className="body">
            <P>{body}</P>
            <div className="formButtons">
                <div className="buttons">
                    <Button
                        colour={"blue"}
                        onClick={async () => {
                            setLoading(true);
                            await close();
                            callback(true);
                            setLoading(false);
                        }}
                    >
                        Yes
                    </Button>
                    <Button
                        colour={"red"}
                        onClick={async () => {
                            setLoading(true);
                            await close();
                            callback(false);
                            setLoading(false);
                        }}
                    >
                        No
                    </Button>
                </div>
            </div>
        </div>
    </div>
}