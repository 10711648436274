import React, { useState, useContext } from 'react';
import { AccountContext, useAsync, ModalContext, CurrencyContext } from '../../hooks';
import Spinner from '../Logo/Spinner';
import Button from '../controls/Button';
import axios from 'axios';
import Ad from '../advert/Ad';
import EditAd from '../advert/EditAd';
import ModalMessage from '../modal/ModalMessage';


export default function Advert({ username }) {
    let { user, subbed } = useContext(AccountContext);
    let { currency } = useContext(CurrencyContext);
    let Modals = useContext(ModalContext);

    let [loading, setLoading] = useState(true);
    let [advert, setAdvert] = useState();
    let [profile, setProfile] = useState();

    useAsync(async () => {
        let { data } = await axios.post('/api/promo-profile', { username });
        if (data) {
            setProfile(data.profile);
            setAdvert(data.advert);
        }
        setLoading(false);
    }, [user])

    return <div className="Advert Panel centrepanel">
        {
            loading ? <div className="centreFlex">
                <Spinner/>
            </div> : advert && profile ? <div>
                <Ad advert={advert} setAdvert={setAdvert} profile={profile}/>
            </div> : user && user.username.toLowerCase() == username ? < div >
                <h1>{user.username}</h1>
                <h4>You have not yet set up your advert!</h4>
                <p>You may advertise your ambigram designing skills to visitors of this site. Please list a selection of your best works, and name a base price, so that any potential clients know exactly what they are getting!</p>
                <Button onClick={subbed ? () => Modals.create(EditAd, {
                    advert: {
                        introduction: '',
                        personalPrice: 50,
                        commercialPrice: 100,
                        currency,
                        links: []
                    },
                    callback: async advert => {
                        setAdvert(advert);
                        setLoading(true);
                        let { data } = await axios.post('/api/profile', { username });
                        setProfile(data);
                        setLoading(false);
                    }
                }) : ()=> Modals.create(ModalMessage,{preset:"Donate"})}>Create advert</Button>
            </div> : <div>
                <h1>There's nothing here!</h1>
            </div>
        }
    </div>
}