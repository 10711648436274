import React from 'react';
import Cross from './Cross';
import { useTimeout, useMount } from '../../hooks';
import { NavLink } from 'react-router-dom';

import { ImgStar, ImgError } from '../../pictures/toast/SVG';

export default function Toast({ close, text, type = "star", decay = 5, preset, to }) {

    if (preset == 'server error') {
        type = 'error';
        text = 'Server error! Maybe try again later.';
    }

    let { doTimeout } = useTimeout();
    let { componentDidMount } = useMount();

    componentDidMount(() => {
        if (decay >= 0) doTimeout(close, decay, 's');
    })
    
    let toast = <div className={"toast " + type}>
        {type=='star'?<ImgStar className="icon"/>:type=='error'?<ImgError className="icon"/>:null}
        <div className="text">{text}</div>
        <Cross close={close} />
    </div>

    return to ? <NavLink className="noUnderline" to={to} onClick={close}>{toast}</NavLink> : toast;
}