import React, {useState, useRef, useContext} from 'react';
import { useMount, useForceRender, useTimeout, useGetState, VisibilityContext } from '../../hooks';

import { Img1,Img2 } from '../../pictures/spinner/SVG';

export default function Spinner({ style = "grey", scale = 1, margins }) {
    style = style.toUpperCase();
    let {componentDidMount} = useMount();
    let [height, setHeight] = useState(0);
    let [width, setWidth] = useState(0);
    let [parts, setParts, getParts] = useGetState([]);
    let { doInterval, doTimeout } = useTimeout();
    let [force, forceRender] = useForceRender();
    let direction = useRef(1);
    let count = useRef(0);
    let {getPageVisible} = useContext(VisibilityContext);

    componentDidMount(() => {
        let parts = [
            { x: 18, y: 0, w: 19, h: 16, r: -180, i: 2, R: Img2 },
            { x:  0, y: 0, w: 36, h: 16, r:  180, i: 1, R: Img1 },
        ];
        let w = 36;
        let h = 16;
        parts.forEach(part => {
            part.x *= scale;
            part.y *= scale;
            part.w *= scale;
            part.h *= scale;
        })

        setParts(parts);
        setHeight(h * scale);
        setWidth(w * scale);

        doTimeout(() => {
            rotateAll();
            doInterval(rotateAll, 1.5, 's');
        },50)
    })

    function rotateAll() {
        if (getPageVisible()) {
            count.current++;
            if (count.current % 5 == 0) direction.current *= -1;
            let parts = getParts();
            parts[0].r += 180 * direction.current;
            parts[1].r -= 180 * direction.current;
            setParts(parts);
            forceRender();
        }
    }

    // let Imgs = [Img1, Img2];

    return <div className={'Spinner'} style={{ width, height }}>
        {parts.map(({ x, y, w, h, r, i, R }) => <R key={i}
            className={'img'+i + ' '+style}
            style={{
                width: w,
                left: x,
                top: y,
                transform: `rotate(${r}deg)`,
            }}
        />)}
    </div>
}