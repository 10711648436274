import React, { useState, memo, useEffect } from 'react';
import Button from './Button';
import P5Wrapper from 'react-p5-wrapper';
import ModalHeading from '../modal/ModalHeading';
import { shuffle } from '../../functions';

let Captcha = memo(({setCorrect}) => {
    let animals = ['bees', 'ducklings', 'goldfishes', 'kittens', 'pandas', 'penguins', 'piglets', 'puppies', 'rabbits', 'sloths', 'snails'];

    function randomPic(animal) {
        if (!animal) animal = animals[Math.floor(Math.random() * animals.length)];
        return { animal, src: animal + Math.floor(Math.random() * 11) + '.jpg', s: false, a:0, loading:true };
    }

    function sketch(p) {
        let fontSize = 18;
        let yOffset = fontSize+5;
        let gap = 5;
        let w = 75 * 3 + gap * 4;
        let h = w + yOffset;
        let pictures = [];
        let animal;
        let tick;
        let c = 0;
        p.setup = function () {
            p.createCanvas(w, h);
            p.fill(255, 255, 255);
            p.noStroke();
            p.rect(0, 0, w, h);
            animal = animals[Math.floor(Math.random() * animals.length)];
            let pic = randomPic(animal);
            pictures.push(pic);
            pic = null;
            while (pic == null || pictures.find(p=>p.src==pic.src)) pic = randomPic(animal);
            pictures.push(pic);
            if (Math.random() < 0.5) {
                pic = null;
                while (pic == null || pictures.find(p=>p.src==pic.src)) pic = randomPic(animal);
                pictures.push(pic);
            }
            for (let i = 0; i < 7; i++) {
                pic = null;
                while (pic == null || pictures.find(p=>p.src==pic.src)) pic = randomPic();
                pictures.push(pic);
            }
            shuffle(pictures);
            pictures.forEach(pic => pic.img = p.loadImage('/api/pictures/' + pic.src, () => pic.loading = false));
            tick = p.loadImage('/api/pictures/tick.png');
        }

        p.draw = function () {
            c++;
            pictures.forEach(pic => {
                if (pic.s && pic.a < 5) {
                    pic.a++;
                }
                if (!pic.s && pic.a > 0) {
                    pic.a--;
                }
            })
            p.fill(255, 255, 255);
            p.rect(0, 0, w, h);
            p.fill(0);
            p.textSize(fontSize);
            p.text('Please click all ' + animal, gap, yOffset - 5);
            for (let x = 0; x < 3; x++) {
                for (let y = 0; y < 3; y++) {
                    let i = x * 3 + y;
                    let px = gap + x * (75 + gap);
                    let py = gap + y * (75 + gap) + yOffset;
                    if (pictures[i].loading) {
                        let cx = px + 75 / 2;
                        let cy = py + 75 / 2;
                        let angle = c / 10 + i;
                        p.strokeWeight(3);
                        p.stroke(23, 189, 193);
                        p.noFill();
                        p.arc(cx, cy, 20, 20, angle, angle + 3.14);
                        p.noStroke();
                    } else {
                        p.image(pictures[i].img, px, py);
                        p.stroke(255);
                        p.strokeWeight(pictures[i].a*2);
                        p.fill(255, 255, 255, pictures[i].a * 20);
                        p.rect(px, py, 75, 75);
                        p.noStroke();
                        if (pictures[i].a > 0) {
                            let tw = pictures[i].a * 4;
                            let tx = px + ((130 - tw) / 2);
                            let ty = py + ((130 - tw) / 2);
                            p.image(tick, tx, ty, tw, tw);
                        }
                    }
                }
            }
        }

        p.mouseClicked = function () {
            let selected = -1;
            for (let x = 0; x < 3; x++) {
                for (let y = 0; y < 3; y++) {
                    let i = x * 3 + y;
                    if (!pictures[i].loading) {
                        let px = gap + x * (75 + gap);
                        let py = gap + y * (75 + gap) + yOffset;
                        if (p.mouseX > px && p.mouseX < px + 75) {
                            if (p.mouseY > py && p.mouseY < py + 75) {
                                selected = i;
                                break
                            }
                        }
                    }
                }
                if (selected >= 0) {
                    pictures[selected].s = !pictures[selected].s;
                    break;
                }
            }
            let c = true;
            pictures.forEach(pic => {
                if (pic.animal == animal && !pic.s) c = false;
                if (pic.animal != animal && pic.s) c = false;
            })
            // console.log(c, pictures.map(p => {
            //     return {animal:p.animal, s:p.s}
            // }));
            setCorrect(c);
        }
    }

    return <div className="captcha">
        <P5Wrapper sketch={sketch} />
    </div>
})

export default function CaptchaScreen({ callback, close }) {
    let [correct, setCorrect] = useState(false);
    return <div className="Captcha modal" >
        <ModalHeading title="Are you a robot?" close={async () => {
            await close();
            callback({closed:true})
        }} />
        <div className="body">
            <Captcha setCorrect={setCorrect} />
            <Button onClick={async () => {
                await close();
                callback({correct});
            }}>Submit</Button>    
        </div>
    </div>
}