import React, { useState, useRef, useContext, memo } from 'react';
import axios from 'axios';
import { useAsync, AccountContext, CentreForceRenderContext } from '../../hooks';
import DelaySpinner from '../Logo/DelaySpinner';
import Pagination from '../posts/Pagination';
import AdvertPreview from './AdvertPreview';


export default function AdvertPages({ startingPage }) {
    let [page, setPage] = useState(startingPage ? startingPage : 0);
    let [pages, setPages] = useState(0);
    let [count, setCount] = useState(0);
    let [adverts, setAdverts] = useState([]);
    let [nothing, setNothing] = useState(false);
    let [loading, setLoading] = useState(false);
    let virgin = useRef(true);
    let { user } = useContext(AccountContext);
    let [centreForce, centreForceRender] = useContext(CentreForceRenderContext);

    let top = useRef();

    useAsync(async () => {
        setLoading(true);
        let { data } = await axios.post('/api/promo-page', { page });
        setLoading(false);
        if (!virgin.current && top.current && top.current.getBoundingClientRect().top < 0) {
            top.current.scrollIntoView();
        }
        let { adverts, pages, count } = data;
        setNothing(adverts.length == 0);
        setAdverts(adverts);
        setPages(pages);
        setCount(count);
        virgin.current = false;
    }, [page, user, centreForce])
    
    return nothing ? <h1>There are no adverts, I am ever so sorry!</h1> : <div className="AdvertPages">
        <div className="PagesSpinner top"><DelaySpinner loading={adverts.length == 0}/></div>
        {adverts.length > 0 ? <>
            <div ref={top}>
                <Pagination page={page} pages={pages} setPage={setPage} url={'/adverts/'} />
                <div className="pagesSpinner top"><DelaySpinner loading={loading}/></div>
                <div className={"advertPages" + (pages == 0 ? ' marginTop' : '')} style={{ opacity: loading ? 0 : 1 }} >
                    {adverts.map(advert => <AdvertPreview advert={advert} key={advert._id}/>)}
                </div>
                <div className="pagesSpinner bottom"><DelaySpinner loading={loading}/></div>
                <Pagination page={page} pages={pages} setPage={setPage} url={'/adverts/'}/>
            </div>
        </> : null}
    </div>
}