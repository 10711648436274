import React from 'react';
import Ambigram from '../ambigram/Ambigram';

export default function E(props) {
    let { path, lowres, width, height, style } = props.match.params;
    if (lowres == '_') lowres = undefined;
    if (style.substr(0, 7) == 'Special') style = style.replace(/_/g, '|');
    width = parseInt(width);
    height = parseInt(height);
    let picture = { lowres, path, width, height };
    
    let bounds = 600;
    let w = '100%';
    let h = 'auto';
    let mw = height>width?width/height*bounds:bounds;
    let mh = bounds;
    let border = 8;

    return <div style={{
        width: '100%',
        height: '100vh',
        backgroundColor: 'white'
    }}>
        <Ambigram picture={picture} style={style} bounds={bounds} />
    </div>
}