import React, { useContext } from 'react';
import { AccountContext } from '../../hooks';
import Pages from '../posts/Pages';
import HeartButton from '../ambigram/HeartButton';

export default function Hearted({ page }) {
    let { user } = useContext(AccountContext);
    return <div className="Panel MainFeed centrepanel">
        {user ? <Pages
            mini={true}
            postsPerPage={24}
            hearted
            // query={
            //     {
            //         $or: [
            //             { type: 'Ambigram' },
            //             { type: 'Entry' },
            //             { type: 'ChallengeEntry' },
            //             { type: 'DuelEntry' },
            //         ],
            //         hearts: user.username
            //     }
            // }
            validateHidden={post=>user&&(user.username==post.username || user.admin)&&!post.visFeed}
            startingPage={page}
            url={'/hearted/'}
            noPosts={<div className="heartedNoPosts">
                <h1 style={{ marginTop: '1rem' }}>Your favourite ambigrams will appear here!</h1>
                <p>Click on the heart icon whenever you find an ambigram you like.</p>
                <p>Here - you can practice on this one:</p>
                <div className="centre"><HeartButton/></div>
            </div>}
            sort={{[`heartStamps.${user.username}`]:-1}}
        /> : <h1>You must log in to view your hearted ambigrams!</h1>}
    </div>
}